import { createTheme }      from '@mui/material/styles';

const theme = createTheme({
    palette: {
        APPROVED: '#36AE7C',
        PROPOSED: '#187498',
        CEASED: '#B70404',
        LAND_AND_BUILDING: 'F6F7C4',
        PLANTS_AND_MACHINERY: 'A1EEBD',
        SECURITIES_OR_FINANCIAL_ASSETS: '7BD3EA',
        DEFAULT: 'a0c8e0'
    },
});

export const getChipColor = (status) => {
    // Change the color of the chip based on its value
    switch (status) {
    case 'Appointed':
        return theme.palette.APPROVED;
    case 'Proposed':
        return theme.palette.PROPOSED;
    case 'Rejected':
        return theme.palette.REJECTED;
    case 'Ceased':
        return theme.palette.CEASED;
    default:
        return theme.palette.DEFAULT;
    }
};

export const ServiceProviderRoles = Object.freeze({
    IRP: 'IRP',
    PROPOSED_IRP: 'Proposed IRP',
    RP: 'RP',
    PROPOSED_RP: 'Proposed RP',
    LIQUIDATOR: 'Liquidator',
    PROPOSED_LIQUIDATOR: 'Proposed Liquidator',
    AUTHORIZED_REPRESENTATIVE: 'Authorized Representative',
    PROPOSED_AUTHORIZED_REPRESENTATIVE: 'Proposed Authorized Representative',
    RESOLUTION_VALUER: 'resolutionValuer',
    LIQUIDATION_VALUER: 'liquidationValuer',
    RV: 'RV',
    RVE: 'RVE'
});

export const ServiceProviderEnum = Object.freeze({
    STATUS: 'status',
    PARTY_ROLE_LOCALE :'partyRoleLocale',
    PARTY_RELATIONSHIP_ID :'partyRelationshipId',
    INF: '+999999999-12-31T23:59:59.999999999-18:00',
    PROPOSED: 'Proposed',
    APPOINTED: 'Appointed',
});


const identificationcd = 8
const partycd = 1
const addresscd = 1

export const contactCDs = {
    email:  1,
    phoneNumber: 2
}

const rolesColumns = [
    { 
        id: 'name', 
        label: 'Name',
        align: 'left'},
    {
        id: 'ibbiRegistrationNo',
        label: 'IBBI_Registration_No',
        align: 'left',
    },
    {
        id: 'status',
        label: 'Status',
        align: 'left',
    },
    {
        id: 'proposedDate',
        label: 'Proposed_Date',
        align: 'left',
    },
    {
        id: 'appointmentDate',
        label: 'Appointment_Date',
        align: 'left',
    },
    {
        id: 'cessationDate',
        label: 'Cessation_Date',
        align: 'left',
    },
    {
      id: 'form',
      label: 'Form',
      align: 'left',
    },
    {
      id: 'action',
      label: 'Action',
      align: 'left',
    },
];

const liquidatorColumns = [
  { 
      id: 'name', 
      label: 'Name',
      align: 'left'},
  {
      id: 'ibbiRegistrationNo',
      label: 'IBBI_Registration_No',
      align: 'left',
  },
  {
      id: 'status',
      label: 'Status',
      align: 'left',
  },
  {
      id: 'appointmentDate',
      label: 'Appointment_Date',
      align: 'left',
  },
  {
      id: 'cessationDate',
      label: 'Cessation_Date',
      align: 'left',
  },
  {
    id: 'form',
    label: 'Form',
    align: 'left',
  },
  {
    id: 'action',
    label: 'Action',
    align: 'left',
  },
];

const authrepColumns = [
    { 
        id: 'name', 
        label: 'Name',
        align: 'left',},
    {
        id: 'ibbiRegistrationNo',
        label: 'IBBI_Registration_No',
        align: 'left',
    },
    {
        id: 'status',
        label: 'Status',
        align: 'left',
    },
    {
        id: 'classOfCreditors',
        label: 'Class_Type',
        align: 'left',
    },

    {
        id: 'cessationDate',
        label: 'Cessation_Date',
        align: 'left',
    },
    {
    id: 'form',
    label: 'Form',
    align: 'left',
    },
    {
    id: 'action',
    label: 'Action',
    align: 'left',
    },
];

const afaColumns = [
    { 
        id: 'certificationNo', 
        label: 'AFA Certificate no.',
        minWidth: '20%'
    },
    {
        id: 'validFrom',
        label: 'Valid From',
        minWidth: '18%'
    },
    {
        id: 'validUpto',
        label: 'Valid Upto',
        minWidth: '18%'
    },
    {
        id: 'ipa',
        label: 'IPA',
        minWidth: '30%'
    },
    {
        id: 'actions',
        label: 'Actions',
        align: 'center',
        minWidth: '14%'
    }
]

  const valuerColumns = [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
      minWidth: '24%'
    },
    {
      id: 'valuerType',
      label: 'Valuer Type',
      align: 'left',
      maxWidth: '9%'
    },
    {
        id: 'assetClass',
        label: 'Asset Class',
        align: 'left',
        minWidth: '19%'
    },
    // { 
    //     id: 'registrationNumber', 
    //     label: 'Registration Number',
    //     align: 'left',
    //     minWidth: '15%'
    // },
    {
        id: 'appointmentDate',
        label: 'Appointment Date',
        align: 'left',
        minWidth: '15%'
    },
    {
        id: 'cessationDate',
        label: 'Cessation Date',
        align: 'left',
        minWidth: '15%'
    },
    {
        id: 'actions',
        label: 'Actions',
        align: 'left',
        minWidth: '16.666%'
    }
  ]

  const afaRows = [
    {
      "ipAfaId": 2,
      "partyRelationshipId": 741,
      "ibbiIpaId": 30,
      "ipa": "Indian Institute of Insolvency Professionals of ICAI",
      "certificateNumber": "C0",
      "startTime": "2023-12-18T11:11:28.092Z",
      "endTime": "2023-12-18T11:11:28.092Z",
      "description": "string",
      "rowCreatedOn": "2023-12-19T05:15:20.26076Z"
    },
    {
      "ipAfaId": 2,
      "partyRelationshipId": 741,
      "ibbiIpaId": 30,
      "ipa": "Indian Institute of Insolvency Professionals of ICAI",
      "certificateNumber": "C0",
      "startTime": "2023-12-18T11:11:28.092Z",
      "endTime": "2023-12-18T11:11:28.092Z",
      "description": "string",
      "rowCreatedOn": "2023-12-19T05:15:20.26076Z"
    },
    {
      "ipAfaId": 2,
      "partyRelationshipId": 741,
      "ibbiIpaId": 30,
      "ipa": "Indian Institute of Insolvency Professionals of ICAI",
      "certificateNumber": "C0",
      "startTime": "2023-12-18T11:11:28.092Z",
      "endTime": "2023-12-18T11:11:28.092Z",
      "description": "string",
      "rowCreatedOn": "2023-12-19T05:15:20.26076Z"
    },
    {
      "ipAfaId": 2,
      "partyRelationshipId": 741,
      "ibbiIpaId": 30,
      "ipa": "Indian Institute of Insolvency Professionals of ICAI",
      "certificateNumber": "C0",
      "startTime": "2023-12-18T11:11:28.092Z",
      "endTime": "2023-12-18T11:11:28.092Z",
      "description": "string",
      "rowCreatedOn": "2023-12-19T05:15:20.26076Z"
    },
    {
      "ipAfaId": 2,
      "partyRelationshipId": 741,
      "ibbiIpaId": 30,
      "ipa": "Indian Institute of Insolvency Professionals of ICAI",
      "certificateNumber": "C0",
      "startTime": "2023-12-18T11:11:28.092Z",
      "endTime": "2023-12-18T11:11:28.092Z",
      "description": "string",
      "rowCreatedOn": "2023-12-19T05:15:20.26076Z"
    },
    {
      "ipAfaId": 2,
      "partyRelationshipId": 741,
      "ibbiIpaId": 30,
      "ipa": "Indian Institute of Insolvency Professionals of ICAI",
      "certificateNumber": "C0",
      "startTime": "2023-12-18T11:11:28.092Z",
      "endTime": "2023-12-18T11:11:28.092Z",
      "description": "string",
      "rowCreatedOn": "2023-12-19T05:15:20.26076Z"
    },
    {
      "ipAfaId": 2,
      "partyRelationshipId": 741,
      "ibbiIpaId": 30,
      "ipa": "Indian Institute of Insolvency Professionals of ICAI",
      "certificateNumber": "C0",
      "startTime": "2023-12-18T11:11:28.092Z",
      "endTime": "2023-12-18T11:11:28.092Z",
      "description": "string",
      "rowCreatedOn": "2023-12-19T05:15:20.26076Z"
    },
    {
      "ipAfaId": 2,
      "partyRelationshipId": 741,
      "ibbiIpaId": 30,
      "ipa": "Indian Institute of Insolvency Professionals of ICAI",
      "certificateNumber": "C0",
      "startTime": "2023-12-18T11:11:28.092Z",
      "endTime": "2023-12-18T11:11:28.092Z",
      "description": "string",
      "rowCreatedOn": "2023-12-19T05:15:20.26076Z"
    },
    {
      "ipAfaId": 2,
      "partyRelationshipId": 741,
      "ibbiIpaId": 30,
      "ipa": "Indian Institute of Insolvency Professionals of ICAI",
      "certificateNumber": "C0",
      "startTime": "2023-12-18T11:11:28.092Z",
      "endTime": "2023-12-18T11:11:28.092Z",
      "description": "string",
      "rowCreatedOn": "2023-12-19T05:15:20.26076Z"
    },
    {
      "ipAfaId": 2,
      "partyRelationshipId": 741,
      "ibbiIpaId": 30,
      "ipa": "Indian Institute of Insolvency Professionals of ICAI",
      "certificateNumber": "C0",
      "startTime": "2023-12-18T11:11:28.092Z",
      "endTime": "2023-12-18T11:11:28.092Z",
      "description": "string",
      "rowCreatedOn": "2023-12-19T05:15:20.26076Z"
    }
  ]

const classOfCreditorColumns = [
    { 
        id: 'className', 
        label: 'Class Name',
        align: 'left',},
    {
        id: 'proposedDate',
        label: 'Proposed_Date',
        align: 'left',
    },
    {
        id: 'appointmentDate',
        label: 'Appointed Date',
        align: 'left',
    },
    {
      id: 'appointmentCessationDate',
      label: 'Cessation Date',
      align: 'left',
    },
    {
      id: 'rejectedDate',
      label: 'Rejected Date',
      align: 'left',
    },
    {
        id: 'actions',
        label: 'Actions',
        align: 'left',
    }
  ];

  const valuerAuthrepColumns = [
    { 
        id: 'name', 
        label: 'Name',
        align: 'left'
    },
    { 
        id: 'identification', 
        label: 'Identification',
        align: 'left'
    },
    {
        id: 'assetClass',
        label: 'Asset_Class',
        align: 'left',
    },
    {
        id: 'appointedDate',
        label: 'Appointment Date',
        align: 'left',
    },
    {
      id: 'cessationdDate',
      label: 'Cessation Date',
      align: 'left',
  },
    {
        id: 'action',
        label: 'Action',
        align: 'left',
    },
];

  const classOfCreditorsDetailsRowList = [
    [
      {
        "className": "Class A",
        "Status": "Proposed",
        "startTime": "2023-12-19T05:15:20.26076Z"
      },
      {
        "className": "Class A",
        "Status": "Proposed",
        "startTime": "2023-12-19T05:15:20.26076Z"
      },
      {
        "className": "Class A",
        "Status": "Proposed",
        "startTime": "2023-12-19T05:15:20.26076Z"
      },
      {
        "className": "Class A",
        "Status": "Proposed",
        "startTime": "2023-12-19T05:15:20.26076Z"
      },
      {
        "className": "Class A",
        "Status": "Proposed",
        "startTime": "2023-12-19T05:15:20.26076Z"
      },
      {
        "className": "Class A",
        "Status": "Proposed",
        "startTime": "2023-12-19T05:15:20.26076Z"
      },
      {
        "className": "Class A",
        "Status": "Proposed",
        "startTime": "2023-12-19T05:15:20.26076Z"
      },
      {
        "className": "Class A",
        "Status": "Proposed",
        "startTime": "2023-12-19T05:15:20.26076Z"
      },
      {
        "className": "Class A",
        "Status": "Proposed",
        "startTime": "2023-12-19T05:15:20.26076Z"
      }
    ]
  ]

export const addRoleChooseOptions = [
    { id: 'rp', value: 'RP' },
    { id: 'IRP', value: 'IRP' },
    { id: 'valuerResolution', value: 'Valuer Resolution' },
    { id: 'liquidator', value: 'Liquidator' },
    { id: 'valuerLiquidation', value: 'Valuer Liquidation' },
    { id: 'authRep', value: 'Authorized Representative' },
];

export const serviceProviderRoles = {
  RP: 'RP',
  IRP: 'IRP',
  VALUER_RESOLUTION: 'resolutionValuer',
  LIQUIDATOR: 'Liquidator',
  VALUER_LIQUIDATION: 'liquidationValuer',
  AUTH_REP: 'Authorized Representative',
  RV: 'RV',
  RVE: 'RVE'
};

const valuerAssetClasses = {
  [serviceProviderRoles.RV]: {
    [serviceProviderRoles.VALUER_RESOLUTION]: [
      {
        relationshipCd: 86,
        registeredOn: null,
        registeredValidTo: null,
        assetClass: "PLANT_AND_MACHINERY",
        isChecked: false,
        minDate: null,
        maxDate: null
      },
      {
        relationshipCd: 88,
        registeredOn: null,
        registeredValidTo: null,
        assetClass: "LAND_AND_BUILDING",
        isChecked: false,
        minDate: null,
        maxDate: null
      },
      {
        relationshipCd: 90,
        registeredOn: null,
        registeredValidTo: null,
        assetClass: "SECURITIES_OR_FINANCIAL_ASSETS",
        isChecked: false,
        minDate: null,
        maxDate: null
      }
    ],
    [serviceProviderRoles.VALUER_LIQUIDATION]: [
      {
        relationshipCd: 92,
        registeredOn: null,
        registeredValidTo: null,
        assetClass: "PLANT_AND_MACHINERY",
        isChecked: false,
        minDate: null,
        maxDate: null
      },
      {
        relationshipCd: 94,
        registeredOn: null,
        registeredValidTo: null,
        assetClass: "LAND_AND_BUILDING",
        isChecked: false,
        minDate: null,
        maxDate: null
      },
      {
        relationshipCd: 96,
        registeredOn: null,
        registeredValidTo: null,
        assetClass: "SECURITIES_OR_FINANCIAL_ASSETS",
        isChecked: false,
        minDate: null,
        maxDate: null
      }
    ]
  },
  [serviceProviderRoles.RVE]: {
    [serviceProviderRoles.VALUER_RESOLUTION]: [
      {
        relationshipCd: 87,
        registeredOn: null,
        registeredValidTo: null,
        assetClass: "PLANT_AND_MACHINERY",
        isChecked: false,
        minDate: null,
        maxDate: null
      },
      {
        relationshipCd: 89,
        registeredOn: null,
        registeredValidTo: null,
        assetClass: "LAND_AND_BUILDING",
        isChecked: false,
        minDate: null,
        maxDate: null
      },
      {
        relationshipCd: 91,
        registeredOn: null,
        registeredValidTo: null,
        assetClass: "SECURITIES_OR_FINANCIAL_ASSETS",
        isChecked: false,
        minDate: null,
        maxDate: null
      }
    ],
    [serviceProviderRoles.VALUER_LIQUIDATION]: [
      {
        relationshipCd: 93,
        registeredOn: null,
        registeredValidTo: null,
        assetClass: "PLANT_AND_MACHINERY",
        isChecked: false,
        minDate: null,
        maxDate: null
      },
      {
        relationshipCd: 95,
        registeredOn: null,
        registeredValidTo: null,
        assetClass: "LAND_AND_BUILDING",
        isChecked: false,
        minDate: null,
        maxDate: null
      },
      {
        relationshipCd: 97,
        registeredOn: null,
        registeredValidTo: null,
        assetClass: "SECURITIES_OR_FINANCIAL_ASSETS",
        isChecked: false,
        minDate: null,
        maxDate: null
      }
    ]
  }
}

const rvAssetClassesResolution = [
  {
    relationshipCd: 86,
    registeredOn: "",
    registeredValidTo: null,
    assetClass: "PLANT_AND_MACHINERY",
    cessationDate: "",
  },
  {
    relationshipCd: 88,
    registeredOn: "",
    registeredValidTo: null,
    assetClass: "LAND_AND_BUILDING",
    cessationDate: ""
  },
  {
    relationshipCd: 90,
    registeredOn: "",
    registeredValidTo: null,
    assetClass: "SECURITIES_OR_FINANCIAL_ASSETS",
    cessationDate: ""
  }
]

const rvAssetClassesLiquidation = [
  {
    relationshipCd: 92,
    registeredOn: "",
    registeredValidTo: null,
    assetClass: "PLANT_AND_MACHINERY",
    cessationDate: "",
  },
  {
    relationshipCd: 94,
    registeredOn: "",
    registeredValidTo: null,
    assetClass: "LAND_AND_BUILDING",
    cessationDate: ""
  },
  {
    relationshipCd: 96,
    registeredOn: "",
    registeredValidTo: null,
    assetClass: "SECURITIES_OR_FINANCIAL_ASSETS",
    cessationDate: ""
  }
]

const rveAssetClassesResolution = [
  {
    relationshipCd: 87,
    registeredOn: "",
    registeredValidTo: null,
    assetClass: "PLANT_AND_MACHINERY",
    cessationDate: "",
  },
  {
    relationshipCd: 89,
    registeredOn: "",
    registeredValidTo: null,
    assetClass: "LAND_AND_BUILDING",
    cessationDate: ""
  },
  {
    relationshipCd: 91,
    registeredOn: "",
    registeredValidTo: null,
    assetClass: "SECURITIES_OR_FINANCIAL_ASSETS",
    cessationDate: ""
  }
]

const rveAssetClassesLiquidation = [
  {
    relationshipCd: 93,
    registeredOn: "",
    registeredValidTo: null,
    assetClass: "PLANT_AND_MACHINERY",
    cessationDate: "",
  },
  {
    relationshipCd: 95,
    registeredOn: "",
    registeredValidTo: null,
    assetClass: "LAND_AND_BUILDING",
    cessationDate: ""
  },
  {
    relationshipCd: 97,
    registeredOn: "",
    registeredValidTo: null,
    assetClass: "SECURITIES_OR_FINANCIAL_ASSETS",
    cessationDate: ""
  }
]


  export  { identificationcd, partycd, addresscd, authrepColumns, rolesColumns, classOfCreditorColumns, valuerAssetClasses,
    classOfCreditorsDetailsRowList, afaColumns, afaRows, valuerColumns,
    valuerAuthrepColumns, liquidatorColumns, rvAssetClassesResolution, rvAssetClassesLiquidation, rveAssetClassesResolution, rveAssetClassesLiquidation};


      