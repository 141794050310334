import { Button, Card, CardContent } from "@mui/material"
import { styled } from "@mui/system"

const DashedCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    border: '2px dashed #9dc3eb',
    borderRadius: '15px !important',
    // [theme.breakpoints.down('md')]: {
    //     // minWidth: '300px',
    //     minHeight: '150px',
    //     padding: '10px !important',
    // },
    // [theme.breakpoints.up("md")]: {
    //     // minWidth: '400px',
    //     minHeight: '150px',
    //     padding: '10px !important',
    // },
    // [theme.breakpoints.up("lg")]: {
    //     minWidth: '400px',
    //     minHeight: '200px',
    //     padding: '0px !important'
    // },
    boxShadow: 'none',
    background: 'rgb(243 243 250) !important',
    justifyContent: 'center',
    position: 'sticky',
    top: 0,
}))

const UploadDialogContent = styled(CardContent)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        maxHeight: '400px'
    },
    [theme.breakpoints.up("md")]: {
        maxHeight: '350px'
    },
    [theme.breakpoints.up("lg")]: {
        maxHeight: '330px'
    },
    padding: '15px'
}))

const ButtonStyled = styled(Button)(() => ({
    boxShadow: '0 13px 27px -5px rgba(50,50,93,0.25),0 8px 16px -8px rgba(0,0,0,0.3)',
    // background: '#0a68c5 !important',
    textTransform: 'none !important',
    fontWeight: 'bold !important',
}))

const FileUploadCard = styled(Card)(() => ({
    boxShadow: '0 13px 27px -5px rgba(50,50,93,0.15),0 8px 16px -8px rgba(0,0,0,0.1)',
    '&:hover': {
        // Animation:
    },
    border: '1px solid #eeeeee',
    padding: '1rem',
    // maxWidth: '300px',
    // minWidth: '300px'
}))

export { DashedCard, UploadDialogContent, ButtonStyled, FileUploadCard }