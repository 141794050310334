import { Chip, Grid, Stack, TableBody, TableCell, TableHead, Typography } from "@mui/material";
import moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICMSAxiosInterceptor } from "../../../config/axios.interceptor";
import { IcmsMuiTable, IcmsTableContainer, IcmsTablePagination, IcmsTableRow, StyledTableCell } from "../../generic-styles/NavPageContainerStyles";
import ICMSLoader from "../../icms-loader/ICMSLoader";
import IcmsSnackbar from "../../icms-snackbar/IcmsSnackbar";


const LoginHistory = (props) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [loginHistory, setLoginHistory] = useState([])
    const [loading, setLoading] = useState(false);
    const [tableContainerPosition, setTableContainerPosition] = useState(200);
    const headerRef = useRef();
    const footerRef = useRef();

    const listLoginHistory = async () => {
        ICMSAxiosInterceptor.get(`login-histories?page=${page + 1}&size=${rowsPerPage}`).then(response => {
            if (response) {
                setErrorMessage('');
                setCount(response.count);
                setLoginHistory(response.loginHistories);
            }
            setLoading(false)
        }).catch(error => {
            if (error.message) {
                setErrorMessage(error.message);
            } else {
                setErrorMessage(t('Try_Again'));
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        setLoading(true);
        listLoginHistory();
        setTableContainerPosition(headerRef.current.getBoundingClientRect().bottom + footerRef.current.getBoundingClientRect().height);
    }, [page, rowsPerPage])


    const handleCancel = () => {
        props.setHandleChangePage('details')
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    return (
        <React.Fragment>
            <Stack justifyContent='center' alignItems='center' ref={headerRef}>
                <Typography style={{ fontWeight: 'bold', fontSize: '19px', marginBottom: '1rem' }} data-testid='login-history'>{t("Login_History")}</Typography>
            </Stack>
            <Stack justifyContent='center' alignItems='center' >
                <IcmsTableContainer position={tableContainerPosition}>
                    <IcmsMuiTable stickyHeader aria-label="sticky table">
                        <TableHead>
                            <IcmsTableRow style={{ backgroundColor: '#dff0f1' }}>
                                <TableCell align="left" data-testid='login-date' style={{ width: '30rem', fontWeight: 'bold', color: '#03585d', background: '#dff0f1' }}>
                                    {t('Login_Date_Time')}
                                </TableCell>
                                <TableCell align="center" data-testid='login-status' style={{ width: '30rem', fontWeight: 'bold', color: '#03585d', background: '#dff0f1' }}>
                                    {t('Login_Status')}
                                </TableCell>
                            </IcmsTableRow>
                        </TableHead>
                        <TableBody>
                            {loginHistory
                                .map((row) => (
                                    <IcmsTableRow key={row.loginHistoryId}>
                                        <StyledTableCell align="left" style={{ padding: '10px 20px' }} data-testid='table-date'>
                                            {row.rowCreatedOn === null ? '-' : moment(row.rowCreatedOn).format("DD-MM-YYYY HH:mm:ss")}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" style={{ padding: '10px 20px' }} data-testid='table-success'>
                                            <Chip label={row.successLogin === true ? t('Success') : t('Failed')}
                                                style={row.successLogin === true ? { background: 'rgb(230 252 229)', color: 'green', padding: '0px 1px', height: '28px' } : { background: 'rgb(255 225 224)', color: 'red', padding: '0px 6px', height: '28px' }}
                                            />
                                        </StyledTableCell>
                                    </IcmsTableRow>
                                ))}
                        </TableBody>
                    </IcmsMuiTable>
                </IcmsTableContainer>
            </Stack>
            <Grid item xs={12} ref={footerRef} sx={{ padding: '0px 20px' }}>
                <IcmsTablePagination sx={{ marginBottom: '10px' }} data-testid="guarantor-list-pagination"
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Grid>
            {loading &&
                <ICMSLoader show={loading} invisible={false} />}
            {errorMessage !== '' && errorMessage !== undefined ?
                <IcmsSnackbar show={true} message={errorMessage} severity="error" reset={() => setErrorMessage(null)}></IcmsSnackbar> : null}
        </React.Fragment>
    );
}

export default LoginHistory;