import React, { useState }                      from "react";
import { Grid, AppBar, Tabs }                   from "@mui/material";
import { useTranslation }                       from "react-i18next";
import { useTheme }                             from '@mui/material/styles';
import { BsFilePersonFill }                     from "react-icons/bs";
import { BiSolidContact }                       from "react-icons/bi";
import { StyledTab, TabPanel }                  from '../icms-styled-components/Tab';
import PublicAnnouncementList                   from "./PublicAnnouncementList";
import InvitationOfEOIList                      from "./InvitationOfEOIList";

// icon: <BsFilePersonFill style={{ fontSize: '1rem' }} />,
// icon: <BiSolidContact style={{ fontSize: '1rem' }} />

const tabs = [
    {label: 'File_Claim', icon:'', color: 'rgb(179 106 160)'},
    {label: 'Submit_EOI', icon:'', color: 'rgb(129 146 10)'}
]

const tabProps = (index) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const PublicAnnoucements = ({errorMessage, setLoading}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    }

    return (
        <div>
            <Grid
                container
                sx={{ padding: '5px 15px 5px 15px !important' }}
                direction="row"
                alignItems="center"
            >
                <AppBar position="static" color="default">
                    <Tabs
                        value={tab}
                        onChange={handleTabChange}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: tabs[tab]?.color || 'rgb(196 151 104)',
                                height: '6px',
                                color: tabs[tab]?.color || 'rgb(196 151 104)'
                            }
                        }}
                        aria-label="creditor details tabs"
                    >
                        {
                            tabs.map((tab, index) => {
                                return <StyledTab key={tab.label} iconPosition='start' icon={tab.icon} 
                                    label={t(tab.label)} {...tabProps(index)} sx={{minHeight: '50px'}}
                                    color={tab.color}/>
                            })
                        }
                    </Tabs>
                </AppBar>
                <TabPanel value={tab} index={0} dir={theme.direction} borderColor={tabs[0].color}>
                    <PublicAnnouncementList errorMessage={errorMessage} setLoading={setLoading}/>
                </TabPanel>
                <TabPanel value={tab} index={1} dir={theme.direction} borderColor={tabs[1].color}>
                    <InvitationOfEOIList errorMessage={errorMessage} setLoading={setLoading}/>
                </TabPanel>
            </Grid>
        </div>
    )
}

export default PublicAnnoucements