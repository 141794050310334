import { CalendarMonth, Delete, Edit }          from "@mui/icons-material"
import { IconButton, Stack, 
    Typography, Tooltip }                       from "@mui/material"
import { Fragment, useContext }                 from "react"
import { FaRupeeSign }                          from "react-icons/fa"
import { Currency, CurrencyFormatter, CurrencyFormatterWithOutSymbol }          from "../../../../../common/utils/Utils"
import moment                                   from "moment"
import { useTranslation }                       from "react-i18next"
import BusinessCenterIcon                       from '@mui/icons-material/BusinessCenter';
import { CaseRoles, Roles }                     from "../../../../../common/GenericCodes";
import { CaseContext }                          from '../../../../../common/context/case/CaseContext';
import { UserContext }                          from "../../../../../common/context/UserContext"

const EmployeeWorkmanClaimView = (props) => {
    const { t } = useTranslation();
    const { contextValue } = useContext(CaseContext);
    const userDetails = useContext(UserContext);
    const { handleDelete, claim, index, claimSubmission, canViewBook, onClickAdmission, canEdit } = props;
    const isEnkAdmin = userDetails.loginDetails?.subscriberRole?.includes(Roles.enkAdmin.roleValue);
    const isEnkSupport = userDetails.loginDetails?.subscriberRole?.includes(Roles.enkSupport.roleValue);

    return (
        <Fragment>
            <Stack direction="row" spacing={6} alignItems='center' width='100%'>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <CalendarMonth style={{ fontSize: '1.5rem', color: '#d4d4d6' }} />
                    <Stack direction='column' spacing={0.2}>
                        <Typography variant='caption' color="GrayText">{t('Default_From')}:</Typography>
                        <Typography variant='body1' color="textSecondary" sx={{ fontWeight: 'bold' }}>
                            {moment(new Date(claim.claimTime)).format('DD/MM/YYYY')}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <CalendarMonth style={{ fontSize: '1.5rem', color: '#d4d4d6' }} />
                    <Stack direction='column' spacing={0.2}>
                        <Typography variant='caption' color="GrayText">{t('Default_To')}:</Typography>
                        <Typography variant='body1' color="textSecondary" sx={{ fontWeight: 'bold' }}>
                            {moment(new Date(claim.claimToTime)).format('DD/MM/YYYY')}
                        </Typography>
                    </Stack>
                </Stack>
                {claimSubmission && <Fragment>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <FaRupeeSign style={{ fontSize: '1.5rem', color: '#d4d4d6' }} />
                        <Stack direction='column' spacing={0.2}>
                            <Typography variant='caption' color="GrayText">{t('Principal_Amount_Rs')}:</Typography>
                            <Typography variant='body1' color="textSecondary" sx={{ fontWeight: 'bold' }}>
                                {CurrencyFormatterWithOutSymbol(Currency.INR, claim.cprincipal)}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <FaRupeeSign style={{ fontSize: '1.5rem', color: '#d4d4d6' }} />
                        <Stack direction='column' spacing={0.2}>
                            <Typography variant='caption' color="GrayText">{t('Interest_Amount_Rs')}:</Typography>
                            <Typography variant='body1' color="textSecondary" sx={{ fontWeight: 'bold' }}>
                                {CurrencyFormatterWithOutSymbol(Currency.INR, claim.cinterest)}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <FaRupeeSign style={{ fontSize: '1.5rem', color: '#d4d4d6' }} />
                        <Stack direction='column' spacing={0.2}>
                            <Typography variant='caption' color="GrayText">{t('Total_Claim_Amount_Rs')}:</Typography>
                            <Typography variant='body1' color="textSecondary" sx={{ fontWeight: 'bold' }}>
                                {CurrencyFormatterWithOutSymbol(Currency.INR, claim.totalClaim)}
                            </Typography>
                        </Stack>
                    </Stack>
                </Fragment>}
                {!claimSubmission && <Fragment>
                    {canViewBook && <Stack direction='row' spacing={2} alignItems='center'>
                        <FaRupeeSign style={{ fontSize: '1.5rem', color: '#d4d4d6' }} />
                        <Stack direction='column' spacing={0.2}>
                            <Typography variant='caption' color="GrayText">{t('Corp_Book_Amount_Rs')}:</Typography>
                            <Typography variant='body1' color="textSecondary" sx={{ fontWeight: 'bold' }}>
                                {CurrencyFormatterWithOutSymbol(Currency.INR, claim.totalBook)}
                            </Typography>
                        </Stack>
                    </Stack>}
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <FaRupeeSign style={{ fontSize: '1.5rem', color: '#d4d4d6' }} />
                        <Stack direction='column' spacing={0.2}>
                            <Typography variant='caption' color="GrayText">{t('Claim_Amount_Rs')}:</Typography>
                            <Typography variant='body1' color="textSecondary" sx={{ fontWeight: 'bold' }}>
                                {CurrencyFormatterWithOutSymbol(Currency.INR, claim.totalClaim)}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <FaRupeeSign style={{ fontSize: '1.5rem', color: '#d4d4d6' }} />
                        <Stack direction='column' spacing={0.2}>
                            <Typography variant='caption' color="GrayText">{t('Admitted_Amount')}:</Typography>
                            <Typography variant='body1' color="textSecondary" sx={{ fontWeight: 'bold' }}>
                                {CurrencyFormatterWithOutSymbol(Currency.INR, claim.totalAdmit)}
                            </Typography>
                        </Stack>
                    </Stack>
                </Fragment>}
            </Stack>
            {canEdit && <Tooltip title={t('Edit')}>
                <IconButton color='primary' sx={{ float: 'right' }}><Edit /></IconButton>
            </Tooltip>}
            {(isEnkAdmin || isEnkSupport || contextValue?.roles?.includes(CaseRoles.ROLE_CASE_MANAGER) || 
                    contextValue?.roles?.includes(CaseRoles.ROLE_CASE_WORKER)) && canEdit && claim.totalAdmit === 0 &&<Tooltip title={t('Claim_Admission')}>
                <IconButton color='success' sx={{ float: 'right' }} 
                        onClick={(event) => onClickAdmission(event, claim)}><BusinessCenterIcon /></IconButton>
            </Tooltip>}
            {canEdit && <Tooltip title={t('Delete')}>
                <IconButton color='error' sx={{ float: 'right' }} onClick={(event) => handleDelete(event, claim.debtDefaultId, index)}>
                    <Delete /></IconButton>
            </Tooltip>}
        </Fragment>
    )
}

export { EmployeeWorkmanClaimView }