import { Person } from "@mui/icons-material";
import BusinessIcon from '@mui/icons-material/Business';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

export const AssetSideValue = {
    DEBTOR_ASSETS: 'asset-debtorAssets',
    GUARANTOR_ASSETS: 'asset-guarantorAssets',
    VALUATION_AT_RESOLUTION: 'asset-valuationAtResolution',
    VALUATION_AT_LIQUIDATION: 'asset-valuationAtLiquidation'
}

// left navigation order will take as in this list
export const AssetSideMenu = [
    {
        id: 'debtor-assets',
        value: AssetSideValue.DEBTOR_ASSETS,
        title: 'Debtor_Assets',
        icon: <BusinessIcon />
    },
    // {
    //     id: 'guarantor-assets',
    //     value: AssetSideValue.GUARANTOR_ASSETS,
    //     title: 'Guarantor_Assets',
    //     icon: <Person />
    // },
    {
        id: 'valuation-at-resolution',
        value: AssetSideValue.VALUATION_AT_RESOLUTION,
        title: 'Valuation_At_Resolution',
        icon: <CurrencyRupeeIcon />
    },
    {
        id: 'valuation-at-liquidation',
        value: AssetSideValue.VALUATION_AT_LIQUIDATION,
        title: 'Valuation_At_Liquidation',
        icon: <CurrencyRupeeIcon />
    }
]

export const CaseStageValuer = {
    [AssetSideValue.VALUATION_AT_RESOLUTION]: 'rp-valuer',
    [AssetSideValue.VALUATION_AT_LIQUIDATION]: 'lp-valuer',
    2: 'rp-valuer',
    3: 'lp-valuer'
}