import React, { 
    Fragment, useEffect, 
    useRef, useState,
}                                           from 'react';
import Tooltip                              from '@mui/material/Tooltip';
import { Edit, Add, Visibility }            from '@mui/icons-material';
import DeleteIcon                           from '@mui/icons-material/Delete';
import moment                               from 'moment';
import {
  Grid, TableBody,
  TableHead,Typography,
  IconButton, Box
}                                           from "@mui/material";
import { useTranslation }                   from 'react-i18next';
import {
    IcmsTableContainer,
    IcmsTablePagination, 
    StyledTableCell, 
    IcmsTableRow, 
    IcmsMuiTable 
}                                           from '../../../../../../common/generic-styles/NavPageContainerStyles';
import { afaColumns }                       from '../ServiceProviderStaticVariables';
import { ICMSAxiosInterceptor }             from '../../../../../../config/axios.interceptor';
import AuthorisationForAssignmentDetails    from './AuthorisationForAssignmentDetails';
import ICMSConfirmDialog                    from '../../../../../../common/ConfirmationDialog/ICMSConfirmDialog';
import IcmsSnackbar                         from '../../../../../../common/icms-snackbar/IcmsSnackbar';
import ICMSLoader                           from '../../../../../../common/icms-loader/ICMSLoader';   
import { ICMSButton }                       from '../../../../../../common/icms-styled-components/IcmsStyledComponents';

const AuthorisationForAssignmentList = (props) => {
    const { isEdit, ipaIpRelationId } = props;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [createAfa, setCreateAfa] = useState(false);
    const [editAfa, setEditAfa] = useState(false)
    const tableContainerPosition = 200;
    const [afaList, setAfaList] = useState([])
    const [ afaRowDetails, setAfaRowDetails] = useState([])
    const [message, setMessage] = useState({
        show    : false,
        message : null,
        severity: null
    })

    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: ''
    })

    const handeleCreateAfa = () => {
        setAfaRowDetails({
            open: true,
            data: {}
        });
        setCreateAfa(true);
    }

    const handleDeleteAFA = (event, row) => {
        setSeekConfirmation({
            show: true,
            title: t("Confirmation"),
            message: <Fragment>{t('Are_You_Sure_That_You_Want_To_Delete')} <b>{row.certificateNumber}</b> {' ?'}</Fragment>,
            onDisAgree: resetConfirmation,
            onAgree: () => {
                handleDeleteRequest(row);
                resetConfirmation();
            }
        })
    }

    const resetConfirmation = () => {
        setSeekConfirmation({
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: ''
        })
    }

    const handleDeleteRequest = (data) => {
        setLoading(true)
        ICMSAxiosInterceptor.delete(`/ip/afa/${data.ipAfaId}`).then(response => {
            if (response) {
                getAFAList();
                setMessage({ show: true, message: t("AFA_Has_been_Deleted_Successfully"), severity: 'success' });
            }
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            setMessage({ show: true, message: error.message, severity: 'error' });
        });
    }

    const handleEditAFA = (event, data) => {
        event.stopPropagation();
        setAfaRowDetails({
            open: true,
            data: data
        });
        setEditAfa(true);
    }

    const handleCloseAfa = (msg) => {
        setAfaRowDetails({
            open: false,
            data: {}
        });
        getAFAList();
        if(msg) {
            setMessage({ show: msg.show, message: msg.message, severity: msg.severity })
        }
        setEditAfa(false);
        setCreateAfa(false);
        
    }
    
    const handleOpenAfa = (event, data) => {
        event.stopPropagation()
        setAfaRowDetails({
            open: true,
            data: data
        });
    }

    const getAFAList = () => {
        setLoading(true)
        if(ipaIpRelationId){
            ICMSAxiosInterceptor.get(`/ip/afa/list/${ipaIpRelationId}`).then(response => {
                if (response) {
                    setAfaList(response);
                    setLoading(false);
                }
            }).catch(error => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            });
        } else {
            ICMSAxiosInterceptor.get(`/ip/afa/list`).then(response => {
                if (response) {
                    setAfaList(response);
                    setLoading(false);
                }
            }).catch(error => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            });
        } 
    }

    useEffect(() => {
        getAFAList();
    }, [])

    return (
        <div>
            <Box component="main" sx={{ flexGrow: 1, width: '100%' }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="baseline" sx={{ padding: '0px 20px'}}>
                    <Grid item>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }} className="afa-title">{t('AFA')}</Typography>
                    </Grid>
                    { isEdit &&
                        <Grid item>
                            <ICMSButton id="add-afa" data-testid="add-afa" variant="contained" startIcon={<Add />} 
                                    size='small' onClick={handeleCreateAfa}>
                                {t('Add_Afa')}
                            </ICMSButton>
                        </Grid>
                    }
                </Grid>
                <Grid container direction="column" alignItems="baseline">
                    <Grid item sx={{width: '100%'}}>
                        <IcmsTableContainer position={tableContainerPosition}>
                            <IcmsMuiTable stickyHeader aria-label="sticky table" data-testid="Afa-list-table">
                                <TableHead>
                                    <IcmsTableRow>
                                        {afaColumns.map((column) => (
                                            <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, padding: '8px' }}>
                                                {t(column.label)}
                                            </StyledTableCell>
                                        ))}
                                    </IcmsTableRow>
                                </TableHead>
                                <TableBody>
                                    {afaList.map((row, index) => {
                                            const checkNull = data => (data !== null && data !== undefined ? data : '--');
                                            return (
                                            <IcmsTableRow key={index} hover role="checkbox" tabIndex={-1}>
                                                <StyledTableCell sx={{padding: '8px'}} onClick={(event) => handleOpenAfa(event, row)}>{checkNull(row.certificateNumber)}</StyledTableCell>
                                                <StyledTableCell sx={{padding: '8px'}} onClick={(event) => handleOpenAfa(event, row)}>{row.startTime ? moment(row.startTime).format('DD-MM-YYYY') : '--'}</StyledTableCell>
                                                <StyledTableCell sx={{padding: '8px'}} onClick={(event) => handleOpenAfa(event, row)}>{row.endTime ? moment(row.endTime).format('DD-MM-YYYY') : '--'}</StyledTableCell>
                                                <StyledTableCell sx={{padding: '8px'}} onClick={(event) => handleOpenAfa(event, row)}>{checkNull(row.ipa)}</StyledTableCell>
                                                <StyledTableCell sx={{textAlign: 'center', padding: '8px'}}>
                                                    { isEdit &&
                                                        <Tooltip title="Edit">
                                                            <IconButton aria-label="edit" onClick={(event) => handleEditAFA(event, row)} data-testid = "edit-button">
                                                                <Edit color='primary'/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                        <Tooltip title="view">
                                                            <IconButton aria-label="view" onClick={(event) => handleOpenAfa(event, row)} data-testid = "view-button">
                                                                <Visibility color='primary' />
                                                            </IconButton>
                                                        </Tooltip>
                                                    { isEdit &&
                                                        <Tooltip title="Delete">
                                                            <IconButton aria-label="delete" onClick={(event) => handleDeleteAFA(event, row)} data-testid = "delete-button">
                                                                <DeleteIcon color='error' />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }                                                         
                                                </StyledTableCell>
                                            </IcmsTableRow>
                                        );
                                    })}
                                </TableBody>
                            {(!loading && afaList.length === 0) && (
                                <IcmsTableRow>
                                    <StyledTableCell colSpan={8}>{t('No_Records_Found')}</StyledTableCell>
                                </IcmsTableRow>
                            )}
                            </IcmsMuiTable>
                        </IcmsTableContainer>
                    </Grid>
                </Grid>
            </Box>
        { afaRowDetails.open && 
            <AuthorisationForAssignmentDetails
                afaRowDetails={afaRowDetails}
                handleCloseAfa={handleCloseAfa}
                isEdit={editAfa}
                isCreate={createAfa}
                ipaIpRelationId={ipaIpRelationId}
            />
        }   
        {loading && <ICMSLoader show={loading}/>} 
        {seekConfirmation.show && 
            <ICMSConfirmDialog show={seekConfirmation.show} title={seekConfirmation.title} message={seekConfirmation.message}
                onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} />
        }
        { message.show && 
            <IcmsSnackbar show={message.show} severity={message.severity} message={message.message}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: ''})}
            />
        }
        </div>
  );
}

export default AuthorisationForAssignmentList;
