import { NavigateBeforeRounded, NavigateNextRounded }   from "@mui/icons-material"
import { Button, Card, Stack, Tooltip }                 from "@mui/material"
import { useFormik }                                    from "formik"
import { Fragment, useEffect, useState }                from "react"
import { BsCheckLg }                                    from "react-icons/bs"
import { IoClose }                                      from "react-icons/io5"
import { useNavigate, useParams }                       from "react-router-dom"
import { CreditorsType, IdentificationType }            from "../../../../common/StaticVariables"
import ICMSLoader2                                      from "../../../../common/icms-loader/icms-loader-2.O/ICMSLoader2"
import IcmsSnackbar                                     from "../../../../common/icms-snackbar/IcmsSnackbar"
import { 
    createClaimant, 
    getClaimant
}                                                       from "../../file-claim-services/FileClaimServices"
import { RelationshipCd }                               from "../FileClaimGenericCodes"
import { CreditorDetails }                              from "./CreditorDetails";
import UpdateCreditorDetails                            from './UpdateCreditorDetails';
import { CreditorValidationSchema }                     from "./CreditorYupSchema"
import { useTranslation }                               from "react-i18next"
import { Section }                                      from "../../../../common/GenericCodes";
import { ICMSButton }                                   from '../../../../common/icms-styled-components/IcmsStyledComponents';

const Creditor = (props) => {
    const { t } = useTranslation();
    const { handlePrevious, handleNext, totalSteps, width, left, type, caseId } = props;
    const param = useParams();
    const isHome = param.isHome === "true";
    const relationshipCd = Number(param.cd);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ show: false, message: '', severity: '' });
    
    const creditorFormik = useFormik({
        initialValues: {
            claimantId: null,
            relationshipCd: relationshipCd,
            partyCd: RelationshipCd.EMPLOYEE_CLAIMANT === relationshipCd || 
                RelationshipCd.WORKMAN_CLAIMANT === relationshipCd ? CreditorsType.INDIVIDUAL : CreditorsType.COMPANY,
            partyLocale: RelationshipCd.EMPLOYEE_CLAIMANT === relationshipCd || 
                RelationshipCd.WORKMAN_CLAIMANT === relationshipCd ? 'First' : 'Company',
            identificationCd: RelationshipCd.EMPLOYEE_CLAIMANT === relationshipCd || 
                RelationshipCd.WORKMAN_CLAIMANT === relationshipCd ? IdentificationType.PAN : IdentificationType.CIN,
            name: '',
            restOfName: '',
            identification: '',
            email: '',
            phoneNumber: '',
            extension: '+91',
            dobDoi: null,
            secured: null,
            realtedParty: null,
            natureOfRelatedParty: '',
            relatedUnderSection: null,
            amountOwedToDebtor: 0,
            contigent: 0,
            mutualDuesSetOff: 0,
            creditorClasses: null,
            addressCd: 2,
            addressLine1: '',
            addressLine2: '',
            cityOrTown: '',
            state: '',
            postalCode: '',
            country: 'India',
            blockClaimModification: false,
            description: '',
            serialNumber: '',
            retentionOfTitleArrangement: '',
            regular: true,
            preferredAuthorizedRepresentativeId: '',
            preferredAuthorizedRepresentative: '',
            voteRight: '',
            loginUid: ''
        },
        onSubmit: () => {
            setLoading(true);
            handleCreateUpdate();
        },
        validationSchema: CreditorValidationSchema,
    })

    const handleReset = () => {
        creditorFormik.handleReset();
    }

    const handleCreateUpdate = () => {
        let payload = {
            relationshipCd: creditorFormik.values.relationshipCd,
            partyCd: creditorFormik.values.partyCd,
            partyLocale: creditorFormik.values.partyLocale,
            identificationCd: creditorFormik.values.identificationCd,
            name: creditorFormik.values.name,
            restOfName: creditorFormik.values.restOfName,
            identification: creditorFormik.values.identification,
            email: creditorFormik.values.email,
            phoneNumber: creditorFormik.values.extension +''+ creditorFormik.values.phoneNumber,
            dobDoi: creditorFormik.values.dobDoi,
            secured: creditorFormik.values.secured,
            realtedParty: creditorFormik.values.realtedParty,
            natureOfRelatedParty: creditorFormik.values.natureOfRelatedParty,
            relatedUnderSection: creditorFormik.values.relatedUnderSection ? Section.SECTION_21_2 : '',
            amountOwedToDebtor: creditorFormik.values.amountOwedToDebtor,
            contigent: creditorFormik.values.contigent,
            mutualDuesSetOff: creditorFormik.values.mutualDuesSetOff,
            creditorClasses: creditorFormik.values.creditorClasses ? [creditorFormik.values.creditorClasses] : [],
            addressCd: creditorFormik.values.addressCd,
            addressLine1: creditorFormik.values.addressLine1,
            addressLine2: creditorFormik.values.addressLine2,
            cityOrTown: creditorFormik.values.cityOrTown,
            state: creditorFormik.values.state,
            postalCode: creditorFormik.values.postalCode,
            country: creditorFormik.values.country,
            blockClaimModification: creditorFormik.values.blockClaimModification,
            description: creditorFormik.values.description,
            serialNumber: creditorFormik.values.serialNumber,
            retentionOfTitleArrangement: creditorFormik.values.retentionOfTitleArrangement,
            regular: creditorFormik.values.regular,
            preferredAuthorizedRepresentativeId: creditorFormik.values.preferredAuthorizedRepresentativeId,
            preferredAuthorizedRepresentative: creditorFormik.values.preferredAuthorizedRepresentative,
            voteRight: creditorFormik.values.voteRight,
            loginUid: creditorFormik.values.loginUid
        }
        createClaimant(param.caseId, payload,
            (response) => {
                let isIndividual = CreditorsType.INDIVIDUAL === creditorFormik.values.partyCd;
                navigate(`/details/file-claim/${Number(param.caseId)}/${Number(param.cd)}/${Number(param.activeStep)}/${response.claimantId}/${param.isHome}/${isIndividual}`, {
                    state: {
                        claimantRelationShipCd: Number(param.cd),
                        isHome: isHome
                    }
                })
                setLoading(false);
                setMessage({ show: true, message: t('Creditor_Created_Succesfully'), severity: 'success' });
            },
            (error) => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            }
        )
    }

    const getCreditor = () => {
        setLoading(true);
        getClaimant(Number(param.caseId), Number(param.id), (response) => {
                creditorFormik.setValues(response);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            }
        )
    }

    useEffect(() => {
        if (param.id !== 'new') {
            getCreditor();
        }
    }, [param])

    return (
        <Fragment>
            {/* Content */}
            {!creditorFormik.values.claimantId && <CreditorDetails creditorFormik={creditorFormik} type={type}/>}
            {creditorFormik.values.claimantId && <UpdateCreditorDetails creditorFormik={creditorFormik} />}
            {/* Actions */}
            <Card sx={{ bottom: 0, position: 'fixed', width: width, borderTop: '2px solid #eeeeee', left: left }}>
                <Stack justifyContent='space-between' direction="row">
                    <ICMSButton startIcon={<NavigateBeforeRounded className='back-icon' />} sx={{ float: 'left', mx: 5, my: 1 }}
                        onClick={handlePrevious} variant="outlined" color='primary' size='small'>{'Back'}
                    </ICMSButton>
                    <span>
                        {!creditorFormik.values.claimantId && creditorFormik.dirty &&
                            <ICMSButton startIcon={<IoClose />} sx={{ float: 'center', mr: 3, my: 1 }} onClick={handleReset} variant="outlined" color='primary'
                                    size='small'>
                                {t('Cancel')}
                            </ICMSButton>}
                        {!creditorFormik.values.claimantId && creditorFormik.dirty &&
                            <ICMSButton startIcon={<BsCheckLg />} sx={{ float: 'center', mr: 3, my: 1 }} onClick={creditorFormik.handleSubmit} variant="contained" color='primary'
                                    disabled={!creditorFormik.isValid} size='small'>
                                {t('Save')}
                            </ICMSButton>}
                    </span>
                    {param.activeStep < totalSteps &&
                        <Tooltip title={t('Create_Creditor_To_Enable')} disableHoverListener={!(param.id === 'new')} placement="top-start">
                            <ICMSButton endIcon={<NavigateNextRounded className={'next-icon'} />} sx={{ float: 'right', mr: 5, my: 1 }} onClick={handleNext} variant="contained" color='primary'
                                disabled={param.id === 'new'} size='small'>
                                {t('Next')}
                            </ICMSButton>
                        </Tooltip>}
                </Stack>
            </Card>
            {loading && <ICMSLoader2 show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}
            />}
        </Fragment>
    )
}

export default Creditor;