import CloseIcon                                    from '@mui/icons-material/Close';
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, 
    Grid, IconButton, Stack, Tooltip, Typography }  from '@mui/material';
import { motion }                                   from 'framer-motion';
import React, { useEffect, useState }               from 'react';
import { useTranslation }                           from 'react-i18next';
import { FaChevronLeft, FaChevronRight }            from 'react-icons/fa';
import { ICMSAxiosInterceptor }                     from '../../../config/axios.interceptor';
import { ICMSTheme }                                from '../../../theme/Theme';
import ICMSLoader                                   from '../../icms-loader/ICMSLoader';
import { ICMSButton } from '../../icms-styled-components/IcmsStyledComponents';

const BankDetailsPage = (props) => {
    const { data, bankListData, handleClose, setMessage } = props;
    const { t } = useTranslation();
    const [slideDirection, setSlideDirection] = useState('left');
    const [pageIndex, setPageIndex] = useState(data.rowIndex);
    const [listData, setListData] = useState([])
    const [loading, setLoading] = useState(false);

    const displayNextRow = () => {
        setPageIndex((prevIndex) => (prevIndex === bankListData.length - 1 ? 0 : prevIndex + 1));
        setSlideDirection('right');
    };

    const displayPrevRow = () => {
        setPageIndex((prevIndex) => (prevIndex === 0 ? bankListData.length - 1 : prevIndex - 1));
        setSlideDirection('left');
    };

    useEffect(() => {
        getBankDetails()
    }, [pageIndex])

    const getBankDetails = () => {
        setListData([])
        setLoading(true)
        ICMSAxiosInterceptor.get(`bank-account/${bankListData[pageIndex].bankAccountUuid}`).then((response) => {
            setListData(response)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            setMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

    return (
            <Dialog open={data.open} fullWidth maxWidth='lg'>
                <DialogTitle>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography data-testid='bank-detail' variant='h6'>{t("Bank_Details")}</Typography>
                        <IconButton onClick={() => handleClose(false)} color='error' data-testid='close-button'>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <motion.div initial={slideDirection === 'right' ? { x: '100%' } : { x: '-100%' }}
                    animate={{ x: '0%' }}
                    exit={slideDirection === 'right' ? { x: '-100%' } : { x: '100%' }}
                    transition={{ duration: 0.5 }}
                    key={pageIndex}>
                    <DialogContent dividers style={{padding:'16px'}}>
                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                            <Tooltip title='Previous' arrow placement='right'>
                                <IconButton onClick={displayPrevRow} data-testid='prev' disabled={pageIndex === 0} style={{ color: pageIndex === 0 ? '' : ICMSTheme.palette.primary.main, paddingLeft:'0px' }}>
                                    <FaChevronLeft />
                                </IconButton>
                            </Tooltip>
                             <Grid container spacing={2} >
                            <Grid item xs={12} lg={4} md={4} sm={12}>
                                 <Typography sx={{ fontSize: (theme) => theme.typography.body2 }}>{t("Account_Holder_Name")}</Typography>
                                 <Card style={{ padding: '10px', border: '1px solid rgb(230 227 227)', borderRadius: '5px', color: 'gray' }}>{listData.accountHolderName}</Card>
                             </Grid>
                             <Grid item xs={12} lg={4} md={4} sm={12}>
                                 <Typography sx={{ fontSize: (theme) => theme.typography.body2 }}>{t("Account_Number")}</Typography>
                                 <Card style={{ padding: '10px', border: '1px solid rgb(230 227 227)', borderRadius: '5px', color: 'gray' }}>{listData.accountNumber}</Card>
                             </Grid>
                             <Grid item xs={12} lg={4} md={4} sm={12}>
                                 <Typography sx={{ fontSize: (theme) => theme.typography.body2 }}>{t("Account_Type")}</Typography>
                                 <Card style={{ padding: '10px', border: '1px solid rgb(230 227 227)', borderRadius: '5px', color: 'gray' }}>{listData.bankAccountLocale}</Card>
                             </Grid>
                             <Grid item xs={12} lg={4} md={4} sm={12}>
                                 <Typography sx={{ fontSize: (theme) => theme.typography.body2 }}>{t("Bank_Name")}</Typography>
                                 <Card style={{ padding: '10px', border: '1px solid rgb(230 227 227)', borderRadius: '5px', color: 'gray' }}>
                                     {listData.bankName}
                                 </Card>
                             </Grid>
                             <Grid item xs={12} lg={4} md={4} sm={12}>
                                 <Typography sx={{ fontSize: (theme) => theme.typography.body2 }}>{t("Branch_Name")}</Typography>
                                 <Card style={{ padding: '10px', border: '1px solid rgb(230 227 227)', borderRadius: '5px', color: 'gray' }}>{listData.branchName}</Card>
                             </Grid>
                             <Grid item xs={12} lg={4} md={4} sm={12}>
                                 <Typography sx={{ fontSize: (theme) => theme.typography.body2 }}>{t("IFSC_Code")}</Typography>
                                 <Card style={{ padding: '10px', border: '1px solid rgb(230 227 227)', borderRadius: '5px', color: 'gray' }}>{listData.ifsc}</Card>
                             </Grid>
                             </Grid>
                            <Tooltip title='Next' arrow placement='left'>
                                <IconButton onClick={displayNextRow} data-testid='next' disabled={pageIndex === bankListData.length - 1} style={{ color: pageIndex === bankListData.length - 1 ? '' : ICMSTheme.palette.primary.main, paddingRight:'0px' }}>
                                    <FaChevronRight />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <ICMSButton onClick={() => handleClose(false)} color='primary' variant='outlined' size='small'>{t("Cancel")}</ICMSButton>
                    </DialogActions>
                </motion.div>
                {loading && <ICMSLoader show={loading} />}
            </Dialog>
    )
}

export default BankDetailsPage