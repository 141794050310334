import { ButtonGroup, Grid, TableBody, TableHead, Typography } from '@mui/material';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FcFile } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { PublicAxiosInterceptor } from '../../config/axios.interceptor';
import { UserContext } from '../context/UserContext';
import { IcmsMuiTable, IcmsTableContainer, IcmsTablePagination, IcmsTableRow, StyledTableCell } from '../generic-styles/NavPageContainerStyles';
import ICMSLoader from '../icms-loader/ICMSLoader';
import IcmsSnackbar from '../icms-snackbar/IcmsSnackbar';
import { ICMSButton } from '../icms-styled-components/IcmsStyledComponents';
import SearchPublicComponents from './SearchPublicComponents';
import { RelationshipType, Stage } from '../GenericCodes';
import CreateResolutionApplicant from '../../icms/case-inside/case-modules/resolution-plan/resolution-applicant/CreateResolutionApplicant';

function InvitationOfEOIList() {
    const { t } = useTranslation();
    const [publicAnnouncementData, setPublicAnnouncementData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ show: false, message: '', severity: '' });
    const context = useContext(UserContext);
    const headerRef = useRef();
    const footerRef = useRef();
    const [tableContainerPosition, setTableContainerPosition] = useState(100);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchBy, setSearchBy] = useState('debtorName');
    const [ascending, setAscending] = useState(false)
    const [showSubmitRARequest, setShowSubmitRARequest] = useState({
        show: false,
        caseId: '',
        relationshipCd: '',
        eoiMinDate: ''
    })

    const columns = [
        { id: 'cd-name', title: t("Corporate_Debtor_Name"), width: '25%' },
        // { id: 'ip-name', title: t('IP_Name'), width: '20%' },
        { id: 'order-number', title: t('NCLT_Order_No'), width: '10%' },
        // { id: 'type-of-pa', title: t('Type_Of_PA'), width: '10%' },
        // { id: 'date-pa', title: t('Date_Of_PA'), width: '10%' },
        { id: 'last-date', title: t('Last_Date_For_Eoi'), width: '10%' },
        // { id: 'form', title: t('Form'), width: '5%' },
        { id: 'action', title: t('Action'), width: '10%', align: 'center' },
    ]

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    useEffect(() => {
        let payload = {
            plan: true
        }
        if (searchQuery) {
            payload = {
                ...payload,
                [searchBy]: searchQuery
            }
        }
        setLoading(true)
        PublicAxiosInterceptor.post(`case/1/automate/public-announcement?page=${page}&limit=${rowsPerPage}&asc=${ascending}`, payload).then((response) => {
            setPublicAnnouncementData(response.items)
            setCount(response.count)
            setLoading(false)
            setTableContainerPosition(headerRef.current.getBoundingClientRect().bottom + footerRef.current.getBoundingClientRect().height);
        }).catch((error) => {
            setMessage({ show: true, message: error.message, severity: 'error' });
            setLoading(false)
        })
    }, [page, rowsPerPage, searchQuery, ascending])

    const handleSubmitClaim = (row) => {
        if (context?.userSession?.userSession?.isAuthenticated) {
            navigate(`/details/select-claim/${row.icmsCaseId}`)
        } else {
            navigate(`/sign-in?redirect=/details/select-claim/${row.icmsCaseId}`)
        }
    }

    const handlePropRARequest = (row) => {
        if (context?.userSession?.userSession?.isAuthenticated) {
            setShowSubmitRARequest({
                show: true,
                caseId: row.icmsCaseId,
                relationshipCd: row?.caseStage == Stage.RESOLUTION ? 
                    RelationshipType.RESOLUTION_RA : (row?.caseStage == Stage.LIQUIDATION ? RelationshipType.LIQUIDATION_RA : null),
                eoiMinDate: row.eoiDate
            })
        }
    }

    const handleCloseRARequest = () => {
        setShowSubmitRARequest({
            show: false,
            caseId: '',
            relationshipCd: '',
            eoiMinDate: ''
        })
    }

    const handleChange = (value) => {
        setAscending(value)
    }

    return (
        <Fragment>
            <Grid
                container
                sx={{ padding: '5px 15px 5px 15px !important' }}
                spacing={2}
                direction="row"
                alignItems="center"
                ref={headerRef}
            >
                {/* <Grid item xs={3.5} container justifyContent="flex-start">
                    <Typography variant="h6">{t("Public_Announcement")}</Typography>
                </Grid> */}
                <Grid item xs={6} container justifyContent="flex-start">
                    <SearchPublicComponents setSearchQuery={setSearchQuery} searchQuery={searchQuery} searchBy={searchBy} setSearchBy={setSearchBy} />
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end">
                    <ButtonGroup variant="outlined" aria-label="process_type" size="small"
                        sx={{ float: 'right', margin: '4px' }}>
                        <ICMSButton color='primary' variant={ascending === true ? "contained" : "outlined"}
                            onClick={() => handleChange(true)}>{t('Ascending')}</ICMSButton>
                        <ICMSButton color='primary' variant={ascending === false ? "contained" : "outlined"}
                            onClick={() => handleChange(false)}>{t('Descending')}</ICMSButton>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <IcmsTableContainer position={tableContainerPosition}>
                <IcmsMuiTable stickyHeader aria-label="sticky table">
                    <TableHead>
                        <IcmsTableRow>
                            {
                                columns.map((column, index) => {
                                    return (
                                        <StyledTableCell style={{ backgroundColor: '#f7fafb', fontWeight: 600, padding: '5px' }} key={column.id}
                                            width={column.width} data-testid={column.id} align={column.align}>{column.title}</StyledTableCell>
                                    )
                                })
                            }
                        </IcmsTableRow>
                    </TableHead>
                    <TableBody>
                        {publicAnnouncementData.length > 0 ? publicAnnouncementData.map((row, index) => {
                            return (
                                <IcmsTableRow key={index} >
                                    <StyledTableCell style={{ padding: '8px', borderLeft: '1px solid rgb(224 224 224)' }} align='left'>{`${row.debtor}`}</StyledTableCell>
                                    {/* <StyledTableCell style={{ padding: '8px' }} align='left'>{row.ipName ? row.ipName : '--'}</StyledTableCell> */}
                                    <StyledTableCell style={{ padding: '8px' }} align='left' >{row.ncltCaseNumber ? row.ncltCaseNumber : '--'}</StyledTableCell>
                                    {/* <StyledTableCell style={{ padding: '8px' }} align='left'>{row.orderType}</StyledTableCell> */}
                                    {/* <StyledTableCell style={{ padding: '8px' }} align='left'>
                                        {row.caseStage == Stage.RESOLUTION ? (row.publicationDateOfRpPublicAnnouncement ? moment(row.publicationDateOfRpPublicAnnouncement).format("DD/MM/YYYY"): '--') 
                                            : (row.publicationDateOfLqPublicAnnouncement ? moment(row.publicationDateOfLqPublicAnnouncement).format("DD/MM/YYYY"): '--')}
                                    </StyledTableCell> */}
                                    <StyledTableCell style={{ padding: '8px' }} align='left'>
                                        {row.receiptLastDateForEoi ? moment(row.receiptLastDateForEoi).format("DD/MM/YYYY"): '--'}
                                    </StyledTableCell>
                                    {/* <StyledTableCell style={{ padding: '8px' }} align='left'>
                                        <FcFile size='50%' />
                                    </StyledTableCell> */}
                                    <StyledTableCell style={{ padding: '8px', borderRight: '1px solid rgb(224 224 224)' }} align='center'>
                                        <ICMSButton color='primary' variant='contained' onClick={() => handlePropRARequest(row)}
                                            size='small'>
                                            {t('Submit')}
                                        </ICMSButton>
                                    </StyledTableCell>
                                </IcmsTableRow>
                            )
                        }) :
                            <IcmsTableRow>
                                <StyledTableCell colSpan={8} data-testid='no-record'>{t("No_Record_Found")}</StyledTableCell>
                            </IcmsTableRow>
                        }
                    </TableBody>
                </IcmsMuiTable>
            </IcmsTableContainer>
            <Grid item xs={12} sx={{ padding: '0px 20px' }} ref={footerRef}>
                <IcmsTablePagination sx={{ marginBottom: '10px' }} data-testid="public-list-pagination"
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Grid>
            {/* </Card> */}
            {loading && <ICMSLoader show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
            {showSubmitRARequest.show && <CreateResolutionApplicant open={showSubmitRARequest.show}
                    caseId={showSubmitRARequest.caseId} relationshipCd={showSubmitRARequest.relationshipCd}
                    eoiMinDate={showSubmitRARequest.eoiMinDate} onClose={handleCloseRARequest}
                    requestFromRA={true} showSelf={true}/>}
        </Fragment>
    )
}

export default InvitationOfEOIList