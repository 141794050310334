import React, { useContext, useState }          from 'react';
import {
    Dialog, DialogTitle, DialogContent,
    DialogActions
}                                   from '@mui/material';
import { useTranslation }           from 'react-i18next';
import { useFormik }                from "formik"
import * as Yup                     from 'yup';
import moment                       from 'moment';
import { ICMSButton }               from '../../../../../common/icms-styled-components/IcmsStyledComponents';
import { 
    ICMSAxiosDownloadInterceptor }  from '../../../../../config/axios.interceptor';
import GenerateFormFieds            from './GenerateFormFields';
import ICMSLoader                   from '../../../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                 from '../../../../../common/icms-snackbar/IcmsSnackbar';
import { CaseType }                 from '../../../../../common/GenericCodes';
import { CaseContext } from '../../../../../common/context/case/CaseContext';

const GenerateFormModel = ({caseId, creditorId, caseStage, formName, show, onClose, onSuccess, isAuthRepForm, caseCd}) => {
    const { t } = useTranslation();
    const { contextValue } = useContext(CaseContext);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''})
    // const [caseCd] = useState(caseCd);

    const generateFormValidationSchema = Yup.object().shape({
        submissionDate: Yup.string().when(['caseCd'], {
            is: (caseCd) => { return Number(caseCd) === CaseType.CIRP },
            then: () => Yup.string().required(t('Submission_Date_Is_Required')),
            otherwise: () => Yup.string().nullable(true)
        })
    });

    const generateFormik = useFormik({
        initialValues: {
            verifiedDate: null,
            verifiedAt: '',
            submissionDate: new Date(),
            submissionBy: '',
            submissionPlace: '',
            affirmedDate: caseCd != CaseType.CIRP ? new Date() : null
        },
        validationSchema: generateFormValidationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true
    })

    const generateClaimForm = () => {
        let payload = {
            report: {
                reportCd: formName?.code,
                reportLocale: formName?.name,
                template: formName?.template,
                transactionId: creditorId,
                reportOf: caseId
            },
            affirmedAt: generateFormik.values.affirmedAt ? generateFormik.values.affirmedAt : generateFormik.values.submissionPlace,
            affirmedDate: generateFormik.values.affirmedDate ? moment(new Date(generateFormik.values.affirmedDate)).format('yyyy-MM-DD') : null,
            verifiedAt: generateFormik.values.verifiedAt,
            verifiedDate: generateFormik.values.verifiedDate,
            date: generateFormik.values.submissionDate ? moment(new Date(generateFormik.values.submissionDate)).format('yyyy-MM-DD') : null,
            place: generateFormik.values.submissionPlace ? generateFormik.values.submissionPlace : generateFormik.values.affirmedAt
        }
        if (isAuthRepForm) {
            setLoading(true);
            ICMSAxiosDownloadInterceptor.post(`case/${caseId}/claim-form/generate-form-e/${creditorId}/case-stage/${caseStage}`, payload)
                    .then(response => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${formName?.name}.pdf`);
                document.body.appendChild(link);
                link.click();
                setLoading(false);
                onSuccess();
            }).catch(error => {
                if(error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                } else {
                    setMessage({show: true, message: t('Try_Again'), severity: 'error'});
                }
                setLoading(false);
            })
        } else {    
            setLoading(true);
            ICMSAxiosDownloadInterceptor.post(`case/${caseId}/claim-form/generate/${creditorId}/case-stage/${caseStage}`, payload)
                    .then(response => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${formName?.name}.pdf`);
                document.body.appendChild(link);
                link.click();
                setLoading(false);
                onSuccess();
            }).catch(error => {
                if(error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                } else {
                    setMessage({show: true, message: t('Try_Again'), severity: 'error'});
                }
                setLoading(false);
            })
        }
    }

    return (
        <div>
            <Dialog open={show} fullWidth maxWidth="sm">
                <DialogTitle>{t('Generate')} {formName?.name}</DialogTitle>
                <DialogContent dividers>
                    <GenerateFormFieds formik={generateFormik} caseCd={caseCd}/>
                </DialogContent>
                <DialogActions>
                    <ICMSButton onClick={onClose} color="primary" size="small" variant='outlined'>
                        {t('Cancel')}
                    </ICMSButton>
                    <ICMSButton onClick={generateClaimForm} color="primary" size="small" variant='contained'
                            disabled={!generateFormik.isValid}>
                        {t('Generate')}
                    </ICMSButton>
                </DialogActions>
                {loading && <ICMSLoader show={loading}/>}
            </Dialog>
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} 
                    handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
        </div>

    )
}

export default GenerateFormModel