import React, {
    useEffect,
    useState
}                               from 'react';
import { useTranslation }       from 'react-i18next';
import {
    TableHead,
    TableBody,
    Grid,
    Button,
    IconButton,
    Tooltip
}                               from '@mui/material';
import AddIcon                  from '@mui/icons-material/Add';
import EditIcon                 from '@mui/icons-material/Edit';
import DeleteIcon               from '@mui/icons-material/Delete';
import ICMSLoader               from '../../../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar             from '../../../../../common/icms-snackbar/IcmsSnackbar';
import {
    IcmsMuiTable,
    IcmsTableRow,
    StyledTableCell,
    IcmsTableContainer
}                               from '../../../../../common/generic-styles/NavPageContainerStyles';
import { ICMSAxiosInterceptor } from '../../../../../config/axios.interceptor';
import ClaimChargesDetail     from './ClaimChargesDetail';
import ICMSConfirmDialog        from '../../../../../common/ConfirmationDialog/ICMSConfirmDialog';
import { ICMSButton }           from '../../../../../common/icms-styled-components/IcmsStyledComponents';

const ClaimChargesList = ({caseId, claimantId, debtDefaultId, canEdit}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [charges, setCharges] = useState([]);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});
    const [showChargeDetail, setShowChargeDetail] = useState({
        show: false,
        chargeDetail: null
    })
    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('Yes'),
        disAgreeBtnLabel: t('No'),
        onDisAgree: ''
    })
    const columns = [
        { id: 'chargeId', label: 'Security_Interest_Id', minWidth: '25%' },
        { id: 'trusteeName', label: 'Trustee_Name', minWidth: '20%' },
        { id: 'description', label: 'Description', minWidth: '35%' },
        { id: 'action', label: 'Action', minWidth: '10%', align:'center' }
    ]

    const getCharges = () => {
        setLoading(true);
        ICMSAxiosInterceptor.get(`case/${caseId}/claimant/${claimantId}/claim/${debtDefaultId}/charge-link`).then(response => {
            setCharges(response);
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'})
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'})
            }
            setLoading(false);
        })
    }

    const resetShowChargeDetail = () => {
        setShowChargeDetail({show: false, chargeDetail: null})
    }

    const onSuccess = (message) => {
        setMessage(message);
        getCharges();
    }

    const deleteConfirmation = (charge) => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: `${t('Are_You_Sure_That_You_Want_To_Delete_Security_Interest')} ${charge?.chargeId} ?`,
            onDisAgree: resetSeekConfirmation,
            onAgree: () => {
                resetSeekConfirmation();
                handleDelete(charge);
            },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    const handleDelete = (charge) => {
        setLoading(true);
        ICMSAxiosInterceptor.delete(`case/${caseId}/claimant/${claimantId}/claim/${debtDefaultId}/charge-link/${charge.claimUnderChargeId}`).then(response => {
            if (response) {
                onSuccess({show: true, message: t('Security_Interest_Deleted_Succssfully'), severity: 'success'});
            }
            setLoading(false);
        }).catch(error => { 
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    useEffect(() => {
        getCharges();
    }, [])
    

    return (
        <div>
            <IcmsTableContainer>
                <Grid container>
                    <Grid item xs={12}>
                        {canEdit && <ICMSButton color="primary" variant="contained" size="small" sx={{float: 'right'}}
                                onClick={() => {setShowChargeDetail({show: true, chargeDetail: null})}}
                                startIcon={<AddIcon />}>
                            {t('Add_Security_Interest')}
                        </ICMSButton>}
                    </Grid>
                </Grid>
                <IcmsMuiTable stickyHeader aria-label="sticky table" data-testid="claim-charge-list-table"
                            sx={{padding: '0px !important'}}>
                    <TableHead>
                        <IcmsTableRow>
                            {columns.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    sx={{padding: '4px'}}
                                >
                                    {t(column.label)}
                                </StyledTableCell>
                            ))}
                        </IcmsTableRow>
                    </TableHead>
                    <TableBody>
                        {charges.length === 0 &&  <IcmsTableRow hover>
                            <StyledTableCell>
                                {t('No_Record_Found')}
                            </StyledTableCell>
                        </IcmsTableRow>}
                        {charges.map(charge => {
                            return (
                                <IcmsTableRow hover key={charge.claimUnderChargeId}>
                                    <StyledTableCell sx={{padding: '4px'}}>{charge.chargeId}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '4px'}}>{charge.trusteeName ? charge.trusteeName : '-'}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '4px'}}>{charge.description ? charge.description : '-'}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '4px', textAlign: 'center'}}>
                                       {canEdit && <Tooltip title={t('Edit')} arrow>
                                            <IconButton color='primary' id='edit'
                                                    onClick={() => {setShowChargeDetail({show: true, chargeDetail: charge})}}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>}
                                        {canEdit && <Tooltip title={t('Delete')} arrow>
                                            <IconButton color='secondary' id='delete' onClick={() => deleteConfirmation(charge)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>}
                                    </StyledTableCell>
                                </IcmsTableRow>
                            )
                        })}
                    </TableBody>
                </IcmsMuiTable>
            </IcmsTableContainer>
            {showChargeDetail.show && <ClaimChargesDetail show={showChargeDetail.show} 
                    chargeDetail={showChargeDetail.chargeDetail} onClose={resetShowChargeDetail}
                    caseId={caseId} claimantId={claimantId} debtDefaultId={debtDefaultId}
                    onSuccess={onSuccess}/>}
            {seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}
            />}
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} 
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
        </div>
    )
}

export default ClaimChargesList