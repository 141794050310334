import { FormHelperText, FormLabel, MenuItem, 
    Select, Stack, styled, InputAdornment,
    IconButton }                                from "@mui/material";
import ClearIcon                                from '@mui/icons-material/Clear';
import { getIn }                                from "formik";

const StyledSelect = styled(Select)({
    '&.Mui-disabled': {
      backgroundColor: '#f0f0f0',
    },
});

const SelectLabelController = (props) => {
    const { id, fieldName, label, formik, menuItems, disabled, required,size, handleChange, menuFieldId, menuFieldTitle, 
        valueField, handleBlur, placeholder, disabledFirstRow, customStyle} =
        { ...props, valueField: props.valueField || props.menuFieldId };

    const handleClear = () => {
        formik.setFieldValue(fieldName, '');
    };
        
    return (
        <Stack>
            <FormLabel htmlFor={id} sx={{ fontSize: (theme) => theme.typography.body2 }} required={required}>{label}</FormLabel>
            <StyledSelect
                fullWidth
                id={id}
                name={fieldName}
                // value={(getIn(formik.values, fieldName) != '' && getIn(formik.values, fieldName) != undefined) 
                //     ? getIn(formik.values, fieldName) : ''}
                value={formik.values[fieldName] === 0 ? 0 : formik.values[fieldName] || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                size={size}
                displayEmpty
                error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                FormHelperTextProps={{ 'data-testid': `${id}-error` }}
                inputProps={{
                    "data-testid": id,
                }}
                aria-label={label}
                sx={{
                    bgcolor: (theme) => theme.palette.background.paper,
                    ...customStyle
                }}
                endAdornment={((formik.values[fieldName] != '' && formik.values[fieldName] != undefined) && !required) && (
                    <InputAdornment position="end">
                        {!disabled && <IconButton
                            sx={{margin: '12px'}}
                            onClick={handleClear}
                            aria-label="clear selection"
                        >
                            <ClearIcon />
                        </IconButton>}
                    </InputAdornment>
                )}
            > 
            {required && placeholder && (
                <MenuItem value="" disabled>
                    <em style={{color:'gray'}}>{placeholder}</em>
                </MenuItem>
            )}
                {menuItems.map((menu, index) => {
                    return (
                        <MenuItem key={`key-${menu[menuFieldId]}`} data-testid={`option-${menu[valueField]}`} value={menu[valueField]} 
                        disabled={(disabledFirstRow && index === 0)}>{menu[menuFieldTitle]}</MenuItem>
                    )
                })}
            </StyledSelect>
            <FormHelperText style={{ color: 'red' }}>{formik.touched[fieldName] && formik.errors[fieldName]}</FormHelperText>
        </Stack>
    )
}

export { SelectLabelController };
