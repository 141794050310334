import { Box, Grid, Stack, Table, TableContainer, TablePagination, TableRow } from '@mui/material';
import MuiList from '@mui/material/List';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { style } from '@mui/system';
import MuiDrawer from '@mui/material/Drawer';


export const NavSideBarWidth = '210px';
export const NavSideBarCollapseWidth = '66px';
export const IcmsTopBarWidth= '48px';

export const CaseNavSideBarWidth = '230px';
export const CaseNavSideBarCollapseWidth = '66px';

export const CaseTopBarHeight = '62px';

export const spaceBetweenTopAndCaseTop = '8px';

export const SideBarContainer = styled(Grid)(({ theme, ismenucollapse }) => ({
    minWidth: ismenucollapse ? NavSideBarWidth : NavSideBarCollapseWidth,
    width: ismenucollapse ? NavSideBarWidth : NavSideBarCollapseWidth
}));

export const SideBarCaseContainer = styled(Grid)(({ theme, ismenucollapse }) => ({
  minWidth: ismenucollapse ? CaseNavSideBarWidth : CaseNavSideBarCollapseWidth,
  width: ismenucollapse ? CaseNavSideBarWidth : CaseNavSideBarCollapseWidth,
  overflow: 'auto'
}));

export const CasePageListOutline = styled(Grid)(({ theme, ismenucollapse }) => ({
  minWidth: ismenucollapse ? `calc(100vw - ${CaseNavSideBarWidth})` : `calc(100vw - ${CaseNavSideBarCollapseWidth})`,
  width: ismenucollapse ? `calc(100vw - ${CaseNavSideBarWidth})` : `calc(100vw - ${CaseNavSideBarCollapseWidth})`,
  overflow: 'auto',
  minHeight: `calc(100vh - ${IcmsTopBarWidth} - ${CaseTopBarHeight})`,
  height: `calc(100vh - ${IcmsTopBarWidth} - ${CaseTopBarHeight})`,
  // padding: '5px'
}));

export const CaseModuleContainer = styled(Box)(() => ({
  overflow: 'auto',
  display: 'flex',
  minHeight: `calc(100vh - ${IcmsTopBarWidth} - ${CaseTopBarHeight})`,
  height: `calc(100vh - ${IcmsTopBarWidth} - ${CaseTopBarHeight})`,
}));

export const PageListOutline = styled(Grid)(({ theme, ismenucollapse }) => ({
    minWidth: ismenucollapse ? `calc(100vw - ${NavSideBarWidth})`  : `calc(100vw - ${NavSideBarCollapseWidth})`,
    width: ismenucollapse ? `calc(100vw - ${NavSideBarWidth})`  : `calc(100vw - ${NavSideBarCollapseWidth})`,
    overflow: 'auto',
    maxHeight: `calc(100vh - ${IcmsTopBarWidth})`,
    height: `calc(100vh - ${IcmsTopBarWidth})`
}));

export const SideBarToggle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
}));

export const IcmsTableContainer = styled(TableContainer)(({ position }) => ({
  maxHeight: `calc(100vh - ${position}px)`
}));

export const SideNavList = styled(MuiList)(({ theme }) => ({
  // selected and (selected + hover) states
  // '&& .Mui-selected, && .Mui-selected:hover': {
  //   backgroundColor: 'red !important',
  //   borderRadius: '0px !important',
  //   '&, & .MuiListItemIcon-root': {
  //     color: 'pink',
  //   },
  // },

  // hover states
  '& .MuiListItemButton-root:hover': {
    // backgroundColor: theme.icmsSideNav.hoverNavbg,
    background: theme.palette.primary.main,
    color: 'aliceblue',
    borderRadius: '10px',
    '&, & .MuiListItemIcon-root': {
      color: 'aliceblue',
    },
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7fafb',
    color: 'black',
    borderBottom: 'none',
    borderTop: 'none',
    fontWeight: '600',
    padding: '10px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: 'none',
    borderTop: 'none',
    padding: '10px'
  },
}));

export const StyledTableCellNone = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7fafb',
    color: 'black',
    borderBottom: 'none',
    borderTop: 'none',
    fontWeight: '600',
    padding: '0px !important'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: 'none',
    borderTop: 'none',
    padding: '0px !important'
  },
}));

export const IcmsMuiTable = styled(Table)({
  borderCollapse: 'separate !important',
  borderSpacing: '0px 10px !important',
  border: 'transparent !important',
  padding: '0px 15px 10px 15px !important'
});

export const IcmsMuiTableTwoHead = styled(Table)({
  borderCollapse: 'separate !important', 
  borderSpacing: '0px 10px !important',
  border: 'transparent !important',
  padding: '0px 0px 10px 0px !important'
});

export const IcmsTableRow = styled(TableRow)({
  // boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
  boxShadow:  'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  background: 'white',
  '&.MuiTableRow-hover:hover': {
    // boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
    background: 'white',
    cursor: 'pointer'
  }
});

export const IcmsTablePagination = styled(TablePagination)({
  boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px'
});

const openedMixin = (theme) => ({
  width: '100% !important',
  position: 'relative',
  background: theme.icmsSideNav.navBg,
  borderRight: `4px solid ${theme.icmsSideNav.navBorder}`,
  // transition: theme.transitions.create('width', {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.enteringScreen,
  // }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  position: 'relative',
  background: theme.icmsSideNav.navBg,
  borderRight: `2px solid ${theme.icmsSideNav.navBorder}`,
  width: '100% !important',
  // [theme.breakpoints.up('sm')]: {
  //   width: `calc(${theme.spacing(8)} + 1px)`,
  // },
});

export const SideBarDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: '100% !important',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    marginTop: '10px',
    marginBottom: '10px',
    borderBottomRightRadius: '10px',
    borderTopRightRadius: '10px',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);