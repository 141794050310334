import { Alert, Snackbar } from "@mui/material"
import { useState } from "react";

const IcmsSnackbar = (props) => {
    const { show, severity, message, handleResetOnClose } = props;
    const [open, setOpen] = useState(show);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        if (reason === 'timeout' && severity === 'error') {
            return;
        }
        if (handleResetOnClose) {
            handleResetOnClose();
        }
        setOpen(false);
    };
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }} variant="filled">
                {message}
            </Alert>
        </Snackbar>
    )
}

export default IcmsSnackbar;