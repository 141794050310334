import { LookUpAxiosICMSInterceptor } from "../config/axios.interceptor";

const listCountries = (handleSuccessCallback, handleErrorCallBack) => {

    LookUpAxiosICMSInterceptor.get(`geo/countries`).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const listState = (countryId, handleSuccessCallback, handleErrorCallBack) => {

    LookUpAxiosICMSInterceptor.get(`geo/country/${countryId}/states`).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const listCityTown = (stateId, handleSuccessCallback, handleErrorCallBack) => {

    LookUpAxiosICMSInterceptor.get(`geo/state/${stateId}/cities`).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

export { listCountries, listState, listCityTown }