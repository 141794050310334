import { Button, Dialog, DialogActions, DialogContent, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import {useNavigate } from 'react-router-dom';
import {BsFillPatchCheckFill} from 'react-icons/bs'

const ConfirmationDialog = (props) => {
    const { title, show, description, redirectUrl } = props
    const [open, setOpen] = useState(show);
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
        navigate(redirectUrl)
    };

    return (
        <div>
            <Dialog PaperProps={{ style: { width: 400 } }}
                open={open}
                onClose={handleClose}>
                    <DialogContent>
                        <Stack justifyContent='center' alignItems='center'spacing={2} >
                            <img src={process.env.PUBLIC_URL+"/images/Group.svg"} alt="ICMS_LOGO" width="30%"></img>
                            {/* <Typography variant="h4" sx={{ fontWeight: 'bold' }}>ICMS</Typography> */}
                            <div><BsFillPatchCheckFill size={62} color='green'/></div>
                            <Typography variant='h5' sx={{ color: (theme) => theme.palette.success.main }}>{title}</Typography>
                            <Typography style={{ textAlign:'center'}}>{description}</Typography>
                        </Stack>
                    </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Grid item className="text-center">
                        <Button onClick={handleClose} data-testid='navigate-to-signin' color="primary" autoFocus variant="contained" sx={{
                            padding: '6px 38px',
                            boxShadow: '0px 1.7px 2.2px rgba(0, 0, 0, 0.02), 0px 4px 5.3px rgba(0, 0, 0, 0.028), 0px 7.5px 10px rgba(0, 0, 0, 0.035),0px 13.4px 17.9px rgba(0, 0, 0, 0.042), 0px 25.1px 33.4px rgba(0, 0, 0, 0.05),0px 60px 80px rgba(0, 0, 0, 0.07)'
                        }}>
                            {'OK'}
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ConfirmationDialog