import React, { useContext, useEffect,
    useState
}                                       from "react"
import { Stack,  Grid, Tooltip, FormControlLabel, Checkbox }                 from "@mui/material"
import { useTranslation }               from "react-i18next"
import FCSearch                         from "../../../../file-claim/fc-search/FCSearch"
import AddressDetail                    from "../../../../address/AddressDetails"
import {  
    FormikLabelMuiPhone, 
    FormikLabelTextField }              from "../../../../../common/formik-fields-components/FormikFieldComponents"
import { IdentificationByPartyType }    from "../../../../../common/StaticVariables";
import { ResolutionApplicationType, 
    ResolutionApplicantTypes, 
    PartyType, 
    IdentificationType,
    AddressTypes}                         from "../../../../../common/GenericCodes";
import { SelectLabelController }        from "../../../../../common/formik-fields-components/SelectLabelController"
import { FormikNewDateTime }            from '../../../../../common/formik-fields-components/FormikNewDateTime';
import YesNoSwitchController            from "../../../../../common/formik-fields-components/yes-no-switch-controller/YesNoSwitchController";
import { FormikLabelNumericFormat }     from "../../../../../common/formik-fields-components/FormikLabelNumericFormat";
import { setFormikValueWithLowerCase, 
    setFormikValueWithUpperCase }       from "../../../../../common/utils/Utils";
import ICMSConfirmDialog                from "../../../../../common/ConfirmationDialog/ICMSConfirmDialog"
import { ICMSAxiosInterceptor }         from "../../../../../config/axios.interceptor"
import { UserContext } from "../../../../../common/context/UserContext"


const ResolutionApplicant = ({formik, isEdit, disabled, eoiMinDate, requestFromRA, showSelf}) => {
    const { t } = useTranslation()
    const [searchPartyDetails, setSearchPartyDetails] = useState({});
    const loginDetails = useContext(UserContext).loginDetails;
    const [requestAsSelf, setRequestAsSelf] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});
    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('Yes'),
        disAgreeBtnLabel: t('No'),
        onDisAgree: ''
    })

    const onSelectSubmit = (party) => {
        let identification;
        let address = party.addressResources.find(add => add.addressCd == AddressTypes.registerdWithIBBI.addressCd);
        if (party.partyCd == PartyType.INDIVIDUAL) {
            identification = party.identifications.find(id => id.identificationCd == IdentificationType.PAN);
        } else {
            identification = party.identifications[0];
        }
        setSearchPartyDetails({
            ...party,
            addressId: address?.addressId || '',
            addressCd: address?.addressCd || '', 
            addressLine1: address?.addressLine1 || '',
            addressLine2: address?.addressLine2 || '',
            cityOrTown: address?.cityOrTown || '',
            state: address?.state || '',
            postalCode: address?.postalCode || '',
            country: address?.country || 'India',
            identificationId: identification?.identificationId || '',
            identificationCd: identification?.identificationCd || '',
            identification: identification?.identification || ''
        });
        formik.setValues(prevState => ({
            ...prevState,
            partyId: party.partyId, 
            resolutionApplicantId: '',
            partyNameId: party.partyNameId,
            partyCd: party.partyCd,
            name: party.name,
            identificationId: identification?.identificationId || '',
            identificationCd: identification?.identificationCd || '',
            identification: identification?.identification || '',
            dobDoi: party.dobDoi,
            rfrpResponseTime: null,
            email: party.recentEmail,
            phoneNumber: party.recentPhoneDetail?.nationalNumber,
            extension: party.recentPhoneDetail?.countryCode ? '+' + party.recentPhoneDetail?.countryCode : '+91',
            website: party.website,
            addressId: address?.addressId || '',
            addressCd: address?.addressCd || AddressTypes.registerdWithIBBI.addressCd,
            addressLine1: address?.addressLine1 || '',
            addressLine2: address?.addressLine2 || '',
            cityOrTown: address?.cityOrTown || '',
            state: address?.state || '',
            postalCode: address?.postalCode || '',
            country: address?.country || 'India'
        }))
    }

    const getIDLocale = (partyCd, value) => {
        let title = '';
        let identificationList = IdentificationByPartyType[partyCd];
        identificationList.forEach((item, index) => {
            if (value === item.id) {
                title = item.title;
            }
        })
        return title;
    }

    const resetForm = () => {
        formik.resetForm();
        formik.validateForm();
    }

    const getPartyDetailsByID = (identification) => {
        if (!formik.values.resolutionApplicantId) {
            let payload = {
                identification: identification
            }
            ICMSAxiosInterceptor.post(`search/party`, payload).then(response => {
                if (response) {
                    setSeekConfirmation(prevState => ({
                        ...prevState,
                        show: true,
                        title: t("Confirmation"),
                        message: `${t('Party_Details')} ${t('With').toLocaleLowerCase()} ${identification} 
                            ${t('Already_Exists').toLocaleLowerCase()}. ${t('Please_Use_The_Same_Details')}`,
                        onAgree: () => {
                            resetSeekConfirmation();
                            onSelectSubmit(response);
                        },
                        agreeBtnLabel: t('Ok'),
                    }))
                }
            }).catch(error => {
    
            })
        }
    }

    const resetSeekConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    useEffect(() => {
        formik.setFieldValue('identificationCd', IdentificationByPartyType[formik.values.partyCd]?.[0].id)
        return () => {}
    }, [formik.values.partyCd]);

    useEffect(() => {
        if (requestAsSelf) {
            setLoading(true);
            ICMSAxiosInterceptor.get(`/search/party/${loginDetails.partyId}`).then((response) => {
                if(response) {
                    onSelectSubmit(response);
                }
                setLoading(false);
            }).catch((error) => {
                setMessage({ show: true, message: error.message, severity: 'error' });
                setLoading(false);
            })
        } else {
            resetForm();
        }
    }, [requestAsSelf])

    return (
        <Stack width = "100%" spacing = {2}>
            {!formik.values.resolutionApplicantId && 
                <Stack 
                    direction="row" 
                    justifyContent='center' 
                    alignItems='center' 
                    spacing={2} my={2}
                >
                    {showSelf && <Tooltip title={t('Request_As_Self_Helper_Text')}>
                        <FormControlLabel control={<Checkbox checked={requestAsSelf} />} label={t('Self')} 
                                onChange={(event) => setRequestAsSelf(event.target.checked)}
                                sx={{border: `1px solid ${requestAsSelf ? '#005588' : 'initial'}`, color: '#005588', 
                                    padding: '0px 8px', borderRadius: '24px'}}/>
                    </Tooltip>}
                    {!requestAsSelf && <FCSearch placeholder={'Search By Identification Type'} width={'40%'} 
                        isOnlyIndividual={false} onSelectSubmit={onSelectSubmit} sx={{zIndex: 1500}}
                        onClear={resetForm} partyTypes={ResolutionApplicationType}/>}
                </Stack>
            }
            <Stack>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <SelectLabelController
                            id="partyCd"
                            fieldName="partyCd"
                            label={t("Resolution_Application_Type")}
                            formik={formik}
                            required={true}
                            size="small"
                            menuItems={ResolutionApplicationType}
                            handleBlur={formik.handleBlur}
                            menuFieldId={'id'}
                            menuFieldTitle={'title'}
                            handleChange={(event) => {formik.handleChange(event)}}
                            disabled={disabled || !isEdit}
                        />
                    </Grid>
                    <Grid item xs = {3}>
                        <FormikLabelTextField
                            id="name"
                            fieldName="name"
                            data-testid="name"
                            label={t(`Name`)}
                            formik={formik}
                            size="small"
                            required={true}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            disabled={(disabled && searchPartyDetails?.name) || !isEdit}
                        />
                    </Grid>
                    
                    <Grid item xs={3}>
                        <SelectLabelController
                            id="identificationCd"
                            fieldName="identificationCd"
                            label={t("Identification_Type")}
                            formik={formik}
                            required={true}
                            size="small"
                            menuItems={IdentificationByPartyType[formik.values.partyCd]}
                            handleBlur={formik.handleBlur}
                            menuFieldId={'id'}
                            menuFieldTitle={'title'}
                            handleChange={formik.handleChange}
                            disabled={(disabled && searchPartyDetails?.identificationId) || !isEdit}
                        />
                    </Grid>
                    <Grid item xs = {3}>
                        <FormikLabelTextField
                            id="identification"
                            fieldName="identification"
                            label={`${t('Identification')} (${getIDLocale(formik.values.partyCd, 
                                formik.values.identificationCd)})`}
                            formik={formik}
                            size="small"
                            required={true}
                            handleChange={(event) => setFormikValueWithUpperCase(event, formik)}
                            handleBlur={(event) => {formik.handleBlur(event); getPartyDetailsByID(formik.values.identification)}}
                            disabled={(disabled && searchPartyDetails?.identificationId) || !isEdit}
                        />
                    </Grid>
                    {(formik.values.resolutionApplicationType === PartyType.COMPANY || 
                            formik.values.resolutionApplicationType === PartyType.PARTNERSHIPFIRM || 
                            formik.values.resolutionApplicationType === PartyType.PROPBBUSINESS) &&
                        <Grid item xs = {3}>
                            <FormikNewDateTime
                                id='dobDoi'
                                fieldName={t('dobDoi')}
                                label={t("Date_Of_Incorporation")}
                                formik={formik}
                                size="small"
                                onlyDate={true}
                                required={true}
                                handleBlur={formik.handleBlur}
                                disabled={(disabled && searchPartyDetails?.dobDoi) || !isEdit}
                            />
                        </Grid>
                    }
                    <Grid item xs = {3}>
                        <FormikLabelMuiPhone
                            id="phoneNumber"
                            fieldName="phoneNumber"
                            label={t("Mobile_Number")}
                            countryCodeName="extension"
                            formik={formik}
                            size="small"
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            value={formik.values.phoneNumber}
                            required={true}
                            disabled={(disabled && searchPartyDetails?.phoneNumber) || !isEdit}
                            />
                        </Grid>
                    <Grid item xs = {3}>
                        <FormikLabelTextField
                            id="email"
                            fieldName="email"
                            label={t("Email")}
                            size="small"
                            formik={formik}
                            handleChange={(event) => setFormikValueWithLowerCase(event, formik)}
                            handleBlur={formik.handleBlur}
                            required={true}
                            disabled={(disabled && searchPartyDetails?.email) || !isEdit}
                        />
                    </Grid>
                </Grid>
            </Stack>
            {!requestFromRA && <Stack>
                <Grid container spacing={1}>
                    <Grid item xs = {3}>
                        <SelectLabelController
                            id="resoultionApplicantCd"
                            fieldName="resoultionApplicantCd"
                            label={t("Category_Of_Resolution_Applicant")}
                            formik={formik}
                            size="small"
                            handleBlur={formik.handleBlur}
                            menuFieldId={'id'}
                            menuFieldTitle={'title'}
                            valueField={'id'}
                            required={true}
                            menuItems={ResolutionApplicantTypes}
                            handleChange={(event) => {formik.handleChange(event);
                            }}
                            disabled={!isEdit}
                        />
                    </Grid>
                    <Grid item xs = {3}>
                        <FormikNewDateTime
                            id='eoiResponseTime'
                            fieldName={t('eoiResponseTime')}
                            label={t("Date_Of_Response_For_EOI")}
                            formik={formik}
                            size="small"
                            onlyDate={true}
                            required={true}
                            minDate={eoiMinDate}
                            maxDate={new Date()}
                            handleBlur={formik.handleBlur}
                            disabled={!isEdit}
                        />
                    </Grid>
                    <Grid item xs = {3}>
                        <FormikNewDateTime
                            id='rfrpResponseTime'
                            fieldName={t('rfrpResponseTime')}
                            label={t("Date_Of_Request_For_Resolution_Plan")}
                            formik={formik}
                            size="small"
                            onlyDate={true}
                            minDate={formik.values.eoiResponseTime}
                            maxDate={new Date()}
                            handleBlur={formik.handleBlur}
                            disabled={!isEdit || !formik.values.eoiResponseTime}
                        />
                    </Grid>
                    <Grid item xs = {3}>
                        <FormikLabelTextField
                            id="groupToWhichItBelongs"
                            fieldName="groupToWhichItBelongs"
                            label={t("Group_To_Which_It_Belongs")}
                            size="small"
                            formik={formik}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            disabled={!isEdit}
                        />
                    </Grid>
                    <Grid item xs = {3}>
                        <FormikLabelTextField
                            id="industry"
                            fieldName="industry"
                            label={t("Industry")}
                            size="small"
                            formik={formik}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            disabled={!isEdit}
                        />
                    </Grid>
                    <Grid item xs = {3}>
                        <FormikLabelTextField
                            id="natureOfResolution"
                            fieldName="natureOfResolution"
                            label={t("Nature_Of_Resolution")}
                            size="small"
                            formik={formik}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            disabled={!isEdit}
                        />
                    </Grid>
                    <Grid item xs = {3}>
                        <FormikLabelTextField
                            id="natureOfPerformanceSecurity"
                            fieldName="natureOfPerformanceSecurity"
                            label={t("Nature_Of_Performance_Security")}
                            size="small"
                            formik={formik}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            disabled={!isEdit}
                        />
                    </Grid>
                </Grid>
            </Stack>}
            {!requestFromRA && <Stack>
                <Grid container spacing={1}>
                    <Grid item xs = {3}>
                        <FormikLabelNumericFormat
                            id="securityValue"
                            fieldName="securityValue"
                            label={t("Security_Value")}
                            formik={formik}
                            size="small"
                            isThousandSeparator={true}
                            handleChange={formik.handleChange}
                            disabled={!isEdit}
                        />
                    </Grid>
                    <Grid item xs = {3}>
                        <FormikLabelTextField
                            id="securitySource"
                            fieldName="securitySource"
                            label={t("Security_Source")}
                            size="small"
                            formik={formik}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            disabled={!isEdit}
                        />
                    </Grid>
                    <Grid item xs = {3}>
                        <FormikNewDateTime
                            id='securityDurationStartTime'
                            fieldName={t('securityDurationStartTime')}
                            label={t("Security_Duration_StartTime")}
                            formik={formik}
                            size="small"
                            onlyDate={true}
                            handleBlur={formik.handleBlur}
                            minDate={formik.values.eoiResponseTime}
                            maxDate={formik.values.securityDurationEndTime ? formik.values.securityDurationEndTime : null}
                            disabled={!isEdit}
                        />
                    </Grid>
                    <Grid item xs = {3}>
                        <FormikNewDateTime
                            id='securityDurationEndTime'
                            fieldName={t('securityDurationEndTime')}
                            label={t("Security_Duration_EndTime")}
                            formik={formik}
                            size="small"
                            onlyDate={true}
                            handleBlur={formik.handleBlur}
                            minDate={formik.values.securityDurationStartTime}
                            disabled={!isEdit || !formik.values.securityDurationStartTime}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <YesNoSwitchController title={t('Compliant_With_Section_302_Title')} 
                            description={t('Compliant_With_Section_302_Description')}
                            fieldName="compliantWithSection302"
                            id="compliantWithSection302"
                            formik={formik}
                            disabled={!isEdit}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <YesNoSwitchController title={t('Obtained_Confidential_Undertaking_Title')} 
                            description={t('RA_Obtained_Confidential_Undertaking_Description')}
                            fieldName="obtainedConfidentialUndertaking"
                            id="obtainedConfidentialUndertaking"
                            formik={formik}
                            isTextField={formik.values.obtainedConfidentialUndertaking === false}
                            textFieldId='reasonForNotObtainConfidentialUndertaking'
                            textFieldfieldName='reasonForNotObtainConfidentialUndertaking'
                            textFieldlabel={t('Reason_For_Not_Obtaining')}
                            disabled={!isEdit}/>
                    </Grid>
                </Grid>
            </Stack>}
            <Stack>
                <AddressDetail addressTitle={t('Address_Details')} addressFormik={formik} 
                    isDisabled={(disabled && searchPartyDetails?.addressLine1) || !isEdit} required/>
            </Stack>
            {seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} isCancel={seekConfirmation.isCancel}
            />}
        </Stack>
    )
}

export default ResolutionApplicant;