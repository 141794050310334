import React, { 
    useState, useEffect, Fragment 
}                                   from 'react';
import { useFormik }                from "formik";
import * as Yup                     from 'yup';
import { Close }                    from "@mui/icons-material";
import {
    Dialog, DialogActions, 
    DialogContent, DialogTitle, 
    Divider, IconButton, Stack, 
    Tooltip, Typography, Grid 
}                                   from "@mui/material";
import { useTranslation }           from 'react-i18next';
import { 
    ICMSUploadAxiosInterceptor, ICMSAxiosInterceptor
}                                   from '../../config/axios.interceptor';
import { SelectLabelController }    from "../formik-fields-components/SelectLabelController"
import { TextAreaLabelController }  from "../formik-fields-components/TextAreaLabelController";
import { UploadDropZone }           from "../../common/upload-form/UploadDropZone"
import { ICMSButton }               from '../../common/icms-styled-components/IcmsStyledComponents';
import ICMSLoader                   from '../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                 from '../../common/icms-snackbar/IcmsSnackbar';
import { UploadDocExt } from '../../common/GenericCodes';

const UploadDialog = (props) => {

    const { open, title, caseId, onClose, onSuccess, documentTypeCd, icmsTablePk, icmsCaseDocId, documentCd } = props;
    const { t } = useTranslation();
    const [documentTypes, setDocumentTypes] = useState([])
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message:'', severity: ''});
    const [files, setFiles] = useState([]);
    const [selectedFileType, setSelectedFileType] = useState(UploadDocExt.default);

    const documentFormValidationSchema = Yup.object().shape({
        documentCd: Yup.string().required(t('Document_Type_Is_Required')),
    });

    const documentFormik = useFormik({
        initialValues: {
            caseId: caseId,
            icmsTablePk: icmsTablePk,
            documentCd: documentCd || null,
            description: '',
            icmsCaseDocId: icmsCaseDocId,
            version: icmsCaseDocId ? true : false
        },
        validationSchema: documentFormValidationSchema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: true,
        onSubmit: (values) => {
        }
    })

    const getDocumentTypes = () => {
        ICMSAxiosInterceptor.get(`codes/document/parent/${documentTypeCd}`).then(response => {
            setDocumentTypes(response);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
        })
    }

    const handleUpload = () => {
        setLoading(true);
        let formData = new FormData();
        formData.append('file', files[0]);
        formData.append('data', JSON.stringify(documentFormik.values));
        ICMSUploadAxiosInterceptor.post(`case/${caseId}/file/${documentFormik.values.documentCd}`, formData)
                .then(response => {
            onSuccess({show: true, message: t('Document_Uploaded_Successfully'), severity: 'success'})
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            setMessage({show: true, message: error.message, severity: 'error'});
        })
    }

    const handleClose = () => {
        onClose(false);
    }

    useEffect(() => {
        UploadDocExt[documentFormik.values.documentCd] ? setSelectedFileType(UploadDocExt[documentFormik.values.documentCd])
            : setSelectedFileType(UploadDocExt.default);
        setFiles([]);
    }, [documentFormik.values.documentCd])

    useEffect(() => {
        getDocumentTypes();
    }, [])
    
    return (
        <Fragment>
            <Dialog open={open} maxWidth='sm' fullWidth>
                <DialogTitle id='upload-files'>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography variant='h6'>{title || t('Upload_Documents')}</Typography>
                        <Tooltip title={t('Close')} arrow>
                            <IconButton id='upload-close' onClick={handleClose} style={{ float: 'right' }}>
                                <Close color='error' />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Stack spacing={2}>
                        <SelectLabelController
                            id="documentCd"
                            fieldName="documentCd"
                            label={t("Document_Type")}
                            formik={documentFormik}
                            size="small"
                            required={true}
                            menuItems={documentTypes}
                            menuFieldId={'codeCd'}
                            menuFieldTitle={'description'}
                            valueField={'codeCd'}
                            disabled={icmsCaseDocId}
                            handleChange={documentFormik.handleChange}
                        />
                        <UploadDropZone
                            setFiles={setFiles}
                            files={files}
                            acceptableFiles={selectedFileType}
                            disabled={documentFormik.values.documentCd ? false : true}
                        />
                        <TextAreaLabelController
                            id={'description'}
                            fieldName='description'
                            label={t('Description')}
                            formik={documentFormik}
                            minRows={2}
                            required={false}
                            handleChange={documentFormik.handleChange}
                        />
                    </Stack>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Grid container>
                        <Grid item xs={12}>
                            <ICMSButton variant='contained' sx={{float: 'right', margin: '4px'}}
                                onClick={handleUpload} disabled={!documentFormik.isValid || files == 0}
                                size='small'>{t('Upload')}</ICMSButton>
                            <ICMSButton variant='outlined' sx={{float: 'right', margin: '4px'}}
                                onClick={handleClose} size='small'>{t('Cancel')}</ICMSButton>
                        </Grid>
                    </Grid>
                </DialogActions>
                {loading && <ICMSLoader show={loading} />}
                {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                    handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
            </Dialog>
        </Fragment>
    )
}

export { UploadDialog }