import { Card, Button } from "@mui/material";
import { styled } from "@mui/system";

const BgUrl = process.env.PUBLIC_URL + '/images/login-wave.svg'
const BgSideUrl = process.env.PUBLIC_URL + '/images/login-shape.svg'
const ProfileBg = process.env.PUBLIC_URL + '/images/Sprinkle.svg'

const AuthDiv = styled('div')({
    height: '400px',
    width: '100vw',
    position: 'fixed',
    background: 'transparent',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${BgUrl})`,
})

const ProfileContent = styled('div')({
    height: '122px',
    width:'83.5vw',
    // position: 'inherit',
    // position: 'fixed',
    marginTop:'0.1rem',
    background: 'transparent',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${ProfileBg})`,
})

const AuthBottomDiv = styled('div')({
    height: '200px',
    width: '330px',
    bottom: '0px',
    left: '-60px',
    position: 'fixed',
    background: 'transparent',
    zIndex: -1,
    // alignItems: 'center',
    // justifyContent: 'center',
    // flexDirection: 'column',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${BgSideUrl})`,
})

const JustifyCard = styled(Card)({
    margin: 'auto',
    borderRadius: 12,
    boxShadow: 'inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%)'
})

const ProfileCard = styled(Card)(({ borderbottomcolor, hovercolor }) => ({
    borderBottom: `3px solid ${borderbottomcolor}`,
    borderRadius:'10px',
    width:'25vw',
    boxShadow: 'inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%) !important',
    height:'100%',
    display:'flex',
    '&:hover': {
        transitionDelay: '1',
        transform:"scale(1.08)",
        boxShadow: `0 10px 20px 0 ${hovercolor}, 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important`,
    },
    padding:'0px'
 }))

// const ProfileCard = styled(Card)({
//     borderBottom:'3px solid red',
//     borderRadius:'10px',
//     width:'25vw',
//     boxShadow: 'inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%) !important',
//     height:'100%',
//     display:'flex',
//     '&:hover': {
//         transitionDelay: '1',
//         transform:"scale(1.08)",
//         boxShadow: '0 10px 20px 0 #FDCEDF, 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important',
//     },
// })

// const PasswordCard = styled(Card)({
//     borderBottom:'3px solid green',
//     borderRadius:'10px',
//     width:'25vw',
//     display:'flex',
//     boxShadow: 'inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%) !important',
//     height:'100%',
//     '&:hover': {
//         transform:"scale(1.08)",
//         boxShadow: '0 10px 20px 0 #d0f5dd, 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important',
//     },
// })

// const AddressCard = styled(Card)({
//     borderBottom:'3px solid #ff6600',
//     borderRadius:'10px',
//     width:'25vw',
//     boxShadow: 'inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%) !important',
//     height:'100%',
//     '&:hover': {
//         transform:"scale(1.08)",
//         boxShadow: '0 10px 20px 0 #FFD9B3, 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important',
//     },
// })
// const LoginHistoryCard = styled(Card)({
//     borderBottom:'3px solid #d48e33',
//     borderRadius:'10px',
//     width:'25vw',
//     boxShadow: 'inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%) !important',
//     height:'100%',
//     '&:hover': {
//         transform:"scale(1.08)",
//         boxShadow: '0 10px 20px 0 #f2d9b8, 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important',
//     },
// })

// const FeedbackCard = styled(Card)({
//     borderBottom:'3px solid blue',
//     borderRadius:'10px',
//     width:'25vw',
//     boxShadow: 'inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%) !important',
//     height:'100%',
//     '&:hover': {
//         transform:"scale(1.08)",
//         boxShadow: '0 10px 20px 0 #cee3f5, 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important',
//     },
// })


// const OtherCard = styled(Card)({
//     borderBottom:'3px solid #b82cde',
//     borderRadius:'10px',
//     width:'25vw',
//     boxShadow: 'inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%) !important',
//     height:'100%',
//     '&:hover': {
//         transform:"scale(1.08)",
//         boxShadow: '0 10px 20px 0 #f2d4fa, 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important',
//     },
// })

const StyledCard = styled(Card)({
    boxShadow: '0px 8px 10px 0px #d8dfeb !important',
    padding: '1rem 1.5rem !important',
    border: '1px solid #eae7e7',
    '&:hover': {
        boxShadow: 'rgb(255 255 255 / 10%) 0px 5px 10px 0px inset, rgb(152 152 162 / 25%) 0px 30px 50px -20px, rgb(130 130 174 / 30%) 0px 30px 30px -30px !important',
    }
})

const ICMSButton = styled(Button)({
    textTransform: 'capitalize',
  });

export { AuthDiv, JustifyCard, AuthBottomDiv, ProfileContent, ProfileCard, StyledCard, ICMSButton};
