import React, { useState, useEffect, Fragment }     from 'react';
import { Dialog, DialogActions, DialogContent, 
    DialogTitle, TableBody, TableHead }             from '@mui/material';
import { useTranslation }                           from 'react-i18next';
import moment                                       from 'moment';
import { NumericFormat }                            from 'react-number-format';
import { IcmsMuiTable, IcmsTableContainer, 
    IcmsTableRow, StyledTableCell
 }                                                  from '../../../../../common/generic-styles/NavPageContainerStyles';
import { ICMSButton }                               from '../../../../../common/icms-styled-components/IcmsStyledComponents';
import { ICMSAxiosInterceptor }                     from '../../../../../config/axios.interceptor';
import ICMSLoader                                   from '../../../../../common/icms-loader/ICMSLoader';

const ClaimHistory = ({show, onClose, caseId, claimantId, debtDefaultId, claimId, admittedHistory}) => {
    const { t } = useTranslation();
    const [claimHistory, setClaimHistory] = useState([])
    const [loading, setLoading] = useState(false);
    const columns = [
        { id: 'source', label: '', minWidth: '8%' },
        { id: 'filingDate', label: 'Modified_Date', minWidth: '10%' },
        { id: 'debtDueDate', label: 'Debt_Due_Date', minWidth: '18%' },
        { id: 'principal', label: 'Principal_Amount_Rs', minWidth: '12%' },
        { id: 'interest', label: 'Interest_Amount_Rs', minWidth: '12%' },
        { id: 'other', label: 'Other_Charges_Rs', minWidth: '12%' },
        { id: 'total', label: 'Total_Rs', minWidth: '12%' },
        { id: 'description', label: 'Remarks', minWidth: '25%' }
    ]

    const getClaimHistory = () => {
        if (admittedHistory) {
            setLoading(true);
            ICMSAxiosInterceptor.get(`case/${caseId}/claimant/${claimantId}/claim/${claimId}/admit-histories`).then(response => {
                setClaimHistory(response);
                setLoading(false);
            }).catch(error => {
                console.log('error', error);
                setLoading(false);
            })
        } else {
            setLoading(true);
            ICMSAxiosInterceptor.get(`case/${caseId}/claimant/${claimantId}/claim/${debtDefaultId}/claim-histories`).then(response => {
                setClaimHistory(response);
                setLoading(false);
            }).catch(error => {
                console.log('error', error);
                setLoading(false);
            })
        }
    }

    useEffect(() => {
        getClaimHistory();
        return () => {}
    }, [])
    
    return (
        <div>
            <Dialog open={show} fullWidth maxWidth="xl">
                <DialogTitle>{admittedHistory ? t('Admit_History') : t('Claim_History')}</DialogTitle>
                <DialogContent dividers>
                    <IcmsTableContainer>
                        <IcmsMuiTable stickyHeader aria-label="sticky table" data-testid="claim-histort-list-table"
                                sx={{padding: '0px !important'}}>
                            <TableHead>
                                <IcmsTableRow>
                                    {columns.map((column) => (
                                        <StyledTableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            sx={{padding: '4px'}}
                                        >
                                            {t(column.label)}
                                        </StyledTableCell>
                                    ))}
                                </IcmsTableRow>
                            </TableHead>
                            <TableBody>
                                {claimHistory.length === 0 &&  <IcmsTableRow hover>
                                    <StyledTableCell>
                                        {t('No_Record_Found')}
                                    </StyledTableCell>
                                </IcmsTableRow>}
                                {!admittedHistory && claimHistory.map(history => {
                                    return (
                                        <Fragment key={history.claimAdmitRejectDisburseId}>
                                            <IcmsTableRow>
                                                <StyledTableCell sx={{padding: '4px'}}><strong>{t('Claim')}</strong></StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>{history.rowCreatedOn ? moment(history.rowCreatedOn).format("DD-MM-YYYY") : '-'}</StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>
                                                    {history.occurredOn ? moment(history.occurredOn).format("DD-MM-YYYY") : '-'}
                                                    {history.occurredTo && ' - ' + moment(history.occurredTo).format("DD-MM-YYYY")}
                                                </StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>{history.principal ? <NumericFormat value={history.principal} displayType={'text'}
                                                        thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>{history.interest ? <NumericFormat value={history.interest} displayType={'text'}
                                                        thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>{history.other ? <NumericFormat value={history.other} displayType={'text'}
                                                        thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>{<NumericFormat value={history.principal + history.interest + history.other}  displayType={'text'}
                                                        thousandSeparator={true} thousandsGroupStyle="lakh" />}</StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>{history.description}</StyledTableCell>
                                            </IcmsTableRow>
                                            {history.claimAdmitRejectDisburses.length > 0 && <IcmsTableRow>
                                                <StyledTableCell sx={{padding: '4px'}}><strong>{t('Admitted')}</strong></StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>{history.claimAdmitRejectDisburses[0]?.rowCreatedOn ? moment(history.claimAdmitRejectDisburses[0]?.rowCreatedOn).format("DD-MM-YYYY") : '-'}</StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>
                                                    {history.claimAdmitRejectDisburses[0]?.occurredOn ? moment(history.claimAdmitRejectDisburses[0].occurredOn).format("DD-MM-YYYY") : '-'}
                                                    {history.claimAdmitRejectDisburses[0]?.occurredTo && ' - ' + moment(history.claimAdmitRejectDisburses[0].occurredTo).format("DD-MM-YYYY")}
                                                </StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>{history.claimAdmitRejectDisburses[0]?.principal ? <NumericFormat value={history.claimAdmitRejectDisburses[0].principal} displayType={'text'}
                                                        thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>{history.claimAdmitRejectDisburses[0]?.interest ? <NumericFormat value={history.claimAdmitRejectDisburses[0].interest} displayType={'text'}
                                                        thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>{history.claimAdmitRejectDisburses[0]?.other ? <NumericFormat value={history.claimAdmitRejectDisburses[0].other} displayType={'text'}
                                                        thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>{<NumericFormat 
                                                        value={history.claimAdmitRejectDisburses[0]?.principal + history.claimAdmitRejectDisburses[0]?.interest + 
                                                            history.claimAdmitRejectDisburses[0]?.other}  displayType={'text'}
                                                        thousandSeparator={true} thousandsGroupStyle="lakh" />}</StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>{history.claimAdmitRejectDisburses[0]?.description}</StyledTableCell>
                                            </IcmsTableRow>}
                                        </Fragment>

                                    )
                                })}
                                {admittedHistory && claimHistory.map(history => {
                                    return (
                                        <Fragment key={history.claimAdmitRejectDisburseId}>
                                            <IcmsTableRow>
                                                <StyledTableCell sx={{padding: '4px'}}><strong>{t('Admit')}</strong></StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>{history.rowCreatedOn ? moment(history.rowCreatedOn).format("DD-MM-YYYY") : '-'}</StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>
                                                    {history.occurredOn ? moment(history.occurredOn).format("DD-MM-YYYY") : '-'}
                                                    {history.occurredTo && ' - ' + moment(history.occurredTo).format("DD-MM-YYYY")}
                                                </StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>{history.principal ? <NumericFormat value={history.principal} displayType={'text'}
                                                        thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>{history.interest ? <NumericFormat value={history.interest} displayType={'text'}
                                                        thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>{history.other ? <NumericFormat value={history.other} displayType={'text'}
                                                        thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>{<NumericFormat value={history.principal + history.interest + history.other}  displayType={'text'}
                                                        thousandSeparator={true} thousandsGroupStyle="lakh" />}</StyledTableCell>
                                                <StyledTableCell sx={{padding: '4px'}}>{history.description}</StyledTableCell>
                                            </IcmsTableRow>
                                        </Fragment>

                                    )
                                })}
                            </TableBody>
                        </IcmsMuiTable>
                    </IcmsTableContainer>
                    {loading && <ICMSLoader show={loading} />}
                </DialogContent>
                <DialogActions>
                    <ICMSButton onClick={onClose} color="primary" size="small" variant='outlined'>
                        {t('Close')}
                    </ICMSButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ClaimHistory