import React, {
    useState, useEffect, Fragment
}                                           from 'react';
import { Autocomplete, TextField, 
    FormLabel }                             from '@mui/material';
import CircularProgress                     from '@mui/material/CircularProgress';

const FormikTextFieldAndAutoComplete = ({id, fieldName, label, size, required, onSelect, onClear, 
        formik, handleBlur, existingOptions, labelField, idField, disabled
}) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState(existingOptions);
    const loading = open && options.length === 0;

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        if (loading) {
            setOptions(existingOptions);
        }
    }, [formik.values[fieldName]])

    const onOptionSelected = (event, data) => {
        if (data != null) {
            onSelect(event, data);
        }
    }

    const onInputChange = (event, data) => {
        onClear(event)
    }

    return (
        <Fragment>
        <FormLabel htmlFor={id} sx={{ fontSize: (theme) => theme.typography.body2 }} required={required}>{label}</FormLabel>
        <Autocomplete
            id={id}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            freeSolo
            onClose={() => {
                setOpen(false);
            }}
            getOptionLabel={(option) => option[labelField]}
            renderOption={(props, option) => (
                <li {...props} key={option[idField]}> {`${option[labelField]}`}</li>
            )}
            onChange={onOptionSelected}
            options={options}
            loading={loading}
            size={size}
            fullWidth
            inputValue={formik.values[fieldName]}
            onInputChange={onInputChange}
            sx={{lineHeight: 1}}
            style={{lineHeight: '1 !important'}}
            disabled={disabled}
            renderInput={(params) => (
                <TextField
                    required={required}
                    {...params}
                    // label={label}
                    variant='outlined'
                    value={formik.values[fieldName]}
                    error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                    helperText={formik.touched[fieldName] && formik.errors[fieldName]}
                    onChange={(event) => {formik.setFieldValue(fieldName, event.target.value); formik.handleChange(event)}}
                    onBlur={handleBlur}
                    disabled={disabled}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                        sx: {
                            background: (theme) => disabled ? theme.palette.grey[100] : theme.palette.background.paper,
                        }
                    }}
                />
            )}
        />
        </Fragment>
    );
}

export default FormikTextFieldAndAutoComplete