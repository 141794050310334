import { Fragment, useState, 
    useContext }                        from "react"
import { Stack, Typography }            from "@mui/material"
import { useFormik }                    from "formik"
import { useTranslation }               from "react-i18next"
import * as Yup                         from 'yup';
import moment                           from 'moment';
import { ICMSAxiosDownloadInterceptor } from '../../../../../config/axios.interceptor';
import GenerateFormFieds                from './GenerateFormFields';
import ICMSLoader                       from '../../../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                     from '../../../../../common/icms-snackbar/IcmsSnackbar';  
import { ICMSButton }                   from "../../../../../common/icms-styled-components/IcmsStyledComponents";
import { CaseContext }                  from "../../../../../common/context/case/CaseContext";
import { CaseType } from "../../../../../common/GenericCodes";

const GenerateForm = ({formName, caseId, creditorId, caseStage, width, onSuccess, caseCd}) => {
    const {t} = useTranslation();
    const { contextValue } = useContext(CaseContext)
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});
    // const [caseCd] = useState(contextValue?.liquidationCaseCd ? contextValue?.liquidationCaseCd : 
    //     contextValue?.resolutionCaseCd);

    const generateFormValidationSchema = Yup.object().shape({
        submissionDate: Yup.string().when(['caseCd'], {
            is: (caseCd) => { return Number(caseCd) === CaseType.CIRP },
            then: () => Yup.string().required(t('Submission_Date_Is_Required')),
            otherwise: () => Yup.string().nullable(true)
        })
    });

    const generateFormik = useFormik({
        initialValues: {
            caseCd: caseCd,
            verifiedDate: null,
            verifiedAt: '',
            submissionDate: new Date(),
            submissionPlace: '',
            affirmedAt: '',
            affirmedDate: caseCd != CaseType.CIRP ? new Date() : null
        },
        validationSchema: generateFormValidationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true
    })

    const generateClaimForm = () => {
        let payload = {
            report: {
                reportCd: formName?.code,
                reportLocale: formName?.name,
                template: formName?.template,
                transactionId: creditorId,
                reportOf: caseId
            },
            affirmedAt: generateFormik.values.affirmedAt ? generateFormik.values.affirmedAt : generateFormik.values.submissionPlace,
            affirmedDate: generateFormik.values.affirmedDate ? moment(new Date(generateFormik.values.affirmedDate)).format('yyyy-MM-DD') : null,
            verifiedAt: generateFormik.values.verifiedAt,
            verifiedDate: generateFormik.values.verifiedDate,
            date: generateFormik.values.submissionDate ? moment(new Date(generateFormik.values.submissionDate)).format('yyyy-MM-DD') : null,
            place: generateFormik.values.submissionPlace ? generateFormik.values.submissionPlace : generateFormik.values.affirmedAt
        }
        setLoading(true);
        ICMSAxiosDownloadInterceptor.post(`case/${caseId}/claim-form/generate/${creditorId}/case-stage/${caseStage}`, payload)
                .then(response => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${formName?.name}.pdf`);
            document.body.appendChild(link);
            link.click();
            setLoading(false);
            onSuccess({show: true, message: t('Form_Generated_Successfully'), severity: 'success'});
        }).catch(error => {
            if(error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    return (
        <Fragment>
            <Stack alignItems='center' justifyContent="space-between" width={width} px={4} py={1} spacing={1.5}>
                <Typography variant="h6">Generate {formName?.name}</Typography>
                
                 <GenerateFormFieds formik={generateFormik} caseCd={caseCd}/>
                 <ICMSButton variant="contained" color="primary" data-testid="generate-form"
                        size='small' onClick={generateClaimForm}
                        disabled={!generateFormik.isValid}> {t('Generate')} </ICMSButton>
            </Stack>
            {loading && <ICMSLoader show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </Fragment>
    )
}

export { GenerateForm }
