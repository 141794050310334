
import { Card, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EMAIL, MOBILE_NUMBER } from "../GenericCodes";
import './EmailIphone.css';

const EmailPhoneToggle = (props) => {
    const {isToggle, setIsToggle} = props;
    const {t} = useTranslation();
    const [domTrigger, setDomTrigger] = useState(1);

    const handleToggleChange = (event) =>{
        setIsToggle(event.target.value);
        setDomTrigger(domTrigger+1);
    }

    return (
        <Stack>
            <RadioGroup row aria-label="email-phone" name="role" value={isToggle} onChange={handleToggleChange} key={domTrigger}>
                <Stack direction="row" width='100%' spacing={2} my={3} mt={3}>
                    <Card className='cardContent'>
                        <div style={{ width: '100%' }}>
                            <FormControlLabel value={EMAIL} control={<Radio color="primary" className='radioFloat' />} style={{ fontWeight: 'bold' }}
                                label={
                                    <Stack direction='row' alignItems='center' justifyContent='start' spacing={0.8}>
                                        <img src={process.env.PUBLIC_URL + '/images/email.svg'} alt="emaiLogo" width='100px' height='70px' />
                                        <Stack direction='column' spacing={1} mt={'5px !important'}>
                                            <Typography color='textSecondary' data-testid='email-text' style={{ fontWeight: 'bold' }}>{t('Email Address')}</Typography>
                                            <Typography color='textSecondary' data-testid='email-typo' variant="subtitle2">{t('Verification_Code_Send_To_Email')}</Typography>
                                        </Stack>
                                    </Stack>
                                }
                            />
                        </div>

                    </Card>
                    <Card className={`cardContent ${isToggle === MOBILE_NUMBER ? 'selectedRole' : ''}`}>
                        <div style={{ width: '100%' }}>
                            <FormControlLabel value={MOBILE_NUMBER} data-testid='sms-radio' control={<Radio color="primary" className='radioFloat' />} style={{ fontWeight: 'bold' }}
                                label={
                                    <Stack direction='row' alignItems='center' justifyContent='start'>
                                        <img src={process.env.PUBLIC_URL + '/images/Phone.svg'} alt="phoneLogo" width='100px' height='70px' />
                                        <Stack direction='column' spacing={1} mt={'5px !important'}>
                                            <Typography color='textSecondary' data-testid='sms-text' style={{ fontWeight: 'bold' }}>{t('SMS')}</Typography>
                                            <Typography color='textSecondary' data-testid='sms-typo' variant="subtitle2">{t('Verification_Code_Send_To_Mobile')}</Typography>
                                        </Stack>
                                    </Stack>
                                }
                            />
                        </div>
                    </Card>
                </Stack>
            </RadioGroup>
        </Stack>
    )
}

export default EmailPhoneToggle;