import { AppBar, Avatar, Box, Button, Container, Menu, MenuItem, Toolbar, Typography, Link } from "@mui/material";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminToolsSideNav, PublicInformationSideNav, RoutePathValues, AdministrationSideNav } from '../common/DeclaredStaticObjects';
import { UserContext } from "../common/context/UserContext";
import "./TopBar.css";
import { CaseManagementSideNav, Roles } from '../common/GenericCodes';
import { AccountCircle, ExitToApp } from '@mui/icons-material'; 
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';


const TopBar = (props) => {
    const { setLoginDetails } = props;
    const userDetails = useContext(UserContext);
    const isAdmin = userDetails.userSession.loginUserRoles ? userDetails.userSession.loginUserRoles.includes("ROLE_ADMIN") : false;
    const navigate = useNavigate();
    const navigationLocation = useLocation();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const isEnkAdmin = userDetails.loginDetails?.subscriberRole.includes(Roles.enkAdmin.roleValue);
    const isEnkSupport = userDetails.loginDetails?.subscriberRole.includes(Roles.enkSupport.roleValue);
    const isAccAdmin = userDetails.loginDetails?.subscriberRole.includes(Roles.accountAdmin.roleValue);
    const isAdministrator = userDetails.loginDetails?.subscriberRole.includes(Roles.administrator.roleValue);
    const isMonitor = userDetails.loginDetails?.subscriberRole.includes(Roles.monitor.roleValue);
    // const pages = ['Products', 'Pricing', 'Blog'];

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = (value) => {
        setAnchorElUser(null);
        if (value === 'Logout') {
            logOut();
        } else if (value === 'Profile') {
            navigateToProfile()
        }
        // else if (value === 'Administration') {
        //     adminOpen();
        // }
    };

    const navigateToProfile = () => {
        navigate("/details/my-profile", {
            state: {
                loginDetails: userDetails.loginDetails
            }
        })
    }

    const logOut = () => {
        setLoginDetails(null);
        userDetails.setUserSession({ isAuthenticated: false, session: null, system_admin_of: [], color: '' });
        sessionStorage.clear();
        navigate("/sign-in")
    }

    const homeOpen = () => {
        navigate("/details/home");
    }

    const claimOpen = () => {
        navigate("/details/file-claim")
    }

    const submitEoI = () => {
        navigate("/details/submit-eoi")
    }

    const caseManagement = () => {
        if (!navigationLocation.pathname.includes(RoutePathValues.caseManagement)) {
            navigate("/details/case-management", {
                state: {
                    caseType: (isAccAdmin || isAdministrator || isMonitor || isEnkAdmin || isEnkSupport) ? CaseManagementSideNav.active.key : CaseManagementSideNav.all.key
                }
            })
        }
    }

    const adminTools = () => {
        if (!navigationLocation.pathname.includes(RoutePathValues.adminTools)) {
            navigate("/details/admin-tools", {
                state: {
                    navValue: AdminToolsSideNav.subscriptionDetail.key,
                    subscriberId: null,
                    subPartyId: null // remove once subscriberId is updated with case-transfer API services for enk-admin

                }
            })
        }
    }

    const administration = () => {
        if (!navigationLocation.pathname.includes(RoutePathValues.administration)) {
            navigate("/details/administration", {
                state: {
                    navValue: AdministrationSideNav.subscribersDetail.key
                }
            })
        }
    }

    const publicInformation = () => {
        if (!navigationLocation.pathname.includes(RoutePathValues.publicInformation)) {
            navigate("/details/public-information", {
                state: {
                    publicValue: PublicInformationSideNav.IP.key
                }
            })
        }
    }

    return (
        <AppBar position="static" sx={{
            background: '#fff !important',
            color: '#000000 !important',
            // boxShadow: '0px 1px 4px 0 rgb(0 0 0 / 30%), 0 0 0 0px rgb(0 0 0 / 10%) !important'
            boxShadow: '0px 1px 10px -1px rgb(0 0 0 / 10%), 0 0 0 -8px rgb(0 0 0 / 4%) !important',
        }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters variant="dense">
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{'ICMS'}</Typography>
                    {/* <img src={process.env.PUBLIC_URL + "/images/Group.svg"} alt="ICMS_LOGO" width="8%"></img> */}
                    {/* <Box sx={{ flexGlow: 1, marginLeft: 4 }}>
                        <Button size="medium" variant={navigationLocation.pathname.includes("home") ? "contained" : "text"}
                            sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                            // startIcon={<AiFillHome />}
                            onClick={homeOpen}
                        >
                            Home
                        </Button>
                    </Box> */}
                    <Box sx={{ flexGlow: 1, marginLeft: 4 }}>
                        <Button size="medium" variant={navigationLocation.pathname.includes("home") ? "contained" : "text"}
                            sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                        >
                            <Link target="_blank" href={process.env.REACT_APP_ICMS1} color='warning'
                                style={{ cursor: 'pointer'}} underline='none'
                             >
                                <OpenInNewRoundedIcon sx={{ verticalAlign: 'bottom !important', paddingRight: '2px !important'}} />
                                    PDA V1
                             </Link>
                        </Button>
                    </Box>
                    {/* {process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid && <Box>
                         <Button
                             id="old-nbid"
                             sx={{ my: 2 }}
                             aria-controls={menuId}
                             className={classes.menuButton} color='warning'
                         >
                             <Link target="_parent" href={process.env.REACT_APP_NBID_URL} color='warning'
                                 style={{ cursor: 'pointer'}}
                                 underline='none'
                             >
                                 <OpenInNewRoundedIcon className={classes.menuButtonIcon} />
                                 {t('Old_Nbid')}
                             </Link>
                         </Button>
                     </Box>} */}
                    { (userDetails.loginDetails?.casesInvoled.length > 0 || userDetails.loginDetails?.subscriberRole.length > 0) &&
                        <Box sx={{ flexGlow: 1, marginLeft: 2 }}>
                        <Button size="medium" variant={navigationLocation.pathname.includes("case-management") ? "contained" : "text"}
                            sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                            // startIcon={<IoBriefcaseSharp />}
                            onClick={caseManagement}
                        >
                            Cases
                        </Button>
                    </Box>}
                    <Box sx={{ flexGlow: 1, marginLeft: 2 }}>
                        <Button size="medium" variant={navigationLocation.pathname.includes("file-claim") ? "contained" : "text"}
                            sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                            // startIcon={<GiReceiveMoney />}
                            onClick={claimOpen}
                        >
                            File Claim
                        </Button>
                    </Box>
                    <Box sx={{ flexGlow: 1, marginLeft: 2 }}>
                        <Button size="medium" variant={navigationLocation.pathname.includes("submit-eoi") ? "contained" : "text"}
                            sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                            onClick={submitEoI}
                        >
                            Submit EoI
                        </Button>
                    </Box>
                    <Box sx={{ flexGlow: 1, marginLeft: 2 }}>
                        <Button size="medium" variant={navigationLocation.pathname.includes("public-information") ? "contained" : "text"}
                            sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                            // startIcon={<GiReceiveMoney />}
                            onClick={publicInformation}
                        >
                            Public Information
                        </Button>
                    </Box>
                    <Box sx={{ flexGrow: 2 }} />
                    { isAccAdmin && <Box sx={{ marginRight: 2 }}>
                        {/* <Button size="medium" sx={{ textTransform: 'capitalize' , fontWeight: 'bold', color:'#26a3db'}} 
                            variant={navigationLocation.pathname.includes("admin-tools") ? "contained" : "text"}
                            onClick={adminTools}>
                            Admin Tools
                        </Button> */}
                        <Button size="medium" variant={navigationLocation.pathname.includes("admin-tools") ? "contained" : "text"}
                            sx={{ textTransform: 'capitalize', fontWeight: 'bold', color: navigationLocation.pathname.includes("admin-tools") ? '#fff' : '#26a3db' }}
                            onClick={adminTools}>
                            Admin Tools
                        </Button>

                    </Box>}
                    {
                        (isEnkAdmin || isEnkSupport) && <Box sx={{ marginRight: 2 }}>
                            <Button size="medium"
                                sx={{ textTransform: 'capitalize', fontWeight: 'bold', color: navigationLocation.pathname.includes("administration") ? '#fff' : '#26a3db' }}
                                // sx={{ textTransform: 'capitalize' , fontWeight: 'bold', color:'#26a3db'}} 
                                variant={navigationLocation.pathname.includes("administration") ? "contained" : "text"}
                                onClick={administration}>
                                Administration
                            </Button>
                        </Box>}
                    <Box sx={{ marginRight: 2 }}>
                        <Button size="medium" sx={{ textTransform: 'capitalize', fontWeight: 'bold', color: '#26a3db' }} >
                            Help Desk
                        </Button>
                    </Box>
                    <Box sx={{ marginRight: 0 }}>
                        <Button size="medium" sx={{ textTransform: 'capitalize', fontWeight: 'bold', color: '#26a3db' }}
                            endIcon={<Avatar sx={{
                                width: 26, height: 26, fontSize: '0.875rem !important', color: '#fff',
                                bgcolor: '#26a3db'
                            }}>{userDetails.loginDetails?.firstName[0]}</Avatar>}
                            onClick={handleOpenUserMenu}>
                            My profile
                        </Button>
                        <Menu
                            sx={{ mt: '32px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={() => handleCloseUserMenu('Profile')}>
                                <AccountCircle style={{ marginRight: 8, color: 'blue' }} /> {/* Adding the Profile icon */}
                                <Typography textAlign="center">{'Profile'}</Typography>
                            </MenuItem>
                            {/* <MenuItem onClick={() => handleCloseUserMenu('Account')}>
                                <Typography textAlign="center">{'Account'}</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => handleCloseUserMenu('Dashboard')}>
                                <Typography textAlign="center">{'Dashboard'}</Typography>
                            </MenuItem> */}
                            {/* { isAdmin &&
                                <MenuItem onClick={() => handleCloseUserMenu('Administration')}>
                                    <Typography textAlign="center">{'Administration'}</Typography>
                                </MenuItem>} */}
                            <MenuItem onClick={() => handleCloseUserMenu('Logout')}>
                                <ExitToApp style={{ marginRight: 8, color: 'red' }} /> {/* Adding the Logout icon */}
                                <Typography textAlign="center">{'Logout'}</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default TopBar;