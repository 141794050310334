import HomeWorkTwoToneIcon from '@mui/icons-material/HomeWorkTwoTone';
import { Box, Tab, Tabs, Grid, Typography, Stack } from "@mui/material";
import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { BsClipboardDataFill } from "react-icons/bs";
import { GiReceiveMoney, GiTakeMyMoney } from 'react-icons/gi';
import { HiMiniBriefcase, HiMiniUserGroup } from 'react-icons/hi2';
import { IoDocuments } from 'react-icons/io5';
import { RiChatHistoryFill, RiDatabaseFill, RiFundsBoxFill } from 'react-icons/ri';
import { AssetSideValue, AssetSideMenu } from "../icms/case-inside/case-modules/asset/AssetSideMenu";
import { AuditTrailSideValue, AuditTrailSideMenu } from "../icms/case-inside/case-modules/audit-trail/AuditTrailSideMenu";
import { CaseDetailSideValue, CaseDetailsSideMenu } from "../icms/case-inside/case-modules/case-detail/CaseDetailSideMenu";
import { CdSideValue, CdSideMenu } from '../icms/case-inside/case-modules/case-detail/corp-debtor/CdSideMenu';
import { ClaimSideValue, ClaimSideMenu } from "../icms/case-inside/case-modules/claim/ClaimSideMenu";
import { CommitteeSideValue, CommitteeSideMenu } from "../icms/case-inside/case-modules/committee/CommitteeSideMenu";
import { FinanceSideValue, FinanceSideMenu } from "../icms/case-inside/case-modules/finance/FinanceSideMenu";
import { FormReportSideValue, FormReportSideMenu } from "../icms/case-inside/case-modules/form-report/FormReportSideMenu";
import { LiquidationSideValue, LiquidationSideMenu } from "../icms/case-inside/case-modules/liquidation/LiquidationSideMenu";
import { ResolutionPlanSideValue, ResolutionPlanSideMenu } from "../icms/case-inside/case-modules/resolution-plan/ResolutionPlanSideMenu";
import { VdrSideValue, VdrSideMenu } from "../icms/case-inside/case-modules/vdr/VdrSideMenu";
import "./TopBar.css";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CaseContext } from '../common/context/case/CaseContext';
import { Stage } from '../common/GenericCodes';

export const ModuleValues = {
    CASE_DETAILS: 0,
    CORPORATE_DEBTOR: 1,
    CLAIM: 2,
    COMMITTE: 3,
    FORM_REPORT: 4,
    ASSET: 5,
    VDR: 6,
    FINANCE: 7,
    RESOLUTIONPLAN: 8,
    LIQUIDATION: 9,
    AUDIT_TRAIL: 10,
}

export const ModulesMenu = {
    0: {
        id: 'case-detail',
        value: ModuleValues.CASE_DETAILS,
        route: 'case-detail',
        title: 'Case Details',
        icons: <HiMiniBriefcase style={{ fontSize: '1.2rem' }} />,
        defaultSideNav: CaseDetailSideValue.CASE_PROFILE,
        sideMenu: CaseDetailsSideMenu
    },
    1: {
        id: 'corporate-debtor',
        value: ModuleValues.CORPORATE_DEBTOR,
        route: 'corporate-debtor',
        title: 'Debtor',
        icons: <RiChatHistoryFill style={{ fontSize: '1.2rem' }} />,
        defaultSideNav: CdSideValue.CORPORATE,
        sideMenu: CdSideMenu
    },
    2: {
        id: 'claim',
        value: ModuleValues.CLAIM,
        route: 'claim',
        title: 'Claim',
        icons: <GiReceiveMoney style={{ fontSize: '1.3rem' }} />,
        defaultSideNav: ClaimSideValue.FINANCIAL,
        sideMenu: ClaimSideMenu
    },
    3: {
        id: 'committee',
        value: ModuleValues.COMMITTE,
        route: 'committee',
        title: 'Committee',
        icons: <HiMiniUserGroup style={{ fontSize: '1.3rem' }} />,
        defaultSideNav: CommitteeSideValue.COMMITTEE,
        sideMenu: CommitteeSideMenu
    },
    4: {
        id: 'form-report',
        value: ModuleValues.FORM_REPORT,
        route: 'form-report',
        title: 'Form/Report',
        icons: <IoDocuments style={{ fontSize: '1.2rem' }} />,
        defaultSideNav: FormReportSideValue.FORMS,
        sideMenu: FormReportSideMenu
    },
    5: {
        id: 'asset',
        value: ModuleValues.ASSET,
        route: 'asset',
        title: 'Asset',
        icons: <HomeWorkTwoToneIcon style={{ fontSize: '1.4rem' }} />,
        defaultSideNav: AssetSideValue.DEBTOR_ASSETS,
        sideMenu: AssetSideMenu
    },
    6: {
        id: 'vdr',
        value: ModuleValues.VDR,
        route: 'vdr',
        title: 'VDR',
        icons: <RiDatabaseFill style={{ fontSize: '1.2rem' }} />,
        defaultSideNav: VdrSideValue.GENERAL_FILES,
        sideMenu: VdrSideMenu
    },
    // 7: {
    //     id: 'finance',
    //     value: ModuleValues.FINANCE,
    //     route: 'finance',
    //     title: 'Finance',
    //     icons: <RiFundsBoxFill style={{ fontSize: '1.2rem' }} />,
    //     defaultSideNav: FinanceSideValue.COST_SHEET,
    //     sideMenu: FinanceSideMenu
    // },
    8: {
        id: 'resolution-plan',
        value: ModuleValues.RESOLUTIONPLAN,
        route: 'resolution-plan',
        title: 'Resolution Plan',
        icons: <BsClipboardDataFill style={{ fontSize: '1.15rem' }} />,
        defaultSideNav: ResolutionPlanSideValue.RA_DETAILS,
        sideMenu: ResolutionPlanSideMenu
    },
    // 9: {
    //     id: 'liquidation',
    //     value: ModuleValues.LIQUIDATION,
    //     route: 'liquidation',
    //     title: 'Liquidation',
    //     icons: <GiTakeMyMoney style={{ fontSize: '1.59rem' }} />,
    //     defaultSideNav: LiquidationSideValue.ASSET_SALE,
    //     sideMenu: LiquidationSideMenu
    // },
    10: {
        id: 'audit-trail',
        value: ModuleValues.AUDIT_TRAIL,
        route: 'audit-trail',
        title: 'Audit Trail',
        icons: <RiChatHistoryFill style={{ fontSize: '1.2rem' }} />,
        defaultSideNav: AuditTrailSideValue.AUDIT_TRAIL,
        sideMenu: AuditTrailSideMenu
    },
    
}

const ModuleTopBar = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const navigationLocation = useLocation();
    const param = useParams();
    const [selectedFilterValue, setSelectedFilterValue] = useState(navigationLocation.state.moduleId);
    const { contextValue } = useContext(CaseContext);
    
    const getModuleAccess = (module) => {
        return Boolean(module.sideMenu.find(item => contextValue.pageRoleAccesses[item.value]?.view == true));
    }

    const getDefaultSideNav = (sideMenu) => {
        return sideMenu.find(item => contextValue.pageRoleAccesses[item.value]?.view == true).value;
    }

    const handleToggleButton = (event, newValue) => {
        event.preventDefault();
        if (newValue !== null) {
            setSelectedFilterValue(newValue);
        }
        if (navigationLocation.state?.moduleId !== newValue) {
            navigate(`/details/case-management/${param.caseId}/${ModulesMenu[newValue].route}`, {
                state: {
                    moduleId: newValue,
                    sideNav: getDefaultSideNav(ModulesMenu[newValue].sideMenu),
                    caseType: navigationLocation.state?.caseType,
                    data: navigationLocation.state?.data
                }
            })
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            {/* <AppBar position="static"> */}
                <Grid container sx={{padding: '4px 24px', background: 'ivory', flexDirection: 'column'}}>
                    <Stack direction='row' spacing={2} justifyContent='space-around'>
                        <Typography fontSize='small'><span style={{color: 'gray'}}>{t('Case_Ref')} : </span> 
                            {contextValue?.clientCaseNumber.length > 30 ? contextValue?.clientCaseNumber.slice(0, 30) + "..." : contextValue?.clientCaseNumber}
                        </Typography>
                        <Typography fontSize='small'><span style={{color: 'gray'}}>{t('NCLT_Order_No')} : </span>
                            {contextValue?.ncltCaseNumber.length > 30 ? contextValue?.ncltCaseNumber.slice(0, 30) + "..." : contextValue?.ncltCaseNumber}
                        </Typography>
                        <Typography fontSize='small'>
                            <span style={{color: 'gray'}}>{t('Corporate_Debtor_Name')} : </span>
                            {contextValue?.debtor.length > 30 ? contextValue?.debtor.slice(0, 30) + "..." : contextValue?.debtor}
                        </Typography>
                    </Stack>
                    {/* <Grid xs={4}>
                        <Typography fontSize='small'><span style={{color: 'gray'}}>{t('Case_Ref')} : </span> 
                            {contextValue?.clientCaseNumber.length > 30 ? contextValue?.clientCaseNumber.slice(0, 30) + "..." : contextValue?.clientCaseNumber}
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Typography fontSize='small'><span style={{color: 'gray'}}>{t('NCLT_Order_No')} : </span>
                            {contextValue?.ncltCaseNumber.length > 30 ? contextValue?.ncltCaseNumber.slice(0, 30) + "..." : contextValue?.ncltCaseNumber}
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Typography fontSize='small'>
                            <span style={{color: 'gray'}}>{t('Corporate_Debtor_Name')} : </span>
                            {contextValue?.debtor.length > 30 ? contextValue?.debtor.slice(0, 30) + "..." : contextValue?.debtor}
                        </Typography>
                    </Grid> */}
                </Grid>
                <Tabs
                    value={selectedFilterValue}
                    onChange={handleToggleButton}
                    aria-label="secondary tabs example"
                    sx={{
                        '.MuiTabs-indicator': {
                            // display: 'flex',
                            display: 'none'
                        },
                    }}
                >
                    {Object.entries(ModulesMenu).map(([key, module]) => (
                        getModuleAccess(module) && <Tab
                            key={module.id}
                            value={module.value} // Ensure the value is set correctly
                            aria-label="creditor details tabs"
                            label={
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    {module.icons}
                                    <span style={{ marginLeft: '8px' }}>{t(module.title)}</span>
                                </span>
                            }
                            sx={{
                                fontSize: '15px',
                                textTransform: 'capitalize',
                                // backgroundColor: '#e8eef2',
                                // margin: '0px',
                                margin: '0px 0px',
                                // borderRadius: '7px', 
                                borderLeft:'1px solid white',
                                borderRight:'1px solid white',
                                background: 'aliceblue',
                                // borderTop:'1px solid whitesmoke',
                                // borderBottom:'1px solid whitesmoke',
                                padding: '6px 12px', // Adjusted padding
                                minHeight: 'auto',
                                maxWidth: 'none',
                                '&.Mui-selected': {
                                    color: 'black',
                                    fontWeight: 'bold',
                                    background:'lightblue'
                                },
                                flexGrow: 1, // Ensure each tab takes up an equal amount of space
                            }}
                        />
                    ))}
                </Tabs>
            {/* </AppBar> */}
        </Box>
    );
}

export default ModuleTopBar;