import React, { useState, Fragment, useEffect }                            from 'react';
import { Autocomplete, TextField, InputAdornment, FormHelperText, FormControl, InputLabel, FormLabel } from '@mui/material';
import CircularProgress     from '@mui/material/CircularProgress';
import { ICMSAxiosInterceptor } from '../../config/axios.interceptor';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";


const FormikCommonAutoComplete = (props) => {
    const { formik, id, fieldName, label, size, required, options, searchText, setSearchText, optionFieldId, optionFieldTitle, optionFieldTitle2, disabled } = props;
    const {t} = useTranslation();
    const [onFocusSearch, setOnFocusSearch] = useState(false);

    const setFormikValue = (event, value) => {
        if (value != null) {
            formik.setFieldValue(fieldName, value);
        } else {
            formik.setFieldValue(fieldName, null);
        }
    }

    return (
        <>
            <FormLabel htmlFor={id} sx={{ fontSize: (theme) => theme.typography.body2 }} required={required}>{label}</FormLabel>
            <Autocomplete
                disabled={disabled}
                id={id}
                size={size}
                fullWidth
                required={required}
                value={formik.values[fieldName]}
                onChange={setFormikValue}
                inputValue={searchText}
                onInputChange={(event, newInputValue) => {
                    setSearchText(newInputValue.trimStart() );
                }}
                onBlur={formik.handleBlur}
                options={options}
                isOptionEqualToValue={(option, value) => option[optionFieldId] === value[optionFieldId]}
                getOptionLabel={ (option) => option[optionFieldTitle] + ((optionFieldTitle2 && option[optionFieldTitle2]) ? (' (' + option[optionFieldTitle2] + ')') : '' )}
                renderOption={(props, option) => <li {...props} key={option[optionFieldId]}>{option[optionFieldTitle]} { (optionFieldTitle2 && option[optionFieldTitle2]) ? ( '(' + option[optionFieldTitle2] + ')') : '' }</li>}
                renderInput={(params) => (
                    <TextField
                        sx={{
                            marginTop: '0px',
                            marginBottom: '0px',
                        }}
                        error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                        onFocus={() => setOnFocusSearch(true)}
                        onBlur={() => setOnFocusSearch(false)}
                        required={required}
                        margin="normal"
                        {...params}
                        InputProps={{  ...params.InputProps,
                            startAdornment: <InputAdornment position="start"><SearchIcon />{ !onFocusSearch && !formik.values[fieldName] && t('Search') }</InputAdornment>,
                            sx: {
                                background: (theme) => disabled ? theme.palette.grey[100] : theme.palette.background.paper,
                            }
                        }}
                    />
                )}
            />
            <FormHelperText style={{color:'red'}}>{ formik.touched[fieldName] && formik.errors[fieldName] }</FormHelperText>
        </>
    );
}

export default FormikCommonAutoComplete