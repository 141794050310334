import { Button, FormHelperText, Stack, Typography } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export const FormikOtpVerification = (props) => {
    const { id, fieldName, label, formik,isEmailOrMobile,resendOtp } = props;

    const {t} = useTranslation();

    const handleChange = (newValue) => {
        formik.setFieldValue(fieldName, newValue)
    }

    const validateChar = (value, index) => {
        return /^\d+$/.test(value)  ? true : false;
    }

    return (
        <Fragment>            
           {/* { (fieldName === "emailOtpValue" || isEmailOrMobile === 'email') ? 
           <Stack direction='row' spacing={1}>
                <MdOutlineMarkEmailUnread style={{fontSize: "1.5rem", color:'#66c8e4'}}/> */}
                {(fieldName === 'emailOtpValue' || isEmailOrMobile === 'email') && <Typography data-testid='email-label' style={{marginBottom:'5px'}}>{label}{( fieldName === 'emailOtpValue' || isEmailOrMobile === 'email') && <span style={{ color: 'red', marginLeft: '4px' }}>*</span>}
                </Typography>}
            {/* </Stack> :            
            <Stack direction='row' >
               <FcCellPhone style={{fontSize: "1.5rem"}}/> */}
                 {(fieldName === 'phoneOtpValue' || isEmailOrMobile === 'mobile') && <Typography data-testid='mobile-label' style={{marginBottom:'5px'}}>{label}{isEmailOrMobile === 'mobile' && <span style={{ color: 'red', marginLeft: '4px'}}>*</span>}
                </Typography>}
            {/* </Stack>
            } */}
            <MuiOtpInput
                id={id}
                value={formik.values[fieldName]}
                onChange={handleChange}
                length={6}
                sx={{ gap: '12px !important' }}
                TextFieldsProps={{
                    placeholder: '-', size: 'small', 'data-testid': id,
                    sx: { width: '40px' }, inputProps: { style: { padding: '8px' } } }}
                fontSize={1}
                validateChar={validateChar}
            />
            <FormHelperText error>{formik.touched[fieldName] && formik.errors[fieldName]}</FormHelperText>
            <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
                <Typography style={{color:'GrayText',marginTop:'10px'}} data-testid='dint-get-code'>{t("Didn't_Get_A_Code")}</Typography>
                {/* <Button endIcon={<LoopIcon />}  size='small' style={{marginTop:'10px'}}>Click to resend</Button> */}
                <Button data-testid='resend' size='small' style={{marginTop:'10px'}} onClick={resendOtp}>{t("Resend")}</Button>
                {/* <Typography>Click to resend</Typography> 
                <IconButton onClick={resendOtp}>
                    <LoopIcon style={{ color: 'blue' }} />
                </IconButton> */}
            </Stack>
        </Fragment>
    )
}