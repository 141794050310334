import { Button, Card, Divider, Stack } from "@mui/material";
import { GenerateForm } from "./generate-form/GenerateForm";
import { UploadForm } from "../../../../common/upload-form/UploadForm";
import { Fragment } from "react";
import { NavigateBeforeRounded, NavigateNextRounded } from "@mui/icons-material";
import { useParams } from "react-router-dom";

const GenerateUploadForm = (props) => {
    const { label, handlePrevious, handleNext, totalSteps, width, left } = props;
    const param = useParams();

    return (
        <Fragment>
            <Stack direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
                sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                    background: (theme) => theme.palette.background.paper,
                    border: '1px solid #eeeeee',
                }}>
                <GenerateForm width={'55%'} label={label} />
                <Divider orientation={'vertical'} flexItem />
                <UploadForm width={'45%'} label={label} />
            </Stack>
            <Card sx={{ bottom: 0, position: 'fixed', width: width, borderTop: '2px solid #eeeeee', left: left }}>
                <Stack justifyContent='space-between' direction="row">
                    <Button startIcon={<NavigateBeforeRounded className='back-icon' />} sx={{ float: 'left', mx: 5, my: 1 }}
                        onClick={handlePrevious} variant="outlined" color='primary'>{'Back'}
                    </Button>
                    {param.activeStep < totalSteps &&
                        <Button endIcon={<NavigateNextRounded className={'next-icon'} />} sx={{ float: 'right', mr: 5, my: 1 }} onClick={handleNext} variant="contained" color='primary'
                        >
                            {'Finish'}
                        </Button>}
                </Stack>
            </Card>
        </Fragment>
    )
}

export default GenerateUploadForm;