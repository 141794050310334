import { Fragment, useEffect, useState }            from "react";
import { Grid, Stack, ButtonGroup }                 from "@mui/material";
import { useFormik }                                from "formik";
import { useTranslation }                           from "react-i18next";
import moment                                       from "moment"
import { FormikLabelTextField }                     from "../../../../../common/formik-fields-components/FormikFieldComponents";
import { StepperSectionHeader }                     from "../../../../../common/icms-styled-components/StepperSectionHeader";
import { FormikNewDateTime }                        from '../../../../../common/formik-fields-components/FormikNewDateTime';
import { createClaim, deleteClaim, updateClaim }    from "../../../file-claim-services/ClaimService";
import ICMSLoader2                                  from "../../../../../common/icms-loader/icms-loader-2.O/ICMSLoader2";
import IcmsSnackbar                                 from "../../../../../common/icms-snackbar/IcmsSnackbar";
import { FormikLabelNumericFormat }                 from "../../../../../common/formik-fields-components/FormikLabelNumericFormat";
import { ClaimValidationSchema }                    from "../../creditor-details/CreditorYupSchema"
import { ICMSAxiosInterceptor }                     from "../../../../../config/axios.interceptor"
import { SelectLabelController }                    from "../../../../../common/formik-fields-components/SelectLabelController"
import ClaimAmountsTableView                        from '../ClaimAmountsTableView';
import ClaimGuaranteeList                           from '../claim-guarantee/ClaimGuaranteeList';
import ClaimChargesList                             from "../claim-charges/ClaimChargesList";
import ClaimAdjudicationList                        from "../claim-adjudication/ClaimAdjudicationList";
import ClaimDistributionList                        from "../claim-distribution/ClaimDistributionList";
import ICMSConfirmDialog                            from '../../../../../common/ConfirmationDialog/ICMSConfirmDialog';
import { ICMSButton }                               from '../../../../../common/icms-styled-components/IcmsStyledComponents';
import { CaseType, ClaimType, Stage }               from "../../../../../common/GenericCodes";
import ClaimPrincipalBorrowerDetail                 from "../claim-principal-borrower/ClaimPrincipalBorrowerDetail";
import ClaimBeneficiaryDetail                       from "../claim-beneficiary/ClaimBeneficiaryDetail";

const FinancialClaimDetail = (props) => {
    const { caseId, claimantId, claimDetail, listForClaim, closeCurrentPanel, index, caseStage,
        claimSubmission, canEditClaim, canEditBook, canEditAdmitted, canViewBook, caseCd, canEdit, claimMinDate,
        relationShipCd } = props;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [claimSubTypes, setClaimSubTypes] = useState([]);
    const [message, setMessage] = useState({ show: false, message: '', severity: '' });
    const [selectedInfoType, setSelectedInfoType] = useState('due');
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('Yes'),
        disAgreeBtnLabel: t('No'),
        onDisAgree: ''
    })

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        })
    }

    const cliamFormik = useFormik({
        initialValues: {
            debtDefaultId: claimDetail === null ? null : claimDetail.debtDefaultId,
            debtId: claimDetail === null ? null : claimDetail.debtId,
            disbursedAmount: claimDetail === null ? 0 : claimDetail.disbursedAmount,
            sanctionSaleAgreementAmount: claimDetail === null ? 0 : claimDetail.sanctionSaleAgreementAmount,
            claimTime: claimDetail === null ? null : claimDetail.claimTime,
            claimToTime: claimDetail === null ? null : claimDetail.claimToTime,
            cprincipal: claimDetail === null ? 0 : claimDetail.cprincipal,
            cinterest: claimDetail === null ? 0 : claimDetail.cinterest,
            cother: claimDetail === null ? 0 : claimDetail.cother,
            cdescription: claimDetail === null ? null : claimDetail.cdescription,
            totalClaim: claimDetail === null ? 0 : claimDetail.totalClaim,
            claimId: claimDetail === null ? null : claimDetail.claimId,
            admitId: claimDetail === null ? null : claimDetail.admitId,
            admittedTime: claimDetail === null ? null : claimDetail.admittedTime,
            principal: claimDetail === null ? 0 : claimDetail.principal,
            interest: claimDetail === null ? 0 : claimDetail.interest,
            other: claimDetail === null ? 0 : claimDetail.other,
            defaultFrom: claimDetail === null ? null : claimDetail.defaultFrom,
            defaultTo: claimDetail === null ? null : claimDetail.defaultTo,
            description: claimDetail === null ? null : claimDetail.description,
            totalBook: claimDetail === null ? 0 : claimDetail.totalBook,
            aprincipal: claimDetail === null ? 0 : claimDetail.aprincipal,
            ainterest: claimDetail === null ? 0 : claimDetail.ainterest,
            aother: claimDetail === null ? 0 : claimDetail.aother,
            adescription: claimDetail === null ? null : claimDetail.adescription,
            totalAdmit: claimDetail === null ? 0 : claimDetail.totalAdmit,
            claimCd: claimDetail === null ? (caseStage == Stage.RESOLUTION ? ClaimType.RES_FIN_AS_PRINCIPAL_BORROWER : ClaimType.LIQ_FIN_AS_PRINCIPAL_BORROWER) : claimDetail.claimCd,
            natureOfClaim: claimDetail === null ? null : claimDetail.natureOfClaim,
        },
        validationSchema: ClaimValidationSchema,
        onSubmit: () => {
            if (claimDetail === null) {
                createFCClaim();
            } else {
                updateFCClaim();
            }
        }
    })

    const handleChange = (event) => {
        cliamFormik.handleChange(event);
    }

    const getClaimCd = (parentId) => {
        ICMSAxiosInterceptor.get(`codes/claim/parent/${parentId}`).then(response => {
            setClaimSubTypes(response.filter(claimCd =>  (claimCd.codeCd != ClaimType.RES_HOME_BUYER && claimCd.codeCd != ClaimType.LIQ_HOME_BUYER)));
        }).catch(error => {
            console.log('error', error);
        })
    }

    const createFCClaim = () => {
        if (validateClaimDetails()) {
            setLoading(true);
            const { totalClaim, totalBook, totalAdmit, ...payload } = cliamFormik.values;
            createClaim(caseId, claimantId, payload,
                (response) => {
                    if(response) {
                        closeCurrentPanel();
                        listForClaim({ show: true, message: t('Claim_Created_Sucessfully'), severity: 'success' });
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    setMessage({ show: true, message: error.message, severity: 'error' });
                })
        }
    }

    const updateFCClaim = () => {
        if (validateClaimDetails()) {
            setLoading(true);
            const { totalClaim, totalBook, totalAdmit, ...payload } = cliamFormik.values;
            updateClaim(caseId, claimantId, claimDetail.debtDefaultId, payload,
                (response) => {
                    if (response) {
                        closeCurrentPanel(index);
                        listForClaim({ show: true, message: t('Claim_Updated_Sucessfully'), severity: 'success' });
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    setMessage({ show: true, message: error.message, severity: 'error' });
                })
        }
    }

    const validateClaimDetails = () => {
        if (cliamFormik.values.claimTime && cliamFormik.values.totalClaim === 0) {
            setMessage({ show: true, message: t('Claim_Amount_Cannot_Be_Zero'), severity: 'error' });
            return false;
        }
        // if (!cliamFormik.values.admittedTime && cliamFormik.values.totalAdmit > 0) {
        //     setMessage({ show: true, message: t('Admitted_Due_Date_Is_Mandatory_With_Admitted_Amount'), severity: 'error' });
        //     return false;
        // }
        return true;
    }

    const deleteConfirmation = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: `${t('Are_You_Sure_That_You_Want_To_Delete_Claim') } `,
            onDisAgree: resetSeekConfirmation,
            onAgree: () => {
                deleteFCClaim();
                resetSeekConfirmation();
            },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }


    const deleteFCClaim = () => {
        setLoading(true);
        deleteClaim(caseId, claimantId, claimDetail.debtDefaultId,
            (response) => {
                listForClaim({ show: true, message: t('Claim_Deleted_Sucessfully'), severity: 'success' });
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            })
    }

    const onSelectedInfoChange = (type) => {
        if (cliamFormik.values.debtDefaultId || type == 'due') {
            setSelectedInfoType(type);
        } else {
            setMessage({show: true, message: `${t('Please_Add_Due_Details_Before_Entering')} 
                ${type} ${t('details')}`, severity: 'error'});
        }
    }

    useEffect(() => {
        cliamFormik.setFieldValue('totalClaim', cliamFormik.values.cprincipal + cliamFormik.values.cinterest + cliamFormik.values.cother);
    }, [cliamFormik.values.cprincipal, cliamFormik.values.cinterest, cliamFormik.values.cother])

    useEffect(() => {
        cliamFormik.setFieldValue('totalBook', cliamFormik.values.principal + cliamFormik.values.interest + cliamFormik.values.other);
    }, [cliamFormik.values.principal, cliamFormik.values.interest, cliamFormik.values.other])

    useEffect(() => {
        cliamFormik.setFieldValue('totalAdmit', cliamFormik.values.aprincipal + cliamFormik.values.ainterest + cliamFormik.values.aother);
    }, [cliamFormik.values.aprincipal, cliamFormik.values.ainterest, cliamFormik.values.aother])

    useEffect(() => {
        if (caseCd == CaseType.CIRP || caseCd == CaseType.FTIRP) {
            getClaimCd(21)
        } else if (caseCd == CaseType.LIQUIDATION || caseCd == CaseType.VAL_LIQUIDATION) {
            getClaimCd(31)
        }
    }, [])
    
    return (
        <Fragment>
            <Stack>
                {
                    <ButtonGroup variant="outlined" aria-label="claimant-details" size="small">
                        <ICMSButton color='primary' 
                            variant={selectedInfoType === 'due' ? "contained" : "outlined"}
                            onClick={() => onSelectedInfoChange('due')}>{t('Due_Details')}</ICMSButton>
                        {(cliamFormik.values.claimCd === ClaimType.LIQ_FIN_AS_GUARANTOR ||
                            cliamFormik.values.claimCd === ClaimType.RES_FIN_AS_GUARANTOR) && <ICMSButton color='primary' 
                            variant={selectedInfoType === 'principal-borrower' ? "contained" : "outlined"}
                            onClick={() => onSelectedInfoChange('principal-borrower')}>{t('Principal_Borrower')}</ICMSButton>}
                        {(cliamFormik.values.claimCd === ClaimType.LIQ_FIN_COVERED_UNDER_CLAUSE_H_I ||
                            cliamFormik.values.claimCd === ClaimType.RES_FIN_COVERED_UNDER_CLAUSE_H_I) && <ICMSButton color='primary' 
                            variant={selectedInfoType === 'beneficiary' ? "contained" : "outlined"}
                            onClick={() => onSelectedInfoChange('beneficiary')}>{t('Beneficiary_Details')}</ICMSButton>}
                        <ICMSButton color='primary' 
                            variant={selectedInfoType === 'guarantee' ? "contained" : "outlined"}
                            onClick={() => onSelectedInfoChange('guarantee')}>{t('Guarantee')}</ICMSButton>
                        <ICMSButton color='primary' 
                            variant={selectedInfoType === 'charge' ? "contained" : "outlined"}
                            onClick={() => onSelectedInfoChange('charge')}>{t('Security_Interest')}</ICMSButton>
                        <ICMSButton color='primary' 
                            variant={selectedInfoType === 'adjudication' ? "contained" : "outlined"}
                            onClick={() => onSelectedInfoChange('adjudication')}>{t('Adjudication')}</ICMSButton>
                        {!claimSubmission && <ICMSButton color='primary' 
                            variant={selectedInfoType === 'distribution' ? "contained" : "outlined"}
                            onClick={() => onSelectedInfoChange('distribution')}>{t('Distribution')}</ICMSButton>}
                    </ButtonGroup>
                }
                {selectedInfoType === 'due' && <Stack>
                    <Grid container spacing={1} my={1}>
                        <Grid item xs={3}>
                            <SelectLabelController
                                id="claimCd"
                                fieldName="claimCd"
                                label={t("Claim_Made_Against_Corporate_Debtor_As")}
                                formik={cliamFormik}
                                size="small"
                                required={true}
                                menuItems={claimSubTypes}
                                menuFieldId='codeCd'
                                menuFieldTitle='description'
                                valueField='codeCd'
                                handleChange={handleChange}
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormikLabelNumericFormat
                                id="debtId"
                                fieldName="debtId"
                                label={t("Loan_Facility_Account_Number")}
                                formik={cliamFormik}
                                size="small"
                                isThousandSeparator={false}
                                required={true}
                                handleChange={handleChange}
                                disabled={!canEdit}
                                tooltip={t("Loan_Account_Number")}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormikLabelTextField
                                id="natureOfClaim"
                                fieldName="natureOfClaim"
                                label={t("Nature_Of_Claim")}
                                formik={cliamFormik}
                                size="small"
                                required={true}
                                handleChange={handleChange}
                                handleBlur={cliamFormik.handleBlur}
                                disabled={!canEdit}
                                tooltip={t("Brief_Description_Of_The_Claim")}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormikLabelNumericFormat
                                id="sanctionSaleAgreementAmount"
                                fieldName="sanctionSaleAgreementAmount"
                                label={t("Total_Sactioned_Amount_Rs")}
                                formik={cliamFormik}
                                size="small"
                                isThousandSeparator={true}
                                // required={true}
                                handleChange={handleChange}
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormikLabelNumericFormat
                                id="disbursedAmount"
                                fieldName="disbursedAmount"
                                label={t("Total_Disbursed_Amount_Rs")}
                                formik={cliamFormik}
                                size="small"
                                isThousandSeparator={true}
                                // required={true}
                                handleChange={handleChange}
                                disabled={!canEdit}
                            />
                        </Grid>
                    </Grid>
                    {!claimSubmission &&
                        <Stack>
                            <ClaimAmountsTableView cliamFormik={cliamFormik} canEditClaim={canEditClaim && canEdit}
                                    canEditBook={canEditBook && canEdit} canEditAdmitted={canEditAdmitted && canEdit} canViewBook={canViewBook}
                                    canEdit={canEdit} claimMinDate={claimMinDate} caseId={caseId} claimantId={claimantId}
                                    relationShipCd={relationShipCd}/>
                            <Grid container spacing={1} mt={1}>
                                <Grid item xs={6}>
                                    <FormikLabelTextField
                                        id='cdescription'
                                        fieldName='cdescription'
                                        label={t('Claimant_Remarks')}
                                        formik={cliamFormik}
                                        required={false}
                                        handleChange={handleChange}
                                        disabled={!canEdit}
                                        tooltip='Any Remarks that claimant needs to record'
                                    />
                                </Grid>
                                {<Grid item xs={6}>
                                    <FormikLabelTextField
                                        id='adescription'
                                        fieldName='adescription'
                                        label={t('IP_Remarks')}
                                        formik={cliamFormik}
                                        required={false}
                                        handleChange={handleChange}
                                        disabled={!canEditAdmitted || !canEdit || cliamFormik.values.totalAdmit == 0}
                                        tooltip='Any Remarks that ip needs to record'
                                    />
                                </Grid>}
                            </Grid>
                        </Stack>
                    }

                    {claimSubmission && <Fragment>
                        <StepperSectionHeader title={`${t('Claim_Amount_Details_As_On')} ${moment(new Date(claimMinDate)).format('DD/MM/YYYY')}`} />
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={2}>
                                <FormikNewDateTime
                                    id='claimTime'
                                    fieldName='claimTime'
                                    formik={cliamFormik}
                                    maxDate={cliamFormik.values.claimToTime ? cliamFormik.values.claimToTime : claimMinDate}
                                    label={t('Default_Date')}
                                    required={true}
                                    handleChange={handleChange}
                                    size='small'
                                    onlyDate={true}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormikLabelNumericFormat
                                    id="cprincipal"
                                    fieldName="cprincipal"
                                    label={t("Principal_Amount_Rs")}
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    required={true}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormikLabelNumericFormat
                                    id="cinterest"
                                    fieldName="cinterest"
                                    label={t("Interest_Amount_Rs")}
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    required={true}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormikLabelNumericFormat
                                    id="cother"
                                    fieldName="cother"
                                    label={t("Other_Charges_Rs")}
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    required={true}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormikLabelNumericFormat
                                    id="totalClaim"
                                    fieldName="totalClaim"
                                    label={t("Total_Claim_Amount_Rs")}
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    required={true}
                                    disabled={true}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikLabelTextField
                                    id='cdescription'
                                    fieldName='cdescription'
                                    label={t('Remarks')}
                                    formik={cliamFormik}
                                    required={false}
                                    handleChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Fragment>}
                    <Stack direction="row" justifyContent='end' spacing={1} mt={3}>
                        {canEdit && <ICMSButton color='primary' variant="outlined" size="small" onClick={() => closeCurrentPanel(index)}>
                            Cancel
                        </ICMSButton>}
                        {canEdit && <ICMSButton color='primary' variant="contained" size="small" onClick={cliamFormik.handleSubmit}
                                disabled={!cliamFormik.isValid}>
                            {claimDetail === null ? t('Save') : t('Update')} 
                        </ICMSButton>}
                        {(claimDetail !== null && canEdit) && <ICMSButton color='error' variant="contained" size="small" 
                                onClick={deleteConfirmation}>
                            {t('Delete')}
                        </ICMSButton>}
                    </Stack>
                </Stack>}
                {selectedInfoType === 'principal-borrower' && <Stack>
                    <ClaimPrincipalBorrowerDetail caseId={caseId} claimantId={claimantId} 
                        debtDefaultId={cliamFormik.values.debtDefaultId} claimAgainst={claimDetail?.claimAgainst}
                        canEdit={canEdit}/>
                </Stack>}
                {selectedInfoType === 'beneficiary' && <Stack>
                    <ClaimBeneficiaryDetail caseId={caseId} claimantId={claimantId} canEdit={canEdit}
                        debtDefaultId={cliamFormik.values.debtDefaultId} claimAgainst={claimDetail?.claimAgainst}/>
                </Stack>}
                {selectedInfoType === 'guarantee' && <Stack>
                    <ClaimGuaranteeList caseId={caseId} claimantId={claimantId} canEdit={canEdit}
                            debtDefaultId={cliamFormik.values.debtDefaultId}/>
                </Stack>}
                {selectedInfoType === 'charge' && <Stack>
                    <ClaimChargesList caseId={caseId} claimantId={claimantId} canEdit={canEdit}
                            debtDefaultId={cliamFormik.values.debtDefaultId}/>
                </Stack>}
                {selectedInfoType === 'adjudication' && <Stack>
                    <ClaimAdjudicationList caseId={caseId} claimantId={claimantId} canEdit={canEdit}
                            debtDefaultId={cliamFormik.values.debtDefaultId}/>
                </Stack>}
                {selectedInfoType === 'distribution' && <Stack>
                    <ClaimDistributionList caseId={caseId} claimantId={claimantId} canEdit={canEdit}
                            debtDefaultId={cliamFormik.values.debtDefaultId} claimId={cliamFormik.values.claimId}
                            refresh={listForClaim}/>
                </Stack>}
            </Stack>
            {loading && <ICMSLoader2 show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
            { seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}
            /> }
        </Fragment>
    )
}

export { FinancialClaimDetail }