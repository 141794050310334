import React, { Fragment }              from "react";  
import { Stack }                        from "@mui/material";
import { useTranslation }               from "react-i18next";
import { DescriptiveDatePicker, 
    DescriptiveTextField }              from "../../../../../common/formik-fields-components/DescriptiveTextField"
import { CaseType } from "../../../../../common/GenericCodes";

const GenerateFormFieds = ({formik, caseCd}) => {
    const { t } = useTranslation();

    return (
        <Stack spacing={3} width='100%'> 
            <DescriptiveTextField
                id='verifiedAt'
                fieldName='verifiedAt'
                label={t('Verified_At')}
                description={t('Place_Where_The_Proof_Of_Claim_Is_Verified')}
                formik={formik}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
            />
            <DescriptiveDatePicker
                id='verifiedDate'
                fieldName='verifiedDate'
                label={t('Verified_Date')}
                description={t('Date_The_Proof_Of_Claim_Is_Verified')}
                formik={formik}
                fullWidth={true}
                handleBlur={formik.handleBlur}
                maxDate={new Date()}
                handleChange={formik.handleChange}
            />
            {caseCd != CaseType.CIRP && <Fragment>
                <DescriptiveTextField
                    id='affirmedAt'
                    fieldName='affirmedAt'
                    label={t('Affirmed_At')}
                    description={t('Place_Where_The_Proof_Of_Claim_Is_Affirmed')}
                    formik={formik}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                />
                <DescriptiveDatePicker
                    id='affirmedDate'
                    fieldName='affirmedDate'
                    label={t('Affirmed_Date')}
                    description={t('Date_The_Proof_Of_Claim_Is_Affirmed')}
                    formik={formik}
                    fullWidth={true}
                    handleBlur={formik.handleBlur}
                    maxDate={new Date()}
                    handleChange={formik.handleChange}
                    disabled
                />
            </Fragment>}
            {caseCd == CaseType.CIRP && <Fragment>
                <DescriptiveDatePicker
                    id='submissionDate'
                    fieldName='submissionDate'
                    label={t('Submission_Date')}
                    description={t('Date_Of_Submission_Of_Claim')}
                    formik={formik}
                    handleChange={formik.handleChange}
                    // required={true}
                    handleBlur={formik.handleBlur}
                    minDate={new Date()}
                    disabled
                />
                <DescriptiveTextField
                    id='submissionPlace'
                    fieldName='submissionPlace'
                    label={t('Submission_Place')}
                    description={t('Place_From_Where_The_Form_Is_Submitted')}
                    formik={formik}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                />
            </Fragment>}
        </Stack>
    )
}

export default GenerateFormFieds;