import { Fragment } from "react";
import "../ICMSLoader.css";
import { Backdrop } from "@mui/material";
import SVGSpinner2 from "./SVGSpinner2";

const ICMSLoader2 = (props) => {
    const { show, invisible } = props;

    return (
        <Fragment>
            <Backdrop className='icms-backdrop' data-testid="icms-spinner2" open={show} invisible={invisible}>
                <SVGSpinner2 />
            </Backdrop>
        </Fragment>
    )
}

export default ICMSLoader2;