import { FormLabel, InputAdornment, Stack, TextField, Tooltip } from '@mui/material';
import { Fragment } from 'react';
import { BiSolidMessageError } from 'react-icons/bi';
import { FaCheck, FaIndianRupeeSign } from 'react-icons/fa6';
import { NumericFormat } from 'react-number-format';
import { getIn } from "formik";


const FormikLabelNumericFormat = (props) => {
    const { id, fieldName, label, formik, handleChange, disabled, size, required, handleBlur, isThousandSeparator, 
        nestedFormik, align, hideLabel, allowNegative, customStyle, tooltip } =
    {
        ...props, size: props.size || "medium",
        isThousandSeparator: props.isThousandSeparator || false
    };

    const handleKeyDown = (event) => {
        let data = nestedFormik ? getIn(formik.values, fieldName) : formik.values[fieldName]
        if (!allowNegative && event.key === '-') {
            event.preventDefault();
        }

        if (event.key != 'Tab' && event.key != 'Backspace' && data >= 100000000000000) {
          event.preventDefault();
        }
    };

    return (
        <Stack>
            { label && <FormLabel htmlFor={id} sx={{ fontSize: (theme) => theme.typography.body2 }} required={required}>{label}</FormLabel> }
            <Tooltip title={tooltip} arrow>
            <NumericFormat
                id={id}
                name={fieldName}
                required={required}
                value={nestedFormik ? getIn(formik.values, fieldName) : formik.values[fieldName]}
                customInput={TextField}
                thousandSeparator={isThousandSeparator}
                thousandsGroupStyle="lakh"
                decimalScale={2}
                size={size}
                onKeyDown={handleKeyDown}
                error={nestedFormik ? (getIn(formik.touched, fieldName) && Boolean(getIn(formik.errors, fieldName))) : (formik.touched[fieldName] && Boolean(formik.errors[fieldName]))}
                helperText={nestedFormik ? (getIn(formik.touched, fieldName) && getIn(formik.errors, fieldName)) : (formik.touched[fieldName] && formik.errors[fieldName])}
                // prefix={prefix + " "}
                disabled={disabled}
                allowNegative={allowNegative}
                onValueChange={({ value }) => {
                    if (value != '' && value != '-' && value != null) {
                        formik.setFieldValue(fieldName, Number(value))
                    } else {
                        formik.setFieldValue(fieldName, null)
                    }
                }}
                onBlur={handleBlur}
                onFocus={() => formik.setFieldTouched(fieldName, true)}
                InputProps={{
                    endAdornment: (
                        <Fragment>
                            <InputAdornment position='end'>
                                {!nestedFormik && formik.touched[fieldName] && Boolean(formik.errors[fieldName]) && <BiSolidMessageError style={{ color: '#d32f2f', fontSize: '1.5rem' }} />}
                                {/* {!nestedFormik && required && formik.touched[fieldName] && !formik.errors[fieldName] && formik.values[fieldName] && <FaCheck style={{ color: '#4caf50', fontSize: '1.3rem' }} />} */}
                                {nestedFormik && getIn(formik.touched, fieldName) && Boolean(getIn(formik.errors, fieldName)) && <BiSolidMessageError style={{ color: '#d32f2f', fontSize: '1.5rem' }} />}
                                {/* {nestedFormik && required && getIn(formik.touched, fieldName) && !getIn(formik.errors, fieldName) && getIn(formik.values, fieldName) && <FaCheck style={{ color: '#4caf50', fontSize: '1.3rem' }} />} */}
                            </InputAdornment>
                        </Fragment>
                    ),
                    sx: {
                        background: (theme) => disabled ? theme.palette.grey[100] : theme.palette.background.paper,
                    }
                    // startAdornment: (isThousandSeparator &&
                    //     <InputAdornment>
                    //         <FaIndianRupeeSign />
                    //     </InputAdornment>
                    // )
                }}
                inputProps={{
                    "data-testid": id,
                    style: {
                        textAlign: align ? align : 'left',
                        ...customStyle
                    },
                }}
            />
            </Tooltip>
        </Stack>
    )
}

export { FormikLabelNumericFormat }