import { ICMSAxiosInterceptor } from "../../../config/axios.interceptor";

const listAuthRep = (caseId, claimantId, handleSuccessCallback, handleErrorCallBack) => {

    ICMSAxiosInterceptor.get(`case/${caseId}/claimant/${claimantId}/authorized-representative/list`).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const createAuthRep = (caseId, claimantId, authRepResource, handleSuccessCallback, handleErrorCallBack) => {

    ICMSAxiosInterceptor.post(`case/${caseId}/claimant/${claimantId}/authorized-representative`, authRepResource).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const updateAuthRep = (caseId, claimantId, authRepId, authRepResource, handleSuccessCallback, handleErrorCallBack) => {
    ICMSAxiosInterceptor.put(`case/${caseId}/claimant/${claimantId}/authorized-representative/${authRepId}`, authRepResource)
            .then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const getAuthRep = (caseId, claimantId, authRepId, handleSuccessCallback, handleErrorCallBack) => {
    ICMSAxiosInterceptor.get(`case/${caseId}/claimant/${claimantId}/authorized-representative/${authRepId}`).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const deleteAuthRep = (caseId, claimantId, authRepId, handleSuccessCallback, handleErrorCallBack) => {

    ICMSAxiosInterceptor.delete(`case/${caseId}/claimant/${claimantId}/authorized-representative/${authRepId}`).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

export { listAuthRep, createAuthRep, updateAuthRep, getAuthRep, deleteAuthRep }