import { Divider, Typography } from "@mui/material";

const StepperSectionHeader = (props) => {
    const { title } = props;

    return (
        <Divider sx={{
            "&::before, &::after": {
                // borderColor: "secondary.main",
                borderTop: '2px solid lightsteelblue'
            },
        }} textAlign="center">
            <Typography
                sx={{
                    fontStyle: 'italic',
                    fontWeight: '600',
                    // color: "secondary.main"
                    color: 'cornflowerblue'
                   
                }}
            >
                {title}
            </Typography>
        </Divider>
    )
}

export { StepperSectionHeader }