import { Button, CardContent, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidMobileVibration } from "react-icons/bi";
import { IoMailUnread } from "react-icons/io5";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { EMAIL, MOBILE_NUMBER, RegEx } from '../common/GenericCodes';
import ICMSReCaptcha from '../common/ICMSReCaptcha';
import { FormikMuiPhoneNumber, FormikPasswordConfirmField, FormikPasswordField, FormikTextField } from '../common/formik-fields-components/FormikFieldComponents';
import ICMSLoader from '../common/icms-loader/ICMSLoader';
import IcmsSnackbar from '../common/icms-snackbar/IcmsSnackbar';
import { AuthDiv, JustifyCard } from '../common/icms-styled-components/IcmsStyledComponents';
import { AuthAxiosInterceptor } from '../config/axios.interceptor';
import {TiInfo} from "react-icons/ti"

function ForgotPassword() {
    const navigate = useNavigate();
    const {t} = useTranslation(); 
    const stateValues = useLocation()
    const [pwdFocus, setPwdFocus] = useState(false)
    const [message, setMessage] = useState({
        show: false,
        message: null,
        severity: null
    })
    const [loading, setLoading] = useState(false);
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);

    const ForgotValidationSchema = Yup.object().shape({
        isEmailOrMobile: Yup.string(),
        email: Yup.string().when('isEmailOrMobile', {
            is: (isEmailOrMobile) => { return isEmailOrMobile === EMAIL},
            then: () => Yup.string().email(t('Invalid_Email_Address')).test('custom-validation', t('Invalid_Email_Address'), (value) => {
                // Check if the email does not match the regex pattern for invalid email addresses
                return RegEx.EMAIL.test(value);
              }).required(t('Email_Address_Is_Required')),
            otherwise: () => Yup.string()
        }),
        extension: Yup.string(),
        phoneNumber: Yup.string().when('isEmailOrMobile', {
            is: (isEmailOrMobile) => { return isEmailOrMobile === MOBILE_NUMBER },
            then: () => Yup.string().required("Required")
                .test("phoneNumber", t("Mobile_Number_Is_Not_Valid"), (str, context) => {
                    return isValidPhoneNumber(context.parent['extension'] + str);
                }),
            otherwise: () => Yup.string()
        }),
        newPassword: Yup.string()
            .required("Please enter a password")
            .matches(
                RegEx.PASSWORD,
                "Please Enter Strong Password"
            ),
        confirmPassword: Yup.string().required(t('Confirm_Password_Required'))
        .oneOf([Yup.ref('newPassword'), null], t('Passwords_Must_Match'))
    });
    

    const forgotFormik = useFormik({
        initialValues:{
            isEmailOrMobile: "email",
            email: '',
            phoneNumber: '',
            newPassword:'',
            confirmPassword:"",
            extension: '+91',
        },
        onSubmit: values => {
            requestOtp()
        },
        validationSchema: ForgotValidationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true,
    })

    const handleChange = (event) => {
        forgotFormik.setFieldTouched([event.target.id], true);
        forgotFormik.handleChange(event);
    }

    const handleBlur = (fieldName) => {
        setPwdFocus(false)
        forgotFormik.setFieldValue(fieldName, false );
      };
    
      const handleFocus = (fieldName) => {
        setPwdFocus(true)
        forgotFormik.setFieldValue(fieldName, true );
      };

    const requestOtp = () => {
        setLoading(true)
        AuthAxiosInterceptor.patch(`user/request-otp?recaptcha=${captcha}&purpose=reset-password`, {
            loginId : forgotFormik.values.isEmailOrMobile === MOBILE_NUMBER ? forgotFormik.values.extension + forgotFormik.values.phoneNumber : forgotFormik.values.email,
            password: forgotFormik.values.newPassword
        }).then(response => {
            setMessage({ show: true, message: t('OTP_Sent_Successfully'), severity: 'success' });
            setLoading(false)
            navigate('/reset-password',{state:{ username:forgotFormik.values.isEmailOrMobile === EMAIL ? forgotFormik.values.email :
                    forgotFormik.values.extension + forgotFormik.values.phoneNumber,
                    isEmailOrMobile: forgotFormik.values.isEmailOrMobile, 
                    password: forgotFormik.values.newPassword
                }})
        }).catch((error) => {
            setLoading(false)
            if (error.message) {
                if(error.message.includes('ReCaptcha Expired')) {
                    setIsRefresh(true);
                    setMessage({ show: true, message: t("ReCaptcha_Expired"), severity: 'error' });
                } else {
                    setMessage({ show: true, message: error.message, severity: 'error' });
                }
            }
            setIsRefresh(true);
            setTimeout(() => {
                setIsRefresh(false);
            }, 1000);
        })
    }

    const backToSignIn = () => {
        navigate('/sign-in')
    }

  return (
    <Fragment>
        <AuthDiv>
            <Stack justifyContent='center' alignItems='center' m='auto' height="100vh">
                <JustifyCard style={{width:'60%'}}>
                    <CardContent sx={{p: 0, pb: '0px !important'}}>
                            {stateValues.state?.accountLocked && <Typography ariant="h6" sx={{ color:'red', background: '#fcf4ec', textAlign:'center' }}>
                                {`Your account ${stateValues.state?.username} is locked, please Reset Password to continue using the application`}
                                </Typography>}
                                {stateValues.state?.accountExpired && <Typography ariant="h6" sx={{ color:'red', background: '#fcf4ec', textAlign:'center' }}>
                                {`Your account ${stateValues.state?.username} is Expired, please Reset Password to continue using the application`}
                                </Typography>}
                    <Stack direction='row'>
                        <Stack p={2} width='45%' spacing={2} alignItems='center' justifyContent='center' sx={{ background: '#e8f0ffcf' }}>
                            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{(stateValues.state?.accountLocked || stateValues.state?.accountExpired) ? 'Reset Password' : 'Forgot Password'}</Typography>
                            {!(stateValues.state?.accountLocked || stateValues.state?.accountExpired) && <Typography variant="body2" color="GrayText" sx={{fontWeight: 'bold'}}>{t('Reset_Your_Password')}</Typography>}
                            <img src={process.env.PUBLIC_URL + 'images/forgot.svg'} alt="Login-png" width="60%"></img>
                        </Stack>
                        <Stack p={2} width='55%' spacing={2.5} mt={2} >
                            <Stack alignItems='center' justifyContent='center' spacing={2}>
                            <img src={process.env.PUBLIC_URL+"/images/Group.svg"} alt="ICMS_LOGO" width="30%"></img>
                            
                            <ToggleButtonGroup
                                color="primary"
                                id="isEmailOrMobile"
                                name="isEmailOrMobile"
                                value={forgotFormik.values.isEmailOrMobile}
                                exclusive
                                size="small"
                                onChange={forgotFormik.handleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton value="email" name="isEmailOrMobile" sx={{ fontWeight: 'bold' }}><IoMailUnread style={{fontSize: "1.45rem"}}/>&nbsp; {t("Email_Address")}</ToggleButton>
                                <ToggleButton value="mobile" name="isEmailOrMobile" sx={{ fontWeight: 'bold' }}><BiSolidMobileVibration style={{ fontSize: "1.5rem" }} />&nbsp; {t("Mobile_Number")}</ToggleButton>
                            </ToggleButtonGroup>
                            <Stack spacing={1} direction='row'>
                            <TiInfo fontSize={20} color='blue'/>
                            <Typography variant="subtitle2" sx={{color:'blue' }} data-testid='info-message'>{ `${t('Ensure_that_your')} ${forgotFormik.values.isEmailOrMobile === EMAIL ? 
                                t("Email_Address") : t("Mobile_Number")} ${t('is_registered_with_us')}`}</Typography>
                            </Stack>
                            </Stack>
                            {forgotFormik.values.isEmailOrMobile === 'email' ? <FormikTextField
                                        id="email"
                                        fieldName="email"
                                        label={t("Email_Address")}
                                        formik={forgotFormik}
                                        handleChange={handleChange}
                                        // disabled={forgotFormik.values.mfaEnabled}
                                        validationIcon={true}
                                    /> :
                                    <FormikMuiPhoneNumber
                                        id="phoneNumber"
                                        fieldName="phoneNumber"
                                        label={t("Mobile_Number")}
                                        countryCodeName="extension"
                                        formik={forgotFormik}
                                        handleChange={handleChange}
                                        // disabled={forgotFormik.values.mfaEnabled}
                                        validationIcon={true}
                                    />}
                                    <FormikPasswordField
                                        id="newPassword"
                                        fieldName="newPassword"
                                        label={t("New_Password")}
                                        formik={forgotFormik}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        handleFocus={handleFocus}
                                        pwdFocus={pwdFocus}
                                        validationIcon={true}
                                    />
                                    <FormikPasswordConfirmField 
                                        id="confirmPassword"
                                        fieldName="confirmPassword"
                                        label={t("Confirm_Password")}
                                        formik={forgotFormik}  
                                        handleChange={handleChange}
                                        validationIcon={true}
                                    />
                            <Stack direction='row' justifyContent='space-between'>
                                <Button onClick={backToSignIn} data-testid='navigate-to-signin' variant='outlined'>{t("Back_To_Sign_In")}</Button>
                                <Button onClick={forgotFormik.handleSubmit} variant='contained' disabled={!forgotFormik.dirty || !forgotFormik.isValid}  data-testid='send-otp'>{t("Send_Otp")}</Button>
                            </Stack>
                        </Stack>
                    </Stack>
                    </CardContent>
                </JustifyCard>
            </Stack>
        </AuthDiv>
        {/* {openOtpDialog && <ResetPassword username={forgotFormik.values.isEmailOrMobile === EMAIL ? forgotFormik.values.email :
        forgotFormik.values.extension + forgotFormik.values.phoneNumber} isEmailOrMobile={forgotFormik.values.isEmailOrMobile} setMessage={setMessage} password={forgotFormik.values.newPassword} setOpenOtpDialog={setOpenOtpDialog}/>} */}
        {loading && <ICMSLoader show={loading}/>}
        {!isRefresh && <ICMSReCaptcha key="login-withmfa" captcha={setCaptcha} refresh={setIsRefresh} />}
        {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
        
    </Fragment>
                            
  )
}

export default ForgotPassword

