import React, {
    useState,
    useEffect
}                               from 'react';
import {
    Grid,
    TableBody,
    TableHead,
    IconButton,
    Tooltip
}                               from '@mui/material';
import {
    IcmsTableContainer,
    IcmsMuiTable,
    IcmsTableRow,
    StyledTableCell
}                               from '../generic-styles/NavPageContainerStyles';
import { useTranslation }       from 'react-i18next';
import AddIcon                  from '@mui/icons-material/Add';
import { ICMSAxiosInterceptor } from '../../config/axios.interceptor';
import ICMSLoader               from '../../common/icms-loader/ICMSLoader';
import IcmsSnackbar             from '../../common/icms-snackbar/IcmsSnackbar';
import IdentificationRow        from './IdentificationRow';

const IdenticationList = ({partyId, partyCd, canAdd, canModify, canDelete}) => {
    const { t } = useTranslation();
    const [identifications, setIdentifications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    });
    const columns = [
        { id: 'type', label: 'Type', minWidth: '25%' },
        { id: 'id', label: 'ID', minWidth: '50%' },
        { id: 'action', label: 'Action', minWidth: '25%', align: 'center' }
    ]

    const addIdentification = () => {
        let identification = {
            identification: '',
            identificationCd: '',
            isEdit: true
        }
        setIdentifications(prevState => ([
            identification,
            ...prevState
        ]))
    }

    const getIdentifications = () => {
        if (partyId) {
            setLoading(true);
            ICMSAxiosInterceptor.post(`identification/list/${partyId}`, {}).then(response => {
                setIdentifications(response.items);
                setLoading(false);
            }).catch(error => {
                if (error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                }
                setLoading(false);
            })
        }
    }

    const createIdentification = (identificationDetails) => {
        if (partyId) {
            setLoading(true);
            ICMSAxiosInterceptor.post(`identification/${partyId}`, identificationDetails).then(response => {
                getIdentifications();
                setMessage({show: true, message: t('Created_Identification_Successfully'), severity: 'success'});
                setLoading(false);
            }).catch(error => {
                if (error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                }
                setLoading(false);
            })
        }
    }

    const updateIdentification = (identificationDetails) => {
        setLoading(true);
        ICMSAxiosInterceptor.patch(`identification/${identificationDetails.identificationId}`, identificationDetails).then(response => {
            getIdentifications();
            setMessage({show: true, message: t('Updated_Identification_Successfully'), severity: 'success'});
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            }
            setLoading(false);
        })
    }

    const deleteIdentification = (identificationId) => {
        if (partyId) { 
            setLoading(true);
            ICMSAxiosInterceptor.delete(`identification/${identificationId}`).then(response => {
                getIdentifications();
                setMessage({show: true, message: t('Deleted_Identification_Successfully'), severity: 'success'});
                setLoading(false);
            }).catch(error => {
                if (error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                }
                setLoading(false);
            })
        }
    }

    useEffect(() => {
        getIdentifications();
    }, [])
    

    return (
        <Grid container direction="row" alignItems="baseline">
            <IcmsTableContainer>
                {canAdd && <Grid container sx={{padding: '0px 20px 0px 20px !important'}} spacing={2}>
                    <Grid item xs={12}>
                        <Tooltip title={t('Add_Identification')}>
                            <IconButton data-testid="add-identification-btn" color="primary" size="small" sx={{float: 'right'}}
                                onClick={addIdentification}><AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>}
                <IcmsMuiTable stickyHeader aria-label="sticky table" data-testid="identification-list-table">
                    <TableHead>
                        <IcmsTableRow>
                            {columns.map((column) => (
                                <StyledTableCell
                                    sx={{padding: '4px'}}
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {t(column.label)}
                                </StyledTableCell>
                            ))}
                        </IcmsTableRow>
                    </TableHead>
                    <TableBody>
                        {identifications.length === 0 && <IcmsTableRow colSpan={2}>
                            <StyledTableCell sx={{padding: '8px'}}>
                                {t('No_Record_Found')}
                            </StyledTableCell>
                        </IcmsTableRow>}
                        {!loading && identifications.map((identification, index) => {
                            return <IdentificationRow key={identification.identification} identificationDetails={identification} 
                                        handleCreate={createIdentification} handleUpdate={updateIdentification} 
                                        handleDelete={deleteIdentification} handleRefresh={getIdentifications} 
                                        edit={identification.isEdit} partyCd={partyCd} partyId={partyId}
                                        canModify={canModify} canDelete={canDelete}/>
                        })}
                    </TableBody>
                </IcmsMuiTable>
            </IcmsTableContainer>
            {loading && <ICMSLoader show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </Grid>
    )
}

export default IdenticationList