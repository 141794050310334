import react, { useState, Fragment }    from 'react';
import { Stack, Typography }            from "@mui/material";
import { useTranslation }               from 'react-i18next';
import { UploadDropZone }               from "./UploadDropZone";
import { ICMSButton }                   from "../icms-styled-components/IcmsStyledComponents";
import { ICMSAxiosInterceptor, ReportAxiosInterceptor }       from '../../config/axios.interceptor';
import ICMSLoader                       from '../icms-loader/ICMSLoader';
import IcmsSnackbar                     from '../icms-snackbar/IcmsSnackbar';
import { UploadDocExt }                 from '../../common/GenericCodes';
import { ApplicantId, ApplicationName, ClaimStatusType, Stage } from '../GenericCodes';
import axios from 'axios';

const UploadForm = (props) => {
    const {width, label, caseId, formName, transactionId, fileName, acceptableFileTypes, onSuccess, caseStage,
        isClaimForm} = props;


    const { t } = useTranslation();
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});

    const getFileType = () => {
        let reportType = 'PDF_A';
        let extension = files[0].name ? files[0].name.split('.').pop() : 'pdf';
        if(extension.toLowerCase().includes('xls') || extension.toLowerCase().includes('xlsx')) {
            reportType = 'EXCEL';
        }
        return reportType;
    }

    const uploadForm =  async () => {
        if (isClaimForm) {
            try {
                let fileLocation = `${ApplicationName}/${process.env.REACT_APP_ENV}/${caseId}/FORMS/${formName?.name}/${fileName}.pdf`;
                setLoading(true);
                await ReportAxiosInterceptor.get(`presigned-url/true?fileName=${fileLocation}`).then(presignedUrl => {
                        axios.put(presignedUrl, files[0], {
                            headers: {
                                'Content-Type': files[0].type
                            },
                        }).then((response) => {
                            let claimStatusCd = caseStage == Stage.RESOLUTION ? ClaimStatusType.RES_CLAIM_SUBMITTED : ClaimStatusType.LIQ_CLAIM_SUBMITTED
                            let payload = {
                                template: formName?.template,
                                reportOf: ''+caseId,
                                applicationId: ApplicantId,
                                reportCd: formName?.code,
                                reportLocale: formName?.name,
                                transactionId: transactionId,
                                fileName: fileName,
                                version: true,
                                reportType: "PDF_A",
                                location: fileLocation,
                                versionId: response?.headers.get('x-amz-version-id')
                            }
                            ICMSAxiosInterceptor.post(`case/${caseId}/claim-form/claim-status/${transactionId}/${caseStage}/${claimStatusCd}`, payload).then(res => {
                                onSuccess({show: true, message: t('Uploaded_Successfully'), severity: 'success'});
                                setLoading(false);
                            }).catch(error => {
                                if (error?.message) {
                                    setMessage({ show: true, message: error.message, severity: 'error' });
                                } else {
                                    setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
                                }
                                setLoading(false);
                            });
                        })
                    })
            } catch (error) {
                if (error?.message) {
                    setMessage({ show: true, message: error.message, severity: 'error' });
                } else {
                    setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
                }
                setLoading(false);
            }
        } else {
            let payload = {
                template: formName?.template,
                reportOf: ''+caseId,
                applicationId: 4, 
                reportCd: formName?.code,
                reportLocale: formName?.name,
                transactionId: transactionId,
                fileName: `${fileName}`,
                version: true,
                reportType: getFileType()
            }
            let formData = new FormData();
            formData.append('file', files[0]);
            formData.append('data', JSON.stringify(payload));
            setLoading(true);
            ReportAxiosInterceptor.post(`upload-file?reportOf=${caseId}`, formData).then(response => {
                onSuccess({show: true, message: t('Uploaded_Successfully'), severity: 'success'});
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                if (error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                } else {
                    setMessage({show: true, message: t('Try_Again'), severity: 'error'});
                }
            })
        }
    }

    return (
        <Fragment>
            <Stack alignItems='center' justifyContent="space-between" width={width} px={2} py={1} spacing={1.5}>
                <Typography variant="h6">Upload {label}</Typography>
                <UploadDropZone setFiles={setFiles} files={files} acceptableFiles={ UploadDocExt.default }/>
                <ICMSButton color='primary' variant="contained" sx={{float: 'inline-end'}} data-testid="fc-upload"
                        onClick={uploadForm} size='small' disabled={files.length == 0}>Upload</ICMSButton>
            </Stack>
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} 
                    handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
        </Fragment>
    )
}

export {UploadForm}