import { Add, NavigateBeforeRounded, NavigateNextRounded } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, Stack, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ICMSLoader2 from "../../../../common/icms-loader/icms-loader-2.O/ICMSLoader2";
import IcmsSnackbar from "../../../../common/icms-snackbar/IcmsSnackbar";
import { deleteClaim, listClaim } from "../../file-claim-services/ClaimService";
import { RelationshipCd } from "../FileClaimGenericCodes";
import { EmployeeWorkmanClaim } from "./emp-work-claim/EmployeeWorkmanClaim";
import { EmployeeWorkmanClaimView } from "./emp-work-claim/EmployeeWorkmanClaimView";
import { FinancialClaimDetail } from "./fc-claim/FinancialClaimDetail";
import { FinancialClaimView } from "./fc-claim/FinancialClaimView";
import { FCHomebuyerClaim } from "./fc-homebuyer-claim/FCHomebuyerClaim";
import { FCHomebuyerClaimView } from "./fc-homebuyer-claim/FCHomebuyerClaimView";
import { OtherCreditorStakeClaim } from "./other-creditor-stake-claim/OtherCreditorStakeClaim";
import { OtherCreditorStakeView } from "./other-creditor-stake-claim/OtherCreditorStakeView";
import { SupplierClaim } from "./supplier-claim/SupplierClaim";
import { SupplierClaimView } from "./supplier-claim/SupplierClaimView";
import { ICMSButton } from '../../../../common/icms-styled-components/IcmsStyledComponents';

const ClaimList = (props) => {
    const { handlePrevious, handleNext, totalSteps, width, left } = props;
    const param = useParams();
    const isHome = param.isHome === "true";
    const relationShipCd = Number(param.cd);
    const [isNew, setIsNew] = useState(false);
    const [claimDetail, setClaimDetail] = useState(null);
    const [claimDetails, setClaimDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ show: false, message: '', severity: '' });

    const [activePanel, setActivePanel] = useState({});

    const handleChange = (index, claim) => (event, isExpanded) => {
        setActivePanel(prevState => ({
            ...prevState,
            ['panel' + index]: isExpanded,
        }));
        setClaimDetail(claim);
    };

    const closeCurrentPanel = (index) => {
        if (index !== undefined) {
           setActivePanel(prevState => ({
               ...prevState,
               ['panel' + (index + 1)]: false,
           }));
        }
        listForClaim();
        setIsNew(false);
    }

    const listForClaim = (message) => {
        setLoading(true);
        setClaimDetails([]);
        listClaim(Number(param.caseId), Number(param.id),
            (response) => {
                if (response.items.lenght > 0) {
                    setClaimDetails(response.items);
                } else {
                    setIsNew(true);
                }
                setLoading(false);
                if (message) {
                    setMessage(message);
                }
            },
            (error) => {
                console.error(error);
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            })
    }

    const handleDelete = (event, id, index) => {
        event.stopPropagation();
        closeCurrentPanel(index);
        setLoading(true);
        deleteClaim(Number(param.caseId), Number(param.id), id,
            (response) => {
                if (response) {
                    closeCurrentPanel(index);
                    listForClaim();
                }
                setLoading(false);
            },
            (error) => {
                console.error(error);
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            })
    }

    useEffect(() => {
        listForClaim();
    }, [])

    return(
        <Fragment>
            {claimDetails &&
                <Stack>
                    {<Stack justifyContent='end' alignItems='end' p={1}>
                        <ICMSButton sx={{ width: 'fit-content' }} color="primary" variant="contained" startIcon={<Add />} disabled={isNew} size="small"
                            data-testid="add-claim" onClick={() => setIsNew(true)}>{'Add claim'}</ICMSButton>
                    </Stack>}
                    {(isNew) &&
                        <Accordion expanded={isNew} onChange={() => { setIsNew(false) }}
                            sx={{
                                mb: '1rem',
                                border: '1px solid #eeeeee',
                                boxShadow: '0px 20px 35px 0px rgba(15.000000000000002, 33.000000000000014, 60.00000000000001, 0.13)',
                                borderRadius: '12px !important',
                                "&::before": {
                                    backgroundColor: 'transparent !important'
                                }
                            }}>
                            <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{

                                }}
                            >
                                <Typography variant="h6">Add New Claim</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {<Fragment>
                                    {(RelationshipCd.FINANCIAL_CLAIMANT === relationShipCd && !isHome) && <FinancialClaimDetail claimDetail={null} listForClaim={listForClaim} 
                                            closeCurrentPanel={closeCurrentPanel} caseId={param.caseId} claimantId={param.id} claimSubmission={true}
                                            canEditClaim={true}/>}
                                    {(RelationshipCd.FINANCIAL_CLAIMANT === relationShipCd && isHome) && <FCHomebuyerClaim claimDetail={null} listForClaim={listForClaim} 
                                            closeCurrentPanel={closeCurrentPanel} caseId={param.caseId} claimantId={param.id} claimSubmission={true}
                                            canEditClaim={true}/>}
                                    {RelationshipCd.SUPPLIER_CLAIMANT === relationShipCd && <SupplierClaim claimDetail={null} listForClaim={listForClaim} 
                                            closeCurrentPanel={closeCurrentPanel} caseId={param.caseId} claimantId={param.id} claimSubmission={true}
                                            canEditClaim={true}/>}
                                    {RelationshipCd.EMPLOYEE_CLAIMANT === relationShipCd && <EmployeeWorkmanClaim claimDetail={null} 
                                            listForClaim={listForClaim} closeCurrentPanel={closeCurrentPanel} caseId={param.caseId} claimantId={param.id} 
                                            claimSubmission={true} canEditClaim={true}/>}
                                    {RelationshipCd.WORKMAN_CLAIMANT === relationShipCd && <EmployeeWorkmanClaim claimDetail={null} 
                                            listForClaim={listForClaim} closeCurrentPanel={closeCurrentPanel} caseId={param.caseId} claimantId={param.id} 
                                            claimSubmission={true} canEditClaim={true}/>}
                                    {RelationshipCd.OTHER_CREDITOR_CLAIMANT === relationShipCd && <OtherCreditorStakeClaim claimDetail={null} 
                                            listForClaim={listForClaim} closeCurrentPanel={closeCurrentPanel} caseId={param.caseId} claimantId={param.id} 
                                            claimSubmission={true} canEditClaim={true}/>}
                                    {RelationshipCd.OTHER_STAKEHOLDER_CLAIMANT === relationShipCd && <OtherCreditorStakeClaim claimDetail={null} 
                                            listForClaim={listForClaim} closeCurrentPanel={closeCurrentPanel} caseId={param.caseId} claimantId={param.id} 
                                            claimSubmission={true} canEditClaim={true}/>}
                                </Fragment>}
                            </AccordionDetails>
                        </Accordion>
                    }
                    {claimDetails.length > 0 && claimDetails.map((claim, index) => {
                        return (
                            <Accordion key={index} expanded={activePanel['panel' + (index + 1)] !== undefined && activePanel['panel' + (index + 1)] === true} onChange={handleChange(index + 1, claim)}
                                sx={{
                                    mb: '1rem',
                                    border: '1px solid #eeeeee',
                                    boxShadow: '0px 20px 35px 0px rgba(15.000000000000002, 33.000000000000014, 60.00000000000001, 0.13)',
                                    borderRadius: '12px !important',
                                    "&::before": {
                                        backgroundColor: 'transparent !important'
                                    }
                                }}>
                                <AccordionSummary
                                    // expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    sx={{

                                    }}
                                >
                                    {activePanel['panel' + (index + 1)] !== undefined && activePanel['panel' + (index + 1)] === true ?
                                        <Typography variant="h6">{index + 1}.&nbsp;Edit Claim&nbsp;-&nbsp;{claim.debtId}</Typography>
                                        :
                                        <Fragment>
                                            {(RelationshipCd.FINANCIAL_CLAIMANT === relationShipCd && !isHome) && <FinancialClaimView claim={claim} handleDelete={handleDelete} index={index}
                                                claimSubmission={true}/>}
                                            {(RelationshipCd.FINANCIAL_CLAIMANT === relationShipCd && isHome) && <FCHomebuyerClaimView claim={claim} handleDelete={handleDelete} 
                                                index={index} claimSubmission={true}/>}
                                            {RelationshipCd.SUPPLIER_CLAIMANT === relationShipCd && <SupplierClaimView   claim={claim} handleDelete={handleDelete} index={index} 
                                                claimSubmission={true}/>}
                                            {RelationshipCd.EMPLOYEE_CLAIMANT === relationShipCd && <EmployeeWorkmanClaimView claim={claim} handleDelete={handleDelete} index={index} claimSubmission={true}/>}
                                            {RelationshipCd.WORKMAN_CLAIMANT === relationShipCd && <EmployeeWorkmanClaimView claim={claim} handleDelete={handleDelete} index={index} claimSubmission={true}/>}
                                            {RelationshipCd.OTHER_CREDITOR_CLAIMANT === relationShipCd && <OtherCreditorStakeView claim={claim} handleDelete={handleDelete} index={index} claimSubmission={true}/>}
                                            {RelationshipCd.OTHER_STAKEHOLDER_CLAIMANT === relationShipCd && <OtherCreditorStakeView claim={claim} handleDelete={handleDelete} index={index} claimSubmission={true}/>}
                                        </Fragment>
                                    }
                                </AccordionSummary>
                                <AccordionDetails>
                                    {claimDetail !== null && <Fragment>
                                        {(RelationshipCd.FINANCIAL_CLAIMANT === relationShipCd && !isHome) && <FinancialClaimDetail claimDetail={claim} listForClaim={listForClaim} 
                                                closeCurrentPanel={closeCurrentPanel} index={index} caseId={param.caseId} claimantId={param.id} claimSubmission={true}/>}
                                        {(RelationshipCd.FINANCIAL_CLAIMANT === relationShipCd && isHome) && <FCHomebuyerClaim claimDetail={claim} listForClaim={listForClaim} 
                                                closeCurrentPanel={closeCurrentPanel} index={index} caseId={param.caseId} claimantId={param.id} claimSubmission={true}/>}
                                        {RelationshipCd.SUPPLIER_CLAIMANT === relationShipCd && <SupplierClaim claimDetail={claim} listForClaim={listForClaim} 
                                                closeCurrentPanel={closeCurrentPanel} index={index} caseId={param.caseId} claimantId={param.id} claimSubmission={true}/>}
                                        {RelationshipCd.EMPLOYEE_CLAIMANT === relationShipCd && <EmployeeWorkmanClaim claimDetail={claim} listForClaim={listForClaim} 
                                                closeCurrentPanel={closeCurrentPanel} index={index} caseId={param.caseId} claimantId={param.id} claimSubmission={true}/>}
                                        {RelationshipCd.WORKMAN_CLAIMANT === relationShipCd && <EmployeeWorkmanClaim claimDetail={claim} listForClaim={listForClaim} 
                                                closeCurrentPanel={closeCurrentPanel} index={index} caseId={param.caseId} claimantId={param.id} claimSubmission={true}/>}
                                        {RelationshipCd.OTHER_CREDITOR_CLAIMANT === relationShipCd && <OtherCreditorStakeClaim claimDetail={claim} listForClaim={listForClaim} 
                                                closeCurrentPanel={closeCurrentPanel} index={index} caseId={param.caseId} claimantId={param.id} claimSubmission={true}/>}
                                        {RelationshipCd.OTHER_STAKEHOLDER_CLAIMANT === relationShipCd && <OtherCreditorStakeClaim claimDetail={claim} listForClaim={listForClaim} 
                                                closeCurrentPanel={closeCurrentPanel} index={index} caseId={param.caseId} claimantId={param.id} claimSubmission={true}/>}
                                    </Fragment>}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </Stack>
            }
            <Card sx={{ bottom: 0, position: 'fixed', width: width, borderTop: '2px solid #eeeeee', left: left }}>
                <Stack justifyContent='space-between' direction="row">
                    <ICMSButton startIcon={<NavigateBeforeRounded className='back-icon' />} sx={{ float: 'left', mx: 5, my: 1 }}
                        onClick={handlePrevious} variant="outlined" color='primary'>{'Back'}
                    </ICMSButton>
                    {param.activeStep < totalSteps &&
                        <ICMSButton endIcon={<NavigateNextRounded className={'next-icon'} />} sx={{ float: 'right', mr: 5, my: 1 }} onClick={handleNext} variant="contained" color='primary'
                            >
                            {'Next'}
                        </ICMSButton>}
                </Stack>
            </Card>
            {loading && <ICMSLoader2 show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </Fragment>
    )

}

export { ClaimList };
