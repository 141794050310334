import React, { useState }                  from 'react';
import { useFormik }                        from "formik";
import { Grid, Stack }                      from '@mui/material';
import { useTranslation }                   from 'react-i18next';
import * as Yup                             from 'yup';
import { isValidPhoneNumber }               from 'react-phone-number-input';
import { AddressTypes, IdentificationByPartyType, 
    IdentificationType, PartyType,
    RegEx}                                  from '../../../../../common/GenericCodes';
import FCSearch                             from '../../../fc-search/FCSearch';
import { SelectLabelController }            from '../../../../../common/formik-fields-components/SelectLabelController';
import AddressDetail                        from '../../../../address/AddressDetails';
import { 
    FormikLabelMuiPhone, 
    FormikLabelTextField 
}                                           from "../../../../../common/formik-fields-components/FormikFieldComponents";
import { setFormikValueWithLowerCase, 
    setFormikValueWithUpperCase }           from '../../../../../common/utils/Utils';
import { ICMSButton }                       from '../../../../../common/icms-styled-components/IcmsStyledComponents';
import { ICMSAxiosInterceptor }             from '../../../../../config/axios.interceptor';
import ICMSLoader                           from '../../../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                         from '../../../../../common/icms-snackbar/IcmsSnackbar';

const BeneficiaryValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').matches(RegEx.USERNAME, 'Invalid Name'),
    identificationCd: Yup.number(),
    identification: Yup.string().required('Identification is required').when('identificationCd', {
        is: (identificationCd) => { return Number(identificationCd) === IdentificationType.PAN},
        then: () => Yup.string().required('Identification is required').matches(RegEx.PAN, 'Invalid PAN')
    }).when('identificationCd', {
        is: (identificationCd) => { return Number(identificationCd) === IdentificationType.PASSPORT},
        then: () => Yup.string().required('Identification is required').matches(RegEx.PASSPORT, 'Invalid Passport No.')
    }).when('identificationCd', {
        is: (identificationCd) => { return Number(identificationCd) === IdentificationType.VOTERID},
        then: () => Yup.string().required('Identification is required').matches(RegEx.VOTER_ID, 'Invalid Voter ID')
    }),
    email: Yup.string().email('Invalid Email Address').nullable(true),
    extension: Yup.string(),
    phoneNumber: Yup.string().nullable(true).test("phoneNumber", "Invalid Mobile Number", function (str) {
        const { extension } = this.parent;
        if (!str) {
            return true;
        }
        return isValidPhoneNumber(extension + str);
    }),
    addressLine1: Yup.string().required('Address Line 1 is required').min(2, 'Enter minimum of 2 letters')
        .max(100, 'Limit is till 100 characters'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    postalCode: Yup.string().required('Pin Code is required').matches(RegEx.PIN_CODE, 'Invalid Pin Code'),
    cityOrTown: Yup.string().required('City is required')
});

const ClaimBeneficiaryDetail = ({caseId, claimantId, debtDefaultId, claimAgainst, canEdit}) => {
    const { t } = useTranslation();
    const [isEdit, setIsEdit] = useState((claimAgainst?.claimAgainstId || !canEdit) ? false : true);
    const [message, setMessage] = useState({ show: false, message: '', severity: '' });
    const [loading, setLoading] = useState(false);
    const beneficiaryBorrowerFormik = useFormik({
        initialValues: {
            claimAgainstId: claimAgainst?.claimAgainstId || '',
            debtDefaultId: debtDefaultId,
            partyId: claimAgainst?.partyId || null,
            partyNameId: claimAgainst?.partyNameId || '',
            name: claimAgainst?.name || '',
            identificationCd: claimAgainst?.identificationCd || IdentificationType.PAN,
            identificationId: claimAgainst?.identificationId || '',
            identification: claimAgainst?.identification || '',
            email: claimAgainst?.email || '',
            emailId: claimAgainst?.emailId || '',
            partyCd: claimAgainst?.partyCd || PartyType.INDIVIDUAL,
            phoneNumberId: claimAgainst?.phoneNumberId || '',
            phoneNumber: claimAgainst?.phoneDetail?.nationalNumber || '',
            extension: claimAgainst?.phoneDetail?.countryCode ? '+' + claimAgainst.phoneDetail?.countryCode : '+91',
            addressId: claimAgainst?.addressId || '',
            addressCd: claimAgainst?.addressCd || AddressTypes.registerdWithIBBI.addressCd,
            addressLine1: claimAgainst?.addressLine1 || '',
            addressLine2: claimAgainst?.addressLine2 || '',
            cityOrTown: claimAgainst?.cityOrTown || '',
            state: claimAgainst?.state || '',
            postalCode: claimAgainst?.postalCode || '',
            country: claimAgainst?.country || 'India',
            remarks: claimAgainst?.remarks || ''
        },
        validationSchema: BeneficiaryValidationSchema,
        validateOnBlur: true,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: (values) => {
        }
    })

    const getIDLocale = (partyCd, value) => {
        let title = '';
        let identificationList = IdentificationByPartyType[partyCd];
        identificationList?.forEach((item, index) => {
            if (value === item.id) {
                title = item.title;
            }
        })
        return title;
    }

    const onSelectSubmit = (party) => {
        beneficiaryBorrowerFormik.setValues({
            claimAgainstId: '',
            debtDefaultId: debtDefaultId,
            partyId: party.partyId,
            partyNameId: party.partyNameId,
            name: party.name,
            identificationCd: party.identificationCd,
            identificationId: party.identificationId,
            identification: party.identification,
            email: party.email,
            emailId: party.emailId,
            partyCd: party.partyCd,
            phoneNumberId: party.phoneNumberId,
            phoneNumber: party.phoneDetail?.nationalNumber || '' ,
            extension: party.phoneDetail?.countryCode ? '+'+party.phoneDetail?.countryCode : '+91',
            addressId: party.addressId,
            addressCd: party.addressCd,
            addressLine1: party.addressLine1,
            addressLine2: party.addressLine2,
            cityOrTown: party.cityOrTown,
            state: party.state,
            postalCode: party.postalCode,
            country: party.country,
            remarks: ''
        });
    }

    const resetForm = () => {
        beneficiaryBorrowerFormik.resetForm();
    }

    const handleSave = () => {
        let payload = {
            ...beneficiaryBorrowerFormik.values,
            phoneNumber: beneficiaryBorrowerFormik.values.extension + '' + beneficiaryBorrowerFormik.values.phoneNumber
        }
        setLoading(true);
        ICMSAxiosInterceptor.put(`case/${caseId}/claimant/${claimantId}/claim-against/${debtDefaultId}`, payload)
                .then(response => {
            setMessage({ show: true, message: 
                beneficiaryBorrowerFormik.values.claimAgainstId ? t('Beneficiary_Details_Updated_Successfully') :
                t('Beneficiary_Details_Added_Successfully'), severity: 'success' });
            beneficiaryBorrowerFormik.setFieldValue('claimAgainstId', response.claimAgainstId);
            beneficiaryBorrowerFormik.setFieldValue('partyId', response.partyId);
            setIsEdit(false);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            setMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

    return (
        <div>
            <Stack width="100%" spacing={2} sx={{paddingTop: '8px'}}>
                {(!beneficiaryBorrowerFormik.values.claimAgainstId && canEdit) && <Stack direction="row" justifyContent='center' alignItems='center' spacing={2}>
                    <FCSearch placeholder={t('Search')} width={'40%'} isOnlyIndividual={false} 
                        onSelectSubmit={onSelectSubmit} onClear={resetForm}/>
                </Stack>}
                <Stack>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <FormikLabelTextField
                                id="name"
                                fieldName="name"
                                label={t("Name")}
                                formik={beneficiaryBorrowerFormik}
                                size="small"
                                required={true}
                                handleChange={beneficiaryBorrowerFormik.handleChange}
                                disabled={(beneficiaryBorrowerFormik.values.partyId && !beneficiaryBorrowerFormik.values.claimAgainstId) || !isEdit}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <SelectLabelController
                                id="identificationCd"
                                fieldName="identificationCd"
                                label={t("Identification_Type")}
                                formik={beneficiaryBorrowerFormik}
                                size="small"
                                required={true}
                                menuItems={IdentificationByPartyType[beneficiaryBorrowerFormik.values?.partyCd]}
                                handleChange={beneficiaryBorrowerFormik.handleChange}
                                handleBlur={beneficiaryBorrowerFormik.handleBlur}
                                disabled={(beneficiaryBorrowerFormik.values.partyId && !beneficiaryBorrowerFormik.values.claimAgainstId) || !isEdit}
                                menuFieldId={'id'}
                                menuFieldTitle={'title'}
                                valueField={'id'}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormikLabelTextField
                                id="identification"
                                fieldName="identification"
                                label={`${t('Identification')} (${getIDLocale(beneficiaryBorrowerFormik.values?.partyCd, beneficiaryBorrowerFormik.values?.identificationCd)})`}
                                formik={beneficiaryBorrowerFormik}
                                size="small"
                                required={true}
                                handleChange={(event) => setFormikValueWithUpperCase(event, beneficiaryBorrowerFormik)}
                                disabled={(beneficiaryBorrowerFormik.values.partyId && !beneficiaryBorrowerFormik.values.claimAgainstId) || !isEdit}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormikLabelTextField
                                id="email"
                                fieldName="email"
                                label={t("Email")}
                                formik={beneficiaryBorrowerFormik}
                                size='small'
                                handleChange={(event) => setFormikValueWithLowerCase(event, beneficiaryBorrowerFormik)}
                                disabled={(beneficiaryBorrowerFormik.values.partyId && !beneficiaryBorrowerFormik.values.claimAgainstId) || !isEdit}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormikLabelMuiPhone
                                id="phoneNumber"
                                fieldName="phoneNumber"
                                label={t("Mobile_Number")}
                                countryCodeName="extension"
                                formik={beneficiaryBorrowerFormik}
                                size='small'
                                handleChange={beneficiaryBorrowerFormik.handleChange}
                                disabled={(beneficiaryBorrowerFormik.values.partyId && !beneficiaryBorrowerFormik.values.claimAgainstId) || !isEdit}
                            />
                        </Grid>
                    </Grid>
                </Stack>
                <Stack>
                    <AddressDetail addressTitle={t('Contact_Details')} 
                        addressFormik={beneficiaryBorrowerFormik} 
                        editMode={!beneficiaryBorrowerFormik.values.partyId || isEdit}
                        required={true} isDisabled={!isEdit}/>
                </Stack>
                <Stack>
                    <Grid container spacing={2} my={1}>
                        <Grid item xs={12}>
                            {(beneficiaryBorrowerFormik.values.claimAgainstId && isEdit) && <ICMSButton color='primary' variant="outlined" size="small" 
                                    onClick={() => {setIsEdit(false)}}
                                    sx={{float: 'right', marginRight: '8px'}}>
                                {t('Cancel')} 
                            </ICMSButton>}
                            {isEdit && <ICMSButton color='primary' variant="contained" size="small" onClick={handleSave}
                                    sx={{float: 'right', marginRight: '8px'}} 
                                    disabled={!beneficiaryBorrowerFormik.isValid || !beneficiaryBorrowerFormik.dirty}>
                                {t('Save')} 
                            </ICMSButton>}
                            {(beneficiaryBorrowerFormik.values.claimAgainstId && !isEdit) && <ICMSButton color='primary' variant="contained" size="small" 
                                    onClick={() => {setIsEdit(true)}}
                                    sx={{float: 'right'}}>
                                {t('Edit')} 
                            </ICMSButton>}
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
            {loading && <ICMSLoader show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </div>
    )
}

export default ClaimBeneficiaryDetail