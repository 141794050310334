import { Fragment } from "react";
import "./ICMSLoader.css";
import { Backdrop } from "@mui/material";
import SVGSpinner2 from "./icms-loader-2.O/SVGSpinner2";

const ICMSLoader = (props) => {
    const { show, invisible } = props;

    return (
        <Fragment>
            <Backdrop className='icms-backdrop' data-testid="icms-spinner" open={show} invisible={invisible}>
                <SVGSpinner2 />
            </Backdrop>
        </Fragment>
    )
}

export default ICMSLoader;