import { Fragment, useEffect, useState }            from "react"
import { Button, Card, Grid, Stack, ButtonGroup }   from "@mui/material"
import { useFormik }                                from "formik"
import { useTranslation }                           from "react-i18next"
import moment                                       from "moment"
import { FormikLabelTextField }                     from "../../../../../common/formik-fields-components/FormikFieldComponents"
import FormikTwoRadioGroup                          from "../../../../../common/formik-fields-components/FormikTwoRadioGroup"
import ICMSLoader2                                  from "../../../../../common/icms-loader/icms-loader-2.O/ICMSLoader2"
import IcmsSnackbar                                 from "../../../../../common/icms-snackbar/IcmsSnackbar"
import { StepperSectionHeader }                     from "../../../../../common/icms-styled-components/StepperSectionHeader"
import { createClaim, deleteClaim, updateClaim }    from "../../../file-claim-services/ClaimService"
import { FormikLabelNumericFormat }                 from "../../../../../common/formik-fields-components/FormikLabelNumericFormat"
import { ClaimValidationSchema }                    from "../../creditor-details/CreditorYupSchema"
import { FormikNewDateTime }                        from '../../../../../common/formik-fields-components/FormikNewDateTime';
import ClaimAmountsTableView                        from '../ClaimAmountsTableView';
import ClaimAdjudicationList                        from '../claim-adjudication/ClaimAdjudicationList';
import ClaimDistributionList                        from '../claim-distribution/ClaimDistributionList';
import { ClaimType, Stage }                         from "../../../../../common/GenericCodes";
import { ICMSButton }                               from '../../../../../common/icms-styled-components/IcmsStyledComponents';
import ClaimChargesList                             from "../claim-charges/ClaimChargesList"
import FormikCommonAutoComplete                     from "../../../../../common/formik-fields-components/FormikCommonAutoComplete"
import { ICMSAxiosInterceptor }                     from "../../../../../config/axios.interceptor"
import ClaimGuaranteeList                           from "../claim-guarantee/ClaimGuaranteeList"
import FormikTextFieldAndAutoComplete from "../../../../../common/formik-fields-components/FormikTextFieldAndAutoComplete"

const FCHomebuyerClaim = (props) => {
    const { t } = useTranslation();
    const { caseId, claimantId, claimDetail, listForClaim, closeCurrentPanel, index,
        claimSubmission, canEditClaim, canEditBook, canEditAdmitted, canViewBook, canEdit, claimMinDate, caseStage, claimCd,
        relationShipCd } = props;
    const [loading, setLoading] = useState(false);
    const [selectedInfoType, setSelectedInfoType] = useState('due');
    const [message, setMessage] = useState({ show: false, message: '', severity: '' });
    const [searchText, setSearchText] = useState('');
    const [existingProjectNames, setExistingProjectNames] = useState([]);
    const cliamFormik = useFormik({
        initialValues: {
            debtDefaultId: claimDetail === null ? null : claimDetail.debtDefaultId,
            debtId: claimDetail === null ? null : claimDetail.debtId,
            claimId: claimDetail === null ? null : claimDetail.claimId,
            projectName: claimDetail === null ? '' : claimDetail.projectName,
            blockNumber: claimDetail === null ? null : claimDetail.blockNumber,
            superBuiltArea: claimDetail === null ? null : claimDetail.superBuiltArea,
            carpetArea: claimDetail === null ? null : claimDetail.carpetArea,
            carParkingArea: claimDetail === null ? null : claimDetail.carParkingArea,
            optForPossessionDuringResolution: claimDetail === null ? true : claimDetail.optForPossessionDuringResolution,
            bhkType: claimDetail === null ? null : claimDetail.bhkType,
            claimTime: claimDetail === null ? null : claimDetail.claimTime,
            claimToTime: claimDetail === null ? null : claimDetail.claimToTime,
            sanctionSaleAgreementAmount: claimDetail === null ? 0 : claimDetail.sanctionSaleAgreementAmount,
            disbursedAmount: claimDetail === null ? 0 : claimDetail.disbursedAmount,
            cprincipal: claimDetail === null ? 0 : claimDetail.cprincipal,
            cinterest: claimDetail === null ? 0 : claimDetail.cinterest,
            cother: claimDetail === null ? 0 : claimDetail.cother,
            cdescription: claimDetail === null ? null : claimDetail.cdescription,
            totalClaim: claimDetail === null ? 0 : claimDetail.totalClaim,
            principal: claimDetail === null ? 0 : claimDetail.principal,
            interest: claimDetail === null ? 0 : claimDetail.interest,
            other: claimDetail === null ? 0 : claimDetail.other,
            defaultFrom: claimDetail === null ? null : claimDetail.defaultFrom,
            defaultTo: claimDetail === null ? null : claimDetail.defaultTo,
            bdescription: claimDetail === null ? null : claimDetail.bdescription,
            totalBook: claimDetail === null ? 0 : claimDetail.totalBook,
            aprincipal: claimDetail === null ? 0 : claimDetail.aprincipal,
            ainterest: claimDetail === null ? 0 : claimDetail.ainterest,
            aother: claimDetail === null ? 0 : claimDetail.aother,
            adescription: claimDetail === null ? null : claimDetail.adescription,
            totalAdmit: claimDetail === null ? 0 : claimDetail.totalAdmit,
            admitId: claimDetail === null ? null : claimDetail.admitId,
            admittedTime: claimDetail === null ? null : claimDetail.admittedTime,
            totalSaleConsideration: claimDetail === null ? 0 : claimDetail.totalSaleConsideration,
            claimCd: claimDetail === null ? (caseStage == Stage.RESOLUTION ? ClaimType.RES_HOME_BUYER : ClaimType.LIQ_HOME_BUYER) : claimDetail.claimCd,
            jointOwner: claimDetail === null ? null : claimDetail.jointOwner,
            natureOfClaim: claimDetail === null ? null : claimDetail.natureOfClaim
        },
        validationSchema: ClaimValidationSchema,
        onSubmit: () => {
            if (claimDetail === null) {
                createHBClaim();
            } else {
                updateHBClaim();
            }
        }
    })

    const onSelectedInfoChange = (type) => {
        if (cliamFormik.values.debtDefaultId || type == 'due') {
            setSelectedInfoType(type);
        } else {
            setMessage({show: true, message: `${t('Please_Add_Due_Details_Before_Entering')} 
                ${type} ${t('details')}`, severity: 'error'});
        }
    }

    const createHBClaim = () => {
        if (validateClaimDetails()) {
            setLoading(true);
            const { totalClaim, totalBook, totalAdmit, ...payload } = cliamFormik.values;
            createClaim(caseId, claimantId, payload,
                (response) => {
                    if (response) {
                        listForClaim({ show: true, message: t('Claim_Created_Sucessfully'), severity: 'success' });
                        closeCurrentPanel(index);
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    setMessage({ show: true, message: error.message, severity: 'error' });
                })
        }
    }

    const updateHBClaim = () => {
        if (validateClaimDetails()) {
            setLoading(true);
            const { totalClaim, totalBook, totalAdmit, ...payload } = cliamFormik.values;
            updateClaim(caseId, claimantId, claimDetail.debtDefaultId, payload,
                (response) => {
                    if (response) {
                        closeCurrentPanel(index);
                        listForClaim({ show: true, message: t('Claim_Updated_Sucessfully'), severity: 'success' });
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    setMessage({ show: true, message: error.message, severity: 'error' });
                })
        }
    }

    const validateClaimDetails = () => {
        if (cliamFormik.values.claimTime && cliamFormik.values.totalClaim === 0) {
            setMessage({ show: true, message: t('Claim_Amount_Cannot_Be_Zero'), severity: 'error' });
            return false;
        }
        // if (!cliamFormik.values.admittedTime && cliamFormik.values.totalAdmit > 0) {
        //     setMessage({ show: true, message: t('Admitted_Due_Date_Is_Mandatory_With_Admitted_Amount'), severity: 'error' });
        //     return false;
        // }
        return true;
    }

    const deleteHBClaim = () => {
        setLoading(true);
        deleteClaim(caseId, claimantId, claimDetail.debtDefaultId,
            (response) => {
                if (response) {
                    closeCurrentPanel(index);
                    listForClaim({ show: true, message: t('Claim_Deleted_Sucessfully'), severity: 'success' });
                }
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            })
    }

    const handleChange = (event) => {
        cliamFormik.handleChange(event);
    }

    const getProjectNames = () => {
        setExistingProjectNames([]);
        setLoading(true);
        ICMSAxiosInterceptor.get(`case/${caseId}/claimant/project`).then(response => {
            setLoading(false);
            response.map(name => {
                setExistingProjectNames(prevState => (
                    [
                        ...prevState,
                        {name: name}
                    ]
                ));
            })
        }).catch(error => {
            setLoading(false);
            setMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

    useEffect(() => {
        cliamFormik.setFieldValue('totalClaim', cliamFormik.values.cprincipal + cliamFormik.values.cinterest + cliamFormik.values.cother);
    }, [cliamFormik.values.cprincipal, cliamFormik.values.cinterest, cliamFormik.values.cother])

    useEffect(() => {
        cliamFormik.setFieldValue('totalBook', cliamFormik.values.principal + cliamFormik.values.interest + cliamFormik.values.other);
    }, [cliamFormik.values.principal, cliamFormik.values.interest, cliamFormik.values.other])

    useEffect(() => {
        cliamFormik.setFieldValue('totalAdmit', cliamFormik.values.aprincipal + cliamFormik.values.ainterest + cliamFormik.values.aother);
    }, [cliamFormik.values.aprincipal, cliamFormik.values.ainterest, cliamFormik.values.aother])

    useEffect(() => {
        getProjectNames();
    }, [])

    const setSelectedProjectName = (event, projectName) => {
        cliamFormik.setFieldValue('projectName', projectName.name);
        cliamFormik.validateForm();
    }

    const resetSelectedProjectName = (event) => {
        cliamFormik.setFieldValue('projectName', '');
        cliamFormik.validateForm();
    }

    return (
        <Fragment>
            <Stack>
                {
                    <ButtonGroup variant="outlined" aria-label="claimant-details" size="small">
                        <ICMSButton color='primary' 
                            variant={selectedInfoType === 'due' ? "contained" : "outlined"}
                            onClick={() => onSelectedInfoChange('due')}>{t('Due_Details')}</ICMSButton>
                        <ICMSButton color='primary' 
                            variant={selectedInfoType === 'guarantee' ? "contained" : "outlined"}
                            onClick={() => onSelectedInfoChange('guarantee')}>{t('Guarantee')}</ICMSButton>
                        <ICMSButton color='primary' 
                            variant={selectedInfoType === 'charge' ? "contained" : "outlined"}
                            onClick={() => onSelectedInfoChange('charge')}>{t('Security_Interest')}</ICMSButton>
                        <ICMSButton color='primary' 
                            variant={selectedInfoType === 'adjudication' ? "contained" : "outlined"}
                            onClick={() => onSelectedInfoChange('adjudication')}>{t('Adjudication')}</ICMSButton>
                        {!claimSubmission && <ICMSButton color='primary' 
                            variant={selectedInfoType === 'distribution' ? "contained" : "outlined"}
                            onClick={() => onSelectedInfoChange('distribution')}>{t('Distribution')}</ICMSButton>}
                    </ButtonGroup>
                }
                {selectedInfoType === 'due' && <Stack>
                    <Grid container spacing={1} my={1}>
                        <Grid item xs={2}>
                            <FormikLabelTextField
                                id="debtId"
                                fieldName="debtId"
                                label={t("Apartment_Plot_Villa_No")}
                                formik={cliamFormik}
                                size="small"
                                required={true}
                                handleChange={handleChange}
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={4} sx={{paddingTop: '4px !important'}}>
                            {/* <FormikCommonAutoComplete
                                formik={cliamFormik}
                                id="projectName"
                                fieldName="projectName"
                                label={t('Project_Name')}
                                size='small'
                                required={true}
                                options={existingProjectNames}
                                searchText={searchText}
                                setSearchText={setSearchText}
                                optionFieldId='partyId'
                                optionFieldTitle='name'
                                disabled={!canEdit}
                                freeSolo={true}
                            /> */}
                            {/* <FormikLabelTextField
                                id="projectName"
                                fieldName="projectName"
                                label={t("Project_Name")}
                                formik={cliamFormik}
                                size="small"
                                required={true}
                                handleChange={handleChange}
                                disabled={!canEdit}
                            /> */}
                            <FormikTextFieldAndAutoComplete 
                                id='projectName'
                                fieldName='projectName'
                                label={t('Project_Name')}
                                size="small"
                                required={true}
                                formik={cliamFormik}
                                onSelect={setSelectedProjectName}
                                onClear={resetSelectedProjectName}
                                handleBlur={cliamFormik.handleBlur}
                                existingOptions={existingProjectNames}
                                labelField='name' 
                                idField='name'
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormikLabelTextField
                                id="natureOfClaim"
                                fieldName="natureOfClaim"
                                label={t("Nature_Of_Claim")}
                                formik={cliamFormik}
                                size="small"
                                required={true}
                                handleChange={handleChange}
                                handleBlur={cliamFormik.handleBlur}
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormikLabelTextField
                                id="blockNumber"
                                fieldName="blockNumber"
                                label={t("Block_Number")}
                                formik={cliamFormik}
                                size="small"
                                // required={true}
                                handleChange={handleChange}
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormikLabelNumericFormat
                                id="superBuiltArea"
                                fieldName="superBuiltArea"
                                label={t("Super_Built_Area_In_Sqft")}
                                formik={cliamFormik}
                                size="small"
                                // required={true}
                                handleChange={handleChange}
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormikLabelNumericFormat
                                id="carpetArea"
                                fieldName="carpetArea"
                                label={t("Carpet_Area_In_Sqft")}
                                formik={cliamFormik}
                                size="small"
                                // required={true}
                                handleChange={handleChange}
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormikLabelNumericFormat
                                id="carParkingArea"
                                fieldName="carParkingArea"
                                label={t("Car_Parking_Area_In_Sqft")}
                                formik={cliamFormik}
                                size="small"
                                // required={true}
                                handleChange={handleChange}
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormikLabelNumericFormat
                                id="bhkType"
                                fieldName="bhkType"
                                label={t("BHK_Type")}
                                placeholder="__ BHK"
                                formik={cliamFormik}
                                size="small"
                                // required={true}
                                handleChange={handleChange}
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormikLabelTextField
                                id="jointOwner"
                                fieldName="jointOwner"
                                label={t("Joint_Owner_Names")}
                                formik={cliamFormik}
                                size="small"
                                // required={true}
                                handleChange={handleChange}
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormikLabelNumericFormat
                                id="sanctionSaleAgreementAmount"
                                fieldName="sanctionSaleAgreementAmount"
                                label={t("Amount_Agreed_As_Per_Sale_Agreement_Rs")}
                                formik={cliamFormik}
                                size="small"
                                isThousandSeparator={true}
                                // required={true}
                                handleChange={handleChange}
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormikLabelNumericFormat
                                id="disbursedAmount"
                                fieldName="disbursedAmount"
                                label={t("Amount_Agreed_As_Per_Construction_Agreement_Rs")}
                                formik={cliamFormik}
                                size="small"
                                isThousandSeparator={true}
                                // required={true}
                                handleChange={handleChange}
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormikLabelNumericFormat
                                id="totalSaleConsideration"
                                fieldName="totalSaleConsideration"
                                label={t("Total_Sale_Consideration_Rs")}
                                formik={cliamFormik}
                                size="small"
                                isThousandSeparator={true}
                                // required={true}
                                handleChange={handleChange}
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container justifyContent='center' alignItems='center' height='100%'>
                                <Grid item xs={12}>
                                    <Card sx={{
                                        boxShadow: '0px 8px 10px 0px #d8dfeb!important', border: '1px solid #eae7e7', height: 'fit-content', width: 'fit-content'
                                    }}>
                                        <FormikTwoRadioGroup
                                            id="optForPossessionDuringResolution"
                                            fieldName="optForPossessionDuringResolution"
                                            formik={cliamFormik}
                                            required={false}
                                            handleChange={handleChange}
                                            radioOneLabel={t("Possession_After_Resolution")}
                                            radioTwoLabel={t("Exit_During_Resolution")}
                                            disabled={!canEdit}
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    { !claimSubmission && <Stack>
                        <ClaimAmountsTableView cliamFormik={cliamFormik} canEditClaim={canEditClaim && canEdit}
                            canEditBook={canEditBook && canEdit} canEditAdmitted={canEditAdmitted && canEdit} canViewBook={canViewBook}
                            claimMinDate={claimMinDate} caseId={caseId} claimantId={claimantId} relationShipCd={relationShipCd}/>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={6}>
                                <FormikLabelTextField
                                    id='cdescription'
                                    fieldName='cdescription'
                                    label={t('Claimant_Remarks')}
                                    formik={cliamFormik}
                                    required={false}
                                    handleChange={handleChange}
                                    disabled={!canEdit}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormikLabelTextField
                                    id='adescription'
                                    fieldName='adescription'
                                    label={t('IP_Remarks')}
                                    formik={cliamFormik}
                                    required={false}
                                    handleChange={handleChange}
                                    disabled={!canEditAdmitted || !canEdit || cliamFormik.values.totalAdmit == 0}
                                />
                            </Grid>
                        </Grid>
                    </Stack>}

                    { claimSubmission && <Fragment>
                        <StepperSectionHeader title={`${t('Claim_Amount_Details_As_On')} ${moment(new Date(claimMinDate)).format('DD/MM/YYYY')}`} />
                            <Grid container spacing={1} mt={1}>
                                <Grid item xs={2}>
                                    <FormikNewDateTime
                                        id='claimTime'
                                        fieldName='claimTime'
                                        formik={cliamFormik}
                                        maxDate={cliamFormik.values.claimToTime ? cliamFormik.values.claimToTime : claimMinDate}
                                        label={t('Default_Date')}
                                        required={true}
                                        handleChange={handleChange}
                                        size='small'
                                        onlyDate={true}
                                    />
                                </Grid>
                                {/* <Grid item xs={2}>
                                    <FormikNewDateTime
                                        id='claimToTime'
                                        fieldName='claimToTime'
                                        formik={cliamFormik}
                                        minDate={cliamFormik.values.claimTime}
                                        maxDate={claimMinDate}
                                        label={caseStage == Stage.RESOLUTION ? t('Due_As_On_ICD') : (caseStage == Stage.LIQUIDATION ? t('Due_As_On_LCD') : t('Due_To'))}
                                        required={true}
                                        handleChange={handleChange}
                                        size='small'
                                        disabled
                                        onlyDate={true}
                                    />
                                </Grid> */}
                                <Grid item xs={2}>
                                    <FormikLabelNumericFormat
                                        id="cprincipal"
                                        fieldName="cprincipal"
                                        label={t("Principal_Amount_Rs")}
                                        formik={cliamFormik}
                                        size="small"
                                        isThousandSeparator={true}
                                        required={true}
                                        handleBlur={cliamFormik.handleBlur}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormikLabelNumericFormat
                                        id="cinterest"
                                        fieldName="cinterest"
                                        label={t("Interest_Amount_Rs")}
                                        formik={cliamFormik}
                                        size="small"
                                        isThousandSeparator={true}
                                        required={true}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormikLabelNumericFormat
                                        id="cother"
                                        fieldName="cother"
                                        label={t("Other_Charges_Rs")}
                                        formik={cliamFormik}
                                        size="small"
                                        isThousandSeparator={true}
                                        required={true}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormikLabelNumericFormat
                                        id="totalClaim"
                                        fieldName="totalClaim"
                                        label={t("Total_Claim_Amount_Rs")}
                                        formik={cliamFormik}
                                        size="small"
                                        isThousandSeparator={true}
                                        required={true}
                                        disabled={true}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikLabelTextField
                                        id='cdescription'
                                        fieldName='cdescription'
                                        label={t('Remarks')}
                                        formik={cliamFormik}
                                        required={false}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                    </Fragment>}
                    <Stack direction="row" justifyContent='end' spacing={1} mt={3}>
                        {canEdit && <ICMSButton color='primary' variant="outlined" size="small" onClick={() => closeCurrentPanel(index)}>
                            {t('Cancel')}
                        </ICMSButton>}
                        {canEdit && <ICMSButton color='primary' variant="contained" size="small" onClick={cliamFormik.handleSubmit}
                                disabled={!cliamFormik.isValid}>
                            {claimDetail === null ? t('Save') : t('Update')}
                        </ICMSButton>}
                        {(claimDetail !== null && canEdit) && <ICMSButton color='error' variant="contained" size="small" onClick={deleteHBClaim}>
                            {t('Delete')}
                        </ICMSButton>}
                    </Stack>
                </Stack>}
                {selectedInfoType === 'guarantee' && <Stack>
                    <ClaimGuaranteeList caseId={caseId} claimantId={claimantId} canEdit={canEdit}
                            debtDefaultId={cliamFormik.values.debtDefaultId}/>
                </Stack>}
                {selectedInfoType === 'charge' && <Stack>
                    <ClaimChargesList caseId={caseId} claimantId={claimantId} canEdit={canEdit}
                            debtDefaultId={cliamFormik.values.debtDefaultId}/>
                </Stack>}
                {selectedInfoType === 'adjudication' && <Stack>
                    <ClaimAdjudicationList caseId={caseId} claimantId={claimantId} canEdit={canEdit}
                            debtDefaultId={cliamFormik.values.debtDefaultId}/>
                </Stack>}
                {selectedInfoType === 'distribution' && <Stack>
                    <ClaimDistributionList caseId={caseId} claimantId={claimantId} canEdit={canEdit}
                            debtDefaultId={cliamFormik.values.debtDefaultId} claimId={cliamFormik.values.claimId}
                            refresh={listForClaim}/>
                </Stack>}
            </Stack>
            {loading && <ICMSLoader2 show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </Fragment>
    )
}

export { FCHomebuyerClaim }
