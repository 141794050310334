import React, { 
    useContext,
    useEffect, 
    useState 
}                                   from 'react';
import { Checkbox, FormControlLabel, Grid, Stack, Tooltip }              from "@mui/material";
import { 
    IdentificationByPartyType, 
    CreditorsType 
}                                   from "../../../../common/StaticVariables";
import { 
    FormikLabelMuiPhone, 
    FormikLabelTextField 
}                                   from "../../../../common/formik-fields-components/FormikFieldComponents";
import { SelectLabelController }    from "../../../../common/formik-fields-components/SelectLabelController";
import AddressDetail                from "../../../address/AddressDetails";
import FCSearch                     from "../../fc-search/FCSearch";
import YesNoSwitchController        from "../../../../common/formik-fields-components/yes-no-switch-controller/YesNoSwitchController";
import { useParams }                from "react-router-dom";
import { useTranslation }           from "react-i18next";
import { getClaimant }              from '../../file-claim-services/FileClaimServices';
import { setFormikValueWithLowerCase, setFormikValueWithUpperCase }  from '../../../../common/utils/Utils';
import { AddressTypes, IdentificationType, PartyType } from '../../../../common/GenericCodes';
import { UserContext }              from '../../../../common/context/UserContext';
import { ICMSAxiosInterceptor } from '../../../../config/axios.interceptor';

const AuthRep = (props) => {
    const { authRepFormik, caseId, claimantId, caseStage, isCreditorIndividual, canSendCredentials, showSelf } = props;
    const {t} = useTranslation();
    const loginDetails = useContext(UserContext).loginDetails;
    const param = useParams();
    const isIndividual = isCreditorIndividual != undefined ? isCreditorIndividual : param.isIndividual === "true";
    const [message, setMessage] = useState({show: false, message: '', severity: ''});
    const [loading, setLoading] = useState(false);
    const [claimantDetails, setClaimantDetails] = useState('');
    const [asSelf, setAsSelf] = useState(false);
    const [searchPartyDetails, setSearchPartyDetails] = useState({});

    const handleChange = (event) => {
        authRepFormik.setFieldTouched([event.target.id], true);
        authRepFormik.handleChange(event);
    }

    const onSelectSubmit = (authRep) => {
        let identification;
        let address = authRep.addressResources.find(add => add.addressCd == AddressTypes.registerdWithIBBI.addressCd);
        if (authRep.partyCd == PartyType.INDIVIDUAL) {
            identification = authRep.identifications.find(id => id.identificationCd == IdentificationType.PAN);
        } else {
            identification = authRep.identifications[0];
        }
        setSearchPartyDetails({
            ...authRep,
            addressId: address?.addressId || '',
            addressCd: address?.addressCd || '', 
            addressLine1: address?.addressLine1 || '',
            addressLine2: address?.addressLine2 || '',
            cityOrTown: address?.cityOrTown || '',
            state: address?.state || '',
            postalCode: address?.postalCode || '',
            country: address?.country || 'India',
            identificationId: identification?.identificationId || '',
            identificationCd: identification?.identificationCd || '',
            identification: identification?.identification || ''
        });
        authRepFormik.setValues({
            partyId: authRep.partyId,
            partyCd: authRep.partyCd,
            identificationCd: identification?.identificationCd || '',
            name: authRep.name,
            identification: identification?.identification || '',
            identificationId: identification?.identificationId || '',
            email: authRep.recentEmail,
            phoneNumber: authRep.recentPhoneDetail?.nationalNumber,
            extension: authRep.recentPhoneDetail?.countryCode ? '+' + authRep.recentPhoneDetail?.countryCode : '+91',
            addressId: address?.addressId || '',
            addressCd: address?.addressCd || AddressTypes.registerdWithIBBI.addressCd,
            addressLine1: address?.addressLine1 || '',
            addressLine2: address?.addressLine2 || '',
            cityOrTown: address?.cityOrTown || '',
            state: address?.state || '',
            postalCode: address?.postalCode || '',
            country: address?.country || 'India',
            isSameAsCreditor: false,
            relationshipName: '',
            allowlogin: authRep.allowlogin,
            createAccountAndSendCredentials: authRep.createAccountAndSendCredentials,
            loginUid: authRep.loginUid
        });
    }

    const getIDLocale = (partyCd, value) => {
        let title = '';
        let identificationList = IdentificationByPartyType[partyCd];
        identificationList.forEach((item, index) => {
            if (value === item.id) {
                title = item.title;
            }
        })
        return title;
    }

    const getCreditor = () => {
        setLoading(true);
        getClaimant(caseId, claimantId, caseStage, (response) => {
                setClaimantDetails(response);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            }
        )
    }

    const handleSameAsCreditor = (isSameAsCreditor) => {
        if (isSameAsCreditor) {
            let address = claimantDetails.addressResources.find(add => add.addressCd == AddressTypes.registerdWithIBBI.addressCd);
            
            setSearchPartyDetails({
                ...claimantDetails,
                addressId: address?.addressId || '',
                addressCd: address?.addressCd || AddressTypes.registerdWithIBBI.addressCd, 
                addressLine1: address?.addressLine1 || '',
                addressLine2: address?.addressLine2 || '',
                cityOrTown: address?.cityOrTown || '',
                state: address?.state || '',
                postalCode: address?.postalCode || '',
                country: address?.country || 'India'
            });
            
            authRepFormik.setValues({
                partyId: claimantDetails.partyId,
                partyCd: claimantDetails.partyCd,
                identificationCd: claimantDetails.identificationCd,
                name: claimantDetails.name,
                identification: claimantDetails.identification,
                email: claimantDetails.email,
                phoneNumber: ''+claimantDetails.phoneDetail?.nationalNumber,
                extension: claimantDetails.phoneDetail?.countryCode ? '+'+claimantDetails.phoneDetail?.countryCode : '+91',
                addressId: address?.addressId || '',
                addressCd: address?.addressCd || AddressTypes.registerdWithIBBI.addressCd,
                addressLine1: address?.addressLine1 || '',
                addressLine2: address?.addressLine2 || '',
                cityOrTown: address?.cityOrTown || '',
                state: address?.state || '',
                postalCode: address?.postalCode || '',
                country: address?.country ? address.country : 'India',
                isSameAsCreditor: true,
                relationshipName: '',
                allowlogin: claimantDetails.allowlogin,
                createAccountAndSendCredentials: claimantDetails.createAccountAndSendCredentials,
                loginUid: claimantDetails.loginUid
            })
        } else {
            authRepFormik.resetForm();
        }
    }

    const resetForm = () => {
        authRepFormik.resetForm();
    }

    useEffect(() => {
        getCreditor();
    }, [])

    useEffect(() => {
        if (asSelf) {
            setLoading(true);
            ICMSAxiosInterceptor.get(`/search/party/${loginDetails.partyId}`).then((response) => {
                if(response) {
                    onSelectSubmit(response);
                }
                setLoading(false);
            }).catch((error) => {
                setMessage({ show: true, message: error.message, severity: 'error' });
                setLoading(false);
            })
        } else {
            resetForm();
        }
    }, [asSelf])

    return (
        <Stack width="100%" spacing={2}>
            <Stack direction="row" justifyContent='center' alignItems='center' spacing={2}>
                {showSelf && <Tooltip>
                    <FormControlLabel control={<Checkbox checked={asSelf} />} label={t('Self')} 
                            onChange={(event) => setAsSelf(event.target.checked)}
                            sx={{border: `1px solid ${asSelf ? '#005588' : 'initial'}`, color: '#005588', 
                                    padding: '0px 8px', borderRadius: '24px'}}/>
                </Tooltip>}
                {!asSelf && <FCSearch placeholder={t('Search')} width={'30%'} isOnlyIndividual={true} 
                    onSelectSubmit={onSelectSubmit} onClear={resetForm} disabled={authRepFormik.values.isSameAsCreditor}/>}
                {!asSelf && isIndividual && <YesNoSwitchController
                    title={t('Is_Authorized_Person_Same_As_Creditor')}
                    fieldName="isSameAsCreditor"
                    id="isSameAsCreditor"
                    onChange={handleSameAsCreditor}
                    formik={authRepFormik} />}
            </Stack>
            <Stack>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormikLabelTextField
                            id="name"
                            fieldName="name"
                            label={t("Name")}
                            formik={authRepFormik}
                            size="small"
                            required={true}
                            handleChange={handleChange}
                            disabled={authRepFormik.values.partyId && searchPartyDetails?.name}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <SelectLabelController
                            id="identificationCd"
                            fieldName="identificationCd"
                            label={t("Identification_Type")}
                            formik={authRepFormik}
                            size="small"
                            required={true}
                            menuItems={IdentificationByPartyType[CreditorsType.INDIVIDUAL]}
                            handleChange={authRepFormik.handleChange}
                            handleBlur={authRepFormik.handleBlur}
                            disabled={authRepFormik.values.partyId && searchPartyDetails?.identificationId}
                            menuFieldId={'id'}
                            menuFieldTitle={'title'}
                            valueField={'id'}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormikLabelTextField
                            id="identification"
                            fieldName="identification"
                            label={`${t('Identification')} (${getIDLocale(authRepFormik.values.partyCd, authRepFormik.values.identificationCd)})`}
                            formik={authRepFormik}
                            size="small"
                            required={true}
                            handleChange={(event) => setFormikValueWithUpperCase(event, authRepFormik)}
                            disabled={authRepFormik.values.partyId && searchPartyDetails?.identificationId}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormikLabelTextField
                            id="relationshipName"
                            fieldName="relationshipName"
                            label={t("Designation")}
                            formik={authRepFormik}
                            size="small"
                            required={true}
                            handleChange={handleChange}
                            // disabled={authRepFormik.values.isSameAsCreditor}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormikLabelTextField
                            id="email"
                            fieldName="email"
                            label={t("Email")}
                            formik={authRepFormik}
                            required={true}
                            size='small'
                            handleChange={(event) => setFormikValueWithLowerCase(event, authRepFormik)}
                            disabled={authRepFormik.values.partyId && searchPartyDetails?.email}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormikLabelMuiPhone
                            id="phoneNumber"
                            fieldName="phoneNumber"
                            label={t("Mobile_Number")}
                            countryCodeName="extension"
                            formik={authRepFormik}
                            required={true}
                            size='small'
                            handleChange={handleChange}
                            disabled={authRepFormik.values.partyId && searchPartyDetails?.phoneNumber}
                        />
                    </Grid>
                    {!authRepFormik.values.authorizedRepresentativeId && canSendCredentials && <Grid item xs={5}>
                        {authRepFormik.values.loginUid && <YesNoSwitchController
                            title={t('Allow_Login')}
                            fieldName="allowlogin"
                            description={t('Claimant_Auth_Rep_Allow_Login_Description')}
                            id="allowlogin"
                            formik={authRepFormik} />}
                        {!authRepFormik.values.loginUid && <YesNoSwitchController
                            title={t('Send_Credentials')}
                            description={t('Claimant_Auth_Rep_Send_Credentials_Description')}
                            fieldName="createAccountAndSendCredentials"
                            id="createAccountAndSendCredentials"
                            formik={authRepFormik} />}
                    </Grid>}
                </Grid>
            </Stack>
            <Stack>
                <AddressDetail addressTitle={t('Authorized_Person_Contact_Details')} 
                    addressFormik={authRepFormik} 
                    isDisabled={authRepFormik.values.partyId && searchPartyDetails?.addressLine1}
                    required={true}/>
            </Stack>
        </Stack>
    )
}

export default AuthRep;