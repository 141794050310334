import SearchIcon from '@mui/icons-material/Search';
import { FormControl, InputAdornment, MenuItem, Stack, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SearchPublicComponents = ({ setSearchQuery, searchQuery, searchBy, setSearchBy }) => {
    const { t } = useTranslation();
    const handleSearchByChange = (event) => {
        setSearchBy(event.target.value);
        setSearchQuery('')
    };

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const displayNames = {
        debtorName: "Corporate Debtor Name",
        // ipName: "IP Name",
        orderNumber: "NCLT Order No."
      };

    return (
        <Stack direction='row' sx={{ width: '100%', mt: '10px' }}>
            <FormControl component="fieldset" sx={{ minWidth: 150 }}>
                <TextField
                    select
                    label="Search By"
                    value={searchBy}
                    size='small'
                    onChange={handleSearchByChange}
                    variant="outlined"
                    fullWidth
                >
                    <MenuItem value='debtorName'>{t('Corporate_Debtor_Name')}</MenuItem>
                    {/* <MenuItem value='ipName'>IP Name</MenuItem> */}
                    <MenuItem value='orderNumber'>{t('NCLT_Order_No')}</MenuItem>
                </TextField>
            </FormControl>
            <TextField
                variant="outlined"
                fullWidth
                size='small'
                value={searchQuery}
                onChange={handleInputChange}
                placeholder={`Search by ${displayNames[searchBy]}`}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }} />
        </Stack>
    );
};

export default SearchPublicComponents;
