import { Typography } from "@mui/material"
import { Fragment, useState } from "react";
import ChoosePurpose from "./first-time-login/ChoosePurpose";
const Details = ({show}) => {
    const [open, setOpen] = useState(show);
    return (
        <Fragment>
            {/* {!open && <Typography variant="h2" color="primary" sx={{ textAlign: 'center', margin: 2 }}>
                {'Welcome to ICMS'}
            </Typography>} */}
            {open && <ChoosePurpose open={open} setOpen={setOpen}/>}
        </Fragment>
    )
}

export default Details;