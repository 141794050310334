import { Person } from "@mui/icons-material";

export const AuditTrailSideValue = {
    AUDIT_TRAIL: 'audit-trail',
    COMMUNICATION: 'audit-communication',
}

// left navigation order will take as in this list
export const AuditTrailSideMenu = [
    {
        id: 'audit-trail',
        value: AuditTrailSideValue.AUDIT_TRAIL,
        title: 'Audit Trail',
        icon: <Person />
    },
    {
        id: 'communication',
        value: AuditTrailSideValue.COMMUNICATION,
        title: 'Communication',
        icon: <Person />
    },
]