import React, { useEffect, useState }   from "react"
import { Button, Stack, Typography }    from "@mui/material"
import { 
    FaBriefcase, 
    FaCartFlatbed, 
    FaMoneyBillTrendUp 
}                                       from "react-icons/fa6";
import { ImHome3 }                      from "react-icons/im";
import { HiUserGroup }                  from "react-icons/hi";
import { HiMiniWrenchScrewdriver }      from "react-icons/hi2";
import { useNavigate, useParams }       from "react-router-dom";
import { useTranslation }               from "react-i18next";
import { RelationshipType,
    CaseManagementSideNav }             from "../../../common/GenericCodes";
import { ICMSAxiosInterceptor }         from "../../../config/axios.interceptor";
import ClaimantTypeCard                 from "./ClaimantTypeCard";
import { ICMSButton }                   from '../../../common/icms-styled-components/IcmsStyledComponents';

const ChooseClaimant = (props) => {
    const { caseTitle, isLiquidation } = { ...props, isLiquidation: props.isLiquidation || false};
    const {t} = useTranslation();
    const navigate = useNavigate();
    const param = useParams();
    const [caseDetails, setCaseDetails] = useState(null);

    const handleSelect = (claimantRelationShipCd, isHome) => {
        navigate(`/details/file-claim/${Number(param.caseId)}`, {state: {
            relationshipCd: claimantRelationShipCd,
            isHome: isHome,
            caseStage: caseDetails?.caseStage,
            caseCd: caseDetails.liquidationCaseCd ? caseDetails.liquidationCaseCd : caseDetails.resolutionCaseCd,
            caseId: Number(param.caseId),
            insolvencementDate: caseDetails.liquidationCaseCd ? caseDetails.liquidationCommencedOn : caseDetails.insolvencyCommencedOn,
        }})
        // let isIndividual = false;
        // let activeStep = 0;
        // navigate(`/details/file-claim/${Number(param.caseId)}/${claimantRelationShipCd}/${activeStep}/${'new'}/${isHome}/${isIndividual}`, {state: {
        //     claimantRelationShipCd: claimantRelationShipCd,
        //     isHome: isHome
        // }})
    }

    const getCaseDetails = () => {
        ICMSAxiosInterceptor.get(`case/${param.caseId}`).then(response => {
            setCaseDetails(response)
        }).catch(error => {
            console.log('error', error);
        })
    }

    const handleCancel = () => {
        navigate('/details/case-management', {
            state: {
                caseType: CaseManagementSideNav.active.key
            }
        });
    }

    useEffect(() => {
        getCaseDetails();
    }, [])
    
    return (
        <Stack justifyContent='center' alignItems='center' spacing={2} height='100%'>
            <Typography variant="h5" color="textSecondary" data-testid='file-claim-text'>
                {t('To_File_Your_Claim_Against')} <span style={{fontWeight: 'bold'}}>{caseDetails?.debtor} </span>
                {t('With_NCLT_Order_Number')} <span style={{fontWeight: 'bold'}}>{caseDetails?.ncltCaseNumber}</span>
            </Typography>
            <Typography variant="h5" color="textSecondary">{t('Select_Your_Claimant_Type')}</Typography>
            <Stack direction="row" spacing={3} justifyContent="center">
                <ClaimantTypeCard
                    title={t('Financial_Creditor')}
                    icon={<FaMoneyBillTrendUp style={{ fontSize: '2rem', position: 'absolute', right: '-16px', bottom: '0px', color: 'rgb(59 175 92)' }} />}
                    personColor={'rgb(181 215 202)'}
                    handleSelect={handleSelect}
                    dataTestId={'card-fc'}
                    claimantRelationShipCd={RelationshipType.FINANCIAL_CLAIMANT}
                    isHome={false}
                    description={t('Financial_Creditor_Description')}
                />
                <ClaimantTypeCard
                    title={t('Financial_Creditor')}
                    secondaryTitle={t('Creditor_In_Class_Allottee')}
                    icon={<ImHome3 style={{ fontSize: '2rem', position: 'absolute', right: '-16px', bottom: '0px', color: 'rgb(179 106 160)' }} />}
                    personColor={'rgb(215 181 205)'}
                    handleSelect={handleSelect}
                    dataTestId={'card-fc-hb'}
                    claimantRelationShipCd={RelationshipType.FINANCIAL_CLAIMANT}
                    isHome={true}
                    description={t('Financial_Creditor__Home_Buyer_Description')}
                />
                <ClaimantTypeCard
                    title={t('Operational_Creditor')}
                    secondaryTitle={t('Supplier')}
                    icon={<FaCartFlatbed style={{ fontSize: '2rem', position: 'absolute', right: '-16px', bottom: '0px', color: 'rgb(95 112 196)' }} />}
                    personColor={'rgb(195 205 228)'}
                    handleSelect={handleSelect}
                    dataTestId={'card-supplier'}
                    claimantRelationShipCd={RelationshipType.SUPPLIER_CLAIMANT}
                    isHome={false}
                    description={t('OC_Supplier_Description')}
                />
            </Stack>
            <Stack direction="row" spacing={3} justifyContent="center">
                <ClaimantTypeCard
                    title={t('Operational_Creditor')}
                    secondaryTitle={t('Employee')}
                    icon={<FaBriefcase style={{ fontSize: '1.8rem', position: 'absolute', right: '-16px', bottom: '0px', color: 'rgb(196 151 104)' }} />}
                    personColor={'rgb(224 205 190)'}
                    handleSelect={handleSelect}
                    dataTestId={'card-employee'}
                    claimantRelationShipCd={RelationshipType.EMPLOYEE_CLAIMANT}
                    isHome={false}
                    description={t('OC_Employee_Description')}
                />
                <ClaimantTypeCard
                    title={t('Operational_Creditor')}
                    secondaryTitle={t('Workman')}
                    icon={<HiMiniWrenchScrewdriver style={{ fontSize: '2rem', position: 'absolute', right: '-16px', bottom: '0px', color: 'rgb(171 83 83)' }} />}
                    personColor={'rgb(215 181 181)'}
                    handleSelect={handleSelect}
                    dataTestId={'card-workman'}
                    claimantRelationShipCd={RelationshipType.WORKMAN_CLAIMANT}
                    isHome={false}
                    description={t('OC_Workman_Description')}
                />
                {!isLiquidation  && <ClaimantTypeCard
                    title={t('Other_Creditor')}
                    icon={<HiUserGroup style={{ fontSize: '2rem', position: 'absolute', right: '-16px', bottom: '0px', color: 'rgb(0 124 136)' }} />}
                    personColor={'rgb(170 207 207)'}
                    handleSelect={handleSelect}
                    dataTestId={'card-ocreditor'}
                    claimantRelationShipCd={RelationshipType.OTHER_CREDITOR_CLAIMANT}
                    isHome={false}
                    description={t('Other_Creditor_Description')}
                />}
                {isLiquidation && <ClaimantTypeCard
                    title={t('Other_Stakeholder')}
                    icon={<HiUserGroup style={{ fontSize: '2rem', position: 'absolute', right: '-16px', bottom: '0px', color: 'rgb(0 124 136)' }} />}
                    personColor={'rgb(170 207 207)'}
                    handleSelect={handleSelect}
                    dataTestId={'card-ostake'}
                    claimantRelationShipCd={RelationshipType.OTHER_STAKEHOLDER_CLAIMANT}
                    isHome={false}
                    description={t('Other_Stakeholder_Description')}
                />}
            </Stack>
            <ICMSButton variant="outlined" color="primary" sx={{ background: (theme) => theme.palette.background.paper, px: 6, mt: 5 }}
                onClick={handleCancel} data-testid="claimant-cancel">{t('Cancel')}</ICMSButton>
        </Stack>
    )
}

export default ChooseClaimant;