import { Person } from "@mui/icons-material";
import { AiFillInteraction } from "react-icons/ai";
import { BsPersonCheckFill } from "react-icons/bs";
import { FaBuildingUser, FaFileInvoice, FaTimeline, FaUserGraduate, FaUsersGear } from "react-icons/fa6";
import { MdAdminPanelSettings } from "react-icons/md";
import { RiProfileFill } from "react-icons/ri";
import { MdHelp } from "react-icons/md";
import { RiUserSettingsFill } from "react-icons/ri";

export const CaseDetailSideValue = {

    CASE_PROFILE: 'cd-case-profile',
    TIMELINE: 'cd-timeline',
    // IBBI_REQUEST: 'cd-ibbi-request',
    APPLICATION_ORDER: 'cd-application-order-appeal',
    APPLICANT: 'cd-applicant',
    SERVICE_PROVIDER: 'cd-service-provider',
    USER_MANAGEMENT: 'cd-user-management',
    HELPDESK: 'cd-helpdesk',
    SETTINGS: 'cd-case-settings'

}

// left navigation order will take as in this list
export const CaseDetailsSideMenu = [
    {
        id: 'case-profile',
        value: CaseDetailSideValue.CASE_PROFILE,
        title: 'Case Profile',
        icon: <RiProfileFill style={{ fontSize: '1.5rem' }} />
    },
    {
        id: 'timeline',
        value: CaseDetailSideValue.TIMELINE,
        title: 'Timeline',
        icon: <FaTimeline style={{ fontSize: '1.5rem' }} />
    },
    // {
    //     id: 'ibbi-request',
    //     value: CaseDetailSideValue.IBBI_REQUEST,
    //     title: 'IBBI Request For',
    //     icon: <AiFillInteraction style={{ fontSize: '1.5rem' }} />
    // },
    {
        id: 'application-order-appeal',
        value: CaseDetailSideValue.APPLICATION_ORDER,
        title: 'Filing / Order',
        icon: <FaFileInvoice style={{ fontSize: '1.5rem' }} />
    },
    {
        id: 'applicant',
        value: CaseDetailSideValue.APPLICANT,
        title: 'Applicant',
        icon: <BsPersonCheckFill style={{ fontSize: '1.5rem' }} />
    },
    {
        id: 'service-provider',
        value: CaseDetailSideValue.SERVICE_PROVIDER,
        title: 'Service Provider',
        icon: <MdAdminPanelSettings style={{ fontSize: '1.5rem' }} />
    },
    {
        id: 'user-management',
        value: CaseDetailSideValue.USER_MANAGEMENT,
        title: 'User Management',
        icon: <FaUsersGear style={{ fontSize: '1.5rem' }} />
    },
    {
        id: 'case-settings',
        value: CaseDetailSideValue.SETTINGS,
        title: 'Settings',
        // subscriptionRoles: SubRolePermissions[CaseDetailSideValue.SETTINGS],
        icon: <RiUserSettingsFill style={{ fontSize: '1.5rem' }} />
    },
    {
        id: 'help-desk',
        value: CaseDetailSideValue.HELPDESK,
        title: 'Help Desk',
        icon: <MdHelp style={{ fontSize: '1.5rem' }} />
    }
]