import React, { useEffect } from 'react';
import { MapContainer, TileLayer, useMapEvents, Polygon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { Marker } from 'react-leaflet'; 
import L from 'leaflet';
import IconLocationOn from '@mui/icons-material/LocationOn';
import { SvgIcon } from '@mui/material';
import ReactDOMServer from 'react-dom/server';
import { useState } from 'react';

const MaterialIconMarker = () => {
  const iconHtml = `
    <div style="font-size: 48px; color: red;">
      ${ReactDOMServer.renderToString(<SvgIcon component={IconLocationOn} style={{ fontSize: 48, color: 'red' }} />)}
    </div>
  `;

  return L.divIcon({
    className: 'custom-icon',
    html: iconHtml,
    iconSize: [48, 48], // Adjust the size as needed
    iconAnchor: [12, 24], // Adjust the anchor as needed
  });
};

const MapComponent = ({ latLng, polygon, setLatLng, setPolygon, disabled }) => {
    const MapEvents = () => {
        useMapEvents({
            click(e) {
                if (!disabled) {
                    const { lat: x, lng: y } = e.latlng;
                    setLatLng({ x, y });
                }
            },
        });
        return null;
    };
  
    const handleCreated = (e) => {
        if (!disabled) {
            const layer = e.layer;
            const polygonCoords = layer.getLatLngs()[0].map(coord => ({
                x: coord.lat,
                y: coord.lng,
            }));
            setPolygon(polygonCoords);
        }
    };
  
    return (
        <MapContainer center={latLng ? [ latLng.x, latLng.y] : [12.9716, 77.5946]} zoom={13} style={{ height: "100vh", width: "100%" }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
              {/* <FeatureGroup>
                  {!disabled && <EditControl
                      position="topright"
                      onCreated={handleCreated}
                      draw={{
                          rectangle: false,
                          circle: false,
                          polyline: false,
                          marker: false,
                          circlemarker: false,
                      }}
                  />}
              </FeatureGroup> */}
              {latLng && <Marker position={[latLng.x, latLng.y]} icon={MaterialIconMarker()}/>}
              {polygon && (
                <Polygon positions={polygon.map(coord => [coord.x, coord.y])} pathOptions={{ color: 'blue' }} />
              )}
            <MapEvents />
        </MapContainer>
    );
};

export default MapComponent;