import React, { Fragment, useState } from 'react';
import {
    Dialog, DialogTitle, Button, DialogContent,
    DialogContentText, DialogActions, Divider, Typography
} from '@mui/material';

const TermsAndConditions = (props) => {
    const [open, setOpen] = useState(props.show);

    const acceptHandler = (isAccept) => {
        setOpen(false);
        props.acceptTermsAndCondition(isAccept);
    }

    const handleClose = () => {
        props.close(false)
    }

    return (
        <Dialog maxWidth="lg" open={open} scroll='paper' aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
            <DialogTitle id="scroll-dialog-title">Terms & Conditions</DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="scroll-dialog-description">
                    <h2 style={{textAlign:'center'}}>REGISTRATION FORM – TERMS & CONDITIONS (T&C)</h2>
                    <Typography>
                    This Registration Form <b>("Application")</b> is provided by National e-Governance Services Limited (hereinafter referred to as <b>“NeSL”</b>) for information and reference purposes only. To create an account, the <b>Applicant</b> (also referred to as <b>“User”</b> or <b>“You”</b>) is required to provide the current, complete and accurate information as stipulated in the Application. NeSL and the Applicant are individually referred to as <b>“Party”</b> and collectively referred to as <b>“Parties”</b>. By registering, the Applicant agrees to the following terms in addition to any other specific terms and conditions that may be posted at an appropriate location of the Website. Please note that each registration is for a single entity only.
                    </Typography><br />
                    <Typography>
                        <b>Disclaimers:</b>Disclaimers: NeSL may from time to time, at its sole discretion, alter, amend, modify or withdraw these T&C including but not limited to comply with the changes in law or regulation, to correct inaccuracies, omissions, errors or ambiguities, reflect changes in the process flow, scope & nature of the services, without any prior notice. It is the sole responsibility of the Applicant to ensure that the information entered in the relevant fields are true and correct. NeSL assumes no liability for errors or omissions in the contents of the Form. Application will reflect Applicant's information as provided by them (depending upon the kind of User) to ensure accuracy. NeSL is not liable for any inaccuracy, false information, error or delay in, or omission of any data, information or message provided herein and shall not be liable for any damages or negative consequences followed thereon, pursuant to the wrong information provided by any such Applicant. Any submission or use of the wrongful information stored with NeSL will be done at Applicant's own risk. NeSL reserves the right to sue the Applicant for providing any such wrong information in the Application and claim any or all losses accrued to NeSL therein on account of such wrong information provided by the Applicant.
                    </Typography>
                    <h3>NeSL’s Obligations:</h3>
                    <ol>
                        <li>NeSL shall use its best efforts to provide this service and process the registration form without any errors or omissions and conforming to prescribed security standards.</li>
                        <li>In spite of this, if any mistake, error or discrepancy is noted, the same may be brought to the notice of NeSL by way of email as mentioned in the website of NeSL, which shall be addressed and the Application shall be amended accordingly. It is suggested that, to avoid any doubt the Applicant should properly check all the columns and contents of the Application thoroughly before submitting such information.</li>
                        <li>NeSL shall endeavor its best efforts to guarantee the protection of the rights of users after obtaining the explicit consent as required under IU Regulations.</li>
                        <li>On successful submission of the Application, the Applicant will be provided with a <b>User ID</b> and <b>Password</b> to access the NeSL Website and NeSL will maintain the login details of the Applicant safe and confidential.</li>
                    </ol>
                    <h3>Applicant’s Obligations:</h3>
                    <ol>
                        <li>You will provide complete and accurate information as required for registration in this registration form and ensure the same is true and correct before submission.</li>
                        <li>You will not divulge your Password to any other registered user or prospective user of NeSL and maintain the secrecy of all information provided herein. You will not store your password in an unprotected location, and will not allow it to be written into computer scripts to achieve automated login.</li>
                        <li>At any time, if you find that your NeSL account has been compromised, you agree to contact NeSL and inform the same as soon as possible.</li>
                        <li>You agree to notify NeSL within three (3) working days in case you no longer need to interact with NeSL. You will make this notification either through the web interface or by notifying the staff of NeSL. This notification will allow NeSL to deactivate your account & protect it from potential abuse by other registered users.</li>
                        <li>You also agree that you have read and understood the Bye-laws of NeSL, which is made available on the website of NeSL, along with the Information Utility Regulations, and Privacy Policy of NeSL.</li>
                        <li>You also agree that this registration is mandatory under the Information utility Regulations which is within the purview of the Insolvency and Bankruptcy Code, 2016. You will note that the Financial information related to you or your company/ firm will be submitted by the other parties (Debtor/Creditor/Guarantor).</li>
                        <li>You agree to pay the fee as prescribed by NeSL, the details of which are published on the website of NeSL which may be amended from time to time.</li>
                    </ol>
                    <Divider></Divider><br />
                    <Typography>
                        <b>No Warranty:</b> No warranty, representation or guarantee, express or implied, is given by NeSL with respect to this registration form other than those specifically mentioned herein. There is no guarantee given by NeSL as to the accuracy of Information provided in this registration form by the Applicant, although all efforts have been made for the same at time of processing of this form.
                    </Typography><br/>
                    <Typography>
                        <b>Waiver & Indemnity:</b> NeSL shall not be deemed to have waived any of its rights or remedies hereunder, unless such waiver is in express writing. No delay or omission on the part of NeSL, in exercising any rights or remedies shall be deemed to be a waiver of such rights or remedies, or infringement of any intellectual property or the other right of anyone by you or by any other User of your account. The Applicant shall indemnify & hold harmless NeSL against any loss or damage and costs that may be awarded or agreed to be paid to any person in respect of a claim or action arising in respect of any breach of these T&C, including but not limited to submission of any wrong information or inaccuracy. NeSL shall indemnify to the user for any loss/ damages suffered due to the exclusive proven act of negligence/ error by NeSL or its employees.
                    </Typography><br/>
                    <Typography>
                         <b>Liability:</b> In no event shall NeSL be liable to the Applicant or any other related third party, for any costs, claims, expenses, losses or damages including, without limitation to, any special, direct, indirect, consequential or incidental damages, or any expenses, losses or damages whatsoever, whether in action of contract or torts, arising out of or in connection with any wrongful information provided by the Applicant or the use of the such information, or in case of circumstances beyond the control of NeSL, including any Force Majeure conditions such as acts of God, fires, casualties, floods, power failures, communication breakdowns, lock-outs, strikes, accidents, terrorism, riots, wars, any adverse governmental law, ruling, regulation, order or decree, or emergency that prevents NeSL from operating normally.
                    </Typography><br/>
                    <Typography>
                        <b>Governing law & Dispute Resolution:</b> These terms and conditions shall be governed by and construed in accordance with the Laws of India. Any dispute arising under these terms and conditions shall be subject to the exclusive jurisdiction of the Courts at Bengaluru, Karnataka. Any dispute, difference, controversy or claim arising between the Parties out of or in relation to, or in connection with these T&C, or the breach, termination, effect, interpretation or application of these T&C or as to their rights, duties or liabilities hereunder, shall be settled by the parties mutually at the Grievance Redressal Committee set up by the Company in accordance with the Clause 12 of the IU Regulations 2017. If unresolved, the same shall be settled by way of arbitration proceedings in accordance with the Arbitration & Conciliation Act, 1996, or any subsequent amendment thereto in English, at Bengaluru by a sole arbitrator appointed on mutual consent within thirty (30) days from the request of either party. The decision of the arbitrators shall be final, conclusive, binding & enforceable.
                    </Typography><br/>
                    <Divider></Divider><br/>
                    <h3 style={{textAlign:'center'}}>DECLARATION</h3>
                    <Typography>
                        By signing below, the Applicant or User (referred to in first person) hereby acknowledges and declares that:
                    </Typography>
                    <ol>
                        <li>I have completely read and understood these terms and conditions of Registration with NeSL and I agree to all the terms and conditions.</li>
                        <li>In addition, I confirm that I have read and understood the bye-laws, IU Regulations and I am fully aware of the legal provisions pertaining to registration with and submission/ use of information stored with NeSL or any other Information Utility in India.</li>
                        <li>I agree to conduct my activities in accordance with prevailing laws, statues, NeSL bye-laws, policies (as amended from time to time) and understand that breaching these standards may result in legal actions, including termination of my registration or any other legal remedy available to NeSL.</li>
                        <li>I further declare that, I am not registered with any other existing Information Utility registered under the Insolvency and Bankruptcy Board of India (Information Utilities) Regulations, 2017.</li>
                        <li>I acknowledge that I have an affirmative duty to report any changes related to the information provided to NeSL and will keep NeSL informed about any such change in future.</li>
                        <li>I am fully aware of my obligations & responsibilities as an Applicant or User, arising from these Terms & Conditions, including provisions on liability & indemnity.</li>
                    </ol>
                    <h3 style={{textAlign:'center'}}>I AGREE.</h3>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{justifyContent:'center'}}>
                {props.view ? <Button onClick={handleClose}>close</Button> : 
                <Fragment>
                <Button id="disagree" onClick={() => { acceptHandler(false) }} color="primary" variant='outlined'>
                    DISAGREE
                </Button>
                <Button id="agree" onClick={() => { acceptHandler(true) }} color="primary" variant='contained'>
                    AGREE
                </Button>
                </Fragment>}
            </DialogActions>
        </Dialog>
    )
}

export default TermsAndConditions;