import React, { useState, useEffect }   from 'react';
import { useTranslation }               from 'react-i18next';
import { Accordion, AccordionSummary,
    AccordionDetails }                  from '@mui/material';
    import ExpandMoreIcon               from '@mui/icons-material/ExpandMore';
import DocumentList                     from '../../../common/documents/DocumentList';
import { ClaimType, DocumentType }                 from '../../../common/GenericCodes';
import { ICMSAxiosInterceptor }         from '../../../config/axios.interceptor';

const CreditorDocuments = ({creditorId, caseId, canUpload, claimCd, caseStage, canDelete, canDownload}) => {
    const { t } = useTranslation();
    const [documentRefs, setDocumentRefs] = useState([]);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});

    const getClaims = () => {
        let payload = {}
        if (claimCd) {
            payload = {
                claimCds: [claimCd]
            }
        } 
        setLoading(true);
        ICMSAxiosInterceptor.post(`case/${caseId}/claimant/${creditorId}/claim/list`, payload)
                .then(response => {
            if (response.items.length > 0) {
                for(const element of response.items) {
                    setDocumentRefs(prevState => ([
                        ...prevState,
                        {
                            icmsTablePk: element.debtDefaultId,
                            documentClass: 'claim',
                            documentTypeCd: getDocumentCdByClaimCd(element.claimCd),
                            label: element.debtId
                        }
                    ]))
                }
            }
            setLoading(false);
        }).catch(error => {
            if (error.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const getClaimDocuments = (msg) => {
        if(msg) {
            setMessage(msg);
        }
        setLoading(true);
        setUploadedDocuments([]);
        ICMSAxiosInterceptor.get(`case/${caseId}/claimant/${creditorId}/document/case-stage/${caseStage}`).then(response => {
            if (response) {
                setUploadedDocuments(response);
            }
            setLoading(false);
        }).catch(error => {
            if (error.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const getDocumentCdByClaimCd = (claimCd) => {
        switch(claimCd) {
            case ClaimType.RES_FIN_AS_GUARANTOR :
            case ClaimType.RES_FIN_AS_PRINCIPAL_BORROWER :
            case ClaimType.RES_FIN_COVERED_UNDER_CLAUSE_H_I :
            case ClaimType.LIQ_FIN_AS_GUARANTOR :
            case ClaimType.LIQ_FIN_AS_PRINCIPAL_BORROWER :
            case ClaimType.LIQ_FIN_COVERED_UNDER_CLAUSE_H_I :
                return {
                    type: DocumentType.LOAN,
                    title: t('Loan_Facility_Account_Number')
                };
            
            case ClaimType.RES_HOME_BUYER:
            case ClaimType.LIQ_HOME_BUYER:
                return {
                    type: DocumentType.ALLOTTEE,
                    title: t('Apartment_Plot_Villa_No')
                };

            case ClaimType.RES_SUPPLIER:
            case ClaimType.RES_GOVT_BODY:
            case ClaimType.LIQ_GOVT_BODY:
            case ClaimType.LIQ_SUPPLIER: 
                return {
                    type: DocumentType.INVOICE,
                    title: t('Reference_Number')
                };
            
            case ClaimType.RES_EMPLOYEE:
            case ClaimType.LIQ_EMPLOYEE:
                return {
                    type: DocumentType.SALARY,
                    title: t('Salary_Documents')
                };

            case ClaimType.RES_WORKMAN:
            case ClaimType.LIQ_WORKMAN:
                return {
                    type: DocumentType.WAGE,
                    title: t('Wages_Documents')
                };
            
            case ClaimType.RES_OTHER_CREDITOR:
                return {
                    type: DocumentType.OTHER_CREDITOR_CLAIM,
                    title: t('Reference_Number')
                };

            case ClaimType.LIQ_STAKEHOLDER:
                return {
                    type: DocumentType.OTHER_STAKEHOLDER_CLAIM,
                    title: t('Reference_Number')
                };
        }
    }

    useEffect(() => {
        getClaimDocuments();
        return () => {}
    }, [documentRefs])
    

    useEffect(() => {
        if (creditorId && (claimCd != ClaimType.RES_EMPLOYEE && 
                claimCd != ClaimType.LIQ_EMPLOYEE && claimCd != ClaimType.RES_WORKMAN && claimCd != ClaimType.LIQ_WORKMAN)) {
            setDocumentRefs([
                {
                    icmsTablePk: creditorId,
                    documentClass: 'claimant',
                    documentTypeCd: {
                        type: DocumentType.CLAIMANT
                    },
                    label: 'General Documents'
                }
            ])
        }
        getClaims();
    }, [])
    
    return (
        <div>
            {
                documentRefs.map(docRef => {
                    return <div style={{padding: '8px'}}>
                        <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                                {docRef.documentTypeCd.title && <span>{docRef.documentTypeCd.title} - </span>} <strong>&nbsp;{docRef.label}</strong>
                            </AccordionSummary>
                            <AccordionDetails>
                                {!loading && <DocumentList icmsTablePk={docRef.icmsTablePk} caseId={caseId} canUpload={canUpload}
                                    canDelete={canDelete} canDownload={canDownload}
                                    uploadedDocuments={uploadedDocuments[docRef.documentClass + '#' + docRef.icmsTablePk] ? uploadedDocuments[docRef.documentClass + '#' + docRef.icmsTablePk] : []}
                                    documentTypeCd={docRef.documentTypeCd.type} handleRefresh={getClaimDocuments}/>}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                })
            }
        </div>
    )
}

export default CreditorDocuments