import React, { Fragment, useEffect, 
    useRef, useState }                      from 'react';
import AddIcon                              from '@mui/icons-material/Add';
import EditIcon                             from '@mui/icons-material/Edit';
import { Button, Grid, IconButton, Stack, 
    TableBody, TableHead, Tooltip, 
    Typography }                            from '@mui/material';
import { useTranslation }                   from 'react-i18next';
import { ICMSAxiosInterceptor }             from '../../../config/axios.interceptor';
import { IcmsMuiTable, IcmsTableContainer, 
    IcmsTableRow, StyledTableCell }         from '../../generic-styles/NavPageContainerStyles';
import ICMSLoader                           from '../../icms-loader/ICMSLoader';
import IcmsSnackbar                         from '../../icms-snackbar/IcmsSnackbar';
import ICMSConfirmDialog                    from '../../ConfirmationDialog/ICMSConfirmDialog';
import { ICMSTheme }                        from '../../../theme/Theme';
import { CreateIdentification }             from './CreateIdentification';

const IdentificationList = ({partyId}) => {
    const [identificationListData, setIdentificationData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState({
        open: false,
        identificationRow: {},
    });
    const { t } = useTranslation();
    const [message, setMessage] = useState({
        show: false,
        message: null,
        severity: null
    })
    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: ''
    })

    const columns = [
        { id: 'ns nome', title: t('S.NO.'), align: 'left' },
        { id: 'type', title: t('Identification_Type'), align: 'left' },
        { id: 'id', title: t('Identification'), align: 'left' },
        { id: 'verified-on', title: t('Verified_On'), align: 'left' },
        { id: 'action', title: t('Actions'), align: 'left' }
    ]

    const addIdentificationDetail = (row) => {
        setOpenDialog({
            open: true,
            identificationRow: row ? row : {}
        })
    }

    const identificationList = () => {
        setLoading(true)
        ICMSAxiosInterceptor.post(`party/${partyId}/identification/list`, {}).then((response) => {
            setIdentificationData(response.items)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            setMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

    useEffect(() => {
        identificationList()
    }, [])

    const handleClose = (reload) => {
        if (reload) {
            identificationList()
        }
        setOpenDialog({
            open: false,
            identificationRow: {}
        })
    }

    return (
        <Fragment>
            <Grid container direction="row" alignItems="baseline" sx={{ padding: '0px 20px' }}>
                <Grid item xs={12} sm={10} md={10} lg={10}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Typography className="case-list-title" sx={{ marginLeft: '3rem' }} data-testid='bank' >
                            {t("Identification List")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2}>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="baseline">
                        <Button id="add-bank"
                            data-testid='add-bank'
                            variant="contained"
                            startIcon={<AddIcon />}
                            sx={{ margin: '10px', textTransform: 'capitalize' }}
                            onClick={() => addIdentificationDetail()}
                        >
                            {t("Add_Identification")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Stack justifyContent='center' alignItems='center' >
                <IcmsTableContainer data-testid='table-container'>
                    <IcmsMuiTable stickyHeader aria-label="sticky table" data-testid='table'>
                        <TableHead data-testid='table-head'>
                            <IcmsTableRow>
                                {
                                    columns.map((column, index) => {
                                        return (
                                            <StyledTableCell key={column.id} data-testid={column.id} width={column.width} align={column.align}>{column.title}</StyledTableCell>
                                        )
                                    })
                                }
                            </IcmsTableRow>
                        </TableHead>
                        <TableBody data-testid='table-body'>
                            {identificationListData.length > 0 ? identificationListData.map((row, index) => {
                                return (
                                    <IcmsTableRow key={row.id} data-testid='table-row'>
                                        <StyledTableCell data-testid='name' align='left'>{index + 1}</StyledTableCell>
                                        <StyledTableCell data-testid='description' align='left' >{row.identificationLocale}</StyledTableCell>
                                        <StyledTableCell data-testid='start-date' align='left' >{row.identification}</StyledTableCell>
                                        <StyledTableCell data-testid='start-date' align='left' >{row.verifiedOn ? row.verifiedOn : '--'}</StyledTableCell>
                                        <StyledTableCell data-testid='action' align='left' sx={{ p: '0px' }} >
                                            <Fragment>
                                                {<Tooltip title={t('Edit')}>
                                                    <IconButton onClick={() => addIdentificationDetail(row)}>
                                                        <EditIcon data-testid='edit-icon' style={{ cursor: 'pointer', fontSize: '1.3rem', color: ICMSTheme.palette.primary.main }} />
                                                    </IconButton>
                                                </Tooltip>}
                                            </Fragment>
                                        </StyledTableCell>
                                    </IcmsTableRow>
                                )
                            }) :
                                <IcmsTableRow>
                                    <StyledTableCell colSpan={5} data-testid='no-record'>{t("No_Record_Found")}</StyledTableCell>
                                </IcmsTableRow>
                            }
                        </TableBody>
                    </IcmsMuiTable>
                </IcmsTableContainer>
            </Stack>
            {openDialog.open && <CreateIdentification data={openDialog} handleClose={handleClose} setMessage={setMessage} partyId={partyId}/>}
            {loading && <ICMSLoader show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
            {seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message}
                onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} />}
        </Fragment>
    )
}

export default IdentificationList;