import React, { Fragment, useState }        from 'react';
import { useFormik }                        from "formik";
import EditIcon                             from '@mui/icons-material/Edit';
import CloseIcon                            from '@mui/icons-material/Close';
import CheckIcon                            from '@mui/icons-material/Check';
import DeleteIcon                           from '@mui/icons-material/Delete';
import { IconButton, Tooltip }              from '@mui/material';
import { useTranslation }                   from 'react-i18next';
import {
    IcmsTableRow,
    StyledTableCell
}                                           from '../generic-styles/NavPageContainerStyles';
import { FormikTextField }                  from '../formik-fields-components/FormikFieldComponents';
import { SelectController }                 from '../formik-fields-components/SelectController'
import { 
    IdentificationType, 
    RegEx,
    IdentificationByPartyType 
}                                           from '../GenericCodes';
import * as Yup                             from 'yup';
import { SelectLabelController } from '../formik-fields-components/SelectLabelController';

const IdentificationValidationSchema = Yup.object().shape({
    identificationCd: Yup.string(),
    identification: Yup.string().required('ID is Required').when('identificationCd', {
            is: (identificationCd) => { return Number(identificationCd) === IdentificationType.CIN},
            then: () => Yup.string().required('ID is Required').matches(RegEx.CIN, 'Invalid CIN')
        }).when('identificationCd', {
            is: (identificationCd) => { return Number(identificationCd) === IdentificationType.PAN},
            then: () => Yup.string().required('ID is Required').matches(RegEx.PAN, 'Invalid PAN')
        }).when('identificationCd', {
            is: (identificationCd) => { return Number(identificationCd) === IdentificationType.PASSPORT},
            then: () => Yup.string().required('ID is Required').matches(RegEx.PASSPORT, 'Invalid Passport No.')
        }).when('identificationCd', {
            is: (identificationCd) => { return Number(identificationCd) === IdentificationType.VOTER_ID},
            then: () => Yup.string().required('ID is Required').matches(RegEx.VOTER_ID, 'Invalid Voter ID')
        }),
});

const IdentificationRow = ({partyCd, identificationDetails, handleCreate, handleUpdate, 
        handleDelete, edit, handleRefresh, canModify, canDelete}) => {
    const { t } = useTranslation();
    const [isEdit, setIsEdit] = useState(edit);
    const identificationFormik = useFormik({
        initialValues: {
            identification: identificationDetails.identification ||  '',
            identificationCd: identificationDetails.identificationCd || '',
            identificationId: identificationDetails.identificationId || '',
            identificationLocale: identificationDetails.identificationLocale || ''
        },
        validationSchema: IdentificationValidationSchema,
        validateOnBlur: true,
        validateOnMount: true,
        validateOnChange: true
    })

    const createIdentification = () => { 
        let payload = {
            identification: identificationFormik.values.identification,
            identificationCd: identificationFormik.values.identificationCd
        }
        handleCreate(payload);
    }

    const updateIdentification = () => { 
        let payload = {
            identificationId: identificationFormik.values.identificationId,
            identificationCd: identificationFormik.values.identificationCd,
            identification: identificationFormik.values.identification
        }
        handleUpdate(payload);
    }

    const deleteIdentification = () => { 
        handleDelete(identificationFormik.values.identificationId);
    }

    const editIdentification = () => {
        setIsEdit(true);
    }

    const cancelEditIdentification = () => {
        setIsEdit(false);
        handleRefresh();
    }

    const handleChange = (event) => {
        identificationFormik.setFieldTouched([event.target.id], true);
        identificationFormik.setFieldValue([event.target.id], event.target.value.toUpperCase());
    }

    return (
        <IcmsTableRow colSpan={2} sx={{verticalAlign: isEdit ? 'top' : 'middle'}}>
            <StyledTableCell sx={{padding: '4px'}}>
                {!isEdit && identificationFormik.values.identificationLocale}
                {isEdit && <SelectLabelController
                    id="identificationCd"
                    fieldName="identificationCd"
                    formik={identificationFormik}
                    size="small"
                    menuItems={IdentificationByPartyType[partyCd]}
                    handleChange={identificationFormik.handleChange}
                    handleBlur={identificationFormik.handleBlur}
                    menuFieldId={'id'}
                    menuFieldTitle={'title'}
                    valueField={'id'}
                />
            }
            </StyledTableCell>
            <StyledTableCell sx={{padding: '4px'}}>
                {!isEdit && identificationFormik.values.identification}
                {isEdit && <FormikTextField 
                    id="identification"
                    fieldName="identification"
                    size='small'
                    formik={identificationFormik} 
                    handleChange={handleChange}
                />}
            </StyledTableCell>
            <StyledTableCell sx={{padding: '4px', textAlign: 'center'}}>
                {(!isEdit && canModify) && <Tooltip title={t('Edit')}>
                    <IconButton size='small' color='primary' onClick={editIdentification}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>}
                {(!isEdit && canDelete) && <Tooltip title={t('Delete')}>
                    <IconButton size='small' color='secondary' onClick={deleteIdentification}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>}
                {isEdit && <Fragment>
                    <Tooltip title={t('Save')}>
                        <IconButton size='small' color='success'
                                onClick={identificationDetails.identificationCd ? updateIdentification : createIdentification}
                                disabled={!identificationFormik.isValid}>
                            <CheckIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Cancel')}>
                        <IconButton size='small' color='secondary' onClick={cancelEditIdentification}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Fragment>}
            </StyledTableCell>
        </IcmsTableRow>
    )
}

export default IdentificationRow