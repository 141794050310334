import { Box, Button, Card, CardContent, CardHeader, FormLabel, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';
import { keyframes } from '@mui/system';
import { ICMSTheme } from '../../theme/Theme'
import { AuthAxiosInterceptor, ICMSAxiosInterceptor } from '../../config/axios.interceptor'
import ICMSLoader from '../icms-loader/ICMSLoader'
import IcmsSnackbar from '../icms-snackbar/IcmsSnackbar'
import { ICMSButton } from '../icms-styled-components/IcmsStyledComponents'
import { TwoStepVerification } from './TwoStepVerification';
import ICMSConfirmDialog from '../ConfirmationDialog/ICMSConfirmDialog';


function UserDetails(props) {
    const { location, contextValues } = props
    const { t } = useTranslation();
    const [Edit, setEdit] = useState(false)
    const [subscriber, setSubscriber] = useState(false)
    const [message, setMessage] = useState({
        show: false,
        message: null,
        severity: null
    })
    const [loading, setLoading] = useState(false);
    const [identification, setIdentification] = useState('')
    const [openDialog, setOpenDialog] = useState(false)
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: ''
    })
    const [changeEnableBtn, setChangeEnableBtn] = useState(contextValues.isMFAEnabled)

    const handleEditDetails = () => {
        setEdit(true)
    }
    const handleCancelEdit = () => {
        setEdit(false)
    }
    const handleSaveEdit = () => {
        setEdit(true)
    }

    const requestToSubscribe = (value) => {
        setSubscriber(value)
    }
    const blink = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
`;

    const identificationList = () => {
        setLoading(true)
        ICMSAxiosInterceptor.post(`party/${location.partyId}/identification/list`, {}).then((response) => {
            response.items.map((value) => {
                setIdentification(value.identification)
            })
            setLoading(false)
        }).catch((error) => {
            setMessage({ show: true, message: error.message, severity: 'error' });
            setLoading(false)
        })
    }

    const handleTwoStep = () => {
        setOpenDialog(true)
    }

    const handleClose = () => {
        setOpenDialog(false)
    }

    useEffect(() => {
        identificationList()
    }, [])

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    const confirmDisableMFA = () => {
        setSeekConfitmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To_Disable_Two_Step_Verification'),
            onAgree: () => disableMFA(),
            onDisAgree: () => resetSeekConfirmation(),
            isCancel: true
        })
    }

    const disableMFA = () => {
        setLoading(true);
        AuthAxiosInterceptor.delete(`mfa/${location.loginUuid}`).then(response => {
            setLoading(false);
            resetSeekConfirmation();
            setChangeEnableBtn(false)
            setMessage({ show: true, message: t("Disabled_Two_Step_Verification"), severity: 'success' });
        }).catch(error => {
            resetSeekConfirmation()
            if (error.message) {
                setMessage({ show: true, message: error.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
            }
            setLoading(false);
        })
    }

    return (
        <div>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography style={{ marginTop: '0.7rem', fontWeight: 'bold', fontSize: '1.5rem' }} data-testid='address'>{t("User_Information")}</Typography>
            </Grid>
            {!(changeEnableBtn) && <Grid item xs={12} sx={{ textAlign: 'end', margin: '0px 20px 0px 0px' }} >
                <Button variant='outlined' size='small' onClick={handleTwoStep}>{t("Enable_Two_Step_Verification")}</Button>
            </Grid>}
            { (changeEnableBtn) && <Grid item xs={12} sx={{ textAlign: 'end', margin: '0px 20px 0px 0px' }} >
                <Button variant='outlined' size='small' onClick={confirmDisableMFA}>{t("Disable_Two_Step_Verification")}</Button>
            </Grid>}
            <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ margin: '20px 0px' }}>
                <Grid item xs={12} sm={3} md={3}>
                    <FormLabel sx={{ fontSize: (theme) => theme.typography.body2 }}>{t("Name")}</FormLabel>
                    <TextField variant='outlined' disabled={true} style={{ width: "100%" }} size='small' value={contextValues.name} />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                    <FormLabel sx={{ fontSize: (theme) => theme.typography.body2 }}>{t("Email")}</FormLabel>
                    <TextField variant='outlined' disabled={true} style={{ width: "100%" }} size='small' value={contextValues.email} />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                    <FormLabel sx={{ fontSize: (theme) => theme.typography.body2 }}>{t("Mobile_Number")}</FormLabel>
                    <TextField variant='outlined' disabled={true} style={{ width: "100%" }} size='small' value={contextValues.phoneNumber} />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                    <FormLabel sx={{ fontSize: (theme) => theme.typography.body2 }}>{t("Identification")}</FormLabel>
                    <TextField variant='outlined' disabled={true} style={{ width: "90%" }} size='small' value={identification} />
                </Grid>
            </Grid>

            {(!location.subscriber) &&
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={8}>
                        <Box
                            sx={{
                                display: 'flex', alignItems: 'center', backgroundColor: '#e0f7fa', border: '2px solid #0288d1',
                                borderRadius: '8px', padding: '16px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <Typography style={{ fontWeight: 'bold', fontSize: '18px' }} >{t("Subscription")}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">{t("Subscription_Details_Required")}</Typography>
                                </Grid>
                                <Grid item xs={12} container alignItems="center" justifyContent='center'>
                                    {!subscriber && <Button
                                        onClick={() => requestToSubscribe(true)}
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            textTransform: 'capitalize',
                                            animation: `${blink} 2s infinite`,
                                            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                                            color: 'white',
                                            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                                            padding: '10px 20px',
                                            borderRadius: '30px',
                                            fontSize: '16px',
                                            '&:hover': {
                                                background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
                                                animation: 'none',
                                            }
                                        }}
                                    >
                                        {t("Request_To_Subscribe")}
                                    </Button>}
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            }
            {subscriber &&
                <Grid container spacing={2} alignItems='center' justifyContent='center'>
                    <Card style={{ borderRadius: '20px', width: '40%', marginTop: '20px', paddingBottom: '10px' }}>
                        <CardHeader
                            title={t("Contact_ICMS_Support_To_Subscribe")}
                            style={{ backgroundColor: ICMSTheme.palette.primary.main, color: 'white', padding: '5px 0px 5px 10px' }}
                        />
                        <CardContent>
                            <Box isplay="flex" flexDirection="column" alignItems="center" width="100%">

                                <Box display="flex" alignItems="center" mb={1} style={{ wordBreak: 'break-all' }}>
                                    <EmailIcon style={{
                                        fontSize: '20px',
                                        backgroundColor: ICMSTheme.palette.primary.main, color: 'white', borderRadius: '50%', padding: '6px', marginRight: '8px'
                                    }} />
                                    <Box>ipsupport@nesl.co.in</Box>
                                </Box>
                                <Box display="flex" alignItems="center" mb={1}>
                                    <PhoneIcon style={{
                                        fontSize: '20px',
                                        backgroundColor: ICMSTheme.palette.primary.main, color: 'white', borderRadius: '50%', padding: '6px', marginRight: '8px'
                                    }} />
                                    <Box>1800 599 2345</Box>
                                </Box>
                                <Box display="flex" alignItems="center" mb={1} style={{ wordBreak: 'break-all' }}>
                                    <LanguageIcon style={{
                                        fontSize: '20px',
                                        backgroundColor: ICMSTheme.palette.primary.main, color: 'white', borderRadius: '50%', padding: '6px', marginRight: '8px'
                                    }} />
                                    <Box>https://nesl.co.in/</Box>
                                </Box>
                            </Box>
                            <ICMSButton variant='outlined' style={{ float: "right" }} onClick={() => requestToSubscribe(false)} data-testid='cancel-btn'>{t("Close")}</ICMSButton>
                        </CardContent>
                    </Card>
                </Grid>}
            {openDialog && <TwoStepVerification open={openDialog} handleClose={handleClose} loginUuid={location.loginUuid} onSuccess={setMessage} contextValues={contextValues} setChangeEnableBtn={setChangeEnableBtn}/>}
            {seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}
            />}
            {loading && <ICMSLoader show={loading} />}
            {
                message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                    handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />
            }
        </div>
    )
}

export default UserDetails
