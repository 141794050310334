import "./NotFound.css";

const NotFound = () => {
    return (
        <div className="flex-container">
            <img src={process.env.PUBLIC_URL+"/images/not-found.svg"} alt="404 - NOT FOUND" width="50%" height="50%"/>
                <h2 className="text-secondary main-text">Oops! The requested Page is not found</h2>
                <p className="text-secondary">The page you are looking for does not exist, have been removed. name changed or is temporarily unavailable
                </p>
                <div className="buttons-con">
                    <div className="action-link-wrap">
                        <a href="/" className="link-button">Go to Home Page</a>
                    </div>
                </div>
        </div>
    )
}

export default NotFound;