import { AppBar, Box, Button, Divider, Grid, List, ListItemButton, Popover, Stack, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineAddCircle } from 'react-icons/md';
import { ICMSAxiosInterceptor } from '../../../config/axios.interceptor';
import ICMSLoader from '../../icms-loader/ICMSLoader';
import { AddressTypes } from '../../../common/GenericCodes';
import IcmsSnackbar from '../../icms-snackbar/IcmsSnackbar';
import { JustifyCard } from '../../icms-styled-components/IcmsStyledComponents';
import { StyledTab, TabPanel } from '../../icms-styled-components/Tab';
import AddressDetails from './AddressDetails';

const AddressTab = (props) => {
    const { partyId, loginUser } = props;
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const allAddresses = [AddressTypes.registerdWithIBBI, AddressTypes.correspondence, AddressTypes.primary, AddressTypes.billing];
    const open = Boolean(anchorEl);
    const id = open ? 'add-address-popper' : undefined;
    const [loadingAddress, setLoadingAddress] = useState(false);
    const [tabAddressLength, setTabAddressLength] = useState(AddressTypes.primary.addressCd)
    const [userAddressLists, setUserAddressLists] = useState([]);
    const [addressTypes, setAddressTypes] = useState([])
    const [message, setMessage] = useState({
        show: false,
        message: null,
        severity: null
    })
    const [disabled, setDisabled] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClosed = () => {
        setAnchorEl(null);
    }

    const listAddress = (activeCd) => {
        setLoadingAddress(true);
        setUserAddressLists([]);
        setAddressTypes([]);
        setTabAddressLength(0);
        const listUrl = loginUser ? `address/list` : `address/list/${partyId}`
        ICMSAxiosInterceptor.get(listUrl).then((response) => {
            response.items.map((address, index) => {
                setUserAddressLists(prevState => ([
                    ...prevState,
                    {
                        addressId: address.addressId,
                        partyAddressId: address.partyAddressId,
                        partyId: address.partyId,
                        addressCd: address.addressCd,
                        addressLocale: address.addressLocale,
                        addressLine1: address.addressLine1,
                        addressLine2: address.addressLine2,
                        country: address.country,
                        countryId: address.countryId,
                        postalCode: address.postalCode,
                        state: address.state,
                        cityOrTown: address.cityOrTown,
                        endTime: address.endTime
                    }
                ]))
                setAddressTypes(prev => ([...prev, address.addressCd]));
                if (activeCd != null && activeCd == address.addressCd) {
                    setTabAddressLength(index);
                }
            })
            setLoadingAddress(false);
        }).catch((error) => {
            setLoadingAddress(false)
            if (error.message) {
                setMessage({ show: true, message: error.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
            }
        })
    }

    const addNewAddress = async (newAddress) => {
        handleClosed();
        setTabAddressLength(userAddressLists.length);
        setUserAddressLists(
            prevState => ([
                ...prevState,
                {
                    addressCd: newAddress.addressCd,
                    addressLocale: t(newAddress.label),
                    addressLine1: '',
                    addressLine2: '',
                    cityOrTown: '',
                    state: '',
                    postalCode: '',
                    country: '',
                    partyAddressId: '',
                    partyId: ''
                }
            ]))
        setDisabled(true);
    }

    const handleTabChange = (event, newValue) => {
        setTabAddressLength(newValue);
    }

    const handleCloseAddress = (addressCd) => {
        listAddress(addressCd);
        setDisabled(false);
    }

    const getRemainingAddresses = () => {
        const remainingAddress = allAddresses.filter((address) => !addressTypes.includes(address.addressCd));
        return remainingAddress
    };

    useEffect(() => {
        listAddress(null);
    }, [])

    return (
        <JustifyCard style={{ width: '100%' }}>
            <Grid container>
                <Grid item xs={12} >
                    {userAddressLists.length < 4 && <Button startIcon={<MdOutlineAddCircle />} style={{ float: 'right', margin: '0.5rem 1rem  0rem 1rem' }} disabled={disabled} data-testid='add-address' variant='outlined' onClick={handleClick} >{t("Add_Address")}</Button>}
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClosed}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        className='addressTypePopper'>
                        <Typography sx={{ margin: 2 }} component="div">
                            <List style={{ paddingTop: '0px' }}>
                                <Typography color='GrayText' sx={{ p: '8px 16px' }}>{t('Select_the_Address_Type')}</Typography>
                                <Divider flexItem />
                                {/* if don't want remaining address map with allAddresses else map with getRemainingAddresses */}
                                {getRemainingAddresses().map((address, index) => {
                                    return (
                                        <div>
                                            <ListItemButton
                                                key={index}
                                                onClick={() => addNewAddress(address)}
                                            >
                                                {t(address.label)}
                                            </ListItemButton>
                                        </div>
                                    )
                                })}
                            </List>
                        </Typography>
                    </Popover>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }} className="loading-container" sx={{ p: '0.5rem 1rem  0rem 1rem' }}>
                    <Box justifyContent="center">
                        <AppBar position="static" color="default">
                            {userAddressLists.length > 0 &&
                                <Tabs
                                    value={tabAddressLength}
                                    onChange={handleTabChange}
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: 'rgb(95 112 196)',
                                            height: '6px',
                                            color: 'rgb(95 112 196)'
                                        }
                                    }}
                                    aria-label="creditor details tabs"
                                >
                                    {
                                        userAddressLists.map((tab, index) => {
                                            return <StyledTab key={tab.addressCd} iconPosition='start' icon={tab.icon}
                                                label={tab.addressLocale} sx={{ minHeight: '50px' }}
                                                color='rgb(95 112 196)' />
                                        })
                                    }
                                </Tabs>
                            }
                        </AppBar>
                        {userAddressLists.map((e, index) => {
                            return <TabPanel key={index} value={tabAddressLength} index={index} >
                                <AddressDetails
                                    data={e}
                                    handleCloseAddress={handleCloseAddress}
                                    setTabAddressLength={setTabAddressLength}
                                    setDisabled={setDisabled}
                                    loginUser={loginUser}
                                    setMessage={setMessage}
                                    partyId={partyId}
                                />
                            </TabPanel>
                        })}
                        {userAddressLists.length === 0 &&
                            <Stack direction='column' spacing={2} justifyContent='center' alignItems='center'>
                                <img src={process.env.PUBLIC_URL + '/images/emptyAddress.svg'} alt='Address-Logo' width='20%' height='20%' />
                                <Typography variant='h5' textAlign='center'>
                                    {t('No_Address_at_the_moment')}
                                </Typography>
                            </Stack>}
                    </Box>
                </Grid>
            </Grid>
            <br />
            {loadingAddress && <ICMSLoader show={loadingAddress} invisible={false} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </JustifyCard>
    )
}

export default AddressTab