import { Fragment, useEffect, useState }            from "react";
import { Add, Edit, 
    NavigateBeforeRounded, NavigateNextRounded 
}                                                   from "@mui/icons-material";
import { 
    Accordion, AccordionDetails, 
    AccordionSummary, Button, Card, 
    Chip, IconButton, Stack, Typography 
}                                                   from "@mui/material";
import { BiSolidBank, BiSolidUserBadge }            from "react-icons/bi";
import { BsFillPersonFill }                         from "react-icons/bs";
import { FaFileLines, FaLocationDot }               from "react-icons/fa6";
import { MdAccountBalanceWallet }                   from "react-icons/md";
import { useParams }                                from "react-router-dom";
import ICMSLoader2                                  from "../../../../common/icms-loader/icms-loader-2.O/ICMSLoader2";
import IcmsSnackbar                                 from "../../../../common/icms-snackbar/IcmsSnackbar";
import { listBank, deleteBank }                     from "../../file-claim-services/BankService";
import { BankDetail }                               from "./BankDetail";
import { BankAccountTypeCd }                        from "./BankGenericCodes";
import { useTranslation } from "react-i18next";

const BankList = (props) => {
    const { handlePrevious, handleNext, totalSteps, width, left } = props;
    const { t } = useTranslation();
    const param = useParams();
    const partyId = Number(param.id);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ show: false, message: '', severity: '' });
    const [isNew, setIsNew] = useState(false);
    const [bankDetails, setBankDetails] = useState([]);

    const [bankDetail, setBankDetail] = useState(null);
    const [activePanel, setActivePanel] = useState({});

    const handleChange = (index, bankDetail) => (event, isExpanded) => {
        setActivePanel(prevState => ({
            ...prevState,
            ['panel' + index]: isExpanded,
        }));
        setBankDetail(bankDetail);
    };

    const closeCurrentPanel = (index) => {
        setActivePanel(prevState => ({
            ...prevState,
            ['panel' + index]: false,
        }));
        setIsNew(false);
    }

    const listForBank = (message) => {
        setLoading(true);
        listBank(Number(param.id),
            (response) => {
                setBankDetails(response);
                setLoading(false);
                if (message) {
                    setMessage(message);
                }
            },
            (error) => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            })
    }

    const handleDelete = (id, index) => {
        if(index) closeCurrentPanel(index);
        setLoading(true);
        deleteBank(id,
            (response) => {
                if (response) {
                    closeCurrentPanel(index);
                    listForBank();
                }
                setLoading(false);
            },
            (error) => {
                console.error(error);
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            })
    }

    useEffect(() => {
        listForBank();
    }, [])

    return (
        <Fragment>
            {bankDetails &&
            <Stack>
                {bankDetails.length > 0 && !isNew && <Stack justifyContent='end' alignItems='end' p={1}>
                    <Button sx={{ width: 'fit-content' }} color="primary" variant="contained" startIcon={<Add />} disabled={isNew} size="small"
                        data-testid="add-bank" onClick={() => setIsNew(true)}>{t('Add_Bank_Account')}</Button>
                </Stack>}
                {(bankDetails.length === 0 || isNew) &&
                    <Accordion expanded={bankDetails.length === 0 || isNew} onChange={() => { setIsNew(false) }}
                        sx={{
                            mb: '1rem',
                            border: '1px solid #eeeeee',
                            boxShadow: '0px 20px 35px 0px rgba(15.000000000000002, 33.000000000000014, 60.00000000000001, 0.13)',
                            borderRadius: '12px !important',
                            "&::before": {
                                backgroundColor: 'transparent !important'
                            }
                        }}>
                        <AccordionSummary
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            sx={{

                            }}
                        >
                            <Typography variant="h6">{t('Add_New_Bank_Account')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                                {<BankDetail bankDetail={null} closeCurrentPanel={closeCurrentPanel} partyId={partyId}
                                    listForBank={listForBank}/>}
                        </AccordionDetails>
                    </Accordion>
                }
                {bankDetails.length > 0 && bankDetails.map((bank, index) => {
                    return (
                        <Accordion expanded={activePanel['panel' + (index + 1)] !== undefined && activePanel['panel' + (index + 1)] === true} onChange={handleChange(index + 1, bank)}
                            key={bank.bankId}
                            sx={{
                                mb: '1rem',
                                border: '1px solid #eeeeee',
                                boxShadow: '0px 20px 35px 0px rgba(15.000000000000002, 33.000000000000014, 60.00000000000001, 0.13)',
                                borderRadius: '12px !important',
                                "&::before": {
                                    backgroundColor: 'transparent !important'
                                }
                            }}>
                            <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{

                                }}
                            >
                                {activePanel['panel' + (index + 1)] !== undefined && activePanel['panel' + (index + 1)] === true ?
                                    <Typography variant="h6">{index + 1}.&nbsp;{t('Edit_Bank_Account')}&nbsp;{bank.accountNumber}</Typography>
                                    :
                                    <Fragment>
                                        <Stack direction="row" spacing={6} alignItems='center' width='100%'>
                                            <Stack direction='row' spacing={2} alignItems='center'>
                                                <BiSolidBank style={{ fontSize: '1.5rem', color: '#d4d4d6' }} />
                                                <Stack direction='column' spacing={0.2}>
                                                    <Typography variant='caption' color="GrayText">{t('Bank_Name')}:</Typography>
                                                    <Typography variant='body1' color="textSecondary" sx={{ fontWeight: 'bold' }}>{bank.bankName}</Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack direction='row' spacing={2} alignItems='center'>
                                                <BsFillPersonFill style={{ fontSize: '1.5rem', color: '#d4d4d6' }} />
                                                <Stack direction='column' spacing={0.2}>
                                                    <Typography variant='caption' color="GrayText">{t('Account_Name')}:</Typography>
                                                    <Typography variant='body1' color="textSecondary" sx={{ fontWeight: 'bold' }}>{bank.accountHolderName}</Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack direction='row' spacing={2} alignItems='center'>
                                                <MdAccountBalanceWallet style={{ fontSize: '1.5rem', color: '#d4d4d6' }} />
                                                <Stack direction='column' spacing={0.2}>
                                                    <Typography variant='caption' color="GrayText">{t('Account_Number')}:</Typography>
                                                    <Typography variant='body1' color="textSecondary" sx={{ fontWeight: 'bold' }}>{bank.accountNumber}</Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack direction='row' spacing={2} alignItems='center'>
                                                <FaFileLines style={{ fontSize: '1.5rem', color: '#d4d4d6' }} />
                                                <Stack direction='column' spacing={0.2}>
                                                    <Typography variant='caption' color="GrayText">{t('IFSC_Code')}:</Typography>
                                                    <Typography variant='body1' color="textSecondary" sx={{ fontWeight: 'bold' }}>{bank.ifsc}</Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack direction='row' spacing={2} alignItems='center'>
                                                <FaLocationDot style={{ fontSize: '1.5rem', color: '#d4d4d6' }} />
                                                <Stack direction='column' spacing={0.2}>
                                                    <Typography variant='caption' color="GrayText">{t('Branch_Name')}:</Typography>
                                                    <Typography variant='body1' color="textSecondary" sx={{ fontWeight: 'bold' }}>{bank.branchName}</Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack direction='row' spacing={2} alignItems='center'>
                                                <BiSolidUserBadge style={{ fontSize: '1.5rem', color: '#d4d4d6' }} />
                                                <Stack direction='column' spacing={0.2}>
                                                    <Typography variant='caption' color="GrayText">{t('Account_Type')}:</Typography>
                                                    <Chip
                                                        label={BankAccountTypeCd.SAVINGS_ACCOUNT.codeCd === bank.bankAccountCd ? BankAccountTypeCd.SAVINGS_ACCOUNT.description :
                                                            BankAccountTypeCd.CURRENT_ACCOUNT.description}
                                                        color="secondary" size="small" />
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        <IconButton color='primary' sx={{ float: 'right' }}><Edit /></IconButton>
                                        {/* <IconButton color='error' sx={{ float: 'right' }}><Delete /></IconButton> */}
                                    </Fragment>
                                }
                            </AccordionSummary>
                            <AccordionDetails>
                                {bankDetail !== null && <BankDetail bankDetail={bankDetail} closeCurrentPanel={closeCurrentPanel} index={index} partyId={partyId}
                                    listForBank={listForBank} handleDelete={handleDelete}/>}
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </Stack>}
            <Card sx={{ bottom: 0, position: 'fixed', width: width, borderTop: '2px solid #eeeeee', left: left }}>
                <Stack justifyContent='space-between' direction="row">
                    <Button startIcon={<NavigateBeforeRounded className='back-icon' />} sx={{ float: 'left', mx: 5, my: 1 }}
                        onClick={handlePrevious} variant="outlined" color='primary'>{t('Back')}
                    </Button>
                    {param.activeStep < totalSteps &&
                        <Button endIcon={<NavigateNextRounded className={'next-icon'} />} sx={{ float: 'right', mr: 5, my: 1 }} onClick={handleNext} variant="contained" color='primary'
                            >
                            {t('Next')}
                        </Button>}
                </Stack>
            </Card>
            {loading && <ICMSLoader2 show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </Fragment>
    )

}

export { BankList };