import React, { useState, Fragment, useEffect }    from 'react';
import { useFormik }                    from "formik";
import { useTranslation }               from 'react-i18next';
import { Grid, Stack, Tooltip, Fab, Select, MenuItem, Typography, FormControl, InputLabel }    from '@mui/material';
import EditTwoToneIcon                  from '@mui/icons-material/EditTwoTone';
import Close                            from '@mui/icons-material/Close';
import Check                            from '@mui/icons-material/Check';
import NoAddress                        from '@mui/icons-material/LocationOff';
import { Delete }                       from '@mui/icons-material';
import { isValidPhoneNumber }           from 'react-phone-number-input';
import * as Yup                         from 'yup';
import { FormikLabelMuiPhone, 
    FormikLabelTextField }              from '../../common/formik-fields-components/FormikFieldComponents';
import { setFormikValueWithLowerCase }  from '../../common/utils/Utils';
import AddressDetail                    from '../address/AddressDetails';
import { ICMSAxiosInterceptor }         from '../../config/axios.interceptor';
import ICMSLoader                       from '../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                     from '../../common/icms-snackbar/IcmsSnackbar';
import { ContactType, PartyContactTypes, 
    RegEx }                             from '../../common/GenericCodes';
import FormikTextFieldAndAutoComplete   from '../../common/formik-fields-components/FormikTextFieldAndAutoComplete';
import ICMSConfirmDialog                from '../../common/ConfirmationDialog/ICMSConfirmDialog';
import { ICMSButton } from '../../common/icms-styled-components/IcmsStyledComponents';

const ContactValidationSchema = Yup.object().shape({
    addressLine1: Yup.string().required('Address Line 1 is required').min(2, 'Enter minimum of 2 letters')
        .max(100, 'Limit is till 100 characters'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    postalCode: Yup.string().required('Pin Code is required').matches(RegEx.PIN_CODE, 'Invalid Pin Code'),
    cityOrTown: Yup.string().required('City is required'),
    email: Yup.string().when(['siteCd'], {
        is: (siteCd) => { return Number(siteCd) === PartyContactTypes.REGISTERED_CONTACT },
        then: () => Yup.string().email('Invalid Email ').test('custom-validation', 'Invalid Email', (value) => {
            return RegEx.EMAIL.test(value);
        }).required('Email is required'),
        otherwise: () => Yup.string().email('Invalid Email').test('custom-validation', 'Invalid Email', (value) => !value || RegEx.EMAIL.test(value))
        .nullable(true)
    }),
    phoneNumber: Yup.string().when(['siteCd'], {
        is: (siteCd) => { return Number(siteCd) === PartyContactTypes.REGISTERED_CONTACT},
        then: () => Yup.string().required("Mobile Number is required")
            .test("phoneNumber", "Invalid Mobile Number", (str, context) => {
                return isValidPhoneNumber(context.parent['extension'] + str);
            }),
        otherwise: () => Yup.string().test("phoneNumber", "Invalid Mobile Number", (str, context) => 
            !str || isValidPhoneNumber(context.parent['extension'] + str)).nullable(true)
    })
});

const CaseContactDetails = ({contact, canEdit, caseId, relationshipId, partyId, refresh, showActionsInBottom}) => {
    const { t } = useTranslation();
    const [isNew, setIsNew] = useState(contact.partyAddressId ? false : true);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});
    const [isEdit, setIsEdit] = useState(contact.partyAddressId ? false : (canEdit ? true : false));
    const [partyAddresses, setPartyAddresses] = useState([]);
    const [partyEmails, setPartyEmails] = useState([]);
    const [partyPhoneNumbers, setPartyPhoneNumbers] = useState([]);
    const contactFormik = useFormik({
        initialValues: {
            siteCd: contact.siteCd || '',
            siteLocale: contact.siteLocale || '',
            partyAddressId: contact.partyAddressId || '',
            addressLine1: contact.addressLine1 || '',
            addressLine2: contact.addressLine2 || '',
            cityOrTown: contact.cityOrTown || '',
            state: contact.state || '',
            postalCode: Number(contact.postalCode) || '',
            country: contact.country || 'India',
            partyEmailId: contact.partyEmailId || '',
            email: contact.email || '',
            partyPhoneNumberId: contact.partyPhoneNumberId || '',
            phoneNumber: contact.phoneNumber || '',
            extension: contact.extension || '+91'
        },
        validationSchema: ContactValidationSchema,
        validateOnMount: true,
        validateOnChange: true,
        validateOnBlur: true
    })

    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('Yes'),
        disAgreeBtnLabel: t('No'),
        onDisAgree: ''
    })

    const handleSave = () => {
        let payload = {
            ...contactFormik.values,
            phoneNumber: contactFormik.values.phoneNumber ? contactFormik.values.extension + '' + contactFormik.values.phoneNumber : ''
        }
        if (isNew) {
            setLoading(true);
            ICMSAxiosInterceptor.post(`case/${caseId}/site/party/${partyId}/relationship/${relationshipId}`, payload).then(response => {
                refresh();
                setLoading(false);
                setMessage({show: true, message: t('Contact_Added_Successfully'), severity: 'success'});
            }).catch(error => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            })
        } else {
            setLoading(true);
            ICMSAxiosInterceptor.put(`case/${caseId}/site/party/${partyId}/relationship/${relationshipId}`, payload).then(response => {
                refresh();
                setLoading(false);
                setMessage({show: true, message: t('Contact_Updated_Successfully'), severity: 'success'});
            }).catch(error => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            })
        }
    }

    const cancelEdit = () => {
        setIsEdit(false);
        refresh();
    }

    const deleteConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: `${t('Are_You_Sure_That_You_Want_To_Delete_Contact')}?`,
            onDisAgree: resetSeekConfirmation,
            onAgree: () => {
                resetSeekConfirmation();
                handleDelete();
            },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    const handleDelete = () => {
        setLoading(true);
        ICMSAxiosInterceptor.delete(`case/${caseId}/site/party/${partyId}/relationship/${relationshipId}/${contact.siteCd}`).then(response => {
            refresh();
            setLoading(false);
            setMessage({show: true, message: t('Contact_Deleted_Successfully'), severity: 'success'});
        }).catch(error => {
            setLoading(false);
            setMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    const listOfAddessesByPartyId = () => {
        setPartyAddresses([]);
        ICMSAxiosInterceptor.post(`address/list/${partyId}?page=${0}&limit=${20}&asc=false`, {}).then(response => {
            setLoading(false);
            response.items.map(address => {
                setPartyAddresses(prevState => ([
                    ...prevState,
                    address
                ]));
            })
        }).catch(error => {
            setLoading(false);
            setMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

    const listOfEmailByPartyId = () => {
        ICMSAxiosInterceptor.get(`contact/list/${partyId}`).then(response => {
            setLoading(false);
            setPartyEmails(response.items?.filter(contact => contact.contactCd == ContactType.EMAIL));
            setPartyPhoneNumbers(response.items?.filter(contact => contact.contactCd == ContactType.PHONE_NUMBER));
        }).catch(error => {
            setLoading(false);
            setMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

    const setSelectedAddress = (event) => {
        let address = partyAddresses.find(address => address.addressId == event.target.value);
        contactFormik.setFieldValue('partyAddressId', address.partyAddressId);
        contactFormik.setFieldValue('addressId', address.addressId);
        contactFormik.setFieldValue('addressLine1', address.addressLine1);
        contactFormik.setFieldValue('addressLine2', address.addressLine2);
        contactFormik.setFieldValue('cityOrTown', address.cityOrTown);
        contactFormik.setFieldValue('state', address.state);
        contactFormik.setFieldValue('postalCode', address.postalCode);
        contactFormik.setFieldValue('country', address.country ? address.country : 'India');
    }   

    const setSelectedEmail = (event, email) => {
        contactFormik.setFieldValue('partyEmailId', email.partyContactId);
        contactFormik.setFieldValue('email', email.contact);
        contactFormik.validateForm();
    }

    const resetSelectedEmail = (event) => {
        contactFormik.setFieldValue('partyEmailId', null);
        contactFormik.setFieldValue('email', '');
        contactFormik.validateForm();
    }

    useEffect(() => {
        listOfAddessesByPartyId();
        listOfEmailByPartyId();
    }, [])

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {(partyAddresses.length > 0 && isEdit) && <FormControl fullWidth>
                        <InputLabel id="address-selection">{t('Select_Address_From_Existing')}</InputLabel>
                        <Select data-testid="select-address" id="select-address" size="small" variant="standard"
                                onChange={setSelectedAddress} fullWidth labelId="address-selection">
                            { partyAddresses.map((option, index) => {
                                return <MenuItem value={option.addressId} key={option.addressId}>
                                    <Stack direction='column'>
                                        <Typography variant='body1' sx={{ color: 'grey'}}>
                                            {option.addressLine1}
                                            {option.addressLine2 ? ', ' + option.addressLine2 : ''}
                                            {option.cityOrTown ? ', ' + option.cityOrTown : ''}
                                            {option.state ? ', ' + option.state : ''}
                                            {option.postalCode ? ', ' + option.postalCode : ''}
                                        </Typography>
                                    </Stack> 
                                </MenuItem>
                            })}
                        </Select>
                    </FormControl>}
                </Grid>
                <Grid item xs={6}>
                    {canEdit && (!showActionsInBottom || contact.partyAddressId) && <Stack direction='row' justifyContent='flex-end' >
                        {!isEdit && <Fragment>
                            <Tooltip title={t('Edit')}>
                                <Fab id='edit' data-testid='edit' style={{ float: 'right', width: '40px', height: '40px', margin: '5px'}} 
                                    onClick={() => {setIsEdit(true)}} 
                                >
                                    <EditTwoToneIcon color="primary" />
                                </Fab>
                            </Tooltip>
                            {contactFormik.values.siteCd != PartyContactTypes.REGISTERED_CONTACT && <Tooltip title={t('Delete')}>
                                <Fab id='delete' data-testid='delete' style={{ float: 'right', width: '40px', height: '40px', margin: '5px'}} 
                                    onClick={deleteConfirmation} 
                                >
                                    <Delete color="secondary" />
                                </Fab>
                            </Tooltip>}
                        </Fragment>}
                        {isEdit && <Tooltip title={t('Save')}>
                            <Fab data-testid='save' id='save'
                                style={{ float: 'right', width: '40px', height: '40px', margin: '5px'}} 
                                onClick={handleSave} disabled={!contactFormik.isValid || !contactFormik.dirty}
                            >
                                <Check color="success" />
                            </Fab>
                        </Tooltip>}
                        {isEdit && <Tooltip title={t('Cancel')}>
                            <Fab id='cancel' data-testid='cancel' style={{ float: 'right', width: '40px', height: '40px', margin: '5px'}} 
                                onClick={cancelEdit}
                            >
                                <Close color="error" />
                            </Fab>
                        </Tooltip>}
                    </Stack>}
                </Grid>
                

                    {(canEdit || contact.partyAddressId) && <Fragment>
                        <Grid item xs={12}>
                            <AddressDetail addressFormik={contactFormik} required={true} editMode={isEdit}
                                isDisabled={!isEdit}/>
                        </Grid>
                            <Grid item xs={6} sx={{paddingTop: '13px !important'}}>
                                <FormikTextFieldAndAutoComplete 
                                    id='email'
                                    fieldName='email'
                                    label={t('Email')}
                                    size="small"
                                    required={isEdit && contactFormik.values.siteCd == PartyContactTypes.REGISTERED_CONTACT}
                                    formik={contactFormik}
                                    onSelect={setSelectedEmail}
                                    onClear={resetSelectedEmail}
                                    handleBlur={contactFormik.handleBlur}
                                    existingOptions={partyEmails}
                                    labelField='contact' 
                                    idField='partyContactId'
                                    disabled={!isEdit}
                                />
                                {/* <FormikLabelTextField
                                    id="email"
                                    fieldName="email"
                                    label={t("Email")}
                                    formik={contactFormik}
                                    size="small"
                                    required={isEdit && contactFormik.values.siteCd == PartyContactTypes.REGISTERED_CONTACT}
                                    handleChange={(event) => setFormikValueWithLowerCase(event, contactFormik)}
                                    handleBlur={contactFormik.handleBlur}
                                    disabled={!isEdit}
                                /> */}
                            </Grid>
                            <Grid item xs={3}>
                                <FormikLabelMuiPhone
                                    id="phoneNumber"
                                    fieldName="phoneNumber"
                                    label={t("Mobile_Number")}
                                    countryCodeName="extension"
                                    formik={contactFormik}
                                    size="small"
                                    required={isEdit && contactFormik.values.siteCd == PartyContactTypes.REGISTERED_CONTACT}
                                    handleChange={contactFormik.handleChange}
                                    handleBlur={contactFormik.handleBlur}
                                    disabled={!isEdit}
                                />
                            </Grid>
                            {showActionsInBottom && !contact.partyAddressId && <Grid item xs={12}>
                                <ICMSButton onClick={handleSave} color="primary" size="small" variant='contained'
                                        disabled={!contactFormik.isValid || !contactFormik.dirty} sx={{float: 'right'}}>
                                    {t('Save')}
                                </ICMSButton>
                            </Grid>}
                    </Fragment>}
                    {(!contact.partyAddressId && !canEdit) && <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                        <NoAddress style={{ fontSize: 50, color: 'gray' }} />
                        <Typography sx={{ color: 'gray'}} variant="h6">No address available</Typography>
                    </Grid>}    
                </Grid>
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} severity={message.severity} message={message.message} 
                    handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
            {seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}
            />}
        </div>
    )
}

export default CaseContactDetails