import { Person } from "@mui/icons-material";

export const CdSideValue = {
    GUARANTOR: 'debtor-guarantor',
    DIVISION: 'debtor-division',
    CORPORATE: 'debtor-details',
    KEY_PERSONS: 'debtor-key-persons',
    ADJUDICATION: 'debtor-adjudication',
    CHARGES: 'debtor-charges'
}

// left navigation order will take as in this list
export const CdSideMenu = [
    {
        id: 'corporate',
        value: CdSideValue.CORPORATE,
        title: 'Summary',
        icon: <Person />
    },
    {
        id: 'key-persons',
        value: CdSideValue.KEY_PERSONS,
        title: 'Key Persons',
        icon: <Person />
    },
    {
        id: 'guarantor',
        value: CdSideValue.GUARANTOR,
        title: 'Guarantor',
        icon: <Person />
    },
    {
        id: 'division',
        value: CdSideValue.DIVISION,
        title: 'Division',
        icon: <Person />
    },
    {
        id: 'adjudication',
        value: CdSideValue.ADJUDICATION,
        title: 'Adjudication',
        icon: <Person />
    },
    {
        id: 'charges',
        value: CdSideValue.CHARGES,
        title: 'Registered Charges',
        icon: <Person />
    }
]