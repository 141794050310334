import React, { useState }      from 'react';
import { useTranslation }       from 'react-i18next';
import { Card, Stack, Button }  from '@mui/material';
import { 
    NavigateBeforeRounded, 
    NavigateNextRounded 
}                               from "@mui/icons-material";
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { useParams }            from 'react-router-dom';
import DocumentList                from '../../../../common/documents/DocumentList';
import { UploadDialog }         from '../../../../common/documents/UploadDialog';
import { DocumentType } from '../../../../common/GenericCodes';
import { ICMSButton }           from '../../../../common/icms-styled-components/IcmsStyledComponents';

const ClaimDocuments = (props) => {
    const { handlePrevious, handleNext, totalSteps, canUpload } = props;
    const { t } = useTranslation();
    const param = useParams();

    return (
        <div>
            <DocumentList icmsTablePk={param.id} caseId={param.caseId} canUpload={canUpload}
                    documentTypeCd={DocumentType.DEBT_DEFAULT}/>
            <Card sx={{ bottom: 0, position: 'fixed', width: '100%', borderTop: '2px solid #eeeeee', left: '0px' }}>
                <Stack justifyContent='space-between' direction="row">
                    <ICMSButton startIcon={<NavigateBeforeRounded className='back-icon' />} sx={{ float: 'left', mx: 5, my: 1 }}
                        onClick={handlePrevious} variant="outlined" color='primary'>{t('Back')}
                    </ICMSButton>
                    {param.activeStep < totalSteps &&
                        <ICMSButton endIcon={<NavigateNextRounded className={'next-icon'} />} sx={{ float: 'right', mr: 5, my: 1 }} 
                                onClick={handleNext} variant="contained" color='primary'>
                            {t('Next')}
                        </ICMSButton>}
                </Stack>
            </Card>
        </div>
    )
}

export default ClaimDocuments