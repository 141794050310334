import { PublicAxiosInterceptor } from "../config/axios.interceptor";

export const scheduleTokenRefresh = () => {
    const accessTokenExpiry = sessionStorage.getItem('accessTokenExpiry');
    const timeToExpire = accessTokenExpiry - Date.now();
    const refreshTime = timeToExpire - 2 * 60 * 1000;
    if (refreshTime > 0) {
        setTimeout(refreshAccessToken, refreshTime);
    }   
};
  
const refreshAccessToken = async () => {
    try {
        PublicAxiosInterceptor.post('token', {
            'refresh_token': sessionStorage.getItem('refreshToken')
        }).then(response => {
            sessionStorage.setItem('token', response.access_token);
            sessionStorage.setItem('accessTokenExpiry', Date.now() + response.expires_in * 1000);
        }).catch(error => {
            if (error.message && error.message === 'refresh-token-expired') {
                sessionStorage.clear();
                window.location.replace('/sign-in')
            }
        })
        scheduleTokenRefresh();
    } catch (error) {
        console.error("Error refreshing access token", error);
    }
};