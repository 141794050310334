import { createTheme } from "@mui/material/styles";

const ICMSTheme = createTheme({
    palette: {
        primary: {
            main: "#005588",
        },
        secondary: {
            // main: "rgb(251, 150, 120)"
            main: "#ed5e5e"
        },
        info: {
            main: "rgb(3, 201, 215)",
            contrastText: '#fff'
        },
        background: {
            default: '#EFEFEF',
            paper: '#fff'
        }
    },
    components: {
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    color: 'red'
                }
            }
        }
    },
    icmsSideNav: {
        navBg: '#f3f7fa !important',
        navTextColor: '#6a7a83',
        navIconColor: '#6a7a83',
        activeNavbg: '#e5eef3',
        activeNavTextColor: '#23282b',
        activeNavIconColor: '#23282b',
        hoverNavbg: '#e8f1f5',
        hoverNavTextColor: '#e8f1f5',
        hoverNavIconColor: '#e8f1f5',
        navBorder: '#eff5f8'
    },
    themePrimaryMedColor: '#719EB9',
    themePrimaryLightColor: '#CDD9E0',
    themeSecondaryMedColor: '#ED9F9F',
    themeSecondaryLightColor: '#EBCCCC',
    typography: {
        fontFamily: [
            '"Roboto"',
            'sans-serif'
        ].join(','),
    },
    claimStepperColor: '#009c00',
    icmsStepperColor: 'royalblue'
})

export { ICMSTheme }