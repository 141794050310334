import { Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import React, { useState } from 'react'
import { ICMSButton } from '../icms-styled-components/IcmsStyledComponents'
import { useTranslation } from 'react-i18next';
import { AuthAxiosInterceptor, ICMSAxiosInterceptor } from '../../config/axios.interceptor';
import { useNavigate } from 'react-router-dom';
import ICMSLoader from '../icms-loader/ICMSLoader';
import IcmsSnackbar from '../icms-snackbar/IcmsSnackbar';

export const TwoStepVerification = (props) => {
    const { open, handleClose, loginUuid, setChangeEnableBtn,onSuccess } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [mfaCd, setMfaCd] = useState("1");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        show: false,
        message: null,
        severity: null
    })
    const handleMFACdChange = (event) => {
        setMfaCd(event.target.value);
    }

    const enableMFA = () => {
        let payload = {
            mfaCd: Number(mfaCd)
        }
        setLoading(true);
        AuthAxiosInterceptor.post(`mfa/${loginUuid}`, payload).then(response => {
            setLoading(false);
            setChangeEnableBtn(true)
            handleCancel();
            onSuccess({ show: true, message: t('Enabled_Two_Step_Verification_Re_Login'), severity: 'success' });
            // navigate("/sign-in")
        }).catch(error => {
            if (error.message) {
                setMessage({ show: true, message: error.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
            }
            setLoading(false);
        })
    }

    const handleCancel = () => {
        // onClose();
        handleClose(false)
    }

    return (
        <Dialog open={open} fullWidth maxWidth='md'>
            <DialogTitle style={{ paddingTop: '10px', fontWeight: 'bold' }}>
                {t('Enable_Two_Step_Verification')}
            </DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography id="enable-two-factor-auth-sub-title" variant="subtitle1" gutterBottom>
                            {t('Enable_Two_Step_Verification_SubTitle')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <RadioGroup row aria-label="mfa-cd" name="role" value={mfaCd} onChange={handleMFACdChange}>
                            <Grid item xs={6} style={{ textAlign: 'center' }}>
                                <Card style={{ margin: '4px', padding: '4px', display: 'flex', flexDirection: 'row', paddingTop: '36px', paddingBottom: '36px' }}>
                                    <div style={{ width: '100%' }}>
                                        <FormControlLabel value={1} control={<Radio color="primary" />} label={<span >{t("Email")}</span>} style={{ fontWeight: 'bold' }} />
                                        <br />
                                        <Typography id="verification-send-to-email-msg" variant="body2" gutterBottom style={{ color: 'gray' }}>
                                            {t('Verification_Send_To_Email_Msg')}
                                        </Typography>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'center' }}>
                                <Card style={{ margin: '4px', padding: '4px', display: 'flex', flexDirection: 'row', paddingTop: '36px', paddingBottom: '36px' }}>
                                    <div style={{ width: '100%' }}>
                                        <FormControlLabel value={2} control={<Radio color="primary" />} label={<span >{t("SMS")}</span>} style={{ fontWeight: 'bold' }} />
                                        <br />
                                        <Typography id="verification-send-to-sms-msg" variant="body2" gutterBottom style={{ color: 'gray' }}>
                                            {t('Verification_Send_To_SMS_Msg')}
                                        </Typography>
                                    </div>
                                </Card>
                            </Grid>
                        </RadioGroup>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ICMSButton id='enable-mfa' size="small" variant="contained" onClick={enableMFA}>
                    {t('Enable')}
                </ICMSButton>
                <ICMSButton id='cancel' size="small" variant="outlined" onClick={handleClose}>
                    {t('Cancel')}
                </ICMSButton>
            </DialogActions>
            {loading && <ICMSLoader show={loading} />}
            {
                message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                    handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />
            }
        </Dialog>
    )
}
