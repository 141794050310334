import React, { Fragment, useState,
    useEffect }                         from 'react';
import { useTranslation }               from 'react-i18next';
import { TableHead, Divider, Stack,
    IconButton, TableBody, Tooltip }    from '@mui/material';
import { Delete, Download, Visibility }             from '@mui/icons-material';
import moment                           from 'moment';
import axios                            from 'axios';
import {
    IcmsTableContainer, IcmsTableRow,
    IcmsMuiTable, StyledTableCell
}                                       from '../../../common/generic-styles/NavPageContainerStyles';
import { ICMSButton }                   from '../../../common/icms-styled-components/IcmsStyledComponents';
import { GenerateForm }                 from '../../file-claim/fc-file-claim/generate-upload-form/generate-form/GenerateForm';
import { UploadForm }                   from '../../../common/upload-form/UploadForm';
import { ReportAxiosInterceptor }       from '../../../config/axios.interceptor';
import ICMSConfirmDialog                from '../../../common/ConfirmationDialog/ICMSConfirmDialog';
import GenerateFormModel                from '../../file-claim/fc-file-claim/generate-upload-form/generate-form/GenerateFormModel';
import ICMSLoader                       from '../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                     from '../../../common/icms-snackbar/IcmsSnackbar';
import UploadFormModel                  from '../../file-claim/fc-file-claim/generate-upload-form/upload-form/UploadFormModel';
import PDFViewerModal                   from '../../../common/pdf-viewer/PDFViewerModal';

const CreditorClaimForm = ({caseId, creditorId, caseStage, formName, claimSubmission, creditorName, 
        setFormUploaded, caseCd, canEdit}) => {
    const { t } = useTranslation();
    const [formVersions, setFormVersions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});
    const [showGenerateForm, setShowGenerateForm] = useState(false);
    const [showUploadForm, setShowUploadForm] = useState(false);
    const [showPdf, setShowPdf] = useState({
        pdfData: '',
        show: false
    })
    const columns = [
        { id: 'formName', label: t('Form_Name'), minWidth: '30%' },
        { id: 'version', label: t('Version'), minWidth: '20%'},
        { id: 'date', label: t('Date'), minWidth: '20%'},
        // { id: 'noOfPages', label: t('Number_Of_Pages'), minWidth: '15%'},
        { id: 'action', label: '', minWidth: '10%'}
    ]

    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('Yes'),
        disAgreeBtnLabel: t('No'),
        onDisAgree: ''
    })

    const getFormVersions = () => {
        setLoading(true);
        ReportAxiosInterceptor.get(`list-icms-report-versions/4/report-cd/${formName?.code}?reportOf=${caseId}&transactionId=${creditorId}`).then(response => {
            setFormVersions(response);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
        })
    }

    const downloadForm = (version, view) => {
        setLoading(true);
        ReportAxiosInterceptor.post(`download-report/4/${version.fileVersionId}/true?key=${version.location}`, {}).then(url => {
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                if (view) {
                    setShowPdf({
                        show: true,
                        pdfData: response.data
                    })
                } else {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${formName?.name}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                }
            })
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const deleteConfirmation = (versionId, versionNum) => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: `${t('Are_You_Sure_That_You_Want_To_Delete_Form_Version')} ${versionNum} ?`,
            onDisAgree: resetSeekConfirmation,
            onAgree: () => {
                resetSeekConfirmation();
                deleteForm(versionId);
            },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    const deleteForm = (versionId) => {
        setLoading(true);
        ReportAxiosInterceptor.delete(`delete-version/${versionId}`).then(response => {
            console.log('response', response);
            getFormVersions();
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    useEffect(() => {
        getFormVersions();
        return () => {}
    }, [])
    
    const onSuccessfulGeneration = () => {
        setShowGenerateForm(false)
        getFormVersions();
    }
    
    const onSuccessfulUpload = () => {
        setShowUploadForm(false)
        getFormVersions();
        if (setFormUploaded) setFormUploaded(true);
    }

    return (
        <div>
            {(formVersions.length > 0 && !claimSubmission) && <Fragment>
                <IcmsTableContainer>
                    {canEdit && <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Tooltip title={`${'Generate'} ${formName?.name}`}>
                            <ICMSButton id="generate-form" color='primary' variant='contained' 
                                onClick={() => setShowGenerateForm(true)} style={{ marginRight: '10px' }} size='small'>
                                {t('Generate')}
                            </ICMSButton>
                        </Tooltip>
                        <Tooltip title={`${'Upload'} ${formName?.name}`}>
                            <ICMSButton id="upload-form" color='primary' variant='contained' 
                                onClick={() => setShowUploadForm(true)} style={{ marginRight: '10px' }} size='small'>
                                {t('Upload')}
                            </ICMSButton>
                        </Tooltip>
                    </div>}
                    <IcmsMuiTable stickyHeader aria-label="sticky table" data-testid="claim-form-list-table">
                        <TableHead>
                            <IcmsTableRow>
                                {columns.map((column) => (
                                    <StyledTableCell
                                        sx={{padding: '4px'}}
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {t(column.label)}
                                    </StyledTableCell>
                                ))}
                            </IcmsTableRow>
                        </TableHead>
                        <TableBody>
                            {
                                formVersions.map((version, index) => {
                                    return (
                                        <IcmsTableRow hover key={version.reportVersionId}>
                                            <StyledTableCell sx={{padding: '4px'}}>{formName?.name} 
                                                <em style={{color: 'gray'}}> - {version.generatedByApplication ? t('Generated') : t('Uploaded')}</em>
                                            </StyledTableCell>
                                            <StyledTableCell sx={{padding: '4px'}}>{t('Version')} {formVersions.length - index}</StyledTableCell>
                                            <StyledTableCell sx={{padding: '4px'}}>{moment(version.rowCreatedOn).format("DD-MM-YYYY HH:mm A")}</StyledTableCell>
                                            {/* <StyledTableCell sx={{padding: '4px'}}>{version.numberOfPages}</StyledTableCell> */}
                                            <StyledTableCell sx={{padding: '4px'}}>
                                                <Tooltip title={t('Download')}>
                                                    <IconButton size='small' color='primary' onClick={() => downloadForm(version, false)}>
                                                        <Download />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={t('View')}>
                                                    <IconButton size='small' color='primary' onClick={() => downloadForm(version, true)}>
                                                        <Visibility />
                                                    </IconButton>
                                                </Tooltip>
                                                {canEdit && <Tooltip title={t('Delete')}>
                                                    <IconButton size='small' color='secondary' onClick={() => 
                                                            deleteConfirmation(version.reportVersionId, (formVersions.length - index))}>
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>}
                                            </StyledTableCell>
                                        </IcmsTableRow>
                                    )
                                })
                            }
                            {formVersions.length === 0 && <IcmsTableRow>
                                <StyledTableCell colSpan={5} sx={{padding: '8px'}}>
                                    {t('No_Record_Found')}
                                </StyledTableCell>
                            </IcmsTableRow>}
                        </TableBody>
                    </IcmsMuiTable>
                </IcmsTableContainer>
            </Fragment>}
            {(formVersions.length === 0 || claimSubmission) && <Fragment>
                <Stack direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
                    sx={{
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                        background: (theme) => theme.palette.background.paper,
                        border: '1px solid #eeeeee',
                    }}>
                        <GenerateForm width={'55%'} label={t('Generate_Form')}
                            formName={formName} caseId={caseId} caseStage={caseStage} creditorId={creditorId}
                            onSuccess={onSuccessfulGeneration} caseCd={caseCd}/>
                        <Divider orientation={'vertical'} flexItem />
                        <UploadForm width={'45%'} label={formName?.name} formName={formName} caseId={caseId} transactionId={creditorId}
                                fileName={`${creditorName}_${creditorId}`} onSuccess={onSuccessfulUpload} caseStage={caseStage}
                                isClaimForm={true}/>
                </Stack>
            </Fragment>}
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} 
                    handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
            {seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}
            />}
            {showGenerateForm && <GenerateFormModel show={showGenerateForm} onClose={() => setShowGenerateForm(false)}
                    formName={formName} caseId={caseId} caseStage={caseStage} creditorId={creditorId}
                    onSuccess={onSuccessfulGeneration} caseCd={caseCd}/>}
            {showUploadForm && <UploadFormModel show={showUploadForm} onClose={() => setShowUploadForm(false)}
                    formName={formName} caseId={caseId} transactionId={creditorId}
                    fileName={`${creditorName}_${creditorId}`} onSuccess={onSuccessfulUpload} caseStage={caseStage}
                    isClaimForm={true}/>}
            {showPdf.show && <PDFViewerModal open={showPdf.show} 
                pdfData={showPdf.pdfData} onClose={() => setShowPdf({show: false, pdfData: ''})}/>}
        </div>
    )
}

export default CreditorClaimForm;