import { FormLabel, InputAdornment, Stack, TextField } from "@mui/material";
import { Fragment } from "react";
import { BiSolidMessageError } from "react-icons/bi";
import { FaCheck } from "react-icons/fa6";

const FormikLabelTextField = (props) => {
    const { id, fieldName, label, formik, handleChange, disabled, size, required, handleBlur, autoFocus } = { ...props, size: props.size || "medium" };
    return (
        <Stack spacing={0.01}>
            <FormLabel htmlFor={id} sx={{ fontSize: (theme) => theme.typography.body2 }} required={required}>{label}</FormLabel>
            <TextField
                fullWidth
                margin="normal"
                id={id}
                size={size}
                // label={label}
                name={fieldName}
                value={formik.values[fieldName]}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                autoFocus={autoFocus}
                // onBlur={formik.handleBlur}
                error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                helperText={formik.touched[fieldName] && formik.errors[fieldName]}
                InputProps={{
                    endAdornment: (
                        <Fragment>
                            <InputAdornment position='end'>
                                {formik.touched[fieldName] && Boolean(formik.errors[fieldName]) && <BiSolidMessageError style={{ color: '#d32f2f', fontSize: '1.5rem' }} />}
                                {/* {formik.touched[fieldName] && !formik.errors[fieldName] && <FaCheck style={{ color: '#4caf50', fontSize: '1.3rem' }} />} */}
                            </InputAdornment>
                        </Fragment>
                    ),
                    sx: {
                        background: (theme) => theme.palette.background.paper,
                    }
                }}
                // inputProps={{
                //     style: {
                //         height: '45px',
                //         padding: '0 14px',
                //     },
                // }}
            />
        </Stack>
    )
}

export { FormikLabelTextField }