import React, { 
    useState,
    useEffect, 
    useContext
}                               from 'react';
import { Grid, Button }         from '@mui/material';
import { useTranslation }       from 'react-i18next';
import { useFormik }            from 'formik';
import AuthRepList              from '../../file-claim/fc-file-claim/auth-rep/AuthRepList';
import{ listAuthRep }           from '../../file-claim/file-claim-services/AuthRepSerivce';
import CreateAuthRep            from '../../file-claim/fc-file-claim/auth-rep/CreateAuthRep';
import ICMSLoader               from '../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar             from '../../../common/icms-snackbar/IcmsSnackbar';
import { ICMSAxiosInterceptor } from '../../../config/axios.interceptor';
import ICMSConfirmDialog        from '../../../common/ConfirmationDialog/ICMSConfirmDialog';
import { ICMSButton }           from '../../../common/icms-styled-components/IcmsStyledComponents';
import AuthRep                  from '../../file-claim/fc-file-claim/auth-rep/AuthRep';
import { AddressTypes, CaseRoles, 
    IdentificationType, 
    Roles}        from '../../../common/GenericCodes';
import { AuthRepValidationSchema, 
    IPCreateAuthRepValidationSchema } from '../../file-claim/fc-file-claim/creditor-details/CreditorYupSchema';

import { CaseContext }          from '../../../common/context/case/CaseContext';
import { UserContext } from '../../../common/context/UserContext';

const CreditorAuthRep = ({caseId, creditorId, caseStage, canEdit, isIndividual, canSendCredentials, 
            claimantPartyRelationshipId, setEnabledSteps, getCreditor}) => {
    const { contextValue } = useContext(CaseContext);
    const userDetails = useContext(UserContext);
    const isEnkAdmin = userDetails?.loginDetails?.subscriberRole?.includes(Roles.enkAdmin.roleValue);
    const isEnkSupport = userDetails?.loginDetails?.subscriberRole?.includes(Roles.enkSupport.roleValue);
    const { t } = useTranslation();
    const [authRepList, setAuthRepList] = useState([]);
    const [showCreateAuthRep, setShowCreateAuthRep] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('Yes'),
        disAgreeBtnLabel: t('No'),
        onDisAgree: ''
    })

    const authRepFormik = useFormik({
        initialValues: {
            identificationCd: IdentificationType.PAN,
            name: '',
            identification: '',
            email: '',
            partyCd: 1,
            phoneNumber: '',
            extension: '+91',
            relationshipName: '',
            isSameAsCreditor: false,
            addressCd: AddressTypes.registerdWithIBBI.addressCd,
            addressLine1: '',
            addressLine2: '',
            cityOrTown: '',
            state: '',
            postalCode: '',
            country: 'India',
            partyId: null,
            allowlogin: '',
            createAccountAndSendCredentials: '',
            loginUid: ''
        },
        validationSchema: (contextValue?.roles?.includes(CaseRoles.ROLE_CASE_MANAGER) || contextValue?.roles?.includes(CaseRoles.ROLE_CASE_WORKER)
                || isEnkAdmin || isEnkSupport) 
            ? IPCreateAuthRepValidationSchema : AuthRepValidationSchema,
        validateOnBlur: true,
        validateOnMount: true,
        onSubmit: (values) => {
        }
    })

    const listOfAuthRep = () => {
        setLoading(true);
        listAuthRep(caseId, creditorId,
            (response) => {
                setAuthRepList(response);
                setLoading(false);
                if (setEnabledSteps) {
                    setEnabledSteps(prevState => ({
                        ...prevState,
                        authrizedPerson: response.length > 0
                    }))
                }
            },
            (error) => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            }
        )
    }

    const handleCreateAuthRep = () => {
        let payload = {
            identificationCd: authRepFormik.values.identificationCd,
            name: authRepFormik.values.name,
            identification: authRepFormik.values.identification,
            email: authRepFormik.values.email,
            partyCd: authRepFormik.values.partyCd,
            phoneNumber: authRepFormik.values.phoneNumber ? authRepFormik.values.extension + '' + authRepFormik.values.phoneNumber : null,
            relationshipName: authRepFormik.values.relationshipName,
            addressCd: authRepFormik.values.addressCd,
            addressLine1: authRepFormik.values.addressLine1,
            addressLine2: authRepFormik.values.addressLine2,
            cityOrTown: authRepFormik.values.cityOrTown,
            state: authRepFormik.values.state,
            postalCode: authRepFormik.values.postalCode,
            country: authRepFormik.values.country,
            partyId: authRepFormik.values.partyId,
            allowlogin: authRepFormik.values.allowlogin,
            createAccountAndSendCredentials: authRepFormik.values.createAccountAndSendCredentials,
            loginUid: authRepFormik.values.loginUid
        };
        setLoading(true);
        ICMSAxiosInterceptor.post(`case/${caseId}/claimant/${creditorId}/authorized-representative`, payload).then(response => {
            onSuccess({show: true, message: t('Authorised_Person_Created_Successfully'), severity: 'success'});
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const deleteConfirmation = (authRep) => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: `${t('Are_You_Sure_That_You_Want_To_Delete_Auth_Person')} ${authRep.name} ?`,
            onDisAgree: resetSeekConfirmation,
            onAgree: () => {
                resetSeekConfirmation();
                handleDelete(authRep.authorizedRepresentativeId);
            },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    const handleDelete = (authorizedRepresentativeId) => {
        setLoading(true);
        ICMSAxiosInterceptor.delete(`case/${caseId}/claimant/${creditorId}/authorized-representative/${authorizedRepresentativeId}`)
                .then(response => {
            setLoading(false);
            onSuccess({ show: true, message: t('Authorised_Person_Deleted_Successfully'), severity: 'success' });
        }).catch(error => {
            setLoading(false);
            if (error?.message) {
                setMessage({ show: true, message: error.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
            }
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    const onSuccess = (message) => {
        setMessage(message);
        listOfAuthRep();
        setShowCreateAuthRep(false);
    }

    useEffect(() => {
        listOfAuthRep();
    }, [])
    
    return (
        <Grid container direction="row" alignItems="center">
            <Grid item xs={6}>
                {/* {t('Authorised_Representatives')} */}
            </Grid>
            {authRepList.length > 0 && <Grid item xs={6}>
                {canEdit && <ICMSButton variant='contained' size='small' sx={{float: 'right'}} 
                    onClick={() => setShowCreateAuthRep(true)}>{t('Add_Auth_Person')}</ICMSButton>}
            </Grid>}
            {authRepList.length > 0 && <Grid item xs={12}>
                <AuthRepList authRepList={authRepList} onDelete={deleteConfirmation} caseId={caseId} 
                    claimantId={creditorId} onSuccess={onSuccess} canEdit={canEdit} 
                    canSendCredentials={canSendCredentials} claimantPartyRelationshipId={claimantPartyRelationshipId}
                    getCreditor={getCreditor}/>
            </Grid>}
            {authRepList.length === 0 && <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AuthRep authRepFormik={authRepFormik} claimantId={creditorId} caseId={caseId}
                        isCreditorIndividual={isIndividual} caseStage={caseStage}
                        canSendCredentials={canSendCredentials} showSelf={true}/>
                </Grid>
                <Grid item xs={12}>
                    <ICMSButton onClick={handleCreateAuthRep} color="primary" size="small" variant='contained'
                            disabled={!authRepFormik.isValid} sx={{float: 'right'}}>
                        {t('Save')}
                    </ICMSButton>
                </Grid>
            </Grid>}
            {showCreateAuthRep && <CreateAuthRep show={showCreateAuthRep} listOfAuthRep={listOfAuthRep}
                onClose={() => setShowCreateAuthRep(false)} caseId={caseId} claimantId={creditorId}
                onSuccess={onSuccess} caseStage={caseStage} isIndividual={isIndividual}
                canSendCredentials={canSendCredentials}/>}
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} 
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
            {seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}
            />}
        </Grid>
    )
}

export default CreditorAuthRep