import React, {
    useState
}                                           from 'react';
import { Grid }                             from "@mui/material";
import { useTranslation }                   from 'react-i18next';
import { FormikLabelTextField }             from '../../../../../../common/formik-fields-components/FormikFieldComponents';
import IcmsSnackbar                         from '../../../../../../common/icms-snackbar/IcmsSnackbar';
import { FormikNewDateTime }                from '../../../../../../common/formik-fields-components/FormikNewDateTime';
import { TextAreaLabelController }          from '../../../../../../common/formik-fields-components/TextAreaLabelController';

const AuthorizationForAssignmentFields = (props) => {
    const { afaFormik, disabled } = props;
    const { t } = useTranslation();
    const handleChange = (event) => {
        afaFormik.handleChange(event);
    };
    const [message, setMessage] = useState({
        show    : false,
        message : null,
        severity: null
    })

    const handleResetOnClose = () => {
        setMessage({show: false, message: '', severity: ''});
    }

    return (
            <div>
                <Grid container spacing={2} my={1}  alignItems="flex-start" sx={{padding: '0px 26px 0px 26px'}}>
                    <Grid item xs={12} md={3} sx={{paddingTop: "0px"}}>
                        <FormikLabelTextField
                            id="certificationNo"
                            fieldName="certificationNo"
                            label={t("Ceritification_No")}
                            formik={afaFormik}
                            handleChange={afaFormik.handleChange}
                            handleBlur={afaFormik.handleBlur}
                            required={!disabled}
                            disabled={disabled}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormikNewDateTime
                            id="validFrom"
                            fieldName="validFrom"
                            label={t('Valid_From')}
                            formik={afaFormik}
                            handleChange={handleChange}
                            handleBlur={afaFormik.handleBlur}
                            value={afaFormik.values.validFrom}
                            required={!disabled}
                            disabled={disabled}
                            maxDate={new Date()}
                            minDate={new Date('2016-01-01')} // Set minDate to January 1, 2016
                            size="small"
                            onlyDate={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormikNewDateTime
                            id="validTo"
                            fieldName="validTo"
                            label={t('Valid_To')}
                            formik={afaFormik}
                            handleChange={handleChange}
                            handleBlur={afaFormik.handleBlur}
                            value={afaFormik.values.validTo}
                            required={!disabled}
                            disabled={true}
                            maxDate={ afaFormik.values.validFrom ? new Date(afaFormik.values.validFrom).setFullYear(new Date(afaFormik.values.validFrom).getFullYear() + 1) : null}
                            minDate={afaFormik.values.validFrom}
                            size="small"
                            onlyDate={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} sx={{paddingTop: "0px"}}>
                        <TextAreaLabelController
                            id="remarks"
                            fieldName="remarks"
                            label={t("Remarks")}
                            formik={afaFormik}
                            minRows={2}
                            handleChange={handleChange}
                            handleBlur={afaFormik.handleBlur}
                            disabled={disabled}
                            size="small"
                        />
                    </Grid>
                </Grid>
                {message.show && 
                <IcmsSnackbar show={message.show} severity={message.severity} message={message.message} handleResetOnClose={handleResetOnClose} />}
            </div>
        );
    }
  
  export default AuthorizationForAssignmentFields;