import FolderTwoToneIcon from '@mui/icons-material/FolderTwoTone';

export const VdrSideValue = {
    SYSTEM_FLIES: 'vdr-systemfiles',
    GENERAL_FILES: 'vdr-generalfiles',
    PERMISSIONS: 'permissions',
}

// left navigation order will take as in this list
export const VdrSideMenu = [
    // {
    //     id: 'systemfiles',
    //     value: VdrSideValue.SYSTEM_FLIES,
    //     title: 'System Files',
    //     icon: <Person />
    // },
    {
        id: 'generalfiles',
        value: VdrSideValue.GENERAL_FILES,
        title: 'General Files',
        icon: <FolderTwoToneIcon />
    },
]