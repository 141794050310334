import { Card, Typography } from "@mui/material";
import { styled } from "@mui/system";

const TimeExpire = styled(Card)(({ theme, minutes }) => ({
    boxShadow: minutes >= 3 ? '2px 2px 4px green' : minutes >= 1  ? '2px 2px 4px orange': '2px 2px 4px red', 
    border: minutes >= 3 ? '1px solid green' :minutes >= 1  ? '1px solid orange': '1px solid red',
    // background: isselected ? '#e6f3ff7d !important' : 'inherit',
    animation: minutes < 1  && 'blink 1s infinite',
    borderRadius:'30px', background:'white', padding:'5px 15px',
    '@keyframes blink': {
        '0%':{
          opacity: 1/* Element is fully visible */
        },
        '50%': {
          opacity: 0.2/* Element becomes invisible */
        },
        '100%': {
          opacity: 1/* Element is fully visible again */
        }
      }
}))

const OtpText = styled(Typography)(({ theme, minutes }) => ({
    color: minutes >= 3 ? 'green' :minutes >= 1  ? 'orange': 'red',
    marginTop: '6px !important',
    transition: 'color 2s ease',
}))

export {TimeExpire,OtpText}