import { Paper } from "@mui/material";
import Draggable from "react-draggable";

function DialogDraggableComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  export default DialogDraggableComponent;