import { Button, Card, Stack, Typography }      from "@mui/material";
import { BsPersonFill }                         from "react-icons/bs";
import { useTranslation }                       from "react-i18next";
import { ICMSButton }                           from '../../../common/icms-styled-components/IcmsStyledComponents';
import "./ClaimantTypeCard.css";

const ClaimantTypeCard = (props) => {
    const {t} = useTranslation();
    const { title, secondaryTitle, description, personColor, icon, handleSelect, dataTestId, claimantRelationShipCd, isHome, width } = { ...props, description: props.description || 'description'}

    return (
        <Card elevation={0} className="claimant-type-card" sx={{width: width ? width:'25%'}}>
            <Stack alignItems='center' justifyContent='space-between' spacing={2} height='100%'>
                <span
                    style={{
                        position: 'relative'
                    }}
                >
                    <BsPersonFill style={{ fontSize: '3rem', color: personColor }} />
                    {icon}

                </span>
                <Stack direction='column' alignItems='center' justifyContent='center' spacing={1}>
                    <Typography color='textSecondary' style={{ fontWeight: 'bold' }}>{title}</Typography>
                    {secondaryTitle && <Typography color='textSecondary' style={{ fontWeight: 'bold', mt: 1 }}>{secondaryTitle}</Typography>}
                    <Typography color='textSecondary' variant="subtitle2">{description}</Typography>
                </Stack>
                {/* Replace 5 with Claimant Cd value */}
                <ICMSButton color="primary" onClick={() => handleSelect(claimantRelationShipCd, isHome)} 
                    data-testid={`${dataTestId}-btn`} variant='outlined'>{t('Select_Continue')}</ICMSButton>
            </Stack>
         </Card>
    )
}

export default ClaimantTypeCard;