import React from 'react';
import { FormControlLabel, Radio, Stack, Typography, Collapse, Grid } from "@mui/material";
import { BsPersonFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { RoleCard } from "./RoleCardStyle";
import { ApplicationUsers } from '../../../common/GenericCodes';
import { FormikTextField } from '../../../common/formik-fields-components/FormikFieldComponents';

export const RoleCardController = (props) => {
    const { t } = useTranslation();
    const { title, value, isselected, icon, id, selectedRole, handleChange, loginPurposeFormik, description } = props;

    const onRoleChange = () => {
        loginPurposeFormik.setFieldValue('selectedRole', value)
    }

    return (
        <RoleCard isselected={+isselected} style={{ width: '100% !important' }} sx={{width: '100%'}}>
            <FormControlLabel
                value={value}
                onChange={onRoleChange}
                control={<Radio color="primary" style={{ order: 2 }} inputProps={{ 'data-testid': `radio-${id}`}}/>}
                style={{ fontWeight: 'bold', width: '100%', padding: '1.5rem', }}
                slotProps={{
                    typography: {
                        style: {
                            width: '85%'
                        },
                        'data-testid': id
                    },
                }}
                label={
                    <Stack direction='row' alignItems='center' justifyContent='start' spacing={4}>
                        <span
                            style={{
                                position: 'relative'
                            }}
                        >
                            <BsPersonFill style={{ fontSize: '3rem', color: 'rgb(181 203 215)' }} />
                            {icon}

                        </span>
                        <Stack direction='column' spacing={1}>
                            <Typography color='textSecondary' style={{ fontWeight: 'bold' }}>{title}</Typography>
                            <Typography color='textSecondary' variant="subtitle2">{t(description)}</Typography>
                        </Stack>
                    </Stack>
                }
            />
            {/* {(selectedRole === ApplicationUsers.IP || selectedRole === ApplicationUsers.VALUER) && <Collapse in={isselected}>
                <Grid container>
                    <Grid item xs={10} sx={{paddingLeft: '6rem'}}>
                        <FormikTextField customStyle={{marginTop: '0px !important'}} id="ibbiRegNumber" fieldName="ibbiRegNumber" label={t('IBBI_Reg_Number')}
                            formik={loginPurposeFormik} handleChange={handleChange} required={true} size='small'
                        />
                    </Grid>
                </Grid>
            </Collapse>}
            {(selectedRole === ApplicationUsers.CLAIMANT || selectedRole === ApplicationUsers.RESOLUTION_APPLICANT) && <Collapse in={isselected}>
                <Grid container>
                    <Grid item xs={10} sx={{paddingLeft: '6rem'}}>
                        <FormikTextField customStyle={{marginTop: '0px !important'}} id="pan" fieldName="pan" label={t('PAN')} size='small'
                            formik={loginPurposeFormik} handleChange={handleChange} required={true}
                        />
                    </Grid>
                </Grid>
            </Collapse>} */}
        </RoleCard>
    )
}