import React, {
    useState,
    useEffect
}                               from 'react';
import {
    Grid,
    TableBody,
    TableHead,
    IconButton,
    Tooltip
}                               from '@mui/material';
import {
    IcmsTableContainer,
    IcmsMuiTable,
    IcmsTableRow,
    StyledTableCell
}                               from '../generic-styles/NavPageContainerStyles';
import { useTranslation }       from 'react-i18next';
import AddIcon                  from '@mui/icons-material/Add';
import EmailRow                 from './EmailRow';
import { ICMSAxiosInterceptor } from '../../config/axios.interceptor';
import { ContactType }          from '../../common/GenericCodes';
import ICMSLoader               from '../../common/icms-loader/ICMSLoader';
import IcmsSnackbar             from '../../common/icms-snackbar/IcmsSnackbar';

const EmailList = ({partyId, canAdd, canModify, canDelete}) => {
    const { t } = useTranslation();
    const [emails, setEmails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    });
    const columns = [
        { id: 'email', label: 'Email_ID', minWidth: '75%' },
        { id: 'action', label: 'Action', minWidth: '25%', align: 'center' }
    ]

    const addEmail = () => {
        let email = {
            email: '',
            isEdit: true
        }
        setEmails(prevState => ([
            email,
            ...prevState
        ]))
    }

    const getEmails = () => {
        if (partyId) {
            setLoading(true);
            ICMSAxiosInterceptor.get(`contact/list/${partyId}?contactCd=${ContactType.EMAIL}`).then(response => {
                setEmails(response.items);
                setLoading(false);
            }).catch(error => {
                if (error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                }
                setLoading(false);
            })
        } else {
            setLoading(true);
            ICMSAxiosInterceptor.get(`contact/list?contactCd=${ContactType.EMAIL}`).then(response => {
                setEmails(response.items);
                setLoading(false);
            }).catch(error => {
                if (error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                }
                setLoading(false);
            })
        }
    }

    const createEmail = (emailDetails) => {
        if (partyId) {
            setLoading(true);
            ICMSAxiosInterceptor.post(`contact/${partyId}`, emailDetails).then(response => {
                getEmails();
                setMessage({show: true, message: t('Created_Email_Successfully'), severity: 'success'});
                setLoading(false);
            }).catch(error => {
                if (error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                }
                setLoading(false);
            })
        } else {
            setLoading(true);
            ICMSAxiosInterceptor.post(`contact`, emailDetails).then(response => {
                getEmails();
                setMessage({show: true, message: t('Created_Email_Successfully'), severity: 'success'});
                setLoading(false);
            }).catch(error => {
                if (error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                }
                setLoading(false);
            })
        }
    }

    const updateEmail = (emailDetails) => {
        setLoading(true);
        ICMSAxiosInterceptor.put(`contact/${emailDetails.partyContactId}`, emailDetails).then(response => {
            getEmails();
            setMessage({show: true, message: t('Updated_Email_Successfully'), severity: 'success'});
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            }
            setLoading(false);
        })
    }

    const deleteEmail = (partyContactId) => {
        if (partyId) { 
            setLoading(true);
            ICMSAxiosInterceptor.delete(`contact/${partyContactId}/party/${partyId}`).then(response => {
                getEmails();
                setMessage({show: true, message: t('Deleted_Email_Successfully'), severity: 'success'});
                setLoading(false);
            }).catch(error => {
                if (error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                }
                setLoading(false);
            })
        } else {
            setLoading(true);
            ICMSAxiosInterceptor.delete(`contact/${partyContactId}`).then(response => {
                getEmails();
                setMessage({show: true, message: t('Deleted_Email_Successfully'), severity: 'success'});
                setLoading(false);
            }).catch(error => {
                if (error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                }
                setLoading(false);
            })
        }
    }

    useEffect(() => {
        getEmails();
    }, [])

    return (
        <Grid container direction="row" alignItems="baseline">
            <IcmsTableContainer>
                {canAdd && <Grid container sx={{padding: '0px 20px 0px 20px !important'}} spacing={2}>
                    <Grid item xs={12}>
                        <Tooltip title={t('Add_Email')}>
                            <IconButton data-testid="add-email-btn" color="primary" size="small" sx={{float: 'right'}}
                                onClick={addEmail}><AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>}
                <IcmsMuiTable stickyHeader aria-label="sticky table" data-testid="email-list-table">
                    <TableHead>
                        <IcmsTableRow>
                            {columns.map((column) => (
                                <StyledTableCell
                                    sx={{padding: '4px'}}
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {t(column.label)}
                                </StyledTableCell>
                            ))}
                        </IcmsTableRow>
                    </TableHead>
                    <TableBody>
                        {emails.length === 0 && <IcmsTableRow colSpan={2}>
                            <StyledTableCell sx={{padding: '8px'}}>
                                {t('No_Record_Found')}
                            </StyledTableCell>
                        </IcmsTableRow>}
                        {!loading && emails.map((email, index) => {
                            return <EmailRow key={email.contact} emailDetails={email} handleCreate={createEmail} 
                                    handleUpdate={updateEmail} handleDelete={deleteEmail}
                                    handleRefresh={getEmails} edit={email.isEdit} partyId={partyId}
                                    canModify={canModify} canDelete={canDelete}/>
                        })}
                    </TableBody>
                </IcmsMuiTable>
            </IcmsTableContainer>
            {loading && <ICMSLoader show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </Grid>
    )
}

export default EmailList