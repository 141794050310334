import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft } from "react-icons/ai";
import AddressTab from "./AddressTab";

const AddressContainer = (props) => {
    const { setHandleChangePage } = props;
    const { t } = useTranslation();

    const handleCancel = () => {
        setHandleChangePage('details')
    }

    return (
        <Fragment>
            <Grid container >
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Typography style={{ marginTop: '0.7rem', fontWeight: 'bold', fontSize: '1.5rem' }} data-testid='address'>{t("Address")}</Typography>
                </Grid>
                <Grid item xs={12} style={{margin: '0px 0px 1% 3%'}}>
                    {/* <Button startIcon={<AiOutlineArrowLeft />}  variant='outlined' onClick={handleCancel} data-testid='back-to-dashboard'>{t("Back_To_Dashboard")}</Button> */}
                </Grid>
            </Grid>
            <AddressTab loginUser={true} />
        </Fragment>
    )
}

export default AddressContainer