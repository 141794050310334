import { Fragment, useEffect, useState }            from "react"
import { Button, Card, ButtonGroup,
    Grid, Stack, Typography }                       from "@mui/material"
import { useFormik }                                from "formik"
import { FaHandshakeSimple }                        from "react-icons/fa6"
import { GiPayMoney }                               from "react-icons/gi"
import { useTranslation }                           from "react-i18next"
import moment                                       from "moment"
import {  
    FormikLabelTextField 
}                                                   from "../../../../../common/formik-fields-components/FormikFieldComponents"
import { FormikLabelNumericFormat }                 from "../../../../../common/formik-fields-components/FormikLabelNumericFormat"
import { createClaim, deleteClaim, updateClaim }    from "../../../file-claim-services/ClaimService"
import ICMSLoader2                                  from "../../../../../common/icms-loader/icms-loader-2.O/ICMSLoader2"
import IcmsSnackbar                                 from "../../../../../common/icms-snackbar/IcmsSnackbar"
import { ClaimValidationSchema }                    from "../../creditor-details/CreditorYupSchema"
import { FormikNewDateTime }                        from '../../../../../common/formik-fields-components/FormikNewDateTime';
import ClaimAmountsTableView                        from '../ClaimAmountsTableView';
import ClaimAdjudicationList                        from '../claim-adjudication/ClaimAdjudicationList';
import ClaimDistributionList                        from '../claim-distribution/ClaimDistributionList';
import ICMSConfirmDialog                            from '../../../../../common/ConfirmationDialog/ICMSConfirmDialog';
import { ICMSButton }                               from '../../../../../common/icms-styled-components/IcmsStyledComponents';    
import { Stage }                                    from "../../../../../common/GenericCodes"
import { StepperSectionHeader } from "../../../../../common/icms-styled-components/StepperSectionHeader"

const EmployeeWorkmanClaim = (props) => {
    const { claimCd, caseId, claimantId, claimDetail, listForClaim, closeCurrentPanel, index,
        claimSubmission, canEditClaim, canEditBook, canEditAdmitted, canViewBook, canEdit, claimMinDate, caseStage,
        relationShipCd } = props;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [selectedInfoType, setSelectedInfoType] = useState('due');
    const [message, setMessage] = useState({ show: false, message: '', severity: '' });
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('Yes'),
        disAgreeBtnLabel: t('No'),
        onDisAgree: ''
    })

    const cliamFormik = useFormik({
        initialValues: {
            debtDefaultId: claimDetail === null ? null : claimDetail.debtDefaultId,
            claimId: claimDetail === null ? null : claimDetail.claimId,
            claimTime: claimDetail === null ? null : claimDetail.claimTime,
            claimToTime: claimDetail === null ? null : claimDetail.claimToTime,
            bookAmount: claimDetail === null ? 0 : claimDetail.bookAmount,
            cprincipal: claimDetail === null ? 0 : claimDetail.cprincipal,
            cinterest: claimDetail === null ? 0 : claimDetail.cinterest,
            cother: claimDetail === null ? 0 : claimDetail.cother,
            cdescription: claimDetail === null ? null : claimDetail.cdescription,
            totalClaim: claimDetail === null ? 0 : claimDetail.totalClaim,
            principal: claimDetail === null ? 0 : claimDetail.principal,
            interest: claimDetail === null ? 0 : claimDetail.interest,
            other: claimDetail === null ? 0 : claimDetail.other,
            description: claimDetail === null ? null : claimDetail.description,
            defaultFrom: claimDetail === null ? null : claimDetail.defaultFrom,
            defaultTo: claimDetail === null ? null : claimDetail.defaultTo,
            totalBook: claimDetail === null ? 0 : claimDetail.totalBook,
            aprincipal: claimDetail === null ? 0 : claimDetail.aprincipal,
            ainterest: claimDetail === null ? 0 : claimDetail.ainterest,
            aother: claimDetail === null ? 0 : claimDetail.aother,
            adescription: claimDetail === null ? null : claimDetail.adescription,
            admitId: claimDetail === null ? null : claimDetail.admitId,
            admittedTime: claimDetail === null ? null : claimDetail.admittedTime,
            totalAdmit: claimDetail === null ? 0 : claimDetail.totalAdmit,
            claimCd: claimDetail === null ? claimCd : claimDetail.claimCd,
            natureOfClaim: claimDetail === null ? null : claimDetail.natureOfClaim,
        },
        validationSchema: ClaimValidationSchema,
        onSubmit: () => {
            if (claimDetail === null) {
                createEmpWorkClaim();
            } else {
                updateEmpWorkClaim();
            }
        }
    })

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        })
    }

    const createEmpWorkClaim = () => {
        if (validateClaimDetails()) {
            setLoading(true);
            const { totalClaim, totalBook, totalAdmit, ...payload } = cliamFormik.values;
            createClaim(caseId, claimantId, payload,
                (response) => {
                    if (response) {
                        listForClaim({ show: true, message: t('Claim_Created_Sucessfully'), severity: 'success' });
                        closeCurrentPanel(index);
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    setMessage({ show: true, message: error.message, severity: 'error' });
                })
        }
    }

    const updateEmpWorkClaim = () => {
        if (validateClaimDetails()) {
            setLoading(true);
            const { totalClaim, totalBook, totalAdmit, ...payload } = cliamFormik.values;
            updateClaim(caseId, claimantId, claimDetail.debtDefaultId, payload,
                (response) => {
                    if (response) {
                        closeCurrentPanel(index);
                        listForClaim({ show: true, message: t('Claim_Updated_Sucessfully'), severity: 'success' });
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    setMessage({ show: true, message: error.message, severity: 'error' });
                })
        }
    }

    const validateClaimDetails = () => {
        if (cliamFormik.values.claimTime && cliamFormik.values.totalClaim === 0) {
            setMessage({ show: true, message: t('Claim_Amount_Cannot_Be_Zero'), severity: 'error' });
            return false;
        }
        // if (!cliamFormik.values.admittedTime && cliamFormik.values.totalAdmit > 0) {
        //     setMessage({ show: true, message: t('Admitted_Due_Date_Is_Mandatory_With_Admitted_Amount'), severity: 'error' });
        //     return false;
        // }
        return true;
    }

    const deleteConfirmation = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: `${t('Are_You_Sure_That_You_Want_To_Delete_Claim') } `,
            onDisAgree: resetSeekConfirmation,
            onAgree: () => {
                deleteEmpWorkClaim();
                resetSeekConfirmation();
            },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    const deleteEmpWorkClaim = () => {
        setLoading(true);
        deleteClaim(caseId, claimantId, claimDetail.debtDefaultId,
            (response) => {
                if (response) {
                    closeCurrentPanel(index);
                    listForClaim({ show: true, message: t('Claim_Deleted_Sucessfully'), severity: 'success' });
                }
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            })
    }

    const handleChange = (event) => {
        cliamFormik.handleChange(event);
    }

    const onSelectedInfoChange = (type) => {
        if (cliamFormik.values.debtDefaultId || type == 'due') {
            setSelectedInfoType(type);
        } else {
            setMessage({show: true, message: `${t('Please_Add_Due_Details_Before_Entering')} 
                ${type} ${t('details')}`, severity: 'error'});
        }
    }

    useEffect(() => {
        cliamFormik.setFieldValue('totalClaim', cliamFormik.values.cprincipal + cliamFormik.values.cinterest + cliamFormik.values.cother);
    }, [cliamFormik.values.cprincipal, cliamFormik.values.cinterest, cliamFormik.values.cother])

    useEffect(() => {
        cliamFormik.setFieldValue('totalBook', cliamFormik.values.principal + cliamFormik.values.interest + cliamFormik.values.other);
    }, [cliamFormik.values.principal, cliamFormik.values.interest, cliamFormik.values.other])

    useEffect(() => {
        cliamFormik.setFieldValue('totalAdmit', cliamFormik.values.aprincipal + cliamFormik.values.ainterest + cliamFormik.values.aother);
    }, [cliamFormik.values.aprincipal, cliamFormik.values.ainterest, cliamFormik.values.aother])

    return (
        <Fragment>
            <Stack>
                {
                    <ButtonGroup variant="outlined" aria-label="claimant-details" size="small">
                        <ICMSButton color='primary' 
                            variant={selectedInfoType === 'due' ? "contained" : "outlined"}
                            onClick={() => onSelectedInfoChange('due')}>{t('Due_Details')}</ICMSButton>
                        <ICMSButton color='primary' 
                            variant={selectedInfoType === 'adjudication' ? "contained" : "outlined"}
                            onClick={() => onSelectedInfoChange('adjudication')}>{t('Adjudication')}</ICMSButton>
                        {!claimSubmission && <ICMSButton color='primary' 
                            variant={selectedInfoType === 'distribution' ? "contained" : "outlined"}
                            onClick={() => onSelectedInfoChange('distribution')}>{t('Distribution')}</ICMSButton>}
                    </ButtonGroup>
                }
                {selectedInfoType === 'due' && <Stack>
                    <Stack>
                        <Grid container spacing={2} sx={{mt: '4px'}}>
                            {/* <Grid item xs={2}>
                                <FormikNewDateTime
                                    id='defaultFrom'
                                    fieldName='defaultFrom'
                                    formik={cliamFormik}
                                    maxDate={(new Date())}
                                    label={t('Due_From')}
                                    required={true}
                                    size='small'
                                    handleChange={handleChange}
                                    onlyDate={true}
                                    disabled={!canEdit}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormikNewDateTime
                                    id='defaultTo'
                                    fieldName='defaultTo'
                                    formik={cliamFormik}
                                    maxDate={(new Date())}
                                    label={t('Due_To')}
                                    required={true}
                                    size='small'
                                    handleChange={handleChange}
                                    onlyDate={true}
                                    disabled={!canEdit}
                                />
                            </Grid> */}
                            <Grid item xs={3}>
                                <FormikLabelTextField
                                    id="natureOfClaim"
                                    fieldName="natureOfClaim"
                                    label={t("Nature_Of_Claim")}
                                    formik={cliamFormik}
                                    size="small"
                                    required={true}
                                    handleChange={handleChange}
                                    handleBlur={cliamFormik.handleBlur}
                                    disabled={!canEdit}
                                />
                            </Grid>
                        </Grid>
                    </Stack>
                    {!claimSubmission && <Stack>
                        <ClaimAmountsTableView cliamFormik={cliamFormik} canEditClaim={canEditClaim && canEdit}
                                canEditBook={canEditBook && canEdit} canEditAdmitted={canEditAdmitted && canEdit}
                                canViewBook={canViewBook} claimMinDate={claimMinDate} caseId={caseId} claimantId={claimantId}
                                relationShipCd={relationShipCd}/>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={6}>
                                <FormikLabelTextField
                                    id='cdescription'
                                    fieldName='cdescription'
                                    label={t('Claimant_Remarks')}
                                    formik={cliamFormik}
                                    required={false}
                                    handleChange={handleChange}
                                    disabled={!canEdit}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormikLabelTextField
                                    id='adescription'
                                    fieldName='adescription'
                                    label={t('IP_Remarks')}
                                    formik={cliamFormik}
                                    required={false}
                                    handleChange={handleChange}
                                    disabled={!canEdit || !canEditAdmitted || cliamFormik.values.totalAdmit == 0}
                                />
                            </Grid>
                        </Grid>
                    </Stack>}
                    {claimSubmission && <Fragment>
                        <StepperSectionHeader title={`${t('Claim_Amount_Details_As_On')} ${moment(new Date(claimMinDate)).format('DD/MM/YYYY')}`} />
                        <Grid container spacing={2} my={1}>
                            <Grid item xs={2}>
                                <FormikNewDateTime
                                    id='claimTime'
                                    fieldName='claimTime'
                                    formik={cliamFormik}
                                    maxDate={cliamFormik.values.claimToTime ? cliamFormik.values.claimToTime : claimMinDate}
                                    label={t('Default_From')}
                                    required={true}
                                    handleChange={handleChange}
                                    size='small'
                                    onlyDate={true}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormikNewDateTime
                                    id='claimToTime'
                                    fieldName='claimToTime'
                                    formik={cliamFormik}
                                    minDate={cliamFormik.values.claimTime}
                                    maxDate={claimMinDate}
                                    label={t('Default_To')}
                                    required={true}
                                    handleChange={handleChange}
                                    size='small'
                                    // disabled
                                    onlyDate={true}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormikLabelNumericFormat
                                    id="cprincipal"
                                    fieldName="cprincipal"
                                    label={t("Principal_Amount_Rs")}
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    required={true}
                                    handleBlur={cliamFormik.handleBlur}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormikLabelNumericFormat
                                    id="cinterest"
                                    fieldName="cinterest"
                                    label={t("Interest_Amount_Rs")}
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    required={true}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormikLabelNumericFormat
                                    id="cother"
                                    fieldName="cother"
                                    label={t("Other_Charges_Rs")}
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    required={true}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormikLabelNumericFormat
                                    id="totalClaim"
                                    fieldName="totalClaim"
                                    label={t("Total_Claim_Amount_Rs")}
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    required={true}
                                    disabled={true}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikLabelTextField
                                    id='cdescription'
                                    fieldName='cdescription'
                                    label={t('Remarks')}
                                    formik={cliamFormik}
                                    required={false}
                                    handleChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Fragment>}
                    <Stack direction="row" justifyContent='end' spacing={1} mt={3}>
                        {canEdit && <ICMSButton color='primary' variant="outlined" size="small" onClick={() => closeCurrentPanel(index)}>
                            {t('Cancel')}
                        </ICMSButton>}
                        {canEdit && <ICMSButton color='primary' variant="contained" size="small" onClick={cliamFormik.handleSubmit}
                                disabled={!cliamFormik.isValid}>
                            {claimDetail === null ? t('Save') : t('Update')}
                        </ICMSButton>}
                        {(claimDetail !== null && canEdit) && <ICMSButton color='error' variant="contained" size="small" onClick={deleteConfirmation}>
                            {t('Delete')}
                        </ICMSButton>}
                    </Stack>
                </Stack>}
                {selectedInfoType === 'adjudication' && <Stack>
                    <ClaimAdjudicationList caseId={caseId} claimantId={claimantId} canEdit={canEdit}
                            debtDefaultId={cliamFormik.values.debtDefaultId}/>
                </Stack>}
                {selectedInfoType === 'distribution' && <Stack>
                    <ClaimDistributionList caseId={caseId} claimantId={claimantId} canEdit={canEdit}
                            debtDefaultId={cliamFormik.values.debtDefaultId} claimId={cliamFormik.values.claimId}
                            refresh={listForClaim}/>
                </Stack>}
            </Stack>
            {loading && <ICMSLoader2 show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
            { seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}
            /> }
        </Fragment>
    )
}

export { EmployeeWorkmanClaim }
