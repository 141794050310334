import { Search } from "@mui/icons-material";
import { Chip, ClickAwayListener, Divider, Grow, IconButton, InputBase, MenuItem, Paper, Popper, Stack, Typography, alpha } from "@mui/material";
import { Fragment, useState } from "react";
import { BsPerson } from "react-icons/bs";
import { CreditorsType, FinancialCreditorTypes, IdentificationByPartyType } from "../../../common/StaticVariables";
import { IoClose } from "react-icons/io5";
import { ICMSAxiosInterceptor } from "../../../config/axios.interceptor";
import { useTranslation } from "react-i18next";
import IcmsSnackbar from "../../../common/icms-snackbar/IcmsSnackbar";

const FCSearch = (props) => {
    const {width, placeholder, isOnlyIndividual, onSelectSubmit, onClear, disabled, updateSearchSelected,
        partyTypes} = props;
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isClaimantType, setIsClaimantType] = useState(!isOnlyIndividual);
    const [isIdType, setIsIdType] = useState(isOnlyIndividual);
    const [claimantType, setClaimantType] = useState({
        id: isOnlyIndividual ? CreditorsType.INDIVIDUAL : '',
        description: '',
    });
    const [idType, setIdType] = useState({
        id: '',
        description: ''
    });
    const [searchText, setSearchText] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [searchByPartyTypes, setSearchByPartyTypes] = useState(partyTypes ? partyTypes : FinancialCreditorTypes);
    const [message, setMessage] = useState({
        show: false,
        message: null,
        severity: null
    })

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget.parentNode);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let open = Boolean(anchorEl);
    let id = open ? "simple-popover" : undefined;

    const onInputSearchText = (event) => {
        setSearchText(event.target.value);
        let payload = {
            searchBy: idType.id === 'byName' ? 'name' : 'identification',
            searchText: event.target.value,
            partyCd: claimantType.id,
            identificationCd: idType.id === 'byName' ? IdentificationByPartyType[claimantType.id][0].id : idType.id
        }
        ICMSAxiosInterceptor.post(`/search`, payload).then((response) => {
            setSearchResult(response)
        }).catch((error) => {
            setMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

    const handleFirst = (id, title) => {
        setClaimantType({
            id: id,
            description: title
        });
        if(claimantType.id !== id){
            setIdType({
                id: '',
                description: ''
            })
            setSearchText('')
        }
        setIsClaimantType(false);
        setIsIdType(true);
    }

    const handleClaimantType = (event) => {
        setAnchorEl(event.currentTarget.parentNode);
        setIsClaimantType(true);
        setIsIdType(false);
    }

    const onDeleteClaimantType = () => {
        setIsClaimantType(true);
        setIsIdType(false);
        setClaimantType({
            id: '',
            description: ''
        })
        setIdType({
            id: '',
            description: ''
        })
        setSearchText('')
    }

    const handleSecond = (id, title) => {
        setIdType({
            id: id,
            description: title
        });
        if(idType.id !== id){
            setSearchText('')
        }
        setIsIdType(false);
    }

    const handleIdType = (event) => {
        setAnchorEl(event.currentTarget.parentNode);
        setIsIdType(true);
        setIsClaimantType(false);
    }

    const onDeleteIdType = () => {
        setIsClaimantType(false);
        setIsIdType(true);
        setIdType({
            id: '',
            description: ''
        })
        setSearchText('');
    }

    const handleClear = () => {
        if (updateSearchSelected){
            updateSearchSelected(false);
        }
        setClaimantType({
            id: isOnlyIndividual ? CreditorsType.INDIVIDUAL : '',
            description: '',
        })
        setIdType({
            id: '',
            description: ''
        })
        setIsClaimantType(!isOnlyIndividual);
        setIsIdType(isOnlyIndividual);
        setSearchText('');
        if (onClear) onClear()
    }

    const onSelect = (partyId) => {
        ICMSAxiosInterceptor.get(`/search/party/${partyId}`).then((response) => {
            if(response) {
                if (onSelectSubmit) {
                    onSelectSubmit(response);
                    handleClose();
                    if (updateSearchSelected){
                        updateSearchSelected(true);
                    }
                }
            }
        }).catch((error) => {
            setMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
    }

    return (
        <Fragment>
            <ClickAwayListener onClickAway={() => { if (anchorEl !== null) handleClose();}}>
                <Stack width={anchorEl !== null && (searchText !== '' || claimantType.id !== '' || idType.id !== '') ? '60%' : width} sx={{ transition: 'width 0.5s ease-in-out'}}>
                    <Paper
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center',
                             boxShadow: '0 .5rem -1rem 0 rgba(44, 51, 73, .1)',
                             border: '1px solid whitesmoke' }}
                    >
                        {!isOnlyIndividual && claimantType.id !== '' && <Chip
                            sx={{
                                // bgcolor: (theme) => alpha(theme.palette.primary.main, 0.15),
                                // color: (theme) => theme.palette.primary.main,
                                marginRight: '0.2rem'
                            }}
                            color='secondary'
                            label={claimantType.description}
                            onClick={handleClaimantType}
                            onDelete={onDeleteClaimantType}
                            size="medium"
                        />}
                        {idType.id !== '' && <Chip
                            sx={{
                                //     bgcolor: (theme) => alpha(theme.palette.primary.main, 0.15),
                                //     color: (theme) => theme.palette.primary.main,
                                marginRight: '0.2rem'
                            }}
                            color='primary'
                            label={idType.description}
                            onClick={handleIdType}
                            onDelete={onDeleteIdType}
                        />}
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder={placeholder}
                            inputProps={{ 'aria-label': 'search claimant' }}
                            fullWidth
                            onClick={handleClick}
                            value={searchText}
                            onChange={onInputSearchText}
                            disabled={disabled}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="clear" onClick={handleClear}
                                disabled={disabled}>
                            <IoClose style={{color: 'red'}} />
                        </IconButton>
                        <Divider orientation="vertical" flexItem sx={{mx: 0.5, my: 0.5}}/>
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <Search />
                        </IconButton>
                    </Paper>
                    <Popper
                        id={id}
                        sx={{ width: width, zIndex: (theme) => theme.zIndex.tooltip }}
                        open={open}
                        anchorEl={anchorEl}
                        placement='bottom-start' transition>
                        {({ TransitionProps }) => (
                            <Grow {...TransitionProps} timeout={350}>
                            <Paper sx={{ width: '100%' }}>
                                {!isOnlyIndividual && isClaimantType && <Fragment>
                                    <Typography color="primary" sx={{
                                        px: 2, py: 1, bgcolor: (theme) => alpha(theme.palette.primary.main, 0.05)
                                    }}>
                                        Select your creditor Type to search...
                                    </Typography>
                                    {searchByPartyTypes.map((menu, index) => {
                                        return (
                                            <MenuItem key={`menu-${index}`} value={menu.id} onClick={() => handleFirst(menu.id, menu.title)}>{menu.title}</MenuItem>
                                        )
                                    })}
                                </Fragment>}
                                {isIdType && <Fragment>
                                    <Typography color="primary" sx={{
                                        px: 2, py: 1, bgcolor: (theme) => alpha(theme.palette.primary.main, 0.05)
                                    }}>
                                        Select search by...
                                    </Typography>
                                    <MenuItem key={`menuitem-name`} value='name' onClick={() => handleSecond('byName', 'Name')}>{t('Name')}</MenuItem>
                                    {IdentificationByPartyType[claimantType.id].map((item, index) => {
                                        return (
                                            <MenuItem key={`menuitem-${index}`} value={item.id} onClick={() => handleSecond(item.id, item.title)}>{item.title}</MenuItem>
                                        )
                                    })}
                                </Fragment>}
                                {!isClaimantType && !isIdType && searchText === '' &&
                                    <Typography sx={{p:2}}>Start Typing the {idType.description}</Typography>
                                }
                                {!isClaimantType && !isIdType && searchText !== '' &&
                                    <Fragment>
                                        <Typography color="primary" sx={{
                                            px: 2, py: 1, bgcolor: (theme) => alpha(theme.palette.primary.main, 0.05)
                                        }}>
                                            Select the creditor to autofill your details
                                        </Typography>
                                        <Stack sx={{maxHeight: '350px !important', overflow: 'auto'}}>
                                            {searchResult.map((item, index) => {
                                                return (
                                                    <MenuItem key={`menuitem-${index}`} value={item.partyId} sx={{ borderBottom: '1px solid #eeeeee' }}
                                                        onClick={() => onSelect(item.partyId)}>
                                                        <Stack direction="row" width="100%" spacing={2} alignItems='center'>
                                                            <IconButton sx={{ height: 'fit-content', bgcolor: (theme) => alpha(theme.palette.secondary.main, 0.08), color: (theme) => theme.palette.secondary.main }}>
                                                                <BsPerson />
                                                            </IconButton>
                                                            <Stack spacing={0.5}>
                                                                <Typography color="textPrimary">{item.name}</Typography>
                                                                <Typography color="textSecondary">{item.identification}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </MenuItem>
                                                )
                                            })}
                                        </Stack>
                                    </Fragment>
                                }
                            </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Stack>
            </ClickAwayListener>
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </Fragment>
    );
}

export default FCSearch;