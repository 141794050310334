const AdminToolsSideNav = {
    subscriptionDetail: { key: 'subscriptionDetail', title: 'Subscription', pageHeader: 'Subscription' },
    userRoleManagement: { key: 'userRoleManagement', title: 'User_Role', pageHeader: 'Role_Management' },
    icmsModulesList: { key: 'icmsModulesList', title: 'Modules', pageHeader: 'ICMS_Modules' },
    caseTransfer: { key: 'caseTransfer', title: 'Case_Transfer', pageHeader: 'Case_Transfer' },
    spaceManagement: { key: 'spaceManagement', title: 'Storage', pageHeader: 'Storage_Management' },
    icmsUserModulesList: { key: 'icmsUserModulesList', title: 'User_Modules', pageHeader: 'ICMS_Modules' },
}

const AdministrationSideNav = {
    subscribersDetail: { key: 'subscribersDetail', title: 'Subcriber', pageHeader: 'Subscribers' },
    usersDetail: { key: 'usersDetail', title: 'User_Details', pageHeader: 'Users_Details' }
}

const PublicInformationSideNav = {
    IP: { key: 'IP', title: 'IP', pageHeader: 'IP' },
    RV: { key: 'RV', title: 'RV', pageHeader: 'RV' },
    RVE: { key: 'RVE', title: 'RVE', pageHeader: 'RVE' },
    RVO: { key: 'RVO', title: 'RVO', pageHeader: 'RVO' },
    Bank: { key: 'Bank', title: 'Bank', pageHeader: 'Bank' },
}

const RoutePathValues = {
    caseManagement: '/case-management',
    adminTools: '/admin-tools',
    publicInformation: '/public-information',
    administration: '/administration',
    fileClaim: '/file-claim',
    submitEoI: '/submit-eoi'
}

const ICMSModulesValues = {
    claimModule: { id: 'claimModule', value: 'Claim Module', moduleCd: 1 },
    meetingModule: { id: 'meetingModule', value: 'Meeting Module', moduleCd: 2 },
    resolutionPlan: { id: 'resolutionPlan', value: 'Resolution Plan (VDR)', moduleCd: 3 },
    assetAndValuation: { id: 'assetAndValuation', value: 'Asset & Valuation', moduleCd: 4 },
    costModule: { id: 'costModule', value: 'Cost Module', moduleCd: 5 },
    disbursementModule: { id: 'disbursementModule', value: 'Disbursement Module', moduleCd: 6 },
}

const FileIcons = {
    pdf: "/images/file-icon/pdf.png",
    doc: "/images/file-icon/word.png",
    docx: "/images/file-icon/word.png",
    xls: "/images/file-icon/excel.png",
    xlsx: "/images/file-icon/excel.png",
    ppt: "/images/file-icon/ppt.png",
    pptx: "/images/file-icon/ppt.png",
    txt: "/images/file-icon/txt.png",
    jpg: "/images/file-icon/jpeg.png",
    jpeg: "/images/file-icon/jpeg.png",
    png: "/images/file-icon/png.png",
    gif: "/images/file-icon/gif.png",
    zip: "/images/file-icon/zip.png",
    default: "/images/file-icon/file.png"
}

export {
    RoutePathValues, AdminToolsSideNav, ICMSModulesValues, FileIcons, PublicInformationSideNav, AdministrationSideNav
}