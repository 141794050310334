import { Stack, StepConnector, StepLabel, stepConnectorClasses, stepLabelClasses } from "@mui/material";
import { styled } from "@mui/system";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#5cb85c",
        },
        [`& .${stepLabelClasses.label}`]: {
            color: "red",
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#5cb85c"
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 10,
        border: 0,
        backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaf0f4",
        borderRadius: 1,
        // "&:before": {
        //     border: "2px solid black"
        // }
    }
}));

const ColorStepLabel = styled(StepLabel)(({ theme, isFirst, isLast, isLastActive }) => ({
    // '&::before': isFirst ?{
    //     content: '""',
    //     background: '#5cb85c',
    //     left: '-26px',
    //     padding: '5px 67px',
    //     position: 'absolute',
    //     top: 22
    // }: {},
    // '&::after': isLast ? {
    //     content: '""',
    //     background: isLastActive ? '#5cb85c' : '#eaf0f4',
    //     right: '-26px',
    //     padding: '5px 67px',
    //     position: 'absolute',
    //     top: 22
    // } : {},
    [`& .${stepLabelClasses.active}`]: {
        color: `${theme.claimStepperColor} !important`,
        fontWeight: 'bold !important'
    },
    [`& .${stepLabelClasses.completed}`]: {
        color: `${theme.claimStepperColor} !important`,
        fontWeight: 'bold !important'
    },
    [`& .${stepLabelClasses.label}`]: {
        fontWeight: 'bold !important',
        color: 'rgb(153, 162, 168)'
    },
}))

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaf0f4",
    zIndex: 1,
    color: "#99a2a8",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
        backgroundColor: "#fff",
        border: "2px solid #5cb85c",
        color: "#5cb85c",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
    }),
    ...(ownerState.completed && {
        backgroundColor: "#5cb85c",
        color: "#fff",
    })
}));

const ColorStepper = styled(Stack)(({theme}) => ({
     background: "#f6f9fb",
     height:'100%',
}))

export { ColorStepLabel, ColorStepper, ColorlibConnector, ColorlibStepIconRoot };
