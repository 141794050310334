import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Divider, alpha } from '@mui/material';

export default function FormikTwoRadioGroup(props) {
    const { id, fieldName, label, radioOneLabel, radioTwoLabel, formik, handleChange, required, disabled } = props;

    return (
        <FormControl>
            {label && <FormLabel id={`${id}-label`}>{label}</FormLabel>}
            <RadioGroup
                row
                aria-labelledby={`${id}-label`}
                name={fieldName}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[fieldName]}
                disabled={disabled}
                required={required}
            >
                <FormControlLabel value={true} control={<Radio />} label={radioOneLabel} disabled={disabled}
                    sx={{ margin: 0, padding: '8px 10px 8px 8px', '&:hover': { bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04) }}}/>
                <Divider flexItem orientation='vertical' />
                <FormControlLabel value={false} control={<Radio />} label={radioTwoLabel} disabled={disabled}
                    sx={{ margin: 0, padding: '8px 10px 8px 8px', '&:hover': { bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04) }}} />
            </RadioGroup>
        </FormControl>
    );
}