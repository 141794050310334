import { Typography, Box, Tab }       from "@mui/material";
import { styled }                     from '@mui/material/styles';

const StyledTab = styled(Tab)(({ color }) => ({
    "&.Mui-selected": {
        color: '#0d47a1',
        // color: color,
        fontWeight: 'bold'
    },
    textTransform: 'capitalize'
}));

const TabPanel = (props) => {
    const { children, value, index, borderColor, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            style={{
                width: '100%',
                mimHeight: '300px',
                overflow: 'scroll',
                border: `1px solid lightgrey`,
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                borderBottomLeftRadius: '6px',
                borderBottomRightRadius: '6px'
            }}
            // style={{
            //     width: '100%',
            //     mimHeight: '300px',
            //     overflow: 'scroll',
            //     border: `2px solid ${borderColor}`
            // }}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export { StyledTab, TabPanel}