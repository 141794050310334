import React, { useContext, 
    useState }                      from 'react';
import {
    Dialog, DialogTitle,
    DialogContent, DialogActions
}                                   from '@mui/material';
import { useTranslation }           from 'react-i18next';
import { useFormik }                from "formik";
import { AddressTypes, CaseRoles, 
    IdentificationType, 
    Roles}            from '../../../../common/GenericCodes';
import { AuthRepValidationSchema, 
    IPCreateAuthRepValidationSchema }  from '../creditor-details/CreditorYupSchema';
import AuthRep                      from './AuthRep';
import { ICMSAxiosInterceptor }     from '../../../../config/axios.interceptor';
import ICMSLoader                   from '../../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                 from '../../../../common/icms-snackbar/IcmsSnackbar';
import { ICMSButton }               from '../../../../common/icms-styled-components/IcmsStyledComponents';
import { CaseContext }              from '../../../../common/context/case/CaseContext';
import { UserContext } from '../../../../common/context/UserContext';

const CreateAuthRep = ({onClose, show, caseId, claimantId, onSuccess, caseStage, isIndividual, canSendCredentials}) => {
    const { contextValue } = useContext(CaseContext);
    const userDetails = useContext(UserContext);
    const isEnkAdmin = userDetails?.loginDetails?.subscriberRole?.includes(Roles.enkAdmin.roleValue);
    const isEnkSupport = userDetails?.loginDetails?.subscriberRole?.includes(Roles.enkSupport.roleValue);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''})

    const authRepFormik = useFormik({
        initialValues: {
            identificationCd: IdentificationType.PAN,
            name: '',
            identification: '',
            email: '',
            partyCd: 1,
            phoneNumber: '',
            extension: '+91',
            relationshipName: '',
            isSameAsCreditor: false,
            addressCd: AddressTypes.registerdWithIBBI.addressCd,
            addressLine1: '',
            addressLine2: '',
            cityOrTown: '',
            state: '',
            postalCode: '',
            country: 'India',
            partyId: null,
            allowlogin: '',
            createAccountAndSendCredentials: '',
            loginUid: ''
        },
        validationSchema: (contextValue?.roles?.includes(CaseRoles.ROLE_CASE_MANAGER) || contextValue?.roles?.includes(CaseRoles.ROLE_CASE_WORKER)
                || isEnkAdmin || isEnkSupport)
            ? IPCreateAuthRepValidationSchema : AuthRepValidationSchema,
        validateOnBlur: true,
        validateOnMount: true,
        onSubmit: (values) => {
        }
    })


    const handleSave = () => {
        let payload = {
            identificationCd: authRepFormik.values.identificationCd,
            name: authRepFormik.values.name,
            identification: authRepFormik.values.identification,
            email: authRepFormik.values.email,
            partyCd: authRepFormik.values.partyCd,
            phoneNumber: authRepFormik.values.extension + '' + authRepFormik.values.phoneNumber,
            relationshipName: authRepFormik.values.relationshipName,
            // isSameAsCreditor: false,
            addressCd: authRepFormik.values.addressCd,
            addressLine1: authRepFormik.values.addressLine1,
            addressLine2: authRepFormik.values.addressLine2,
            cityOrTown: authRepFormik.values.cityOrTown,
            state: authRepFormik.values.state,
            postalCode: authRepFormik.values.postalCode,
            country: authRepFormik.values.country,
            partyId: authRepFormik.values.partyId,
            allowlogin: authRepFormik.values.allowlogin,
            createAccountAndSendCredentials: authRepFormik.values.createAccountAndSendCredentials,
            loginUid: authRepFormik.values.loginUid
        };
        setLoading(true);
        ICMSAxiosInterceptor.post(`case/${caseId}/claimant/${claimantId}/authorized-representative`, payload).then(response => {
            onSuccess({show: true, message: t('Authorised_Person_Created_Successfully'), severity: 'success'});
            setLoading(false);
            authRepFormik.resetForm();
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    return (
        <div>
            <Dialog open={show} fullWidth maxWidth="lg">
                <DialogTitle>{t('Auth_Person_Details')}</DialogTitle>
                <DialogContent dividers>
                    <AuthRep authRepFormik={authRepFormik} claimantId={claimantId} caseId={caseId}
                            isCreditorIndividual={isIndividual} caseStage={caseStage}
                            canSendCredentials={canSendCredentials} showSelf={true}/>
                </DialogContent>
                <DialogActions>
                    <ICMSButton onClick={onClose} color="primary" size="small" variant='outlined'>
                        {t('Cancel')}
                    </ICMSButton>
                    <ICMSButton onClick={handleSave} color="primary" size="small" variant='contained'
                            disabled={!authRepFormik.isValid}>
                        {t('Save')}
                    </ICMSButton>
                </DialogActions>
                {loading && <ICMSLoader show={loading}/>}
                {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
            </Dialog>
        </div>
    )
}

export default CreateAuthRep