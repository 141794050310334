import { Visibility, VisibilityOff } from "@mui/icons-material"
import { Card, FormLabel, Checkbox, FormControlLabel, IconButton, InputAdornment, Stack, TextField, alpha, Typography, ToggleButtonGroup, ToggleButton, Tooltip } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import moment from "moment"
import MuiPhoneNumber from "mui-phone-number"
import { Fragment, useState } from "react"
import { BiSolidMessageError } from "react-icons/bi"
import { FaCheck } from "react-icons/fa6";
import { getIn } from "formik";
import TermsAndConditions from "../../authentication/TermsAndCondition"
import { isValidPhoneNumber } from "react-phone-number-input"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { BsPatchCheckFill } from "react-icons/bs"
import { PartyType, RegEx } from "../GenericCodes"
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import InfoIcon from '@mui/icons-material/Info';
import AutorenewIcon from "@mui/icons-material/Autorenew";

const FormikTextField = (props) => {
    const { id, fieldName, label, formik, handleChange, disabled, required, size, value, textTransform, customStyle, validationIcon } = { ...props, size: props.size || "medium" };

    return (
        <TextField
            fullWidth
            size={size}
            id={id}
            name={fieldName}
            label={label}
            value={value || formik.values[fieldName]}
            onChange={handleChange}
            disabled={disabled}
            autoComplete='new-password'
            onBlur={formik.handleBlur}
            error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
            helperText={formik.touched[fieldName] && formik.errors[fieldName]}
            FormHelperTextProps={{ 'data-testid': `${id}-error` }}
            InputLabelProps={{ style: { fontSize: '0.9rem' } }}
            inputProps={{
                "data-testid": id,
                style: { textTransform: textTransform }
            }}
            style={customStyle}
            required={required}
            InputProps={{
                endAdornment: (
                    <Fragment>
                        <InputAdornment position='end'>
                            {formik.touched[fieldName] && Boolean(formik.errors[fieldName]) && <BiSolidMessageError style={{ color: '#d32f2f', fontSize: '1.5rem' }} />}
                            {validationIcon && formik.touched[fieldName] && !formik.errors[fieldName] && formik.values[fieldName] && <FaCheck style={{ color: '#4caf50', fontSize: '1.3rem' }} />}
                        </InputAdornment>
                    </Fragment>
                ),
            }}
        />
    )
}

const FormikPasswordField = (props) => {
    const { id, fieldName, pwdFocus, label,size, formik, handleChange, disabled, handleBlur, handleFocus, validationIcon } = { ...props, disabled: props.disabled || false };
    const [passwordVisibility, setPasswordVisibility] = useState(false);

    const toggleChange = () => {
        setPasswordVisibility(!passwordVisibility);
    }

    return (
        <Fragment>
            <TextField
                fullWidth
                id={id}
                name={fieldName}
                label={label}
                size={size}
                type={passwordVisibility ? 'text' : 'password'}
                value={formik.values[fieldName]}
                onChange={handleChange}
                disabled={disabled}
                autoComplete='new-password'
                onBlur={handleBlur}
                onFocus={handleFocus}
                InputLabelProps={{ style: { fontSize: '0.9rem' } }} // Adjust the font size here
                error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                helperText={(formik.values[fieldName].length >= 1 && pwdFocus) ? '' : formik.touched[fieldName] && formik.errors[fieldName]}
                FormHelperTextProps={{ 'data-testid': `${id}-error` }}
                inputProps={{
                    "data-testid": id,
                }}
                InputProps={{
                    endAdornment: (
                        <Fragment>
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={toggleChange}
                                    onMouseDown={toggleChange}>
                                    {passwordVisibility && <Visibility />}
                                    {!passwordVisibility && <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            <InputAdornment position='end'>
                                {formik.touched[fieldName] && Boolean(formik.errors[fieldName]) && <BiSolidMessageError style={{ color: '#d32f2f', fontSize: '1.5rem' }} />}
                                {validationIcon && formik.touched[fieldName] && !formik.errors[fieldName] && <FaCheck style={{ color: '#4caf50', fontSize: '1.3rem' }} />}
                            </InputAdornment>
                        </Fragment>
                    ),
                }}
            />
            {(formik.values[fieldName].length >= 1 && pwdFocus) && <div>
                <Card id='passwordCard' style={{ position: "absolute", left: '40%', padding: '20px', zIndex: 4 }} >
                    Password must contain:<br></br><br></br>
                    <div id='password' style={{ textAlign: 'left' }}>
                        <div id='pwdlength' style={formik.values[fieldName].length >= 8 ? { color: 'green' } : { color: 'red' }}>{formik.values[fieldName].length >= 8 ? <BsPatchCheckFill style={{ fontSize: 'small' }} /> : <AiOutlineCloseCircle id='isuppericon' style={{ fontSize: 'small' }} />}  atleast  8 characters </div>
                        <div id='isupper' style={formik.values[fieldName].match(/[A-Z]/) ? { color: 'green' } : { color: 'red' }}>{formik.values[fieldName].match(/[A-Z]/) ? <BsPatchCheckFill style={{ fontSize: 'small' }} /> : <AiOutlineCloseCircle id='pwdlengtgicon' style={{ fontSize: 'small' }} />}  atleast  one Uppercase letter </div>
                        <div id='islower' style={formik.values[fieldName].match(/[a-z]/) ? { color: 'green' } : { color: 'red' }}>{formik.values[fieldName].match(/[a-z]/) ? <BsPatchCheckFill style={{ fontSize: 'small' }} /> : <AiOutlineCloseCircle id='islowericon' style={{ fontSize: 'small' }} />}  atleast  one Lowercase letter </div>
                        <div id='idnumber' style={formik.values[fieldName].match(/\d+/g) ? { color: 'green' } : { color: 'red' }}>{formik.values[fieldName].match(/\d+/g) ? <BsPatchCheckFill style={{ fontSize: 'small' }} /> : <AiOutlineCloseCircle id='isnumbericon' style={{ fontSize: 'small' }} />}  atleast  one number  </div>
                        <div id='isSpecial' style={RegEx.SPECIAL_CHAR.test(formik.values[fieldName]) ? { color: 'green' } : { color: 'red' }}>{RegEx.SPECIAL_CHAR.test(formik.values[fieldName]) ? <BsPatchCheckFill style={{ fontSize: 'small' }} /> : <AiOutlineCloseCircle id='isSpecialicon' style={{ fontSize: 'small' }} />}  atleast one special character </div><br />
                        <div><span style={{ color: 'red' }}>Note:</span> Make sure your Password is Strong.<br />
                            <Typography style={{ marginLeft: "42px" }}>Using Names, Date of Births, Spouse Name, Pet Name, Locations etc.<br /> is not advisable</Typography>
                        </div>
                    </div><br></br>
                </Card>
            </div>}
        </Fragment>
    )
}

const FormikLabelPasswordField = (props) => {
    const { id, fieldName, pwdFocus, label, formik, handleChange, disabled, handleBlur, handleFocus,size } = { ...props, disabled: props.disabled || false };
    const [passwordVisibility, setPasswordVisibility] = useState(false);

    const toggleChange = () => {
        setPasswordVisibility(!passwordVisibility);
    }

    return (
        <Fragment>
            <FormLabel htmlFor={id} sx={{ fontSize: (theme) => theme.typography.body2 }} >{label}</FormLabel>
            <TextField
                fullWidth
                id={id}
                name={fieldName}
                size={size}
                type={passwordVisibility ? 'text' : 'password'}
                value={formik.values[fieldName]}
                onChange={handleChange}
                disabled={disabled}
                autoComplete='new-password'
                onBlur={handleBlur}
                onFocus={handleFocus}
                // error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                // helperText={(formik.values[fieldName].length >= 1 && pwdFocus) ? '' : formik.touched[fieldName] && formik.errors[fieldName]}
                FormHelperTextProps={{ 'data-testid': `${id}-error` }}
                inputProps={{
                    "data-testid": id,
                }}
                InputProps={{
                    endAdornment: (
                        <Fragment>
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={toggleChange}
                                    onMouseDown={toggleChange}>
                                    {passwordVisibility && <Visibility />}
                                    {!passwordVisibility && <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            <InputAdornment position='end'>
                                {formik.touched[fieldName] && Boolean(formik.errors[fieldName]) && <BiSolidMessageError style={{ color: '#d32f2f', fontSize: '1.5rem' }} />}
                                {/* {formik.touched[fieldName] && !formik.errors[fieldName] && <FaCheck style={{ color: '#4caf50', fontSize: '1.3rem' }} />} */}
                            </InputAdornment>
                        </Fragment>
                    ),
                }}
            />
        </Fragment>
    )
}

export const validateMobileNumber = (mobileNumber, formik, fieldName) => {
    if (mobileNumber !== undefined && mobileNumber !== '' && !isValidPhoneNumber(mobileNumber)) {
        return formik.setFieldError(fieldName, "Invalid Mobile Number");
    } else {
        return formik.setFieldError(fieldName, undefined);
    }
}

const FormikMuiPhoneNumber = (props) => {
    const { id, fieldName, label, formik, countryCodeName, disabled, validationIcon } = props;

    const onCountryCodeChange = (value) => {
        formik.setFieldTouched(fieldName, true);
        formik.setFieldValue(countryCodeName, value)
    }

    const onInputMobileNoChange = (event) => {
        formik.setFieldTouched(fieldName, true);
        let value = event.target.value;
        if (/^\d+$/.test(value)) {
            formik.setFieldValue(fieldName, value)
            // validateMobileNumber(formik.values[countryCodeName] + value, formik, fieldName)
        } else if (value.length === 0) {
            formik.setFieldValue(fieldName, '')
        }
    }

    return (
        <TextField
            id={id}
            name={fieldName}
            label={label}
            value={formik.values[fieldName]}
            error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
            helperText={formik.touched[fieldName] && formik.errors[fieldName]}
            onChange={onInputMobileNoChange}
            disabled={disabled}
            autoComplete='new-password'
            fullWidth
            // onBlur={onMobileBlur}
            FormHelperTextProps={{ 'data-testid': `${id}-error` }}
            inputProps={{
                "data-testid": id,
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment
                        position="start"
                        style={{ width: `${44 + formik.values.extension.length * 14}px` }}
                    >
                        <MuiPhoneNumber
                            id="extension"
                            name="extension"
                            defaultCountry={"in"}
                            // disableCountryCode
                            value={formik.values[countryCodeName]}
                            error={formik.touched[countryCodeName] && Boolean(formik.errors[countryCodeName])}
                            helperText={formik.touched[countryCodeName] && formik.errors[countryCodeName]}
                            onChange={(event) => onCountryCodeChange(event)}
                            autoFormat={false}
                            // onBlur={onMobileBlur}
                            style={{ top: "1px", color: "black !important" }}
                            required
                            disabled
                            disableDropdown={disabled}
                            countryCodeEditable={false}
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                    </InputAdornment>
                ),
                endAdornment: (
                    <Fragment>
                        <InputAdornment position='end'>
                            {formik.touched[fieldName] && Boolean(formik.errors[fieldName]) && <BiSolidMessageError style={{ color: '#d32f2f', fontSize: '1.5rem' }} />}
                            {validationIcon && formik.touched[fieldName] && !formik.errors[fieldName] && <FaCheck style={{ color: '#4caf50', fontSize: '1.3rem' }} />}
                        </InputAdornment>
                    </Fragment>
                )
            }}
        />
    )
}

const FormikLabelTextField = (props) => {
    const { id, fieldName, label, formik, handleChange, disabled, size, required, handleBlur, type, placeholder,infoIcon,handleFocus,
        customStyle, tooltip} = { ...props, size: props.size || "medium" };

    const handleKeyDown = (event) => {
        if (event.key === ' ' && (formik.values[fieldName] === '' || formik.values[fieldName].endsWith(' '))) {
            event.preventDefault();
        }
    };

    return (
        <Stack spacing={0.01}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {label && <FormLabel htmlFor={id} sx={{ fontSize: (theme) => theme.typography.body2 }} required={required}>
                    {label}
                </FormLabel>}
                {infoIcon && <Tooltip title='Further details including last available financial statements (with schedules) of two years, lists of creditors are available at URL:' arrow>
                    <IconButton aria-label="info" size="small" sx={{p:'0px 0px 0px 5px'}}>
                        <InfoIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>}
            </div>
            {/* <FormLabel htmlFor={id} sx={{ fontSize: (theme) => theme.typography.body2 }} required={required}>{label}</FormLabel> */}
            <Tooltip title={tooltip} arrow>
                <TextField
                fullWidth
                margin="normal"
                id={id}
                size={size}
                // label={label}
                placeholder={placeholder ? placeholder : ''}
                name={fieldName}
                value={getIn(formik.values, fieldName)}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                type={type ? type : 'text'}
                error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                helperText={formik.touched[fieldName] && formik.errors[fieldName]}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            {formik.touched[fieldName] && Boolean(formik.errors[fieldName]) && <BiSolidMessageError style={{ color: '#d32f2f', fontSize: '1.5rem' }} />}
                            {/* {validationIcon && formik.touched[fieldName] && !formik.errors[fieldName]  && (formik.values[fieldName] !== '' && formik.values[fieldName] !== undefined) && <FaCheck style={{ color: '#4caf50', fontSize: '1.3rem' }} />} */}
                        </InputAdornment>
                    ),
                    sx: {
                        background: (theme) => disabled ? theme.palette.grey[100] : theme.palette.background.paper,
                    }
                }}
                FormHelperTextProps={{ 'data-testid': `${id}-error` }}
                inputProps={{
                    "data-testid": id,
                    style: {
                        ...customStyle
                    }
                }}
            />
            </Tooltip>
        </Stack>
    )
}

const FormikLabelMuiPhone = (props) => {
    const { id, fieldName, label, formik, countryCodeName, disabled, required, handleBlur, size, handleFocus } = props;
    const onCountryCodeChange = (value) => {
        formik.setFieldTouched(fieldName, true);
        formik.setFieldValue(countryCodeName, value)
    }

    const onInputMobileNoChange = (event) => {
        formik.setFieldTouched(fieldName, true);
        let value = event.target.value;
        if (/^\d+$/.test(value)) {
            formik.setFieldValue(fieldName, value)
            // validateMobileNumber(formik.values[countryCodeName] + value, formik, fieldName)
        } else if (value.length === 0) {
            formik.setFieldValue(fieldName, '')
        }
    }

    return (
        <Stack spacing={0.01}>
            <FormLabel htmlFor={id} sx={{ fontSize: (theme) => theme.typography.body2 }} required={required}>{label}</FormLabel>
            <TextField
                id={id}
                name={fieldName}
                value={formik.values[fieldName]}
                error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                helperText={formik.touched[fieldName] && formik.errors[fieldName]}
                onChange={onInputMobileNoChange}
                onBlur={handleBlur}
                disabled={disabled}
                onFocus={handleFocus}
                size={size}
                // onBlur={onMobileBlur}
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment
                            position="start"
                            style={{ width: `${45 + formik.values.extension.length * 19}px` }}
                        >
                            <MuiPhoneNumber
                                id="extension"
                                name="extension"
                                defaultCountry={"in"}
                                // disableCountryCode
                                value={formik.values[countryCodeName]}
                                error={formik.touched[countryCodeName] && Boolean(formik.errors[countryCodeName])}
                                helperText={formik.touched[countryCodeName] && formik.errors[countryCodeName]}
                                onChange={(event) => onCountryCodeChange(event)}
                                autoFormat={false}
                                // onBlur={onMobileBlur}
                                style={{ top: "1px", color: "black !important", pointerEvents: disabled && 'none' }}
                                required
                                disabled
                                // disableDropdown={disabled}
                                countryCodeEditable={false}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <Fragment>
                            <InputAdornment position='end'>
                                {formik.touched[fieldName] && Boolean(formik.errors[fieldName]) && <BiSolidMessageError style={{ color: '#d32f2f', fontSize: '1.5rem' }} />}
                                {/* {formik.touched[fieldName] && !formik.errors[fieldName] && <FaCheck style={{ color: '#4caf50', fontSize: '1.3rem' }} />} */}
                            </InputAdornment>
                        </Fragment>
                    ),
                    sx: {
                        background: disabled ? '#f0f0f0' : (theme) => theme.palette.background.paper,
                    }
                }}
                inputProps={{
                    "data-testid": id,
                }}
            />
        </Stack>
    )
}


const FormikLabelDatePicker = ({ id, fieldName, formik, minDate, maxDate, label, required, handleBlur, disabled, onlyDate, size }) => {

    const handleDateChange = (date) => {
        if (minDate && date.isBefore(minDate)) {
            date = moment(minDate);
        } else if (maxDate && date.isAfter(maxDate)) {
            date = moment(maxDate);
        }
        if (onlyDate) {
            if (fieldName === 'observedOn') {
                formik.setFieldValue('rectifiedOn', null);
            }
            formik.setFieldValue(fieldName, date.format("YYYY-MM-DD"));
        } else {
            formik.setFieldValue(fieldName, date);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={0.01}>
                <FormLabel htmlFor={id} sx={{ fontSize: (theme) => theme.typography.body2 }} required={required}>{label}</FormLabel>
                <DatePicker
                    id={id}
                    renderInput={(props) =>
                        <TextField margin="normal" fullWidth {...props} required={required} onBlur={handleBlur} size={size}
                            onFocus={() => formik.setFieldTouched(fieldName, true)}
                            helperText={formik.touched[fieldName] && formik.errors[fieldName]}
                            error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])} />}
                    onChange={handleDateChange}
                    value={formik.values[fieldName]}
                    inputFormat="DD/MM/YYYY"
                    minDate={minDate}
                    inputProps={{ readOnly: true, "data-testid": id }}
                    disabled={disabled}
                    maxDate={maxDate}
                // InputProps={{
                //     style: {
                //         height: '45px',
                //         padding: '0px 15px 0px 0px'
                //     },
                // }}
                />
            </Stack>
        </LocalizationProvider>
    );
};

const FormikPasswordConfirmField = (props) => {
    const { id, fieldName, label, formik, handleChange, validationIcon } = props;
    const [passwordVisibility, setPasswordVisibility] = useState(false);

    const toggleChange = () => {
        setPasswordVisibility(!passwordVisibility);
    }

    return (
        <TextField
            fullWidth
            id={id}
            name={fieldName}
            label={label}
            type={passwordVisibility ? 'text' : 'password'}
            value={formik.values[fieldName]}
            autoComplete='new-password'
            onChange={handleChange}
            error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
            helperText={formik.touched[fieldName] && formik.errors[fieldName]}
            FormHelperTextProps={{ 'data-testid': `${id}-error` }}
            inputProps={{
                "data-testid": id,
            }}
            InputProps={{
                endAdornment: (
                    <Fragment>
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                onClick={toggleChange}
                                onMouseDown={toggleChange}>
                                {passwordVisibility && <Visibility />}
                                {!passwordVisibility && <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        <InputAdornment position='end'>
                            {formik.touched[fieldName] && Boolean(formik.errors[fieldName]) && <BiSolidMessageError style={{ color: '#d32f2f', fontSize: '1.5rem' }} />}
                            {validationIcon && formik.touched[fieldName] && !formik.errors[fieldName] && <FaCheck style={{ color: '#4caf50', fontSize: '1.3rem' }} />}
                        </InputAdornment>
                    </Fragment>
                ),
            }}
        />
    )
}

const FormikTermsAndCondition = (props) => {
    const { id, formik, acceptConditions, checked, label } = props;

    const openTermsAndCondition = (value) => {
        formik.setFieldValue(id, value)
        // setTermsAndCondition(true)
    }

    return (
        <Fragment>
            <FormControlLabel control={
                <Checkbox onClick={() => openTermsAndCondition(!checked)} data-testid='checkbox'
                    id="accept-terms" checked={checked} color="primary"
                />
            }
                label={label} disabled={false}
            />
        </Fragment>
    )
}



const FormikLabelColorCode = (props) => {
    const { id, fieldName, label, formik, handleChange, disabled, size, required, handleBlur, type, placeholder,handleChangeColor,handleFocus, } = { ...props, disabled: props.disabled || false };
    const handleKeyDown = (event) => {
        if (event.key === ' ' && (formik.values[fieldName] === '' || formik.values[fieldName].endsWith(' '))) {
            event.preventDefault();
        }
    };

    return (
        <Fragment>
            <TextField
                fullWidth
                margin="normal"
                id={id}
                size={size}
                label={label}
                placeholder={placeholder ? placeholder : ''}
                name={fieldName}
                value={getIn(formik.values, fieldName)}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                type={type ? type : 'text'}
                error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                helperText={formik.touched[fieldName] && formik.errors[fieldName]}
                inputProps={{
                    "data-testid": id,
                }}
                InputProps={{
                    endAdornment: (
                        <Fragment>
                            <InputAdornment position='end'>
                                <IconButton onClick={handleChangeColor}
                                    aria-label='get a new code'>
                                    <AutorenewIcon />
                                </IconButton>
                            </InputAdornment>
                        </Fragment>
                    ),
                }}
            />
        </Fragment>
    )
}

export {
    FormikTextField, FormikPasswordField, FormikMuiPhoneNumber, FormikPasswordConfirmField, FormikTermsAndCondition,
    FormikLabelTextField, FormikLabelMuiPhone, FormikLabelDatePicker,FormikLabelPasswordField, FormikLabelColorCode
}
