import React                                        from 'react';
import { useTranslation }                           from 'react-i18next';
import ClaimantBankDetail                           from '../../file-claim/fc-file-claim/claimant-bank/ClaimantBankDetail';

const CreditorBank = ({caseId, creditorId, partyId, bankId, caseStage, onBankChange, canEdit, setEnabledSteps}) => {
    const { t } = useTranslation();

    return (
        <ClaimantBankDetail caseId={caseId} claimantId={creditorId} partyId={partyId} bankId={bankId}
            caseStage={caseStage} onBankChange={onBankChange} canEdit={canEdit} setEnabledSteps={setEnabledSteps}/>
    )
}

export default CreditorBank