import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import React from 'react'
import { FormikOtpInput } from '../common/formik-fields-components/FormikOtpInput'
import ICMSLoader from '../common/icms-loader/ICMSLoader';

const ConfirmOtpDialog = ({ open, loginFormik, onSubmit, loading }) => {

    return (
        <Dialog open={open} sx={{ 
            '& .MuiDialog-paper': { 
                borderRadius: '10px'
            } 
        }}>
            <DialogContent>
                <FormikOtpInput
                    id="mfaOtp"
                    fieldName="mfaOtp"
                    label="Two Step Verification Code"
                    formik={loginFormik}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={onSubmit} fullWidth>
                    Login
                </Button>
            </DialogActions>
            {loading && <ICMSLoader show={loading} />}
        </Dialog>
    )
}

export default ConfirmOtpDialog