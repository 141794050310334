import React, { 
    useState
}                                           from 'react';
import { useFormik }                        from "formik";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid
}                                           from '@mui/material';
import * as Yup                             from 'yup';
import { useTranslation }                   from 'react-i18next';
import ICMSLoader                           from '../../../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                         from '../../../../../common/icms-snackbar/IcmsSnackbar';
import { ICMSAxiosInterceptor }             from '../../../../../config/axios.interceptor';
import { FormikLabelNumericFormat }         from '../../../../../common/formik-fields-components/FormikLabelNumericFormat';
import { FormikNewDateTime }                from '../../../../../common/formik-fields-components/FormikNewDateTime';
import { TextAreaLabelController }          from '../../../../../common/formik-fields-components/TextAreaLabelController';
import { ICMSButton }                       from '../../../../../common/icms-styled-components/IcmsStyledComponents';

const ClaimDistributionDetail = ({caseId, claimantId, debtDefaultId, show, onClose, distributionDetail,
        onSuccess, claimId}) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});

    const DistributionValidationSchema = Yup.object().shape({
        occurredOn: Yup.string().required(t('Distribution_Date_Is_Required')),
        principal: Yup.number().min(1, t('Distribution_Amount_Should_Be_More_Than_Zero')).required(t('Distribution_Amount_Is_Required'))
    });

    const distributionFormik = useFormik({
        initialValues: {
            claimAdmitRejectDisburseId: distributionDetail?.claimAdmitRejectDisburseId ||  '',
            debtDefaultId: distributionDetail?.debtDefaultId ||  '',
            claimAdmitRejectDisburseCd: distributionDetail?.claimAdmitRejectDisburseCd ||  '',
            occurredOn: distributionDetail?.occurredOn ||  null,
            principal: distributionDetail?.principal ||  0,
            interest: distributionDetail?.interest ||  0,
            other: distributionDetail?.other ||  0,
            description: distributionDetail?.description ||  null
        },
        validationSchema: DistributionValidationSchema,
        validateOnBlur: true,
        validateOnMount: true,
        validateOnChange: true
    })
    
    const handleSave = () => {
        if (!distributionFormik.values.claimAdmitRejectDisburseId) {
            createDistribution();
        } else {
            updateDistribution();
        }
    }

    const createDistribution = () => {
        setLoading(false);
        ICMSAxiosInterceptor.post(`case/${caseId}/claimant/${claimantId}/disburse/${claimId}`, 
                distributionFormik.values).then(response => {
            onSuccess({show: true, message: t('Distribution_Added_Successfully'), severity: 'success'});
            onClose();
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'})
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'})
            }
            setLoading(false);
        })
    }

    const updateDistribution = () => {
        setLoading(false);
        ICMSAxiosInterceptor.put(`case/${caseId}/claimant/${claimantId}/disburse/${distributionFormik.values.claimAdmitRejectDisburseId}`, 
                distributionFormik.values).then(response => {
            onSuccess({show: true, message: t('Distribution_Updated_Successfully'), severity: 'success'});
            onClose();
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'})
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'})
            }
            setLoading(false);
        })
    }
    
    return (
        <Dialog open={show} fullWidth maxWidth="xs">
            <DialogTitle>{t('Distribution_Details')}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormikNewDateTime
                            id='occurredOn'
                            fieldName='occurredOn'
                            formik={distributionFormik}
                            maxDate={(new Date())}
                            label={t('Distribution_Date')}
                            required={true}
                            handleChange={distributionFormik.handleChange}
                            size='small'
                            onlyDate={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikLabelNumericFormat
                            id="principal"
                            fieldName="principal"
                            label={t("Distribution_Amount")}
                            formik={distributionFormik}
                            size="small"
                            isThousandSeparator={true}
                            required={true}
                            handleChange={distributionFormik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextAreaLabelController
                            id={'description'}
                            fieldName='description'
                            label={t('Description')}
                            formik={distributionFormik}
                            minRows={2}
                            textAlign='start'
                            handleChange={distributionFormik.handleChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ICMSButton onClick={onClose} color="primary" size="small" variant='outlined'>
                    {t('Cancel')}
                </ICMSButton>
                <ICMSButton onClick={handleSave} color="primary" size="small" variant='contained' 
                        disabled={!distributionFormik.isValid || !distributionFormik.dirty}>
                    {t('Save')}
                </ICMSButton>
            </DialogActions>
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} 
                    handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
        </Dialog>
    )
}

export default ClaimDistributionDetail