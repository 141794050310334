import React, { Fragment, useState } from 'react';
import {
    Stack, TableHead, TableBody,
    IconButton,
    Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import HistoryIcon from '@mui/icons-material/History';
import moment from 'moment'
import {
    IcmsMuiTable, IcmsTableRow,
    StyledTableCell
} from '../../../../common/generic-styles/NavPageContainerStyles';
import { FormikNewDateTime } from '../../../../common/formik-fields-components/FormikNewDateTime';
import { FormikLabelNumericFormat } from "../../../../common/formik-fields-components/FormikLabelNumericFormat"
import { RelationshipType } from '../../../../common/GenericCodes';
import ClaimHistory from './claim-history/ClaimHistory';

const ClaimAmountsTableView = ({ cliamFormik, canViewBook, canEditBook, canEditClaim, canEditAdmitted, claimMinDate,
    caseId, claimantId, relationShipCd
}) => {
    const { t } = useTranslation();
    const [showClaimHistory, setShowClaimHistory] = useState({
        show: false,
        admitHistory: false
    });
    const columns = [
        { id: 'source', label: '', minWidth: '10%', colSpan: 1, rowSpan: 1 },
        {
            id: 'dueDates', label: relationShipCd == RelationshipType.FINANCIAL_CLAIMANT ? 'Default_Date' : 'Default_From',
            minWidth: relationShipCd == RelationshipType.FINANCIAL_CLAIMANT ? '18%' : '15%', required: true, colSpan: 1, rowSpan: 1
        },
        {
            id: 'dueDates', label: relationShipCd == RelationshipType.FINANCIAL_CLAIMANT ? '' : 'Default_To',
            minWidth: relationShipCd == RelationshipType.FINANCIAL_CLAIMANT ? '0%' : '15%', required: relationShipCd == RelationshipType.FINANCIAL_CLAIMANT ? false : true, colSpan: 1, rowSpan: 1
        },
        { id: 'principal', label: 'Principal_Amount_Rs', minWidth: '15%', colSpan: 1, rowSpan: 1, tooltip: t("Principal_Amount_Due_The_Claimant_On_Date_Of_Commencement") },
        { id: 'interest', label: 'Interest_Amount_Rs', minWidth: '15%', colSpan: 1, rowSpan: 1, tooltip: t("Interest_Amount_Due_The_Claimant_On_Date_Commencement") },
        { id: 'other', label: 'Other_Charges_Rs', minWidth: '15%', colSpan: 1, rowSpan: 1, tooltip: t("Other_Charges_Due_The_Claimant_On_Date_Of_Commencement") },
        { id: 'total', label: 'Total_Rs', minWidth: '18%', colSpan: 1, rowSpan: 1, tooltip: t("Total_Claim_Due_The_Claimant_On_Date_Commencement") },
        { id: 'action', label: '', minWidth: '5%', rowSpan: 1 }
    ]

    return (
        <div>
            <Stack>
                <IcmsMuiTable stickyHeader aria-label="sticky table" data-testid="claim-list-table"
                    sx={{ padding: '0px !important' }}>
                    <TableHead>
                        <IcmsTableRow>
                            {columns.map((column) => (
                                <Tooltip title={column.tooltip}>
                                    <StyledTableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        sx={{ padding: '4px' }}
                                        colSpan={column.colSpan}
                                        rowSpan={column.rowSpan}
                                    >
                                        {t(column.label)} {column.required && <span style={{ color: 'red' }}>*</span>}
                                    </StyledTableCell>
                                </Tooltip>
                            ))}
                        </IcmsTableRow>
                        {/* {relationShipCd != RelationshipType.FINANCIAL_CLAIMANT && <IcmsTableRow sx={{ borderSpacing: '0px 2px !important' }}>
                            <StyledTableCell style={{ padding: '4px' }} align='center'>{t("From")}</StyledTableCell>
                            <StyledTableCell style={{ padding: '4px' }} align='center'>{t("To")}</StyledTableCell>
                        </IcmsTableRow>} */}
                    </TableHead>
                    <TableBody>
                        {canViewBook && <IcmsTableRow hover>
                            <Tooltip title={t('Claim_Corp_Debtor_Books_As_Date_Of_Commencement')}>
                            <StyledTableCell sx={{ padding: '4px' }}><strong>{t('Corp_Debtor_Books')}</strong></StyledTableCell>
                            </Tooltip>
                            <StyledTableCell sx={{ padding: '4px' }}>
                                {canEditBook && <FormikNewDateTime
                                    id='defaultFrom'
                                    fieldName='defaultFrom'
                                    formik={cliamFormik}
                                    maxDate={cliamFormik.values.claimToTime ? cliamFormik.values.claimToTime : claimMinDate}
                                    handleChange={cliamFormik.handleChange}
                                    size='small'
                                    onlyDate={true}
                                />}
                                {!canEditBook && <Fragment>{cliamFormik.values.defaultFrom ? moment(cliamFormik.values.defaultFrom).format("DD-MM-YYYY") : '-'}</Fragment>}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '4px' }}>
                                {relationShipCd != RelationshipType.FINANCIAL_CLAIMANT && <Fragment>
                                    {canEditBook && <FormikNewDateTime
                                        id='defaultTo'
                                        fieldName='defaultTo'
                                        formik={cliamFormik}
                                        maxDate={claimMinDate}
                                        minDate={cliamFormik.values.defaultFrom}
                                        handleChange={cliamFormik.handleChange}
                                        size='small'
                                        onlyDate={true}
                                    />}
                                    {!canEditBook && <Fragment>{cliamFormik.values.defaultTo ? moment(cliamFormik.values.defaultTo).format("DD-MM-YYYY") : '-'}
                                    </Fragment>}
                                </Fragment>}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '4px' }}>
                                {canEditBook && <FormikLabelNumericFormat
                                    id="principal"
                                    fieldName="principal"
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    handleChange={cliamFormik.handleChange}
                                />}
                                {!canEditBook && <Fragment>{cliamFormik.values.principal ? <NumericFormat value={cliamFormik.values.principal} displayType={'text'}
                                    thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</Fragment>}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '4px' }}>
                                {canEditBook && <FormikLabelNumericFormat
                                    id="interest"
                                    fieldName="interest"
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    handleChange={cliamFormik.handleChange}
                                />}
                                {!canEditBook && <Fragment>{cliamFormik.values.interest ? <NumericFormat value={cliamFormik.values.interest} displayType={'text'}
                                    thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</Fragment>}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '4px' }}>
                                {canEditBook && <FormikLabelNumericFormat
                                    id="other"
                                    fieldName="other"
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    handleChange={cliamFormik.handleChange}
                                />}
                                {!canEditBook && <Fragment>{cliamFormik.values.other ? <NumericFormat value={cliamFormik.values.other} displayType={'text'}
                                    thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</Fragment>}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '4px' }}>
                                {canEditBook && <FormikLabelNumericFormat
                                    id="totalBook"
                                    fieldName="totalBook"
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    disabled={true}
                                    handleChange={cliamFormik.handleChange}
                                />}
                                {!canEditBook && <Fragment>{cliamFormik.values.totalBook ? <NumericFormat value={cliamFormik.values.totalBook} displayType={'text'}
                                    thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</Fragment>}
                            </StyledTableCell>
                        </IcmsTableRow>}
                        <IcmsTableRow hover>
                        <Tooltip title={t('Claim filed by claimant as on date of commencement')}>
                            <StyledTableCell sx={{ padding: '4px' }}><strong>{t('Claim')}</strong></StyledTableCell>
                            </Tooltip>
                            <StyledTableCell sx={{ padding: '4px' }}>
                                {canEditClaim && <FormikNewDateTime
                                    id='claimTime'
                                    fieldName='claimTime'
                                    formik={cliamFormik}
                                    // minDate={claimMinDate}
                                    maxDate={claimMinDate}
                                    handleChange={cliamFormik.handleChange}
                                    size='small'
                                    onlyDate={true}
                                />}
                                {!canEditClaim && <Fragment>{cliamFormik.values.claimTime ? moment(cliamFormik.values.claimTime).format("DD-MM-YYYY") : '-'}</Fragment>}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '4px' }}>
                                {relationShipCd != RelationshipType.FINANCIAL_CLAIMANT && <Fragment> {canEditClaim &&
                                    <FormikNewDateTime
                                        id='claimToTime'
                                        fieldName='claimToTime'
                                        formik={cliamFormik}
                                        minDate={cliamFormik.values.claimTime}
                                        maxDate={claimMinDate}
                                        handleChange={cliamFormik.handleChange}
                                        size='small'
                                        onlyDate={true}
                                    />}
                                    {!canEditClaim && <Fragment>{cliamFormik.values.claimToTime ? moment(cliamFormik.values.claimToTime).format("DD-MM-YYYY") : '-'}</Fragment>}
                                </Fragment>}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '4px' }}>
                                {canEditClaim && <FormikLabelNumericFormat
                                    id="cprincipal"
                                    fieldName="cprincipal"
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    handleChange={cliamFormik.handleChange}
                                />}
                                {!canEditClaim && <Fragment>{cliamFormik.values.cprincipal ? <NumericFormat value={cliamFormik.values.cprincipal} displayType={'text'}
                                    thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</Fragment>}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '4px' }}>
                                {canEditClaim && <FormikLabelNumericFormat
                                    id="cinterest"
                                    fieldName="cinterest"
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    handleChange={cliamFormik.handleChange}
                                />}
                                {!canEditClaim && <Fragment>{cliamFormik.values.cinterest ? <NumericFormat value={cliamFormik.values.cinterest} displayType={'text'}
                                    thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</Fragment>}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '4px' }}>
                                {canEditClaim && <FormikLabelNumericFormat
                                    id="cother"
                                    fieldName="cother"
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    handleChange={cliamFormik.handleChange}
                                />}
                                {!canEditClaim && <Fragment>{cliamFormik.values.cother ? <NumericFormat value={cliamFormik.values.cother} displayType={'text'}
                                    thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</Fragment>}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '4px' }}>
                                {canEditClaim && <FormikLabelNumericFormat
                                    id="totalClaim"
                                    fieldName="totalClaim"
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    disabled={true}
                                    handleChange={cliamFormik.handleChange}
                                />}
                                {!canEditClaim && <Fragment>{cliamFormik.values.totalClaim ? <NumericFormat value={cliamFormik.values.totalClaim} displayType={'text'}
                                    thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</Fragment>}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '4px', textAlign: 'center' }}>
                                {cliamFormik.values.debtDefaultId && <IconButton color='primary' size='small'
                                    onClick={() => { setShowClaimHistory({ show: true, admitHistory: false }) }}>
                                    <HistoryIcon />
                                </IconButton>}
                            </StyledTableCell>
                        </IcmsTableRow>
                        <IcmsTableRow hover>
                        <Tooltip title={t('Claim_Admitted_On_Date_Of_Commencement')}>
                            <StyledTableCell sx={{ padding: '4px' }}><strong>{t('Admitted')}</strong></StyledTableCell>
                            </Tooltip>
                            <StyledTableCell sx={{ padding: '4px' }}>
                                {/* {canEditAdmitted && <FormikNewDateTime
                                    id='admittedTime'
                                    fieldName='admittedTime'
                                    maxDate={claimMinDate}
                                    formik={cliamFormik}
                                    // maxDate={(new Date())}
                                    handleChange={cliamFormik.handleChange}
                                    size='small'
                                    onlyDate={true}
                                />}
                                {!canEditAdmitted && <Fragment>{cliamFormik.values.admittedTime ? moment(cliamFormik.values.admittedTime).format("DD-MM-YYYY") : '-'}</Fragment>} */}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '4px' }}>
                                {/* {relationShipCd != RelationshipType.FINANCIAL_CLAIMANT && <strong style={{color: 'darkgray'}}>NA</strong>} */}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '4px' }}>
                                {canEditAdmitted && <FormikLabelNumericFormat
                                    id="aprincipal"
                                    fieldName="aprincipal"
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    handleChange={cliamFormik.handleChange}
                                />}
                                {!canEditAdmitted && <Fragment>{cliamFormik.values.aprincipal ? <NumericFormat value={cliamFormik.values.aprincipal} displayType={'text'}
                                    thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</Fragment>}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '4px' }}>
                                {canEditAdmitted && <FormikLabelNumericFormat
                                    id="ainterest"
                                    fieldName="ainterest"
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    handleChange={cliamFormik.handleChange}
                                />}
                                {!canEditAdmitted && <Fragment>{cliamFormik.values.ainterest ? <NumericFormat value={cliamFormik.values.ainterest} displayType={'text'}
                                    thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</Fragment>}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '4px' }}>
                                {canEditAdmitted && <FormikLabelNumericFormat
                                    id="aother"
                                    fieldName="aother"
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    handleChange={cliamFormik.handleChange}
                                />}
                                {!canEditAdmitted && <Fragment>{cliamFormik.values.aother ? <NumericFormat value={cliamFormik.values.aother} displayType={'text'}
                                    thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</Fragment>}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '4px' }}>
                                {canEditAdmitted && <FormikLabelNumericFormat
                                    id="totalAdmit"
                                    fieldName="totalAdmit"
                                    formik={cliamFormik}
                                    size="small"
                                    isThousandSeparator={true}
                                    disabled={true}
                                    handleChange={cliamFormik.handleChange}
                                />}
                                {!canEditAdmitted && <Fragment>{cliamFormik.values.totalAdmit ? <NumericFormat value={cliamFormik.values.totalAdmit} displayType={'text'}
                                    thousandSeparator={true} thousandsGroupStyle="lakh" /> : '-'}</Fragment>}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '4px', textAlign: 'center' }}>
                                {cliamFormik.values.claimId && <IconButton color='primary' size='small' onClick={() => { setShowClaimHistory({ show: true, admitHistory: true }) }}>
                                    <HistoryIcon />
                                </IconButton>}
                            </StyledTableCell>
                        </IcmsTableRow>
                    </TableBody>
                </IcmsMuiTable>
            </Stack>
            {showClaimHistory.show && <ClaimHistory show={showClaimHistory.show} onClose={() => { setShowClaimHistory(false) }}
                caseId={caseId} claimantId={claimantId} debtDefaultId={cliamFormik.values.debtDefaultId}
                claimId={cliamFormik.values.claimId}
                admittedHistory={showClaimHistory.admitHistory} />}
        </div>
    )
}

export default ClaimAmountsTableView