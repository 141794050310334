import { Button, CardContent, Link, Stack, ToggleButton, ToggleButtonGroup, Typography, Box } from "@mui/material";
import { useFormik } from "formik";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiSolidMobileVibration } from "react-icons/bi";
import { IoMailUnread } from "react-icons/io5";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from 'yup';
import ICMSReCaptcha from "../common/ICMSReCaptcha";
import PoweredBy from "../common/PoweredBy";
import { UserContext } from "../common/context/UserContext";
import { FormikMuiPhoneNumber, FormikPasswordField, FormikTextField } from "../common/formik-fields-components/FormikFieldComponents";
import { FormikOtpInput } from "../common/formik-fields-components/FormikOtpInput";
import ICMSLoader from "../common/icms-loader/ICMSLoader";
import IcmsSnackbar from "../common/icms-snackbar/IcmsSnackbar";
import { AuthBottomDiv, AuthDiv, JustifyCard } from "../common/icms-styled-components/IcmsStyledComponents";
import { AuthAxiosInterceptor, PublicAxiosInterceptor, ICMSAxiosInterceptor } from "../config/axios.interceptor";
import { CaseManagementSideNav, Roles } from '../common/GenericCodes';
import TermsAndConditions from "./TermsAndCondition";
import { RxHome } from "react-icons/rx";
import { setFormikValueWithLowerCase } from "../common/utils/Utils";
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { scheduleTokenRefresh } from "./tokenManager";

export const EMAIL = "email";
export const MOBILE_NUMBER = "mobile";

const LoginValidationSchema = Yup.object().shape({
    isEmailOrMobile: Yup.string(),
    // email: Yup.string()
    //     .required("Must enter email address")
    //     .matches(/(([^<>()[]\\.,;\s@"]+(\.[^<>()[]\\.,;\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Invalid Email Address"),
    email: Yup.string().when('isEmailOrMobile', {
        is: (isEmailOrMobile) => { return isEmailOrMobile === EMAIL },
        then: () => Yup.string().email('Invalid Email Address').required('Email Address is Required'),
        otherwise: () => Yup.string()
    }),
    extension: Yup.string(),
    phoneNumber: Yup.string().when('isEmailOrMobile', {
        is: (isEmailOrMobile) => { return isEmailOrMobile === MOBILE_NUMBER },
        then: () => Yup.string().required("Mobile Number is Required")
            .test("phoneNumber", "Invalid Mobile Number", (str, context) => {
                return isValidPhoneNumber(context.parent['extension'] + str);
            }),
        otherwise: () => Yup.string()
    }),
    password: Yup.string()
        .required('Please Enter your password'),
    mfaEnabled: Yup.boolean(),
    mfaOtp: Yup.string().when('mfaEnabled', {
        is: (mfaEnabled) => { return mfaEnabled === true },
        then: () => {
            return Yup.string()
                .min(6, "Enter Code Correctly").max(6, "Enter Code Correctly")
                .required("Two Step Verification Code is required")
        },
        otherwise: () => Yup.string()
    }),
});

const Login = (props) => {
    const { setLoginDetails } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const context = useContext(UserContext);
    const [message, setMessage] = useState({
        show: false,
        message: null,
        severity: null
    })
    const [captcha, setCaptcha] = useState('');
    const [loginCaptcha, setLoginCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const [isLoginRefresh, setIsLoginRefresh] = useState(false);
    // const [mfaEnabledUpdate, setMfaEnabledUpdate] = useState(false);
    const [pwdValue, setPswValue] = useState('')
    const [openTerms, setOpenTerms] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirectUrl = queryParams.get('redirect');
    const [isMFAEnabled, setIsMFAEnabled] = useState(false);

    const loginFormik = useFormik({
        initialValues: {
            isEmailOrMobile: "email",
            email: '',
            phoneNumber: '',
            extension: '+91',
            password: '',
            mfaEnabled: false,
            mfaOtp: ''
        },
        onSubmit: (values) => {
            if (values.mfaEnabled && values.mfaOtp !== '') {
                signIn();
            } else {
                checkMFA(values);
            }
        },
        validationSchema: LoginValidationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true,
    });

    const checkMFA = async (values) => {
        let username = loginFormik.values.isEmailOrMobile === MOBILE_NUMBER ? loginFormik.values.extension + loginFormik.values.phoneNumber : loginFormik.values.email
        let payload = {
            loginId: username
        }
        // setMessage({ show: false, message: '', severity: '' });
        setLoading(true);
        AuthAxiosInterceptor.post(`mfa/?recaptcha=${captcha}`, payload).then(response => {
            refreshCaptcha();
            refreshLoginCaptcha();
            if (response) {
                setIsMFAEnabled(response)
                loginFormik.setFieldValue('mfaEnabled', true);
                // setMfaEnabledUpdate(true);
                setMessage({ show: true, message: t('OTP_HAS_BEEN_SENT'), severity: 'success' });
                setLoading(false);
            } else {
                signIn();
            }
        }).catch(error => {
            refreshCaptcha();
            refreshLoginCaptcha();
            if (error.message === `Your account: ${username} is not verified...`) {
                // handleNotVerified();
                navigate('/verify-account',{state:{ isEmailOrMobile: loginFormik.values.isEmailOrMobile}})
                // setMessage({ show: true, message: error.message, severity: 'error' });
            } else if (error.message) {
                setMessage({ show: true, message: error.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
            }
            setLoading(false);
        })
    }

    const refreshCaptcha = () => {
        setIsRefresh(true);
        setTimeout(() => {
            setIsRefresh(false);
        }, 1000);
    }

    const refreshLoginCaptcha = () => {
        setIsLoginRefresh(true);
        setTimeout(() => {
            setIsLoginRefresh(false);
        }, 1000);
    }

    const onSubmit = () => {
        loginFormik.handleSubmit();
    }

    const handleChange = (event) => {
        const {id, value} = event.target
        loginFormik.setFieldTouched(id, true);
        loginFormik.handleChange({
            target: {
                id,
                value: value.trimStart()
            },
        });
    }

    const decodeToken = (token) => {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    const updateUserContext = (response) => {
        let userInfo = decodeToken(response.access_token);
        context.setUserSession(prevState => ({
            ...prevState,
            userSession: {
                isAuthenticated: true, session: {
                    accessToken: response.access_token,
                    refreshToken: response.refresh_token,
                    name: userInfo.firstName,
                    middleName: userInfo.middleName !== undefined && userInfo.middleName !== null ? userInfo.middleName : '',
                    lastName: userInfo.lastName !== undefined && userInfo.lastName !== null ? userInfo.lastName : '',
                    dateOfBirth: userInfo.dateOfBirth !== undefined ? userInfo.dateOfBirth : null,
                    individual: userInfo.individual,
                    email: userInfo.email,
                    phoneNumber: userInfo.phoneNumber,
                    userId: userInfo.sub,
                    isMFAEnabled: isMFAEnabled ? isMFAEnabled : ''
                }
            },
            loginUserRoles: userInfo.roles,
            currentDateTime: null,
        })
        )

    }

    const signIn = () => {
        let code = loginFormik.values.mfaOtp.replaceAll(' ', '')
        setLoading(true);
        setMessage({ show: false, message: null, severity: null });
        let payload = {
            userName: loginFormik.values.isEmailOrMobile === MOBILE_NUMBER ? loginFormik.values.extension + loginFormik.values.phoneNumber : loginFormik.values.email,
            password: loginFormik.values.password,
            otp: code
        }
        if (loginCaptcha !== '') {
            PublicAxiosInterceptor.post(`login?recaptcha=${loginCaptcha}`, payload).then(response => {
                setLoading(false);
                context.setUserSession({
                    isAuthenticated: true, session: {
                        accessToken: response.access_token,
                        refreshToken: response.refresh_token
                    }
                });
                sessionStorage.setItem('token', response.access_token);
                sessionStorage.setItem('refreshToken', response.refresh_token);
                sessionStorage.setItem('accessTokenExpiry', Date.now() + response.expires_in * 1000);
                scheduleTokenRefresh()
                updateUserContext(response);
                setLoading(true);
                getLoginUserDetails();
                // setTimeout(() => {
                //     navigate("/details/home");
                // }, 2000);
            }).catch((error) => {
                // refreshCaptcha();
                refreshLoginCaptcha();
                if (error.message) {
                    if (error.message.includes('User credentials have expired') || error.message.includes('Need to reset password')) {
                        navigate('/forgot-password',{state:{ username:loginFormik.values.isEmailOrMobile === EMAIL ? loginFormik.values.email :
                            loginFormik.values.extension + loginFormik.values.phoneNumber,
                            // isEmailOrMobile: loginFormik.values.isEmailOrMobile,
                            // password: loginFormik.values.password,
                            accountExpired: true
                        }})
                        setMessage({ show: true, message: error.message, severity: 'error' });
                    } else if (error.message.includes('User account is locked')) {
                        setMessage({ show: true, message: 'User account is locked', severity: 'error' });
                        navigate('/forgot-password',{state:{ username:loginFormik.values.isEmailOrMobile === EMAIL ? loginFormik.values.email :
                            loginFormik.values.extension + loginFormik.values.phoneNumber,
                            // isEmailOrMobile: loginFormik.values.isEmailOrMobile,
                            // password: loginFormik.values.password,
                            accountLocked: true
                        }})
                        // resetUserAccount();
                    } else {
                        setMessage({ show: true, message: error.message, severity: 'error' });
                    }
                } else {
                    setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
                }
                setLoading(false);
            })
        } else {
            // refreshCaptcha();
            refreshLoginCaptcha();
            setMessage({ show: false, message: null, severity: null });
            setTimeout(() => {
                setLoading(false);
                setMessage({ show: true, message: t('Please_Check_Internet_Connection_And_Refresh_The_Page'), severity: 'error' })
            }, 1000);
        }
    }

    const getLoginUserDetails = () => {
        ICMSAxiosInterceptor.get('login-user').then(function (response) {
            if (response) {
                setLoginDetails(response);
                if (response.hasIdentification) {
                    setTimeout(() => {
                        if (redirectUrl) {
                            navigate(redirectUrl);
                        } else {
                            if( response.casesInvoled.length > 0 || response.subscriberRole.length > 0 ) {   
                                navigate("/details/case-management", {
                                    state: {
                                        caseType: (response.subscriberRole.includes(Roles.enkAdmin.roleValue) ||
                                            response.subscriberRole.includes(Roles.enkSupport.roleValue) ||
                                            response.subscriberRole.includes(Roles.administrator.roleValue) ||
                                            response.subscriberRole.includes(Roles.monitor.roleValue) ||
                                            response.subscriberRole.includes(Roles.accountAdmin.roleValue)
                                        ) ? CaseManagementSideNav.active.key : CaseManagementSideNav.all.key
                                    }
                                })
                            } else {
                                navigate("/details/my-profile", {
                                    state: {
                                        loginDetails: response
                                    }
                                })
                            }
                        }
                    }, 2000);
                } else {
                    navigate(`/details/kyc${redirectUrl ? `?redirect=${redirectUrl}` : ''}`);
                }
            }
        }).catch(function (error) {
            setLoading(false);
            setMessage({ show: true, message: (error.message ? error.message : error), severity: 'error' });
        });
    }

    const handleSignUp = () => {
        navigate(`/sign-up${redirectUrl ? `?redirect=${redirectUrl}` : ''}`);
    }

    const forgotPassword = () => {
        navigate('/forgot-password')
    }

    const verifyAccount = () => {
        navigate('/verify-account')
    }
    // useEffect(() => {
    //     if (mfaEnabledUpdate)
    //         loginFormik.setFieldValue('mfaEnabled', true);
    // }, [mfaEnabledUpdate, loginFormik.values.mfaOtp])

    const handleOpenTerms = () => {
        setOpenTerms(true)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            loginFormik.handleSubmit();
        }
    };

    const navigateToHomePage = () => {
        navigate('/home-page')
    }

    return (
        <form onKeyDown={handleKeyDown}>
            <AuthDiv>
                <Button startIcon={<RxHome />} onClick={navigateToHomePage} variant='outlined' color='primary' style={{background:'white', margin:'10px 0px 0px 10px'}}>Home</Button>
                <Button size="medium" variant="contained"
                    sx={{ textTransform: 'capitalize', fontWeight: 'bold', color: 'aliceblue', padding: '6px 12px', margin:'10px 10px 0px 0px', float: 'right' }}>
                    <Link target="_blank" href={process.env.REACT_APP_ICMS1} color='aliceblue'
                        style={{ cursor: 'pointer'}} underline='none'>
                        <OpenInNewRoundedIcon sx={{ verticalAlign: 'bottom !important'}} />
                            PDA V1
                        </Link>
                </Button>
                <Stack justifyContent='center' alignItems='center' m='auto' height="100vh">
                    <JustifyCard>
                        <CardContent sx={{ p: 0, pb: '0px !important' }}>
                            <Stack direction='row'>
                                <Stack p={2} width='50%' spacing={2} alignItems='center' justifyContent='center' sx={{ background: '#e8f0ffcf' }}>
                                    <Typography variant="h3" sx={{ fontWeight: 'bold' }}>{'ICMS'}</Typography>
                                    <Typography variant="body2" color="GrayText" sx={{ fontWeight: 'bold' }}>{t('Insolvency_Case_Management_System_-_IBC_code_2016')}</Typography>
                                    <img src={process.env.PUBLIC_URL + 'images/icms.svg'} alt="Login-png" width="50%"></img>
                                </Stack>
                                <Stack p={2} width='50%' spacing={2.5} mt={2} alignItems='center' justifyContent='center'>
                                    <Typography variant="h4">{'Sign In'}</Typography>
                                    <ToggleButtonGroup
                                        color="primary"
                                        id="isEmailOrMobile"
                                        name="isEmailOrMobile"
                                        value={loginFormik.values.isEmailOrMobile}
                                        exclusive
                                        size="small"
                                        onChange={loginFormik.handleChange}
                                        aria-label="Platform"
                                        disabled={loginFormik.values.mfaEnabled}
                                    >
                                        <ToggleButton value="email" name="isEmailOrMobile"
                                            sx={{ fontWeight: 'bold' }}><IoMailUnread style={{ fontSize: "1.45rem" }} />&nbsp; Email Address</ToggleButton>
                                        <ToggleButton value="mobile" name="isEmailOrMobile"
                                            sx={{ fontWeight: 'bold' }}><BiSolidMobileVibration style={{ fontSize: "1.5rem" }} />&nbsp; Mobile Number</ToggleButton>
                                    </ToggleButtonGroup>
                                    {loginFormik.values.isEmailOrMobile === EMAIL ? <FormikTextField
                                        id="email"
                                        fieldName="email"
                                        label="Username (Email Address)"
                                        formik={loginFormik}
                                        handleChange={(event) => setFormikValueWithLowerCase(event, loginFormik)}
                                        // handleChange={handleChange}
                                        disabled={loginFormik.values.mfaEnabled}
                                        validationIcon={true}
                                    /> :
                                        <FormikMuiPhoneNumber
                                            id="phoneNumber"
                                            fieldName="phoneNumber"
                                            label="Mobile Number"
                                            countryCodeName="extension"
                                            formik={loginFormik}
                                            handleChange={handleChange}
                                            disabled={loginFormik.values.mfaEnabled}
                                            validationIcon={true}
                                        />}
                                    <FormikPasswordField
                                        id="password"
                                        fieldName="password"
                                        label="Password"
                                        formik={loginFormik}
                                        pwdValue={pwdValue}
                                        handleChange={handleChange}
                                        validationIcon={true}
                                    />
                                    {loginFormik.values.mfaEnabled && <FormikOtpInput
                                        id="mfaOtp"
                                        fieldName="mfaOtp"
                                        label="Two Step Verification Code"
                                        formik={loginFormik}
                                    />}
                                    <Stack width="100%" mt={'0px !important'}>
                                        <Typography id="forgotpwd" variant="body2" sx={{ textAlign: 'right', marginTop: '12px !important' }}>
                                            <Link id="forgotlink" onClick={forgotPassword} className="pointer">Forgot Password?</Link>
                                        </Typography>
                                        <Typography id="notVerify" variant="body2" marginTop='5px' sx={{ textAlign: 'right', marginTop: '5px !important' }}>
                                            Not Verified? <Link id="verify" onClick={verifyAccount} className="pointer">Verify</Link>
                                        </Typography>
                                    </Stack>
                                    <Button variant="contained" fullWidth onClick={onSubmit} data-testid='submit'>
                                        {loginFormik.values.mfaEnabled ? 'Confirm OTP & Sign In' : 'Submit'}
                                    </Button>
                                    <Typography id="donthaveaccount" variant="body2">
                                        <Link onClick={handleOpenTerms} className="pointer font-weight">Terms and Conditions</Link>
                                    </Typography>
                                    {/* <Typography id="forgotpwd" variant="body2" sx={{ textAlign: 'right', marginTop: '10px !important' }}>
                                        <Link id="forgotlink" onClick={forgotPassword} className="pointer">Forgot Password?</Link>
                                    </Typography>
                                    <Typography id="notVerify" variant="body2" marginTop='5px' sx={{ textAlign: 'right', marginTop: '5px !important' }}>
                                        Not Verified? <Link id="verify" onClick={verifyAccount} className="pointer">Verify</Link>
                                    </Typography> */}
                                    <Typography id="donthaveaccount" variant="body2">
                                        Don't have an account? <Link onClick={handleSignUp} className="pointer font-weight">Sign Up</Link>
                                    </Typography>
                                    {/* <Typography id="donthaveaccount" variant="body2">
                                        Don't have an account? <Link onClick={handleSignUp} className="pointer">Sign Up</Link>
                                    </Typography> */}
                                    {/* <PoweredBy /> */}
                                </Stack>
                            </Stack>
                        </CardContent>
                    </JustifyCard>
                </Stack>
            </AuthDiv>
            <AuthBottomDiv />
            {openTerms && <TermsAndConditions show={openTerms} close={setOpenTerms} view={true} />}
            {!isRefresh && <ICMSReCaptcha key="login-withmfa" captcha={setCaptcha} refresh={setIsRefresh} />}
            {!isLoginRefresh && <ICMSReCaptcha key="login" captcha={setLoginCaptcha} refresh={setIsLoginRefresh} />}
            {loading && <ICMSLoader show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </form>
    )
}

export default Login;