import { ICMSAxiosInterceptor } from "../../../config/axios.interceptor";

const listClaim = (caseId, claimantId, handleSuccessCallback, handleErrorCallBack) => {

    ICMSAxiosInterceptor.post(`case/${caseId}/claimant/${claimantId}/claim/list`, {}).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const createClaim = (caseId, claimantId, claimResource, handleSuccessCallback, handleErrorCallBack) => {
    ICMSAxiosInterceptor.post(`case/${caseId}/claimant/${claimantId}/claim`, claimResource).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const updateClaim = (caseId, claimantId, claimId, claimResource, handleSuccessCallback, handleErrorCallBack) => {
    ICMSAxiosInterceptor.put(`/case/${caseId}/claimant/${claimantId}/claim/${claimId}`, claimResource).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const getClaim = (claimId, handleSuccessCallback, handleErrorCallBack) => {
    ICMSAxiosInterceptor.get(`/claim/${claimId}`).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const deleteClaim = (caseId, claimantId, claimId, handleSuccessCallback, handleErrorCallBack) => {

    ICMSAxiosInterceptor.delete(`case/${caseId}/claimant/${claimantId}/claim/${claimId}`).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

export { listClaim, createClaim, updateClaim, getClaim, deleteClaim }