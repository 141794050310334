import React, { useState }                        from 'react';
import { Dialog, DialogActions, 
    DialogContent, DialogTitle }    from '@mui/material';
    import { useTranslation }       from 'react-i18next';
import { ICMSButton }               from '../../icms-styled-components/IcmsStyledComponents';
import MapComponent                 from './MapComponent';

const MapModalComponent = ({show, onClose, coordinates, area, setCoordinates, setArea, disabled}) => {
    const { t } = useTranslation();
    const [latLng, setLatLng] = useState(coordinates ? coordinates : null);
    const [polygon, setPolygon] = useState(area ? area : []);

    const handleSave = () => {
        setCoordinates(latLng);
        setArea(polygon);
        onClose();
    }

    return (
        <div>
            <Dialog open={show} fullWidth maxWidth="xl" PaperProps={{
                style: {
                    minHeight: '95vh'
                }
            }}>
                <DialogTitle>{t('Map')}</DialogTitle>
                <DialogContent dividers>
                    <MapComponent latLng={latLng} polygon={polygon} 
                        setLatLng={setLatLng} setPolygon={setPolygon} disabled={disabled}/>
                </DialogContent>
                <DialogActions>
                    <ICMSButton onClick={onClose} color="primary" size="small" 
                            variant='outlined' sx={{float: 'right', marginRight: '2px'}}>
                        {t('Close')}
                    </ICMSButton>
                    {!disabled && <ICMSButton onClick={handleSave} color="primary" size="small" 
                            variant='contained' sx={{float: 'right', marginRight: '2px'}}>
                        {t('Save')}
                    </ICMSButton>}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default MapModalComponent