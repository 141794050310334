import { CardContent } from "@mui/material";
import { Stack } from "@mui/system";
import { Fragment } from "react";

const ClaimStepContent = (props) => {
    const { steps, activeStep, component } = props;
    return (
        <CardContent sx={{background: '#fff', mt: 2, borderRadius: '14px', mx: '1rem', px: '2rem'}}>
            {steps.map((value, index) => {
                return (
                    <Fragment key={index}>
                        {activeStep === index && <Stack
                        // className={`step ${activeStep === index ? 'active' : ''} ${nextAnimation === 1 ? 'fade-out' : ''}`}
                        >
                            {/* <Typography>{'Step 1'}{nextAnimation === 1 ? 'fade-out' : ''}</Typography> */}
                            {component}
                        </Stack>}
                    </Fragment>
                )
            })}
        </CardContent>
    )
}

export default ClaimStepContent;