import React, {
    useState,
    useEffect
}                               from 'react';
import {
    TableHead,
    TableBody,
    IconButton,
    Tooltip,
    Button
}                               from '@mui/material';
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { IoIosDocument }        from "react-icons/io";
import { useTranslation }       from 'react-i18next';
import {
    IcmsTableContainer,
    IcmsMuiTable,
    IcmsTableRow,
    StyledTableCell
}                               from '../../common/generic-styles/NavPageContainerStyles';
import { ICMSButton }           from '../../common/icms-styled-components/IcmsStyledComponents';
import { ICMSAxiosInterceptor } from '../../config/axios.interceptor';
import { UploadDialog }         from '../../common/documents/UploadDialog';
import IcmsSnackbar             from '../icms-snackbar/IcmsSnackbar';
import ICMSLoader               from '../icms-loader/ICMSLoader';
import { formatBytes }          from '../../common/utils/Utils';
import ICMSConfirmDialog        from '../../common/ConfirmationDialog/ICMSConfirmDialog';
import DocumentRow from './DocumentRow';

const DocumentList = (props) => {
    const {caseId, icmsTablePk, canUpload, uploadedDocuments, documentTypeCd, handleRefresh, canDelete, canDownload} = props;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});
    const [showUploadDocument, setShowUploadDocument] = useState({show: false, icmsCaseDocId: ''});
    const columns = [
        { id: 'name', label: 'Name', minWidth: '25%'},
        { id: 'documentType', label: 'Document_Type', minWidth: '20%' },
        { id: 'description', label: 'Description', minWidth: '20%'},
        { id: 'size', label: 'Size', minWidth: '10%'},
        { id: 'noOfVersions', label: 'Number_Of_Versions', minWidth: '15%'},
        { id: 'action', label: 'Actions', minWidth: '10%'}
    ]

    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('Yes'),
        disAgreeBtnLabel: t('No'),
        onDisAgree: ''
    })

    const handleUpload = (icmsCaseDocId, documentCd) => {
        setMessage({ show: false, message: '', severity: '' });
        setShowUploadDocument({show: true, icmsCaseDocId: icmsCaseDocId, documentCd: documentCd})
    }

    const onSuccessfullFileUpload = (message) => {
        setShowUploadDocument({show: false, icmsCaseDocId: ''});
        handleRefresh(message);
    }

    const deleteConfirmation = (documentId) => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: `${t('Are_You_Sure_That_You_Want_To_Delete_Document')}?`,
            onDisAgree: resetSeekConfirmation,
            onAgree: () => {
                resetSeekConfirmation();
                deleteDocument(documentId);
            },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }
    
    const deleteDocument = (documentId) => {
        setLoading(true);
        setMessage({ show: false, message: '', severity: '' });
        ICMSAxiosInterceptor.delete(`case/${caseId}/file/${documentId}`).then(response => {
            handleRefresh({ show: true, message: t('Document_Deleted_Successfully'), severity: 'success' });
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({ show: true, message: error?.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' })
            } 
            setLoading(false); 
        })
    }

    const deleteVersionConfirmation = (documentId, versionId, versionNumber) => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: `${t('Are_You_Sure_That_You_Want_To_Delete_Document')} ${t('Version')}   ${versionNumber}?`,
            onDisAgree: resetSeekConfirmation,
            onAgree: () => {
                resetSeekConfirmation();
                deleteDocumentVersion(documentId, versionId);
            },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }
    
    const deleteDocumentVersion = (documentId, versionId) => {
        setLoading(true);
        setMessage({ show: false, message: '', severity: '' });
        ICMSAxiosInterceptor.delete(`case/${caseId}/file/${documentId}/version/${versionId}`).then(response => {
            handleRefresh({ show: true, message: t('Document_Version_Deleted_Successfully'), severity: 'success' });
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({ show: true, message: error?.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' })
            } 
            setLoading(false); 
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }
    
    return (
        <div>
            <IcmsTableContainer>
                {canUpload && <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <ICMSButton id="upload-documents" color='primary' variant='contained' 
                        onClick={() => handleUpload(null)} style={{ marginBottom: '10px' }} size='small'>
                        <AiOutlineCloudUpload size='1.5rem' /> &nbsp;{t('Upload')}
                    </ICMSButton>
                </div>}
                <IcmsMuiTable stickyHeader aria-label="sticky table" data-testid="document-list-table">
                    <TableHead>
                        <IcmsTableRow>
                            {columns.map((column) => (
                                <StyledTableCell
                                    sx={{padding: '4px'}}
                                    key={column.id}
                                    align={column.align}
                                    style={{ width: column.minWidth }}
                                >
                                    {t(column.label)}
                                </StyledTableCell>
                            ))}
                        </IcmsTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            uploadedDocuments.map((document, index) => {
                                return (
                                    <DocumentRow caseId={caseId} selectedDocument={document} canUpload={canUpload} 
                                            deleteConfirmation={deleteConfirmation} handleUpload={handleUpload}
                                            deleteVersionConfirmation={deleteVersionConfirmation}/>
                                )
                            })
                        }
                        {uploadedDocuments.length === 0 && <IcmsTableRow>
                            <StyledTableCell colSpan={6} sx={{padding: '8px'}}>
                                {t('No_Record_Found')}
                            </StyledTableCell>
                        </IcmsTableRow>}
                    </TableBody>
                </IcmsMuiTable>
            </IcmsTableContainer>
            {showUploadDocument.show && <UploadDialog
                open={showUploadDocument.show}
                onClose={setShowUploadDocument}
                isMultiple={false}
                caseId={caseId}
                onSuccess={onSuccessfullFileUpload}
                icmsTablePk={icmsTablePk}
                icmsCaseDocId={showUploadDocument.icmsCaseDocId}
                documentTypeCd={documentTypeCd}
                documentCd={showUploadDocument.documentCd}
            />}
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} 
                    handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
            {seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}
            />}
        </div>
    )
}

export default DocumentList;

