import React, { 
    useState,
    useEffect 
}                                           from 'react';
import { useFormik }                        from "formik";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid
}                                           from '@mui/material';
import * as Yup                             from 'yup';
import { useTranslation }                   from 'react-i18next';
import ICMSLoader                           from '../../../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                         from '../../../../../common/icms-snackbar/IcmsSnackbar';
import { ICMSAxiosInterceptor }             from '../../../../../config/axios.interceptor';
import { SelectLabelController }            from '../../../../../common/formik-fields-components/SelectLabelController';
import { FormikLabelNumericFormat }         from '../../../../../common/formik-fields-components/FormikLabelNumericFormat';
import { TextAreaLabelController }          from '../../../../../common/formik-fields-components/TextAreaLabelController';
import { ICMSButton }                       from '../../../../../common/icms-styled-components/IcmsStyledComponents';

const ClaimAdjudicationDetail = ({caseId, claimantId, debtDefaultId, show, onClose, adjudicationDetail,
        onSuccess}) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});
    const [adjudications, setAdjudications] = useState([]);

    const AdjudicationValidationSchema = Yup.object().shape({
        adjudicationId: Yup.string().required(t('Adjudication_Is_Required')),
        amount: Yup.number().required(t('Amount_Is_Required')).min(0, "Invalid Amount")
    });

    const adjudicationFormik = useFormik({
        initialValues: {
            claimAdjudicationId: adjudicationDetail?.claimAdjudicationId ||  '',
            debtDefaultId: adjudicationDetail?.debtDefaultId ||  '',
            adjudicationId: adjudicationDetail?.adjudicationId ||  '',
            orderReferenceNumber: adjudicationDetail?.orderReferenceNumber ||  '',
            typeOfOrder: adjudicationDetail?.typeOfOrder || '',
            initiated: adjudicationDetail?.initiated || false,
            amount: adjudicationDetail?.amount || 0,
            description: adjudicationDetail?.description ||  null
        },
        validationSchema: AdjudicationValidationSchema,
        validateOnBlur: true,
        validateOnMount: true,
        validateOnChange: true
    })

    const getAdjudications = () => {
        setLoading(true);
        ICMSAxiosInterceptor.post(`case/${caseId}/adjudication/list`, {}).then(response => {
            setAdjudications(response.items);
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'})
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'})
            }
            setLoading(false);
        })
    }
    
    const handleSave = () => {
        if (!adjudicationFormik.values.claimAdjudicationId) {
            createAdjudication();
        } else {
            updateAdjudication();
        }
    }

    const createAdjudication = () => {
        setLoading(false);
        ICMSAxiosInterceptor.post(`case/${caseId}/claimant/${claimantId}/claim/${debtDefaultId}/adjudication-link/${adjudicationFormik.values.adjudicationId}`, 
                adjudicationFormik.values).then(response => {
            onSuccess({show: true, message: t('Adjudication_Added_Successfully'), severity: 'success'});
            onClose();
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'})
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'})
            }
            setLoading(false);
        })
    }

    const updateAdjudication = () => {
        setLoading(false);
        ICMSAxiosInterceptor.put(`case/${caseId}/claimant/${claimantId}/claim/${debtDefaultId}/adjudication-link/${adjudicationFormik.values.claimAdjudicationId}`, 
                adjudicationFormik.values).then(response => {
            onSuccess({show: true, message: t('Adjudication_Updated_Successfully'), severity: 'success'});
            onClose();
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'})
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'})
            }
            setLoading(false);
        })
    }

    useEffect(() => {
        getAdjudications();
    }, [])
    
    return (
        <Dialog open={show} fullWidth maxWidth="xs">
            <DialogTitle>{t('Adjudication_Details')}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <SelectLabelController
                            id="adjudicationId"
                            fieldName="adjudicationId"
                            formik={adjudicationFormik}
                            label={t('Select_Adjudication')}
                            size="small"
                            menuItems={adjudications}
                            required={true}
                            handleChange={adjudicationFormik.handleChange}
                            handleBlur={adjudicationFormik.handleBlur}
                            menuFieldId={'adjudicationId'}
                            menuFieldTitle={'orderReferenceNumber'}
                            valueField={'adjudicationId'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikLabelNumericFormat
                            id="amount"
                            fieldName="amount"
                            label={t("Amount")}
                            formik={adjudicationFormik}
                            size="small"
                            isThousandSeparator={true}
                            required={true}
                            handleChange={adjudicationFormik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextAreaLabelController
                            id={'description'}
                            fieldName='description'
                            label={t('Description')}
                            formik={adjudicationFormik}
                            minRows={2}
                            textAlign='start'
                            handleChange={adjudicationFormik.handleChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ICMSButton onClick={onClose} color="primary" size="small" variant='outlined'>
                    {t('Cancel')}
                </ICMSButton>
                <ICMSButton onClick={handleSave} color="primary" size="small" variant='contained' 
                        disabled={!adjudicationFormik.isValid || !adjudicationFormik.dirty}>
                    {t('Save')}
                </ICMSButton>
            </DialogActions>
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
        </Dialog>
    )
}

export default ClaimAdjudicationDetail