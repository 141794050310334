const BankAccountTypeCd = {
    SAVINGS_ACCOUNT: {
        codeCd: 1,
        description: 'Savings Account'
    },
    CURRENT_ACCOUNT: {
        codeCd: 2,
        description: 'Current Account'
    }
}

const AccountTypes = [
    {
        id: BankAccountTypeCd.SAVINGS_ACCOUNT.codeCd,
        title: BankAccountTypeCd.SAVINGS_ACCOUNT.description
    },
    {
        id: BankAccountTypeCd.CURRENT_ACCOUNT.codeCd,
        title: BankAccountTypeCd.CURRENT_ACCOUNT.description
    }
]

export { BankAccountTypeCd, AccountTypes }