import { ICMSAxiosInterceptor } from "../../../config/axios.interceptor";

const listBank = (partyId, handleSuccessCallback, handleErrorCallBack) => {

    ICMSAxiosInterceptor.get(`bank/${partyId}`).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const createBank = (bankResource, handleSuccessCallback, handleErrorCallBack) => {

    ICMSAxiosInterceptor.post(`/bank`, bankResource).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const updateBank = (bankId, bankResource, handleSuccessCallback, handleErrorCallBack) => {

    ICMSAxiosInterceptor.put(`/bank/${bankId}`, bankResource).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const getBank = (bankId, handleSuccessCallback, handleErrorCallBack) => {

    ICMSAxiosInterceptor.get(`/bank/${bankId}`).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const deleteBank = (bankId, handleSuccessCallback, handleErrorCallBack) => {

    ICMSAxiosInterceptor.delete(`/bank/accountId/${bankId}`).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

export { listBank, createBank, updateBank, getBank, deleteBank }