import { BsBuildingFillCheck } from "react-icons/bs";

export const LiquidationSideValue = {
    ASSET_SALE: 'liq-assetsale',
}

// left navigation order will take as in this list
export const LiquidationSideMenu = [
    {
        id: 'assetsale',
        value: LiquidationSideValue.ASSET_SALE,
        title: 'Asset Sale',
        icon: <BsBuildingFillCheck style={{ fontSize: '1.5rem' }} />
    },
]