import React, { useState, Fragment, useEffect }    from 'react';
import PropTypes                        from 'prop-types';
import {
    Dialog, DialogTitle, DialogContent,
    DialogActions, Grid, Paper,
    BottomNavigation
}                                       from '@mui/material';
import { useLocation, useNavigate }     from 'react-router-dom';
import { useTranslation }               from 'react-i18next';
import { BiSolidBank, BiSolidContact }  from "react-icons/bi";
import { 
    BsFilePersonFill, BsPersonFillGear 
}                                       from "react-icons/bs";
import { HiDocumentDuplicate }          from 'react-icons/hi';
import { FaFileSignature }              from "react-icons/fa6";
import { GiReceiveMoney }               from "react-icons/gi";
import { useFormik }                    from "formik";
import ClaimSteps                       from '../../claim-stepper/ClaimSteps';
import { ColorlibStepIconRoot }         from '../../claim-stepper/ClaimStyles';
import { CreditorDetails }              from '../../file-claim/fc-file-claim/creditor-details/CreditorDetails';
import UpdateCreditorDetails            from '../../file-claim/fc-file-claim/creditor-details/UpdateCreditorDetails';
import { 
    PartyType, RelationshipType, CaseManagementSideNav,
    IdentificationType, Section, ClaimType, ResClaimFormByRelationshipCd, Stage, LiqClaimFormByRelationshipCd, CaseType, AddressTypes, ClaimStatusType,
    ValLiqClaimFormByRelationshipCd,
    FTIRPResClaimFormByRelationshipCd
}                                       from '../../../common/GenericCodes';
import { CreditorValidationSchema }     from '../../file-claim/fc-file-claim/creditor-details/CreditorYupSchema';
import ICMSLoader                       from '../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                     from '../../../common/icms-snackbar/IcmsSnackbar';
import { getClaimant, createClaimant }  from '../../file-claim/file-claim-services/FileClaimServices'; 
import CreditorAuthRep                  from '../creditor-detail/CreditorAuthRep';
import CreditorClaim                    from '../creditor-detail/CreditorClaim';
import CreditorBank                     from '../creditor-detail/CreditorBank';
import CreditorDocuments                from '../creditor-detail/CreditorDocuments';
import { ICMSButton }                   from '../../../common/icms-styled-components/IcmsStyledComponents';
import CreditorClaimForm                from '../creditor-detail/CreditorClaimForm';
import { ICMSAxiosInterceptor } from '../../../config/axios.interceptor';
import CaseContactList from '../../contact/CaseContactList';

const ColorlibStepIcon = (props) => {
    const { active, completed, className } = props;

    const icons = {
        1: <BsFilePersonFill style={{ fontSize: '1.5rem' }} />,
        2: <BiSolidContact style={{ fontSize: '1.5rem' }} />,
        3: <BsPersonFillGear style={{ fontSize: '1.5rem' }} />,
        4: <GiReceiveMoney style={{ fontSize: '1.7rem' }} />,
        5: <BiSolidBank style={{ fontSize: '1.5rem' }} />,
        6: <HiDocumentDuplicate style={{ fontSize: '1.5rem' }} />,
        7: <FaFileSignature style={{ fontSize: '1.5rem' }} />
    };

    return (
        <ColorlibStepIconRoot
            ownerState={{ completed, active }}
            className={className}
        >
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const FileClaim = ({onClose, setLoginDetails}) => {
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const location = useLocation()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''})
    const [relationshipCd, setRelationShipCd] = useState(location.state.relationshipCd);
    const [caseStage, setCaseStage] = useState(location.state.caseStage);
    const [caseCd, setCaseCd] = useState(location.state.caseCd);
    const [caseId, setCaseId] = useState(location.state.caseId);
    const [insolvencementDate, setInsolvencementDate] = useState(location.state.insolvencementDate);
    const [claimCd, setClaimCd] = useState('');
    const [isHomeBuyer, setIsHomeBuyer] = useState(location.state.isHome);
    const [formUploaded, setFormUploaded] = useState(false);
    const [enabledSteps, setEnabledSteps] = useState({
        creditorDetails: false,
        contactDetails : false,
        authrizedPerson: false,
        claimDetails: false,
        bankDetails: false,
        documents: true,
        form: true
    })

    const steps = [
        "Creditor Details",
        "Contact Details",
        "Authorized Person Details",
        "Claim Details",
        "Bank Details",
        "Documents",
        "Form"
    ];

    const creditorFormik = useFormik({
        initialValues: {
            claimantId: null,
            relationshipCd: relationshipCd,
            partyCd: RelationshipType.EMPLOYEE_CLAIMANT === relationshipCd || 
                RelationshipType.WORKMAN_CLAIMANT === relationshipCd ? PartyType.INDIVIDUAL : PartyType.COMPANY,
            partyLocale: RelationshipType.EMPLOYEE_CLAIMANT === relationshipCd || 
                RelationshipType.WORKMAN_CLAIMANT === relationshipCd ? 'Individual' : 'Company',
            identificationCd: RelationshipType.EMPLOYEE_CLAIMANT === relationshipCd || 
                RelationshipType.WORKMAN_CLAIMANT === relationshipCd ? IdentificationType.PAN : IdentificationType.CIN,
            name: '',
            restOfName: '',
            identification: '',
            email: '',
            phoneNumber: '',
            extension: '+91',
            dobDoi: null,
            secured: null,
            realtedParty: null,
            natureOfRelatedParty: '',
            relatedUnderSection: null,
            amountOwedToDebtor: 0,
            contigent: 0,
            mutualDuesSetOff: 0,
            creditorClasses: null,
            addressCd: AddressTypes.registerdWithIBBI.addressCd,
            addressLine1: '',
            addressLine2: '',
            cityOrTown: '',
            state: '',
            postalCode: '',
            country: 'India',
            blockClaimModification: false,
            description: '',
            partyId: '',
            reconciliationAmount: 0,
            retentionOfTitleArrangement: '',
            preferredAuthorizedRepresentativeId: '',
            preferredAuthorizedRepresentative: '',
            regular: true,
            voteRight: '',
            loginUid: '',
            voteShare: '',
            coPartners: '',
            coPartnersClaim: 0,
            coPartnersConsideration: ''
        },
        validationSchema: CreditorValidationSchema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: true,
        onSubmit: () => {
        }
    })

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    }

    const handlePrevious = () => {
        setActiveStep(activeStep - 1);
    }

    const getCreditor = () => {
        setLoading(true);
        getClaimant(caseId, creditorFormik.values.claimantId, caseStage, (claimant) => {
            setLoading(false);
            creditorFormik.setValues({
                claimantId: claimant?.claimantId || '',
                partyNameId: claimant?.partyNameId || '',
                emailId: claimant?.emailId ||  '',
                emailContactCd: claimant?.emailContactCd || '',
                phoneNumberId: claimant?.phoneNumberId || '',
                phoneNumberContactCd: claimant?.phoneNumberContactCd || '',
                partyRelationshipId: claimant?.partyRelationshipId || '',
                partyCd: claimant?.partyCd || '',
                bankAccountId: claimant?.bankAccountId || '',
                bankAccountUuid: claimant?.bankAccountUuid || '',
                claimantCaseId: claimant?.claimantCaseId || '',
                icmsCaseId: claimant?.icmsCaseId || '',
                relationshipCd: claimant?.relationshipCd || '',
                partyLocale: claimant?.partyLocale || '',
                identificationId: claimant?.identificationId || '',
                identificationCd: claimant?.identificationCd || '',
                name: claimant?.name || '',
                restOfName: claimant?.restOfName || '',
                identification: claimant?.identification || '',
                email: claimant?.email || '',
                phoneNumber: claimant.phoneDetail?.nationalNumber || '',
                extension: claimant.phoneDetail?.countryCode ? '+' + claimant.phoneDetail?.countryCode : '+91',
                dobDoi: claimant?.dobDoi || '',
                secured: claimant?.secured || '',
                realtedParty: claimant?.realtedParty || '',
                natureOfRelatedParty: claimant?.natureOfRelatedParty || '',
                relatedUnderSection: claimant?.relatedUnderSection === Section.SECTION_21_2,
                amountOwedToDebtor: claimant?.amountOwedToDebtor || '',
                contigent: claimant?.contigent || '',
                mutualDuesSetOff: claimant?.mutualDuesSetOff || '',
                creditorClasses: (claimant?.creditorClasses && claimant.creditorClasses?.[0]) ? claimant.creditorClasses?.[0] : null,
                addressCd: claimant?.addressCd || '',
                addressLine1: claimant?.addressLine1 || '',
                addressLine2: claimant?.addressLine2 || '',
                cityOrTown: claimant?.cityOrTown || '',
                state: claimant?.state || '',
                postalCode: claimant?.postalCode || '',
                country: claimant?.country || '',
                blockClaimModification: claimant?.blockClaimModification || '',
                description: claimant?.description || '',
                partyId: claimant?.partyId || '',
                partyAddressId: claimant?.partyAddressId || '',
                addressId: claimant?.addressId || '',
                reconciliationAmount: claimant?.reconciliationAmount || 0,
                retentionOfTitleArrangement: claimant?.retentionOfTitleArrangement || '',
                preferredAuthorizedRepresentativeId: claimant?.preferredAuthorizedRepresentativeId || '', 
                preferredAuthorizedRepresentative: claimant?.preferredAuthorizedRepresentative || '', 
                regular: claimant?.regular || '', 
                voteRight: claimant?.voteRight || '',
                loginUid: claimant?.loginUid || '',
                voteShare: claimant?.voteShare || '',
                coPartners: claimant?.coPartners || '',
                coPartnersClaim: claimant?.coPartnersClaim || 0,
                coPartnersConsideration: claimant?.coPartnersConsideration || '',
            });
        },
        (error) => {
            setLoading(false);
            setMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

    const handleSaveCreditor = () => {
        let payload = {
            relationshipCd: creditorFormik.values.relationshipCd,
            partyCd: creditorFormik.values.partyCd,
            partyLocale: creditorFormik.values.partyLocale,
            identificationCd: creditorFormik.values.identificationCd,
            name: creditorFormik.values.name,
            restOfName: creditorFormik.values.restOfName,
            identification: creditorFormik.values.identification,
            email: creditorFormik.values.email,
            phoneNumber: creditorFormik.values.extension + '' + creditorFormik.values.phoneNumber,
            dobDoi: creditorFormik.values.dobDoi,
            secured: creditorFormik.values.secured,
            realtedParty: creditorFormik.values.realtedParty,
            natureOfRelatedParty: creditorFormik.values.natureOfRelatedParty,
            relatedUnderSection: creditorFormik.values.relatedUnderSection ? Section.SECTION_21_2 : '',
            amountOwedToDebtor: creditorFormik.values.amountOwedToDebtor,
            contigent: creditorFormik.values.contigent,
            mutualDuesSetOff: creditorFormik.values.mutualDuesSetOff,
            creditorClasses: creditorFormik.values.creditorClasses ? [creditorFormik.values.creditorClasses] : [],
            addressCd: creditorFormik.values.addressCd,
            addressLine1: creditorFormik.values.addressLine1,
            addressLine2: creditorFormik.values.addressLine2,
            cityOrTown: creditorFormik.values.cityOrTown,
            state: creditorFormik.values.state,
            postalCode: creditorFormik.values.postalCode,
            country: creditorFormik.values.country,
            blockClaimModification: creditorFormik.values.blockClaimModification,
            description: creditorFormik.values.description,
            partyId: creditorFormik.values.partyId,
            reconciliationAmount: creditorFormik.values.reconciliationAmount,
            retentionOfTitleArrangement: creditorFormik.values.retentionOfTitleArrangement,
            preferredAuthorizedRepresentativeId: creditorFormik.values.preferredAuthorizedRepresentativeId, 
            preferredAuthorizedRepresentative: creditorFormik.values.preferredAuthorizedRepresentative, 
            regular: creditorFormik.values.regular, 
            voteRight: creditorFormik.values.voteRight,
            loginUid: creditorFormik.values.loginUid,
            coPartners: creditorFormik.values.coPartners,
            coPartnersClaim: creditorFormik.values.coPartnersClaim,
            coPartnersConsideration: creditorFormik.values.coPartnersConsideration,
        }
        setLoading(true);
        createClaimant(caseId, payload,
            (response) => {
                setLoading(false);
                creditorFormik.setFieldValue('claimantId', response.claimantId);
                creditorFormik.setFieldValue('partyId', response.partyId);
                creditorFormik.setFieldValue('relatedUnderSection', response.relatedUnderSection === Section.SECTION_21_2);
                creditorFormik.setFieldValue('partyRelationshipId', response.partyRelationshipId);
                handleNext();
                setMessage({ show: true, message: t('Creditor_Created_Succesfully'), severity: 'success' });
                setEnabledSteps(prevState => ({
                    ...prevState,
                    creditorDetails: true
                }))
            },
            (error) => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            }
        )
    }

    const handleClose = () => {
        navigate(`/details/select-claim/${caseId}`);
    }

    const handleFinish = () => {
        ICMSAxiosInterceptor.get('login-user').then(function (response) {
            if (response) {
                setLoginDetails(response);
                navigate('/details/case-management', {
                    state: {
                        caseType: CaseManagementSideNav.claimant.key
                    }
                });
            }
        }).catch(function (error) {
            setLoading(false);
            setMessage({ show: true, message: (error.message ? error.message : error), severity: 'error' });
        });
    }

    const submitClaim = () => {
        let claimStatusCd = (caseStage === Stage.RESOLUTION ? ClaimStatusType.RES_CLAIM_SUBMITTED : 
                (caseStage === Stage.LIQUIDATION ? ClaimStatusType.LIQ_CLAIM_SUBMITTED : null))
        if (claimStatusCd) {
            setLoading(true);
            ICMSAxiosInterceptor.patch(`case/${caseId}/claimant/${creditorFormik.values.claimantId}/case-stage/${caseStage}/status/${claimStatusCd}`, {}).then(response => {
                setMessage({ show: true, message: t('Your_Claim_Submitted_Successfully'), severity: 'error' });
                setTimeout(() => {
                    setLoading(false);
                    handleClose();
                }, 5000);
            }).catch(error => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            })
        }
    }

    const getActionByStep = (step) => {
        switch (step) {
            case 0: 
                return (<Grid container>
                    <Grid item xs={12}>
                        {!creditorFormik.values.claimantId && <ICMSButton onClick={handleSaveCreditor} color="primary" size="small" variant='contained'
                                sx={{float: 'right', marginRight: '2px'}} disabled={!creditorFormik.isValid}>
                            {t('Save_And_Next')}
                        </ICMSButton>}
                        {creditorFormik.values.claimantId && <ICMSButton onClick={handleNext} color="primary" size="small" 
                                variant='contained' sx={{float: 'right', marginRight: '2px'}} disabled={!enabledSteps.creditorDetails}>
                            {t('Next')}
                        </ICMSButton>}
                        <ICMSButton onClick={handleClose} color="primary" size="small" variant='outlined' sx={{float: 'right', marginRight: '2px'}}>
                            {creditorFormik.values.claimantId ? t('Close') : t('Cancel')}
                        </ICMSButton>
                    </Grid>
                </Grid>)
            case 1: 
                return (<Grid container>
                    <Grid item xs={12}>
                        <ICMSButton onClick={handlePrevious} color="primary" size="small" variant='outlined'>
                            {t('Previous')}
                        </ICMSButton>
                        <ICMSButton onClick={handleNext} color="primary" size="small" variant='contained' 
                                sx={{float: 'right', marginRight: '2px'}} disabled={!enabledSteps.contactDetails}>
                            {t('Next')}
                        </ICMSButton>
                        <ICMSButton onClick={handleFinish} color="primary" size="small" variant='outlined' sx={{float: 'right', marginRight: '2px'}}>
                            {t('Close')}
                        </ICMSButton>
                    </Grid>
                </Grid>)
            case 2: 
                return (<Grid container>
                    <Grid item xs={12}>
                        <ICMSButton onClick={handlePrevious} color="primary" size="small" variant='outlined'>
                            {t('Previous')}
                        </ICMSButton>
                        <ICMSButton onClick={handleNext} color="primary" size="small" variant='contained' 
                                sx={{float: 'right', marginRight: '2px'}} disabled={!enabledSteps.authrizedPerson}>
                            {t('Next')}
                        </ICMSButton>
                        <ICMSButton onClick={handleFinish} color="primary" size="small" variant='outlined' sx={{float: 'right', marginRight: '2px'}}>
                            {t('Close')}
                        </ICMSButton>
                    </Grid>
                </Grid>)
            case 3:
                return (<Grid container>
                    <Grid item xs={12}>
                        <ICMSButton onClick={handlePrevious} color="primary" size="small" variant='outlined'>
                            {t('Previous')}
                        </ICMSButton>
                        <ICMSButton onClick={handleNext} color="primary" size="small" variant='contained' 
                                sx={{float: 'right', marginRight: '2px'}} disabled={!enabledSteps.claimDetails}>
                            {t('Next')}
                        </ICMSButton>
                        <ICMSButton onClick={handleFinish} color="primary" size="small" variant='outlined' sx={{float: 'right', marginRight: '2px'}}>
                            {t('Close')}
                        </ICMSButton>
                    </Grid>
                </Grid>)
            case 4:
                return (<Grid container>
                    <Grid item xs={12}>
                        <ICMSButton onClick={handlePrevious} color="primary" size="small" variant='outlined'>
                            {t('Previous')}
                        </ICMSButton>
                        <ICMSButton onClick={handleNext} color="primary" size="small" variant='contained' 
                                sx={{float: 'right', marginRight: '2px'}} disabled={!enabledSteps.bankDetails}>
                            {t('Next')}
                        </ICMSButton>
                        <ICMSButton onClick={handleFinish} color="primary" size="small" variant='outlined' sx={{float: 'right', marginRight: '2px'}}>
                            {t('Close')}
                        </ICMSButton>
                    </Grid>
                </Grid>)
            case 5:
                return (<Grid container>
                    <Grid item xs={12}>
                        <ICMSButton onClick={handlePrevious} color="primary" size="small" variant='outlined'>
                            {t('Previous')}
                        </ICMSButton>
                        <ICMSButton onClick={handleNext} color="primary" size="small" variant='contained' 
                                sx={{float: 'right', marginRight: '2px'}} disabled={!enabledSteps.documents}>
                            {t('Next')}
                        </ICMSButton>
                        <ICMSButton onClick={handleFinish} color="primary" size="small" variant='outlined' sx={{float: 'right', marginRight: '2px'}}>
                            {t('Close')}
                        </ICMSButton>
                    </Grid>
                </Grid>)
            case 6:
                return (<Grid container>
                    <Grid item xs={12}>
                        <ICMSButton onClick={handlePrevious} color="primary" size="small" variant='outlined'>
                            {t('Previous')}
                        </ICMSButton>
                        <ICMSButton onClick={handleFinish} color="primary" size="small" variant='outlined' sx={{float: 'right', marginRight: '2px'}}>
                            {t('Close')}
                        </ICMSButton>
                        <ICMSButton onClick={handleFinish} color="primary" size="small" variant='contained' 
                                sx={{float: 'right'}} disabled={!formUploaded}>
                            {t('Finish')}
                        </ICMSButton>
                    </Grid>
                </Grid>)
        }
    }

    useEffect(() => {
        if (caseCd === CaseType.CIRP || caseCd === CaseType.FTIRP) {
            switch (location.state.relationshipCd) {
                case RelationshipType.SUPPLIER_CLAIMANT : creditorFormik.values.partyCd == PartyType.GOVT_BODY ?  setClaimCd(ClaimType.RES_GOVT_BODY) : setClaimCd(ClaimType.RES_SUPPLIER) 
                    break;
                case RelationshipType.EMPLOYEE_CLAIMANT : setClaimCd(ClaimType.RES_EMPLOYEE) 
                    break;
                case RelationshipType.WORKMAN_CLAIMANT : setClaimCd(ClaimType.RES_WORKMAN) 
                    break;
                case RelationshipType.OTHER_CREDITOR_CLAIMANT : setClaimCd(ClaimType.RES_OTHER_CREDITOR) 
                    break;
            }
        } else if (caseCd === CaseType.LIQUIDATION || caseCd === CaseType.VAL_LIQUIDATION) {
            switch (location.state.relationshipCd) {
                case RelationshipType.SUPPLIER_CLAIMANT : creditorFormik.values.partyCd == PartyType.GOVT_BODY ? setClaimCd(ClaimType.LIQ_GOVT_BODY) : setClaimCd(ClaimType.LIQ_SUPPLIER) 
                    break;
                case RelationshipType.EMPLOYEE_CLAIMANT : setClaimCd(ClaimType.LIQ_EMPLOYEE) 
                    break;
                case RelationshipType.WORKMAN_CLAIMANT: setClaimCd(ClaimType.LIQ_WORKMAN) 
                    break;
                case RelationshipType.OTHER_STAKEHOLDER_CLAIMANT : setClaimCd(ClaimType.LIQ_STAKEHOLDER) 
                    break;
            }
        }
    }, [caseCd, location.state.relationshipCd])

    return (
        <div>
            <Paper sx={{padding: '1.5rem', margin: '1rem'}}>
                <ClaimSteps steps={steps} activeStep={activeStep} ColorlibStepIcon={ColorlibStepIcon}/>
                {activeStep === 0 && <Fragment>
                    {!creditorFormik.values.claimantId && <CreditorDetails creditorFormik={creditorFormik} classOfCreditorMenu={[]}
                        relationshipType={relationshipCd} claimSubmission={true} caseStage={caseStage} isHomeBuyer={isHomeBuyer}
                        setEnabledSteps={setEnabledSteps} showSelf={true}/>}
                    {creditorFormik.values.claimantId && <UpdateCreditorDetails caseId={caseId} creditorFormik={creditorFormik} 
                        restore={getCreditor} canEdit={true} caseStage={caseStage} claimSubmission={true}
                        canUpdateVoteShare={false} isHomeBuyer={isHomeBuyer} setEnabledSteps={setEnabledSteps}/>}
                </Fragment>}
                {activeStep === 1 && <CaseContactList caseId={caseId} partyId={creditorFormik.values.partyId} 
                        relationshipId={creditorFormik.values.partyRelationshipId} canEdit={true} setEnabledSteps={setEnabledSteps}
                        showActionsInBottom={true}/>}
                {activeStep === 2 && <CreditorAuthRep caseId={caseId} creditorId={creditorFormik.values.claimantId}
                    caseStage={caseStage} canEdit={true} isIndividual={creditorFormik.values.partyCd === PartyType.INDIVIDUAL} setEnabledSteps={setEnabledSteps}/>}
                {activeStep === 3 && <CreditorClaim caseId={caseId} creditorId={creditorFormik.values.claimantId}
                    relationShipCd={relationshipCd}  claimCd={claimCd} caseCd={caseCd} claimSubmission={true}
                    homeBuyer={isHomeBuyer} canEdit={true} caseStage={caseStage} setEnabledSteps={setEnabledSteps}
                    insolvencementDate={insolvencementDate ? insolvencementDate : null}/>}
                {activeStep === 4 && <CreditorBank caseId={caseId} creditorId={creditorFormik.values.claimantId}
                        partyId={creditorFormik.values.partyId} bankId={creditorFormik.values.bankAccountUuid}
                        onBankChange={getCreditor} caseStage={caseStage} canEdit={true} setEnabledSteps={setEnabledSteps}/>}
                {activeStep === 5 && <CreditorDocuments caseId={caseId} creditorId={creditorFormik.values.claimantId}
                    claimCd={claimCd} caseStage={caseStage} canDelete={true} canUpload={true}
                    canDownload={true} setEnabledSteps={setEnabledSteps}/>}
                {activeStep === 6 && <CreditorClaimForm caseId={caseId} creditorId={creditorFormik.values.claimantId} 
                        claimCd={claimCd} caseStage={caseStage} caseCd={caseCd} canEdit={true}
                        setFormUploaded={setFormUploaded} creditorName={creditorFormik.values?.name}
                        formName={caseStage === Stage.RESOLUTION ?
                            isHomeBuyer && caseCd !== CaseType.FTIRP ? ResClaimFormByRelationshipCd[relationshipCd+'homeBuyer'] : 
                            (caseCd == CaseType.CIRP ? ResClaimFormByRelationshipCd[relationshipCd] : FTIRPResClaimFormByRelationshipCd[relationshipCd]) 
                            : caseCd === CaseType.LIQUIDATION ? LiqClaimFormByRelationshipCd[relationshipCd] : 
                            ValLiqClaimFormByRelationshipCd[relationshipCd]} 
                        setEnabledSteps={setEnabledSteps}/>}
            </Paper>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, marginTop: 1 }} elevation={3}>
                <BottomNavigation showLabels >
                    <Grid container direction="row" alignItems="center" sx={{padding: '0px 8px'}}>
                        <Grid item xs={12}>
                            {getActionByStep(activeStep)}
                        </Grid>
                    </Grid>
                </BottomNavigation>
            </Paper>
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} 
                    handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
        </div>
    )
}

export default FileClaim;