import React, { Fragment, useState, useEffect }     from 'react';
import { useFormik }                                from "formik";
import { Stack, Grid, Button, Typography }                      from '@mui/material';
import { useTranslation }                           from 'react-i18next';
import { SelectLabelController }                    from "../../../../common/formik-fields-components/SelectLabelController"
import ICMSLoader                                   from '../../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                                 from '../../../../common/icms-snackbar/IcmsSnackbar';
import { BankAccountType, Stage }                          from '../../../../common/GenericCodes';
import { ICMSAxiosInterceptor }                     from '../../../../config/axios.interceptor';
import BankAccountFields                            from '../../../../common/bank/BankAccountFields';
import { BankValidationSchema }                     from '../creditor-details/CreditorYupSchema';
import { ICMSButton }                               from '../../../../common/icms-styled-components/IcmsStyledComponents';

const ClaimantBankDetail = ({caseId, claimantId, partyId, bankId, caseStage, onBankChange, canEdit, setEnabledSteps}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isChange, setIsChange] = useState(bankId ? false : true);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [bankAccountId, setBankAccountId] = useState(bankId);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});

    const bankFormik = useFormik({
        initialValues: {
            bankAccountUuid: null,
            branchId: '',
            partyId: partyId,
            accountHolderName: '',
            accountNumber: '',
            bankAccountCd: BankAccountType.SAVINGS_ACCOUNT.codeCd,
            bankName: '',
            branchName: '',
            ifsc: ''
        },
        validationSchema: BankValidationSchema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: true
    })

    const getBankListByPartyId = () => {
        setLoading(true);
        ICMSAxiosInterceptor.get(`bank-account/list/${partyId}`).then(banks => {
            setBankAccounts(banks.map(bank => {
                return {
                    bankAccountUuid: bank.bankAccountUuid,
                    accountNumber: bank.accountNumber,
                    ifsc: bank.ifsc,
                    branchId: bank.branchId,
                    accountHolderName: bank.accountHolderName,
                    bankAccountCd: bank.bankAccountCd,
                    bankName: bank.bankName,
                    branchName: bank.branchName,
                }
            }))
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error?.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const handleSave = () => {
        if (bankFormik.values.bankAccountUuid) {
            linkBank(bankFormik.values.bankAccountUuid);
        } else {
            createBank();
        }
    }

    const createBank = () => {
        setLoading(true);
        ICMSAxiosInterceptor.post(`bank-account/${partyId}`, bankFormik.values).then(response => {
            linkBank(response.bankAccountUuid);
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error?.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const linkBank = (bankAccountUuid) => {
        setLoading(true);
        ICMSAxiosInterceptor.patch(`case/${caseId}/claimant/${claimantId}/link-bank/${bankAccountUuid}/case-stage/${caseStage}`, {})
                .then(response => {
            setLoading(false);
            setIsChange(false);
            setBankAccountId(bankAccountUuid);
            if (onBankChange) onBankChange();
            if (setEnabledSteps) {
                setEnabledSteps(prevState => ({
                    ...prevState,
                    bankDetails: true
                }))
            }
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error?.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const handleUpdate = () => {
        if (isEdit) {
            setLoading(true);
            ICMSAxiosInterceptor.put(`bank-account/${bankFormik.values.bankAccountUuid}`, bankFormik.values).then(response => {
                handleCancel();
                setLoading(false);
            }).catch(error => {
                if (error?.message) {
                    setMessage({show: true, message: error?.message, severity: 'error'});
                } else {
                    setMessage({show: true, message: t('Try_Again'), severity: 'error'});
                }
                setLoading(false);
            })
        } else if(isChange) {
            handleSave()
        }
    }

    const handleCancel = () => {
        setIsEdit(false);
        setIsChange(false);
        if (bankAccountId) {
            getBankAccountDetails(bankAccountId);
        }
    }

    const handleEdit = () => {
        setIsEdit(true);
    }

    const handleChange = () => {
        setIsChange(true);
        bankFormik.setValues({
            bankAccountUuid: '',
            branchId: '',
            partyId: partyId,
            accountHolderName: '',
            accountNumber: '',
            bankAccountCd: '',
            bankName: '',
            branchName: '',
            ifsc: '',
        })
    }

    const getBankAccountDetails = (bankAccountUuid) => {
        setLoading(true);
        ICMSAxiosInterceptor.get(`bank-account/${bankAccountUuid}`).then(response => {
            bankFormik.setValues({
                bankAccountUuid: response.bankAccountUuid,
                branchId: response.branchId,
                partyId: response.partyId,
                accountHolderName: response.accountHolderName,
                accountNumber: response.accountNumber,
                bankAccountCd: response.bankAccountCd,
                bankName: response.bankName,
                branchName: response.branchName,
                ifsc: response.ifsc,
            })
            if (setEnabledSteps) {
                setEnabledSteps(prevState => ({
                    ...prevState,
                    bankDetails: true
                }))
            }
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error?.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const onSelectBankAccount = (bankAccount) => {
        let selectedBank = bankAccounts.find(bank => bank.bankAccountUuid === bankAccount.target.value);
        bankFormik.setValues({
            bankAccountUuid: selectedBank.bankAccountUuid,
            branchId: selectedBank.branchId,
            partyId: partyId,
            accountHolderName: selectedBank.accountHolderName,
            accountNumber: selectedBank.accountNumber,
            bankAccountCd: selectedBank.bankAccountCd,
            bankName: selectedBank.bankName,
            branchName: selectedBank.branchName,
            ifsc: selectedBank.ifsc,
        })
    }

    useEffect(() => {
        if (bankAccountId) {
            getBankAccountDetails(bankAccountId);
        }
        getBankListByPartyId();
    }, [])
    

    return (
        <Fragment>
            <Stack px={2} spacing={1}>
                <Typography>{caseStage == Stage.RESOLUTION ? t('Resolution_Claim_Form_Bank_Account_Details_Helper_Text') : 
                        t('Liquidation_Claim_Form_Bank_Account_Details_Helper_Text')}</Typography>
                {((isChange && canEdit) && bankAccounts.length > 0) && <Grid container>
                    <Grid item xs={4}>
                        <SelectLabelController
                            id="bankAccountUuid"
                            fieldName="bankAccountUuid"
                            label={t("Select_Bank_Account")}
                            formik={bankFormik}
                            size="small"
                            menuItems={bankAccounts}
                            menuFieldId={'bankAccountUuid'}
                            menuFieldTitle={'accountNumber'}
                            valueField={'bankAccountUuid'}
                            handleChange={onSelectBankAccount}
                        />  
                    </Grid>
                </Grid>}
                <BankAccountFields bankFormik={bankFormik} disabled={(!isEdit && !isChange) || !canEdit} 
                    showIFSCSearch={(isEdit || isChange) && canEdit}/>
                <Stack direction="row" justifyContent='end' spacing={2} mt={4}>
                    {(isEdit || isChange) && bankAccountId && <ICMSButton color='primary' variant="outlined" size="small" onClick={handleCancel}>
                        {t('Cancel')}
                    </ICMSButton>}
                    {(canEdit && (isEdit || isChange)) && <ICMSButton color='primary' variant="contained" size="small" 
                            onClick={bankAccountId ? handleUpdate : handleSave } disabled={!bankFormik.isValid}>
                        {t('Save')}
                    </ICMSButton>}
                    {(bankAccountId && !isEdit && !isChange) && canEdit && <ICMSButton color='primary' variant="contained" size="small" 
                            onClick={handleChange}>
                        {t('Change')}
                    </ICMSButton>}
                    {(bankAccountId && !isEdit && !isChange) && canEdit && <ICMSButton color='primary' variant="contained" size="small" 
                            onClick={handleEdit}>
                        {t('Edit')}
                    </ICMSButton>}
                </Stack>
            </Stack>
            {loading && <ICMSLoader show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </Fragment>
    )
}

export default ClaimantBankDetail