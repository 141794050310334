import { Button, Card, CardContent, Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { AuthAxiosInterceptor } from '../../../config/axios.interceptor';
import { EMAIL } from '../../GenericCodes';
import ICMSReCaptcha from '../../ICMSReCaptcha';
import { FormikPasswordConfirmField, FormikPasswordField } from '../../formik-fields-components/FormikFieldComponents';
import ICMSLoader from '../../icms-loader/ICMSLoader';
import IcmsSnackbar from '../../icms-snackbar/IcmsSnackbar';
import EmailPhoneToggle from '../EmailPhoneToggle';
import ChangePasswordWithOtp from './ChangePasswordWithOtp';


const ForgotValidationSchema = Yup.object().shape({
    isEmailOrMobile: Yup.string(),
    newPassword: Yup.string()
        .required('Please Enter your password')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
    confirmPassword: Yup.string().required('Confirm Password Required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
});

const ChangePassword = (props) => {
        const {t} = useTranslation(); 
        const [message, setMessage] = useState({
            show: false,
            message: null,
            severity: null
        })
        const [loading, setLoading] = useState(false);
        const [captcha, setCaptcha] = useState('');
        const [isRefresh, setIsRefresh] = useState(false);
        const [isEmail, setIsEmail] = useState(EMAIL);
        const [openOtpDialog, setOpenOtpDialog] = useState(false);

    const changeFormik = useFormik({
        initialValues:{
            isEmailOrMobile: "email",
            newPassword:'',
            confirmPassword:"",
        },
        onSubmit: values => {
            sendOtp()
        },
        validationSchema: ForgotValidationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true,
    })

    const sendOtp = () => {
            setLoading(true)
            AuthAxiosInterceptor.patch(`user/request-otp?recaptcha=${captcha}&purpose=reset-password`, {
                loginId : isEmail === EMAIL ? props.contextValues.email : props.contextValues.phoneNumber,
                password: changeFormik.values.newPassword
            }).then(response => {
                setMessage({ show: true, message: t('OTP_Sent_Successfully'), severity: 'success' });
                setLoading(false)
                setOpenOtpDialog(true)
            }).catch((error) => {
                setLoading(false)
                if (error.message) {
                    if(error.message.includes('ReCaptcha Expired')) {
                        setIsRefresh(true);
                        setMessage({ show: true, message: t("ReCaptcha_Expired"), severity: 'error' });
                    } else {
                        setMessage({ show: true, message: error.message, severity: 'error' });
                    }
                }
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 1000);
            })
    }

    const handleChange = (event) => {
        changeFormik.setFieldTouched([event.target.id], true);
        changeFormik.handleChange(event);
    }

    const handleCancel = () => {
        props.setHandleChangePage('details')
    }

  return ( 
    <Fragment>
        <Stack justifyContent='center' alignItems='center' alignContent='center'>
            <Card elevation={10} style={{borderRadius:'10px', padding:'5px'}}>
                {/* <div style={{width:'20%'}}>{leafIcon}</div> */}
                <CardContent>
                    <Stack direction='column' spacing={3} width='100%'>
                        <h3 style={{textAlign:'center'}}>Change Password</h3>
                    <FormikPasswordField
                        id="newPassword"
                        fieldName="newPassword"
                        label={t("New_Password")}
                        formik={changeFormik}
                        handleChange={handleChange}
                        // disabled={changeFormik.values.mfaEnabled}
                    />
                    <FormikPasswordConfirmField
                        id="confirmPassword"
                        fieldName="confirmPassword"
                        label={t("Confirm_Password")}
                        formik={changeFormik}  
                        handleChange={handleChange}
                    />
                    </Stack>
                    <EmailPhoneToggle isToggle={isEmail} setIsToggle={setIsEmail} />
                    <Stack direction='row' justifyContent='space-between'>
                    {/* <Button onClick={handleCancel} variant='outlined'>{t("Back_To_Dashboard")}</Button> */}
                    <Button onClick={changeFormik.handleSubmit} variant='contained'>{t("Request_Otp")}</Button>
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
        {openOtpDialog && <ChangePasswordWithOtp open={openOtpDialog}  username={isEmail === EMAIL ? props.contextValues.email : props.contextValues.phoneNumber}
         isEmailOrMobile={isEmail === EMAIL? 'email': 'mobile'} password={changeFormik.values.newPassword} setOpenOtpDialog={setOpenOtpDialog}/>}
        {loading && <ICMSLoader show={loading}/>}
        {!isRefresh && <ICMSReCaptcha key="login-withmfa" captcha={setCaptcha} refresh={setIsRefresh} />}
        {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
        
    </Fragment>
  )
}

export default ChangePassword