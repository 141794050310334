import React, { Fragment, useState }        from 'react';
import { useFormik }                        from "formik";
import EditIcon                             from '@mui/icons-material/Edit';
import CloseIcon                            from '@mui/icons-material/Close';
import CheckIcon                            from '@mui/icons-material/Check';
import DeleteIcon                           from '@mui/icons-material/Delete';
import { IconButton, Tooltip }              from '@mui/material';
import { useTranslation }                   from 'react-i18next';
import {
    IcmsTableRow,
    StyledTableCell
}                                           from '../generic-styles/NavPageContainerStyles';
import { FormikTextField }                  from '../formik-fields-components/FormikFieldComponents';
import { ContactType }                      from '../GenericCodes';
import * as Yup                             from 'yup';
import { setFormikValueWithLowerCase } from '../utils/Utils';

const EmailValidationSchema = Yup.object().shape({
    contact: Yup.string().email('Invalid Email ID').required('Email ID is Required')
});

const EmailRow = ({partyId, emailDetails, handleCreate, handleUpdate, handleDelete, edit,
        handleRefresh, canModify, canDelete}) => {
    const { t } = useTranslation();
    const [isEdit, setIsEdit] = useState(edit);
    const emailFormik = useFormik({
        initialValues: {
            contact: emailDetails.contact ||  '',
            contactId: emailDetails.contactId || '',
            partyContactId: emailDetails.partyContactId || ''
        },
        validationSchema: EmailValidationSchema,
        validateOnBlur: true,
        validateOnMount: true,
        validateOnChange: true
    })

    const createEmail = () => { 
        let payload = {
            partyId: partyId,
            contactCd: ContactType.EMAIL,
            contact: emailFormik.values.contact,
            email: true,

        }
        handleCreate(payload);
    }

    const updateEmail = () => { 
        let payload = {
            partyId: partyId,
            contactCd: ContactType.EMAIL,
            contactId: emailFormik.values.contactId,
            partyContactId: emailFormik.values.partyContactId,
            contact: emailFormik.values.contact,
            email: true
        }
        handleUpdate(payload);
    }

    const deleteEmail = () => { 
        handleDelete(emailFormik.values.partyContactId);
    }

    const editEmail = () => {
        setIsEdit(true);
    }

    const cancelEditEmail = () => {
        setIsEdit(false);
        handleRefresh();
    }

    return (
        <IcmsTableRow colSpan={2} sx={{verticalAlign: isEdit ? 'top' : 'middle'}}>
            <StyledTableCell sx={{padding: '4px'}}>
                {!isEdit && emailFormik.values.contact}
                {isEdit && <FormikTextField     
                    id="contact"
                    fieldName="contact"
                    size='small'
                    formik={emailFormik} 
                    handleChange={(event) => setFormikValueWithLowerCase(event, emailFormik)}
                />}
            </StyledTableCell>
            <StyledTableCell sx={{padding: '4px', textAlign: 'center'}}>
                {( !isEdit && canModify ) && <Tooltip title={t('Edit')}>
                    <IconButton size='small' color='primary' onClick={editEmail}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>}
                {( !isEdit && canDelete ) && <Tooltip title={t('Delete')}>
                    <IconButton size='small' color='secondary' onClick={deleteEmail}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>}
                {isEdit && <Fragment>
                    <Tooltip title={t('Save')}>
                        <IconButton size='small' color='success'
                                onClick={emailDetails.partyContactId ? updateEmail : createEmail}
                                disabled={!emailFormik.isValid}>
                            <CheckIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Cancel')}>
                        <IconButton size='small' color='secondary' onClick={cancelEditEmail}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Fragment>}
            </StyledTableCell>
        </IcmsTableRow>
    )
}

export default EmailRow