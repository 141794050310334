import { Card, Stack } from '@mui/material';
import React, { Fragment, useRef, useState } from 'react';
import { BiSolidBank } from "react-icons/bi";
import { BsFilePersonFill, BsPersonFillGear } from "react-icons/bs";
import { HiDocumentDuplicate } from 'react-icons/hi';
import { FaFileSignature } from "react-icons/fa6";
import { GiReceiveMoney } from "react-icons/gi";
import ClaimStepContent from '../../claim-stepper/ClaimStepContent';
import ClaimSteps from '../../claim-stepper/ClaimSteps';
import { ColorlibStepIconRoot } from '../../claim-stepper/ClaimStyles';
import { ClaimList } from './claim-list/ClaimList';
import { BankList } from './bank-list/BankList';
import GenerateUploadForm from './generate-upload-form/GenerateUploadForm';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RelationshipCd } from './FileClaimGenericCodes';
import { useTranslation } from 'react-i18next';
import Creditor from './creditor-details/Creditor';
import AuthRepContainer from './auth-rep/AuthRepContainer';
import ClaimDocuments   from '../fc-file-claim/claim-documents/ClaimDocuments';

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <BsFilePersonFill style={{ fontSize: '1.5rem' }} />,
        2: <BsPersonFillGear style={{ fontSize: '1.5rem' }} />,
        3: <GiReceiveMoney style={{ fontSize: '1.7rem' }} />,
        4: <BiSolidBank style={{ fontSize: '1.5rem' }} />,
        5: <HiDocumentDuplicate style={{ fontSize: '1.5rem' }} />,
        6: <FaFileSignature style={{ fontSize: '1.5rem' }} />
    };

    return (
        <ColorlibStepIconRoot
            ownerState={{ completed, active }}
            className={className}
        >
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const FCFileClaim = (props) => {
    const { width, left } = { ...props, width: props.width || '100%', left: props.left || '0px'}
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const param = useParams();
    const isHome = param.isHome === "true";
    const relationShipCd = Number(param.cd);
    const activeStep = Number(param.activeStep);

    const getLabel = (value) => {
        switch (value) {
            case RelationshipCd.FINANCIAL_CLAIMANT: if (isHome) return t('Financial_Creditor_HomeBuyer'); else return t('Financial_Creditor');
            case RelationshipCd.SUPPLIER_CLAIMANT: return t('Operational_Creditor_Supplier');
            case RelationshipCd.EMPLOYEE_CLAIMANT: return t('Operational_Creditor_Employee');
            case RelationshipCd.WORKMAN_CLAIMANT: return t('Operational_Creditor_Workman');
            case RelationshipCd.OTHER_CREDITOR_CLAIMANT: return t('Other_Creditor');
            case RelationshipCd.OTHER_STAKEHOLDER_CLAIMANT: return t('Other_Stakeholder');
            default: return t('Financial_Creditor');
        }
    }

    const getFormLabel = (value) => {
        switch (value) {
            case RelationshipCd.FINANCIAL_CLAIMANT: return t('Form C');
            case RelationshipCd.SUPPLIER_CLAIMANT: return t('Form B');
            case RelationshipCd.EMPLOYEE_CLAIMANT: return t('Form D');
            case RelationshipCd.WORKMAN_CLAIMANT: return t('Form D');
            case RelationshipCd.OTHER_CREDITOR_CLAIMANT: return t('Form F');
            case RelationshipCd.OTHER_STAKEHOLDER_CLAIMANT: return t('Form A');
            default: return t('Form');
        }
    }

    const steps = [
        "Creditor Details",
        "Authorized Representative Details",
        "Claim Details",
        "Bank Details",
        "Documents",
        `Generate & Upload ${getFormLabel(relationShipCd)}`
    ];

    const handlePreviousFirstStep = () => {
        navigate(`/details/select-claim/${param.caseId}`)
    }

    const handlePrevious = () => {
        let activeParamStep = Number(param.activeStep);
        if (activeParamStep > 0) {
            navigate(`/details/file-claim/${Number(param.caseId)}/${Number(param.cd)}/${Number(param.activeStep) - 1}/${Number(param.id)}/${param.isHome}/${param.isIndividual}`, {
                state: {
                    claimantRelationShipCd: Number(param.cd),
                    isHome: location.state.isHome
                }
            })
        } else {
            handlePreviousFirstStep();
        }
    }

    const handleNext = () => {
        navigate(`/details/file-claim/${Number(param.caseId)}/${Number(param.cd)}/${Number(param.activeStep) + 1}/${Number(param.id)}/${param.isHome}/${param.isIndividual}`, {
            state: {
                claimantRelationShipCd: Number(param.cd),
                isHome: location.state.isHome
            }
        })
    }

    const getStepContent = (step) => {
        console.warn(step, step)
        switch (step) {
            case 0:
                return (<Creditor handleNext={handleNext} handlePrevious={handlePrevious} totalSteps={steps.length} 
                    type={getLabel(relationShipCd)}width={width} left={left} caseId={param.caseId}/>)
            case 1:
                return (<AuthRepContainer handleNext={handleNext} handlePrevious={handlePrevious} totalSteps={steps.length} width={width} left={left} caseId={param.caseId}/>)
            case 2:
                return (<ClaimList handleNext={handleNext} handlePrevious={handlePrevious} totalSteps={steps.length} width={width} left={left} />)
            case 3:
                return (<BankList handleNext={handleNext} handlePrevious={handlePrevious} totalSteps={steps.length} width={width} left={left} />)
            case 4:
                return (<ClaimDocuments handleNext={handleNext} handlePrevious={handlePrevious} 
                    totalSteps={steps.length} canUpload={true}/>)
            case 5:
                return (<GenerateUploadForm handleNext={handleNext} handlePrevious={handlePrevious} totalSteps={steps.length} label={getFormLabel(relationShipCd)} width={width} left={left} />)
            default: return (<p>{'Not Implemented'}</p>)
        }
    }


    return (
        <Stack>
            <Card sx={{ height: 'calc(100% - 54px)', p: 3, mx: 4, mt: 1, pb: 7 }}>
                <ClaimSteps steps={steps} activeStep={activeStep} ColorlibStepIcon={ColorlibStepIcon} label={getLabel(relationShipCd)}
                    // description={t('Please_Identify_And_Enter_Details_About_Your_Company_Then_Click_On_NEXT')}
                    />
                <ClaimStepContent steps={steps} activeStep={activeStep}
                    component={getStepContent(activeStep)} />
            </Card>
        </Stack>
    )
}

export default FCFileClaim
