import React, { useState } from 'react';
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { FormLabel, InputAdornment, Stack, TextField, Typography, IconButton, MenuItem, 
    FormHelperText, Select, styled } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { Fragment } from "react";
import { BiSolidMessageError } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';

const StyledSelect = styled(Select)({
    '&.Mui-disabled': {
      backgroundColor: '#f0f0f0',
    },
});

const DescriptiveTextField = (props) => {
    const { id, fieldName, label, formik, handleChange, disabled, size, required, description } = props;

    return (
        <Stack spacing={0.1} direction="row">
            <Stack width="120%">
                <FormLabel htmlFor={id} sx={{ fontSize: (theme) => theme.typography.body1, fontWeight: '600', color: (theme) => theme.palette.text.secondary }} required={required}>{label}</FormLabel>
                <Typography sx={{ fontSize: (theme) => theme.typography.body2 }} color="textSecondary">{description}</Typography>
            </Stack>
            <TextField
                fullWidth
                margin="normal"
                id={id}
                size={size}
                // label={label}
                name={fieldName}
                value={formik.values[fieldName]}
                onChange={handleChange}
                disabled={disabled}
                // onBlur={formik.handleBlur}
                error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                helperText={formik.touched[fieldName] && formik.errors[fieldName]}
                InputProps={{
                    endAdornment: (
                        <Fragment>
                            <InputAdornment position='end'>
                                {formik.touched[fieldName] && Boolean(formik.errors[fieldName]) && <BiSolidMessageError style={{ color: '#d32f2f', fontSize: '1.5rem' }} />}
                            </InputAdornment>
                        </Fragment>
                    ),
                    sx: {
                        background: (theme) => theme.palette.background.paper,
                        // border: '1px solid #d8e1e7'
                    }
                }}
                inputProps={{
                    style: {
                        height: '45px',
                        padding: '0 14px',
                    },
                }}
            />
        </Stack>
    )
}

const DescriptiveSelectField = (props) => {
    const { id, fieldName, label, formik, menuItems, disabled, required,size, handleChange, menuFieldId, menuFieldTitle, 
        valueField, handleBlur, placeholder, disabledFirstRow, customStyle, description} =
        { ...props, valueField: props.valueField || props.menuFieldId };

    const handleClear = () => {
        formik.setFieldValue(fieldName, '');
    };

    return (
        <Stack spacing={0.1} direction="row">
            <Stack width="120%">
                <FormLabel htmlFor={id} sx={{ fontSize: (theme) => theme.typography.body1, fontWeight: '600', color: (theme) => theme.palette.text.secondary }} required={required}>{label}</FormLabel>
                <Typography sx={{ fontSize: (theme) => theme.typography.body2 }} color="textSecondary">{description}</Typography>
            </Stack>
            <StyledSelect
                fullWidth
                id={id}
                name={fieldName}
                // value={(getIn(formik.values, fieldName) != '' && getIn(formik.values, fieldName) != undefined) 
                //     ? getIn(formik.values, fieldName) : ''}
                value={formik.values[fieldName] === 0 ? 0 : formik.values[fieldName] || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                size={size}
                displayEmpty
                error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                FormHelperTextProps={{ 'data-testid': `${id}-error` }}
                inputProps={{
                    "data-testid": id,
                }}
                aria-label={label}
                sx={{
                    bgcolor: (theme) => theme.palette.background.paper,
                    ...customStyle
                }}
                endAdornment={((formik.values[fieldName] != '' && formik.values[fieldName] != undefined) && !required) && (
                    <InputAdornment position="end">
                        {!disabled && <IconButton
                            sx={{margin: '12px'}}
                            onClick={handleClear}
                            aria-label="clear selection"
                        >
                            <ClearIcon />
                        </IconButton>}
                    </InputAdornment>
                )}
            > 
            {required && placeholder && (
                <MenuItem value="" disabled>
                    <em style={{color:'gray'}}>{placeholder}</em>
                </MenuItem>
            )}
                {menuItems.map((menu, index) => {
                    return (
                        <MenuItem key={`key-${menu[menuFieldId]}`} data-testid={`option-${menu[valueField]}`} value={menu[valueField]} 
                        disabled={(disabledFirstRow && index === 0)}>{menu[menuFieldTitle]}</MenuItem>
                    )
                })}
            </StyledSelect>
            <FormHelperText style={{ color: 'red' }}>{formik.touched[fieldName] && formik.errors[fieldName]}</FormHelperText>
        </Stack>
    )
}

const DescriptiveDatePicker = (props) => {
    const { id, fieldName, label, formik, required, description, maxDate, minDate, handleBlur,
        fullWidth, disabled, size } = {
        ...props, minDate: props.minDate || null, maxDate: props.maxDate || null
    };
    const [error, setError] = useState(null);

    const handleDateChange = (date) => {
        if (minDate && date.isBefore(minDate)) {
            date = moment(minDate);
        } else if (maxDate && date.isAfter(maxDate)) {
            date = moment(maxDate);
        }
        formik.setFieldValue(fieldName, date);;
    };

    const errorMessage = React.useMemo(() => {
        switch (error) {
          case "minDate": {
            return 'Please select a date after ' + moment(dayjs(minDate).$d).format("DD/MM/YYYY")
          }
          case "minTime": {
            return 'Please select a time after ' + moment(dayjs(minDate).$d).format("hh:mm A")
          }
          case "maxDate": {
            return 'Please select a date before' + moment(dayjs(maxDate).$d).format("DD/MM/YYYY")
          }
          case "maxTime": {
            return 'Please select a time before ' + moment(dayjs(maxDate).$d).format("hh:mm A")
          }
          case "invalidDate": {
            return 'Your date is not valid'
          }
          default: {
            return 'Invalid Date';
          }
        }
    }, [error]);

    return (
        <Stack spacing={0.1} direction="row">
            <Stack width="120%">
                <FormLabel htmlFor={id} sx={{ fontSize: (theme) => theme.typography.body1, fontWeight: '600', color: (theme) => theme.palette.text.secondary }} required={required}>{label}</FormLabel>
                <Typography sx={{ fontSize: (theme) => theme.typography.body2 }} color="textSecondary">{description}</Typography>
            </Stack>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']} sx={{ overflow: 'visible', paddingTop:'0px' }}>
                    <DatePicker
                        id={id}
                        slotProps={{
                            textField: {
                                variant: 'outlined',
                                error: (formik.touched[fieldName] && (Boolean(formik.errors[fieldName]) || Boolean(error))) ? true : false,
                                required: required,
                                fullWidth: fullWidth,
                                InputProps: {
                                    readOnly: true,
                                    sx: {
                                        background: (theme) => disabled ? theme.palette.grey[100] : theme.palette.background.paper,
                                    }
                                },
                                size: size ? size : 'small',
                                onKeyDown: (e) => {
                                    e.preventDefault();
                                },
                                style: { marginTop: '0px', minWidth: 'auto'},
                                helperText: (formik.touched[fieldName] && (formik.errors[fieldName] || error)) ?
                                    (formik.errors[fieldName] ? formik.errors[fieldName] : errorMessage) : ''
                            }
                        }}
                        onChange={handleDateChange}
                        value={formik.values[fieldName] && dayjs(formik.values[fieldName])}
                        format={"DD/MM/YYYY"}
                        minDate={minDate ? dayjs(minDate) : null}
                        inputProps={{ readOnly: true, "data-testid": id }}
                        disabled={disabled}
                        maxDate={maxDate ? dayjs(maxDate) : null}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </Stack>
    )
}

const DescriptivePasswordField = (props) => {
    const { id, fieldName, label, formik, handleChange, disabled, size, required, description } = props;
    const [passwordVisibility, setPasswordVisibility] = useState(false);

    const toggleChange = () => {
        setPasswordVisibility(!passwordVisibility);
    }

    return (
        <Stack spacing={0.1} direction="row">
            <Stack width="120%">
                <FormLabel htmlFor={id} sx={{ fontSize: (theme) => theme.typography.body1, fontWeight: '600', color: (theme) => theme.palette.text.secondary }} required={required}>{label}</FormLabel>
                <Typography sx={{ fontSize: (theme) => theme.typography.body2 }} color="textSecondary">{description}</Typography>
            </Stack>
            <TextField
                fullWidth
                margin="normal"
                id={id}
                size={size}
                type={passwordVisibility ? 'text' : 'password'}
                name={fieldName}
                value={formik.values[fieldName]}
                onChange={handleChange}
                disabled={disabled}
                error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                helperText={formik.touched[fieldName] && formik.errors[fieldName]}
                InputProps={{
                    endAdornment: (
                        <Fragment>
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={toggleChange}
                                    onMouseDown={toggleChange}>
                                    {passwordVisibility && <Visibility />}
                                    {!passwordVisibility && <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            <InputAdornment position='end'>
                                {formik.touched[fieldName] && Boolean(formik.errors[fieldName]) && <BiSolidMessageError style={{ color: '#d32f2f', fontSize: '1.5rem' }} />}
                            </InputAdornment>
                        </Fragment>
                    ),
                    sx: {
                        background: (theme) => theme.palette.background.paper,
                        // border: '1px solid #d8e1e7'
                    }
                }}
                inputProps={{
                    style: {
                        height: '45px',
                        padding: '0 14px',
                    },
                }}
            />
        </Stack>
    )
}

export { DescriptiveTextField, DescriptiveDatePicker, DescriptivePasswordField, DescriptiveSelectField }