import React                from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    DialogActions,
    Button
}                           from '@mui/material';

import PDFViewer            from './PDFViewer';
import { useTranslation } from 'react-i18next';
import { ICMSButton }       from '../../common/icms-styled-components/IcmsStyledComponents';

const PDFViewerModal = ({pdfData, open, onClose}) => {
    const { t } = useTranslation();

    const handleClose = () => {
        onClose();
    }

    return (
        <div>
            <Dialog open={open} fullWidth maxWidth='xl'>
                <DialogTitle>{t('Pdf_Viewer')}</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <PDFViewer pdfData={pdfData} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ICMSButton onClick={handleClose} variant='outlined' size='small'>{t('Close')}</ICMSButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default PDFViewerModal;