import React, { Fragment, useState }        from 'react'
import { Divider, IconButton, Tooltip, 
    Menu, MenuItem }                        from '@mui/material'
import { IoIosDocument }                    from "react-icons/io";
import { useTranslation }                   from 'react-i18next';
import { Delete, Download }                 from '@mui/icons-material';
import axios                                from 'axios';
import { AiOutlineCloudUpload }             from 'react-icons/ai'
import { formatBytes }                      from '../utils/Utils'
import { IcmsTableRow, StyledTableCell, StyledTableCellNone }    from '../generic-styles/NavPageContainerStyles';
import { ICMSAxiosInterceptor }             from '../../config/axios.interceptor';
import ICMSLoader                           from '../icms-loader/ICMSLoader';
import IcmsSnackbar                         from '../icms-snackbar/IcmsSnackbar';

const DocumentRow = ({caseId, selectedDocument, canUpload, deleteConfirmation, handleUpload, deleteVersionConfirmation}) => {
    const { t } = useTranslation();
    const [versions, setVersions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});
    const open = Boolean(anchorEl);


    const GetFileName = ({fileLocation}) => {
        let fileLocationArray = fileLocation.split('/');
        return fileLocationArray[fileLocationArray.length - 1];
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getDocumentDetails = (event) => {
        setLoading(true);
        ICMSAxiosInterceptor.get(`case/${caseId}/file/${selectedDocument.icmsCaseDocId}`).then(response => {
            setVersions(response.icmsCaseDocVersions);
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            if (error?.message) {
                setMessage({ show: true, message: error?.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' })
            } 
        })
    }

    const downloadVersion = (versionId) => {
        setLoading(true);
        ICMSAxiosInterceptor.post(`case/${caseId}/file/${selectedDocument.icmsCaseDocId}/version/${versionId}/true`, {}).then(url => {
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let fileLocationArray = selectedDocument.fileLocation.split('/');
                link.setAttribute('download', `${fileLocationArray[fileLocationArray.length-1]}`);
                document.body.appendChild(link);
                link.click();
            })
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            if (error?.message) {
                setMessage({ show: true, message: error?.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' })
            } 
        })
    }

    const handleDownload = (event) => {
        if (selectedDocument.versionCount === 1) {
            downloadVersion(selectedDocument.icmsCaseDocVersionId);
        } else {
            getDocumentDetails(event); 
            handleClick(event);
        }
    }

    return (
        <Fragment>
            <IcmsTableRow hover key={selectedDocument.icmsCaseDocId}>
                <StyledTableCell><GetFileName fileLocation={selectedDocument.fileLocation} /></StyledTableCell>
                <StyledTableCell>{selectedDocument.documentLocale}</StyledTableCell>
                <StyledTableCell>{selectedDocument.description || '-'}</StyledTableCell>
                <StyledTableCell>
                    {selectedDocument.fileSize ? formatBytes(selectedDocument.fileSize) : '-'}
                </StyledTableCell>
                <StyledTableCell>{selectedDocument.versionCount}</StyledTableCell>
                <StyledTableCellNone>
                    {canUpload && <Tooltip title={t('Upload')}>
                        <IconButton color='primary' style={{padding:'0px'}} onClick={() => handleUpload(selectedDocument.icmsCaseDocId, selectedDocument.documentCd)}>
                            <AiOutlineCloudUpload />
                        </IconButton>
                    </Tooltip>}
                    <Tooltip title={t('Download')}>
                        <IconButton color='primary' style={{padding:'0px'}} onClick={(event) => {handleDownload(event)}}>
                            <Download />
                        </IconButton>
                    </Tooltip>
                    { canUpload &&<Tooltip title={t('Delete')}>
                        <IconButton size='small' color='secondary' style={{padding:'0px'}}
                                onClick={() => deleteConfirmation(selectedDocument.icmsCaseDocId)}>
                            <Delete />
                        </IconButton>
                    </Tooltip>}
                </StyledTableCellNone>
            </IcmsTableRow>
            <Menu anchorEl={anchorEl} id="version-menu" open={open}
                    onClose={handleClose} onClick={handleClose}
                    inputProps={{ elevation: 0, sx: {
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {
                    versions.map((version, index) => {
                        return (<MenuItem onClick={() => {}} id='download-version'>
                            {`${t('Version')} ${index + 1}  `}
                            <Tooltip title={t('Download')}>
                                <IconButton id={`download${index}`} onClick={() => downloadVersion(version.icmsCaseDocVersionId)} color='primary'>
                                    <Download   />
                                </IconButton>
                            </Tooltip>
                            {canUpload && <Tooltip title={t('Delete')}>
                                <IconButton id={`delete${index}`} onClick={() => 
                                        deleteVersionConfirmation(selectedDocument.icmsCaseDocId, version.icmsCaseDocVersionId, index + 1)} color='secondary'>
                                    <Delete />
                                </IconButton>
                            </Tooltip>}
                        </MenuItem>)
                    })
                }
            </Menu>
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} 
                    handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
        </Fragment>
    )
}

export default DocumentRow