import React, { Fragment, useState }        from 'react';
import { useFormik }                        from "formik";
import EditIcon                             from '@mui/icons-material/Edit';
import CloseIcon                            from '@mui/icons-material/Close';
import CheckIcon                            from '@mui/icons-material/Check';
import DeleteIcon                           from '@mui/icons-material/Delete';
import { IconButton, Tooltip }              from '@mui/material';
import { useTranslation }                   from 'react-i18next';
import { isValidPhoneNumber }               from 'react-phone-number-input';
import * as Yup                             from 'yup';
import {
    IcmsTableRow,
    StyledTableCell
}                                           from '../generic-styles/NavPageContainerStyles';
import { FormikLabelMuiPhone }              from '../formik-fields-components/FormikLabelMuiPhone';
import { ContactType }                      from '../GenericCodes';

const PhoneNumberValidationSchema = Yup.object().shape({
    extension: Yup.string(),
    contact: Yup.string().required("Phone Number is Required")
        .test("phoneNumber", "Invalid Phone Number", (str, context) => {
            return isValidPhoneNumber(context.parent['extension'] + str);
        })
});

const PhoneNumberRow = ({partyId, phoneNumberDetails, handleCreate, handleUpdate, handleDelete, edit,
        handleRefresh, canModify, canDelete}) => {
    const { t } = useTranslation();
    const [isEdit, setIsEdit] = useState(edit);
    const phoneNumberFormik = useFormik({
        initialValues: {
            contact: phoneNumberDetails.phoneDetail?.nationalNumber ||  '',
            contactId: phoneNumberDetails.contactId || '',
            partyContactId: phoneNumberDetails.partyContactId || '',
            extension: phoneNumberDetails.phoneDetail?.countryCode ? '+'+phoneNumberDetails.phoneDetail?.countryCode :  '+91',
        },
        validationSchema: PhoneNumberValidationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true
    })

    const createPhoneNumber = () => { 
        let payload = {
            partyId: partyId,
            contactCd: ContactType.PHONE_NUMBER,
            contact: phoneNumberFormik.values.contact,
            phoneNumber: true

        }
        handleCreate(payload);
    }

    const updatePhoneNumber = () => { 
        let payload = {
            partyId: partyId,
            contactCd: ContactType.PHONE_NUMBER,
            contactId: phoneNumberFormik.values.contactId,
            partyContactId: phoneNumberFormik.values.partyContactId,
            contact: phoneNumberFormik.values.extension + '' + phoneNumberFormik.values.contact,
            phoneNumber: true
        }
        handleUpdate(payload);
    }

    const deletePhoneNumber = () => { 
        handleDelete(phoneNumberFormik.values.partyContactId);
    }

    const editPhoneNumber = () => {
        setIsEdit(true);
    }

    const cancelEditPhoneNumber = () => {
        setIsEdit(false);
        handleRefresh();
    }

    return (
        <IcmsTableRow colSpan={2} sx={{verticalAlign: isEdit ? 'top' : 'middle'}}>
            <StyledTableCell sx={{padding: '4px'}}>
                {!isEdit && phoneNumberFormik.values.extension + '' + phoneNumberFormik.values.contact}
                {isEdit && <FormikLabelMuiPhone
                    id="contact"
                    fieldName="contact"
                    countryCodeName="extension"
                    formik={phoneNumberFormik}
                    size="small"
                    handleChange={phoneNumberFormik.handleChange}
                    handleBlur={phoneNumberFormik.handleBlur}
                />
                }
            </StyledTableCell>
            <StyledTableCell sx={{padding: '4px', textAlign: 'center'}}>
                {(!isEdit && canModify) && <Tooltip title={t('Edit')}>
                    <IconButton size='small' color='primary' onClick={editPhoneNumber}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>}
                {(!isEdit && canDelete) && <Tooltip title={t('Delete')}>
                    <IconButton size='small' color='secondary' onClick={deletePhoneNumber}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>}
                {isEdit && <Fragment>
                    <Tooltip title={t('Save')}>
                        <IconButton size='small' color='success'
                                disabled={!phoneNumberFormik.isValid}
                                onClick={phoneNumberDetails.partyContactId ? updatePhoneNumber : createPhoneNumber}>
                            <CheckIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Cancel')}>
                        <IconButton size='small' color='secondary' onClick={cancelEditPhoneNumber}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Fragment>}
            </StyledTableCell>
        </IcmsTableRow>
    )
}

export default PhoneNumberRow