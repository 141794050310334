import { Button, CardContent, Stack, Typography } from '@mui/material'
import { useFormik } from 'formik'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FcUnlock } from 'react-icons/fc'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import ConfirmationDialog from '../common/ConfirmationDialog/ConfirmationDialog'
import ICMSReCaptcha from '../common/ICMSReCaptcha'
import { FormikOtpVerification } from '../common/formik-fields-components/FormikOtpVerification'
import ICMSLoader from '../common/icms-loader/ICMSLoader'
import IcmsSnackbar from '../common/icms-snackbar/IcmsSnackbar'
import { AuthDiv, JustifyCard } from '../common/icms-styled-components/IcmsStyledComponents'
import { AuthAxiosInterceptor } from '../config/axios.interceptor'
import { OtpText, TimeExpire } from './OtpExpireStyle'


const resetValidationSchema = Yup.object().shape({
    otpValue:Yup.string().required("OTP Required").min(6, "Enter Code Correctly").max(6, "Enter Code Correctly")
});

function ResetPassword(props) {
    const navigate = useNavigate();
    const pwdLocation = useLocation()
    const {t} = useTranslation(); 
    const [message, setMessage] = useState({
        show: false,
        message: null,
        severity: null
    })
    const [loading, setLoading] = useState(false);
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [time, setTime] = useState(300); // 300 seconds = 5 minutes
    const countdownRef = useRef(null);
    const [timeExpired, setTimeExpired] = useState(false)

    const resetFormik = useFormik({
        initialValues:{
            otpValue:''
        },
        onSubmit: (values) => {
            passwordReset()
        },
        validationSchema: resetValidationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true,
    })

    const passwordReset = () => {
        let code = resetFormik.values.otpValue.replaceAll(' ', '')
        setLoading(true)
        setMessage({ show: false, message: '', severity: '' });
        let payload = {
            loginId:  pwdLocation.state.username,
            password: pwdLocation.state.password,
            otp: code
        }
        AuthAxiosInterceptor.patch(`user/reset-password?recaptcha=${captcha}`, payload).then(response => {
            setLoading(false)
            setOpenDialog(true)
        }).catch((error) => {
            setLoading(false)
            if (error.message) {
                if(error.message.includes('ReCaptcha Expired')) {
                    setIsRefresh(true);
                    setMessage({ show: true, message: "ReCaptcha Expired", severity: 'error' });
                } else {
                    setMessage({ show: true, message: error.message, severity: 'error' });
                }
            }
            setIsRefresh(true);
            setTimeout(() => {
                setIsRefresh(false);
            }, 1000);
        })
    }
    const cancelDialog = () => {
        navigate('/forgot-password')
    }

    const resendOtp = () => {
        resetFormik.setFieldValue('otpValue', '');
        setLoading(true)
        AuthAxiosInterceptor.patch(`user/request-otp?recaptcha=${captcha}&purpose=reset-password`, {
            loginId: pwdLocation.state.username, password: pwdLocation.state.password
        }).then(response => {
            setLoading(false)
            setMessage({ show: true, message: t('OTP_Sent_Successfully'), severity: 'success' });
            setIsRefresh(true);
            setTimeExpired(false)
            setTime(300)
            setTimeout(() => {
                setIsRefresh(false);
            }, 1000);
        }).catch((error) => {
            setLoading(false)
            setIsRefresh(true);
            setTimeout(() => {
                setIsRefresh(false);
            }, 1000);
            if (error.message) {
                setMessage({ show: true, message: error.message, severity: 'error' });
            }
        })
    }

    useEffect(() => {
        countdownRef.current = setInterval(() => {
          setTime((prevTime) => {
            if (prevTime === 0) {
              clearInterval(countdownRef.current);
              setTimeExpired(true)
              // Timer reached zero, you can add your logic here
              return prevTime;
            }
            return prevTime - 1;
          });
        }, 1000); // Update every 1000ms (1 second)
    
        // Cleanup the timer when the component unmounts
        return () => clearInterval(countdownRef.current);
      }, [timeExpired]);
    
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;

  return (
    <Fragment>
        <AuthDiv>
            <Stack justifyContent='center' alignItems='center' m='auto' height="100vh">
                <JustifyCard style={{width:'27%'}}>
                    <CardContent sx={{p: 0, pb: '0px !important'}}>
                        <Stack direction='column'>
                            <Stack p={2} justifyContent='center' alignItems='center' spacing={2}>
                            <img src={process.env.PUBLIC_URL+"/images/Group.svg"} alt="ICMS_LOGO" width="30%"></img>
                            <div><FcUnlock size={100}/></div>
                                <Typography variant="h5" sx={{ fontWeight: 'bold' }} data-testid='otp-verify'>{t('OTP_Verification')}</Typography>
                                {<Typography variant="subtitle2" style={{textAlign:'center'}} data-testid='otp-info'><Fragment>{t('We_Have_Sent_Verification_Code_To')} <b>{ pwdLocation.state.isEmailOrMobile === 'email' ? 'Email Address' : 'Mobile Number'}</b></Fragment></Typography>}
                            </Stack>
                            <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
                                {!timeExpired ? 
                                    <Fragment>
                                        <OtpText minutes={minutes}>{t("OTP_Will_Expire_With_In")}</OtpText>
                                        <TimeExpire minutes={minutes}>
                                            {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                                        </TimeExpire>
                                    </Fragment> : 
                                    <Fragment>
                                        <Typography style={{color:'red', textAlign:'center'}}>{t("Your_OTP_Has_Been_Expired_Please_Click_To_Resend")}</Typography>
                                    </Fragment>
                                }
                            </Stack>
                            <Stack p={2} justifyContent='center' alignItems='center'>
                                <FormikOtpVerification
                                    id="otpValue"
                                    fieldName="otpValue"
                                    label={pwdLocation.state.isEmailOrMobile === 'email' ? t("Enter_Email_Verification_Code") : t("Enter_Mobile_Number_Verification_Code") }
                                    formik={resetFormik}
                                    isEmailOrMobile={ pwdLocation.state.isEmailOrMobile}
                                    resendOtp={resendOtp}
                                />
                            </Stack>
                            <Stack direction='row' justifyContent='space-between' p={2}>
                                <Button onClick={cancelDialog} variant='outlined'  data-testid='cancel-pwd'>{t("Cancel")}</Button>
                                <Button onClick={resetFormik.handleSubmit} variant='contained' data-testid='reset-pwd'>{t("Reset")}</Button>
                            </Stack>
                        </Stack>
                    </CardContent>
                </JustifyCard>
            </Stack>
        </AuthDiv>
        {openDialog ? <ConfirmationDialog title={t('Password_Updated_Successful')} show={openDialog}
        description={[t('Your_'), <strong>{t('App_Name')}</strong>, t('_Password_Has_Been_Changed_Successfully'), <strong>{t('Sign_In')}</strong>, t("Page")]} redirect={true} redirectUrl="/sign-in" /> : null}
   
        {!isRefresh && <ICMSReCaptcha key="login-withmfa" captcha={setCaptcha} refresh={setIsRefresh} />}
        {loading && <ICMSLoader show={loading}/>}
        {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
    </Fragment>
  )
}

export default ResetPassword
