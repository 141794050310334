import {
    Box, Button, Grid, IconButton, Stack, Tooltip, Typography,
    Card, Link
} from "@mui/material";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CiSquareMore } from "react-icons/ci";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { CaseManagementSideNav, RegEx, Roles } from '../common/GenericCodes';
import ICMSReCaptcha from "../common/ICMSReCaptcha";
import { UserContext } from "../common/context/UserContext";
import { FormikPasswordField, FormikTextField } from "../common/formik-fields-components/FormikFieldComponents";
import ICMSLoader from "../common/icms-loader/ICMSLoader";
import IcmsSnackbar from "../common/icms-snackbar/IcmsSnackbar";
import { AuthBottomDiv, AuthDiv } from "../common/icms-styled-components/IcmsStyledComponents";
import PublicAnnouncementList from "../common/poblic-announcement/PublicAnnouncementList";
import { AuthAxiosInterceptor, ICMSAxiosInterceptor, PublicAxiosInterceptor } from "../config/axios.interceptor";
import TermsAndConditions from "./TermsAndCondition";
import PublicAnnoucements from "../common/poblic-announcement/PublicAnnoucements";
import { FormikOtpInput } from "../common/formik-fields-components/FormikOtpInput";
import ConfirmOtpDialog from "./ConfirmOtpDialog";
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { scheduleTokenRefresh } from "./tokenManager";

export const EMAIL = "email";
export const MOBILE_NUMBER = "mobile";

const LoginValidationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email Address').test('custom-validation', 'Invalid Email Address', (value) => {
        return RegEx.EMAIL.test(value);
    }).required('Email Address is Required'),
    password: Yup.string()
        .required('Please Enter your password'),
    mfaEnabled: Yup.boolean(),
    mfaOtp: Yup.string().when('mfaEnabled', {
        is: (mfaEnabled) => { return mfaEnabled === true },
        then: () => {
            return Yup.string()
                .min(6, "Enter Code Correctly").max(6, "Enter Code Correctly")
                .required("Two Step Verification Code is required")
        },
        otherwise: () => Yup.string()
    }),
});

const HomePage = (props) => {
    const { setLoginDetails } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const context = useContext(UserContext);
    const [message, setMessage] = useState({
        show: false,
        message: null,
        severity: null
    })
    const [captcha, setCaptcha] = useState('');
    const [loginCaptcha, setLoginCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const [isLoginRefresh, setIsLoginRefresh] = useState(false);
    const [openTerms, setOpenTerms] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirectUrl = queryParams.get('redirect');
    const [isMFAEnabled, setIsMFAEnabled] = useState(false);

    const loginFormik = useFormik({
        initialValues: {
            email: '',
            password: '',
            mfaEnabled: false,
            mfaOtp: ''
        },
        onSubmit: (values) => {
            if (values.mfaEnabled && values.mfaOtp !== '') {
                signIn();
            } else {
                checkMFA(values);
            }
        },
        validationSchema: LoginValidationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true,
    });

    const checkMFA = async (values) => {
        let payload = {
            loginId: loginFormik.values.email
        }
        setLoading(true);
        AuthAxiosInterceptor.post(`mfa/?recaptcha=${captcha}`, payload).then(response => {
            refreshCaptcha();
            refreshLoginCaptcha();
            if (response) {
                setIsMFAEnabled(response)
                // setMfaEnabledUpdate(true);
                loginFormik.setFieldValue('mfaEnabled', true);
                setMessage({ show: true, message: t('OTP_HAS_BEEN_SENT'), severity: 'success' });
                setLoading(false);
            } else {
                signIn();
            }
        }).catch(error => {
            refreshCaptcha();
            refreshLoginCaptcha();
            if (error.message === `Your account: ${loginFormik.values.email} is not verified...`) {
                navigate('/verify-account', { state: { isEmailOrMobile: loginFormik.values.isEmailOrMobile } })
            } else if (error.message) {
                setMessage({ show: true, message: error.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
            }
            setLoading(false);
        })
    }

    const refreshCaptcha = () => {
        setIsRefresh(true);
        setTimeout(() => {
            setIsRefresh(false);
        }, 1000);
    }

    const refreshLoginCaptcha = () => {
        setIsLoginRefresh(true);
        setTimeout(() => {
            setIsLoginRefresh(false);
        }, 1000);
    }

    const onSubmit = () => {
        loginFormik.handleSubmit();
    }

    const handleChange = (event) => {
        const { id, value } = event.target
        loginFormik.setFieldTouched(id, true);
        loginFormik.handleChange({
            target: {
                id,
                value: value.trimStart()
            },
        });
    }

    const decodeToken = (token) => {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    const updateUserContext = (response) => {
        let userInfo = decodeToken(response.access_token);
        context.setUserSession(prevState => ({
            ...prevState,
            userSession: {
                isAuthenticated: true, session: {
                    accessToken: response.access_token,
                    refreshToken: response.refresh_token,
                    name: userInfo.firstName,
                    middleName: userInfo.middleName !== undefined && userInfo.middleName !== null ? userInfo.middleName : '',
                    lastName: userInfo.lastName !== undefined && userInfo.lastName !== null ? userInfo.lastName : '',
                    dateOfBirth: userInfo.dateOfBirth !== undefined ? userInfo.dateOfBirth : null,
                    individual: userInfo.individual,
                    email: userInfo.email,
                    phoneNumber: userInfo.phoneNumber,
                    userId: userInfo.sub,
                    isMFAEnabled: isMFAEnabled ? isMFAEnabled : ''

                }
            },
            loginUserRoles: userInfo.roles,
            currentDateTime: null,
        })
        )

    }

    const signIn = () => {
        let code = loginFormik.values.mfaOtp.replaceAll(' ', '')
        setLoading(true);
        let payload = {
            userName: loginFormik.values.email,
            password: loginFormik.values.password,
            otp: code
        }
        if (loginCaptcha !== '') {
            PublicAxiosInterceptor.post(`login?recaptcha=${loginCaptcha}`, payload).then(response => {
                setLoading(false);
                context.setUserSession({
                    isAuthenticated: true, session: {
                        accessToken: response.access_token,
                        refreshToken: response.refresh_token
                    }
                });

                sessionStorage.setItem('token', response.access_token);
                sessionStorage.setItem('refreshToken', response.refresh_token);
                sessionStorage.setItem('accessTokenExpiry', Date.now() + response.expires_in * 1000);
                scheduleTokenRefresh()
                updateUserContext(response);
                setLoading(true);
                getLoginUserDetails();
                // setTimeout(() => {
                //     navigate("/details/home");
                // }, 2000);
            }).catch((error) => {
                // refreshCaptcha();
                refreshLoginCaptcha();
                if (error.message) {
                    if (error.message.includes('User credentials have expired') || error.message.includes('Need to reset password')) {
                        navigate('/forgot-password', {
                            state: {
                                username: loginFormik.values.email,
                                accountExpired: true
                            }
                        })
                        setMessage({ show: true, message: error.message, severity: 'error' });
                    } else if (error.message.includes('User account is locked')) {
                        setMessage({ show: true, message: 'User account is locked', severity: 'error' });
                        navigate('/forgot-password', {
                            state: {
                                username: loginFormik.values.email,
                                accountLocked: true
                            }
                        })
                    } else {
                        setMessage({ show: true, message: error.message, severity: 'error' });
                    }
                } else {
                    setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
                }
                setLoading(false);
            })
        } else {
            // refreshCaptcha();
            refreshLoginCaptcha();
            setMessage({ show: false, message: null, severity: null });
            setTimeout(() => {
                setLoading(false);
                setMessage({ show: true, message: t('Please_Check_Internet_Connection_And_Refresh_The_Page'), severity: 'error' })
            }, 1000);
        }
    }

    const getLoginUserDetails = () => {
        ICMSAxiosInterceptor.get('login-user').then(function (response) {
            if (response) {
                setLoginDetails(response);
                if (response.hasIdentification) {
                    setTimeout(() => {
                        if (redirectUrl) {
                            navigate(redirectUrl);
                        } else {
                            if (response.casesInvoled.length > 0 || response.subscriberRole.length > 0) {
                                navigate("/details/case-management", {
                                    state: {
                                        caseType: (response.subscriberRole.includes(Roles.enkAdmin.roleValue) ||
                                            response.subscriberRole.includes(Roles.enkSupport.roleValue) ||
                                            response.subscriberRole.includes(Roles.administrator.roleValue) ||
                                            response.subscriberRole.includes(Roles.monitor.roleValue) ||
                                            response.subscriberRole.includes(Roles.accountAdmin.roleValue)
                                        ) ? CaseManagementSideNav.active.key : CaseManagementSideNav.all.key
                                    }
                                })
                            } else {
                                navigate("/details/my-profile", {
                                    state: {
                                        loginDetails: response
                                    }
                                })
                            }
                        }
                    }, 2000);
                } else {
                    navigate("/details/kyc");
                }
            }
        }).catch(function (error) {
            setLoading(false);
            setMessage({ show: true, message: (error.message ? error.message : error), severity: 'error' });
        });
    }

    const handleSignUp = () => {
        navigate('/sign-up')
    }

    const navigateToLoginPage = () => {
        navigate('/sign-in')
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            loginFormik.handleSubmit();
        }
    };

    return (
        <form onKeyDown={handleKeyDown}>
            <AuthDiv>
                <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={8} lg={4} md={4} alignSelf='center'>
                        <Stack direction='row' alignItems='center' justifyContent='center' spacing={1}>
                            <Typography variant="h4" fontWeight='bold' color='whitesmoke'>ICMS</Typography>
                            <Typography variant="body2" color='whitesmoke' sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                                {/* {'Insolvency Case Management System - IBC-2016'} */}
                                {'Insolvency Case Management System'}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} lg={1} md={1} alignSelf='center'>
                        <Button size="medium" variant="contained"
                            sx={{ textTransform: 'capitalize', fontWeight: 'bold', color: 'aliceblue', padding: '6px 12px' }}>
                            <Link target="_blank" href={process.env.REACT_APP_ICMS1} color='aliceblue'
                                style={{ cursor: 'pointer'}} underline='none'>
                                <OpenInNewRoundedIcon sx={{ verticalAlign: 'bottom !important'}} />
                                    PDA V1
                                </Link>
                        </Button>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6} alignSelf='end'>
                        <Box style={{ padding: '8px 8px 8px 8px', background: 'white', borderRadius: '5px' }}>
                            <Grid container spacing={1} alignItems='center' >
                                <Grid item xs={12} sm={12} lg={1.5} md={1.5}>
                                    <Typography style={{ textAlign: 'center' }}>Sign In</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={5} md={5}>
                                    <FormikTextField
                                        id="email"
                                        fieldName="email"
                                        label="Username (Email Address)"
                                        formik={loginFormik}
                                        size='small'
                                        handleChange={handleChange}
                                        disabled={loginFormik.values.mfaEnabled}
                                        validationIcon={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} lg={3.5} md={3.5}>
                                    <FormikPasswordField
                                        id="password"
                                        fieldName="password"
                                        label="Password"
                                        size='small'
                                        formik={loginFormik}
                                        // pwdValue={pwdValue}
                                        handleChange={handleChange}
                                        validationIcon={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} lg={2} md={2} >
                                    <Button variant="contained" onClick={onSubmit} style={{ textTransform: 'capitalize', marginTop: '4px' }} data-testid='submit' size="small">
                                        Login
                                    </Button>
                                    <Tooltip title='More Option' arrow>
                                        <IconButton style={{ float: 'right' }} onClick={navigateToLoginPage}>
                                            <CiSquareMore />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            {/* <Grid container item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Grid item style={{ textAlign: 'center' }}>
                                    {loginFormik.values.mfaEnabled && < FormikOtpInput
                                        id="mfaOtp"
                                        fieldName="mfaOtp"
                                        label="Two Step Verification Code"
                                        formik={loginFormik}
                                    />}
                                </Grid>
                            </Grid> */}
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={0.7} md={0.7} alignSelf='center'>
                        <Stack alignItems='end'>
                            <Button variant="contained" onClick={handleSignUp} data-testid='submit' size="small" style={{ textTransform: 'capitalize' }}>
                                {'sign up'}
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack alignItems='center' justifyContent='center'>
                            <Card style={{ padding: '10px', width: '95%' }}>
                                <PublicAnnoucements errorMessage={setMessage} setLoading={setLoading} />
                            </Card>
                        </Stack>
                    </Grid>
                </Grid>
            </AuthDiv>
            <AuthBottomDiv />
            {loginFormik.values.mfaEnabled && <ConfirmOtpDialog open={loginFormik.values.mfaEnabled} loginFormik={loginFormik} loading={loading} onSubmit={onSubmit} />}
            {openTerms && <TermsAndConditions show={openTerms} close={setOpenTerms} view={true} />}
            {!isRefresh && <ICMSReCaptcha key="login-withmfa" captcha={setCaptcha} refresh={setIsRefresh} />}
            {!isLoginRefresh && <ICMSReCaptcha key="login" captcha={setLoginCaptcha} refresh={setIsLoginRefresh} />}
            {loading && <ICMSLoader show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </form>
    )
}

export default HomePage;