import { Autocomplete, FormLabel, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { ICMSAxiosInterceptor } from "../config/axios.interceptor";
import { IdentificationType, PartyType } from "../common/GenericCodes";

const SearchBankByIFSC = ({ handleSelectIFSC, id, required, label }) => {
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);

    const searchBankByIFSC = (searchResource, handleSuccessCallback, handleErrorCallBack) => {
        ICMSAxiosInterceptor.post(`search?page=0&size=50`, searchResource).then(response => {
            if (response) {
                handleSuccessCallback(response);
            }
        }).catch(error => {
            handleErrorCallBack(error);
        })
    }

    const getBankByPartyId = (partyId, handleSuccessCallback, handleErrorCallBack) => {
        ICMSAxiosInterceptor.get(`search/party/${partyId}`).then(response => {
            if (response) {
                handleSuccessCallback(response);
            }
        }).catch(error => {
            handleErrorCallBack(error);
        })
    }

    const handleGetParty = (value) => {
        if (value) {
            getBankByPartyId(value.partyId,
                (response) => {
                    handleSelectIFSC(response);
                },
                (error) => {
                    console.log('search', error)
                })
        }
    }

    useEffect(() => {
        if (inputValue !== '') {
            searchBankByIFSC({
                searchBy: "identification",
                searchText: inputValue,
                partyCd: PartyType.BANK_BRANCH,
                identificationCd: IdentificationType.IFSC
            },
                (response) => {
                    if (response) {
                        setOptions(response)
                    }
                },
                (error) => {
                    console.log('search', error)
                })
        }
    }, [inputValue]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleClear = () => {
        setValue(null);
        setInputValue('');
        setOptions([]);
        handleSelectIFSC(null); 
    };

    return (
        <Stack>
            <FormLabel htmlFor={id} sx={{ fontSize: (theme) => theme.typography.body2 }} required={required}>{label}</FormLabel>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    handleGetParty(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue, reason) => {
                    setInputValue(newInputValue);
                    if (newInputValue === '') {
                        handleClear();
                    }
                }}
                id="controllable-states-demo"
                options={options}
                getOptionLabel={(option) => { return option.identification }}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} placeholder="Search Bank by IFSC" sx={{ boxShadow: '0 .5rem 1rem 0 rgba(44, 51, 73, .1)' }} />}
            />
        </Stack>
    )
}

export { SearchBankByIFSC }