import { Button, CardContent, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidMobileVibration } from "react-icons/bi";
import { IoMailUnread } from "react-icons/io5";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { EMAIL, MOBILE_NUMBER } from '../common/GenericCodes';
import ICMSReCaptcha from '../common/ICMSReCaptcha';
import { FormikMuiPhoneNumber, FormikTextField } from '../common/formik-fields-components/FormikFieldComponents';
import ICMSLoader from '../common/icms-loader/ICMSLoader';
import IcmsSnackbar from '../common/icms-snackbar/IcmsSnackbar';
import { AuthDiv, JustifyCard } from '../common/icms-styled-components/IcmsStyledComponents';
import { AuthAxiosInterceptor } from '../config/axios.interceptor';
import {IoIosUnlock} from 'react-icons/io'
import {TiInfo} from "react-icons/ti"


// export const EMAIL = "email";
// export const MOBILE_NUMBER = "mobile";

const verifyAccountValidation = Yup.object().shape({
  isEmailOrMobile: Yup.string(),
  email: Yup.string().when('isEmailOrMobile', {
      is: (isEmailOrMobile) => { return isEmailOrMobile === EMAIL},
      then: () => Yup.string().email('Invalid Email Address').required('Email Address is Required'),
      otherwise: () => Yup.string()
  }),
  extension: Yup.string(),
  phoneNumber: Yup.string().when('isEmailOrMobile', {
      is: (isEmailOrMobile) => { return isEmailOrMobile === MOBILE_NUMBER },
      then: () => Yup.string().required("Required")
          .test("phoneNumber", "Invalid Mobile Number", (str, context) => {
              return isValidPhoneNumber(context.parent['extension'] + str);
          }),
      otherwise: () => Yup.string()
  })
});

function VerifyAccount() {
  const navigate = useNavigate();
  const accountLocation = useLocation()
  const {t} = useTranslation(); 
  const [captcha, setCaptcha] = useState('');
  const [isRefresh, setIsRefresh] = useState(false);
  const [message, setMessage] = useState({
      show: false,
      message: null,
      severity: null
  })
  const [loading, setLoading] = useState(false);

  const verifyAccountFormik = useFormik({
    initialValues:{
      isEmailOrMobile: accountLocation.state ? accountLocation.state.isEmailOrMobile : 'email',
      extension: '+91',
      email:'',
      phoneNumber:''
    },
    validationSchema: verifyAccountValidation,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: (values) =>{
      sendOtp()
    }
  })

  const backToSignIn = () => {
    navigate('/sign-in')
  }

  const handleChange = (event) => {
    verifyAccountFormik.setFieldTouched([event.target.id], true);
    verifyAccountFormik.handleChange(event);
  }

  const sendOtp = event => {
        setLoading(true);
        AuthAxiosInterceptor.patch(`user/request-otp?recaptcha=${captcha}&purpose=verify-signup`, {
            loginId: verifyAccountFormik.values.isEmailOrMobile === EMAIL ? verifyAccountFormik.values.email : 
            verifyAccountFormik.values.extension + verifyAccountFormik.values.phoneNumber
        }).then(response => {
            setLoading(false);
            // setMessage({ show: true, message: 'OTP Sent Successfully', severity: 'success' });
            navigate('/verify-otp', 
              {state: { 
                verifyAccount: true,
                selectedValue:verifyAccountFormik.values.isEmailOrMobile === EMAIL? 'email': 'phoneNumber',
                emailOrPhoneNumber: verifyAccountFormik.values.isEmailOrMobile === EMAIL ? verifyAccountFormik.values.email : 
                verifyAccountFormik.values.extension + verifyAccountFormik.values.phoneNumber,
              }})
           
        }).catch((error) => {
            setIsRefresh(true);
            setTimeout(() => {
                setIsRefresh(false);
            }, 1000);
            if (error.message) {
              setMessage({ show: true, message: error.message, severity: 'error' });
            }
            setLoading(false);
        })
}

  return (
    <Fragment>
      <AuthDiv>
        <Stack justifyContent='center' alignItems='center' m='auto' height="100vh">
          <JustifyCard style={{width:'35%'}}>
            <CardContent sx={{p: 0, pb: '0px !important'}}>
              <Stack direction='column'>
                <Stack p={2} justifyContent='center' alignItems='center'>
                            <img src={process.env.PUBLIC_URL+"/images/Group.svg"} alt="ICMS_LOGO" width="30%"></img>
                  {/* <Typography variant="h4" sx={{ fontWeight: 'bold' }}>ICMS</Typography> */}
                     <div><IoIosUnlock size={120} color='#66c8e4'/></div>
                    {/* <img src={process.env.PUBLIC_URL + 'images/lockIcon.svg'} alt="Login-png" width="30%"></img> */}
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{t("Account_Verification")}</Typography>
                </Stack>
                <Stack p={2} justifyContent='center' alignItems='center'>
                  <ToggleButtonGroup
                      color="primary"
                      id="isEmailOrMobile"
                      name="isEmailOrMobile"
                      value={verifyAccountFormik.values.isEmailOrMobile}
                      exclusive
                      size="small"
                      onChange={verifyAccountFormik.handleChange}
                      aria-label="Platform"
                  >
                      <ToggleButton value="email" name="isEmailOrMobile" sx={{ fontWeight: 'bold' }}><IoMailUnread style={{fontSize: "1.45rem"}}/>&nbsp; {t("Email_Address")} </ToggleButton>
                      <ToggleButton value="mobile" name="isEmailOrMobile" sx={{ fontWeight: 'bold' }}><BiSolidMobileVibration style={{ fontSize: "1.5rem" }} />&nbsp; {t("Mobile_Number")}</ToggleButton>
                  </ToggleButtonGroup><br/>
                  <Stack spacing={1} direction='row'>
                      <TiInfo fontSize={20} color='blue'/>
                      <Typography variant="subtitle2" sx={{color:'blue' }} data-testid='verify-info'>{ `${t('Ensure_that_your')} ${verifyAccountFormik.values.isEmailOrMobile === EMAIL ? 
                                t("Email_Address") : t("Mobile_Number")} ${t('is_registered_with_us')}`}</Typography>
                  </Stack>
                  <Typography style={{background:'#fcf4ec', color: '#ff7100', fontSize: "0.9rem", borderRadius:'5px', textAlign:'center'}}>
                    {t("All_Communications_And_Notifications_Will_Only_Sent_Verified_Email_Mobile_Number")}</Typography>
                  <br/>
                  {verifyAccountFormik.values.isEmailOrMobile === 'email' ? <FormikTextField
                                        id="email"
                                        fieldName="email"
                                        label={t("Email_Address")}
                                        formik={verifyAccountFormik}
                                        handleChange={handleChange}
                                        validationIcon={true}
                                    /> :
                                    <FormikMuiPhoneNumber
                                        id="phoneNumber"
                                        fieldName="phoneNumber"
                                        label={t("Mobile_Number")}
                                        countryCodeName="extension"
                                        formik={verifyAccountFormik}
                                        handleChange={handleChange}
                                        validationIcon={true}
                                    />}
                </Stack>
                <Stack direction='row' justifyContent='space-between' p={2}>
                    <Button onClick={backToSignIn} variant='outlined' data-testid='navigate-to-signin'>{t("Back_To_Sign_In")}</Button>
                    <Button onClick={verifyAccountFormik.handleSubmit} variant='contained' data-testid='navigate-to-otp'>{t("Request_Otp")}</Button>
                </Stack>
              </Stack>
            </CardContent>
          </JustifyCard>
        </Stack>
      </AuthDiv>
        {loading && <ICMSLoader show={loading}/>}
        {!isRefresh && <ICMSReCaptcha key="login-withmfa" captcha={setCaptcha} refresh={setIsRefresh} />}
        {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
    </Fragment>
  )
}

export default VerifyAccount
