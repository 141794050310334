import React, { useEffect, 
    useState }                      from "react";
import { 
    Dialog, DialogTitle, 
    DialogContent, DialogActions,
    Button, Grid
}                                   from '@mui/material';
import { useFormik }                from "formik";
import { useTranslation }           from "react-i18next";
import { FormikNewDateTime }        from '../../../../../common/formik-fields-components/FormikNewDateTime';
import { FormikLabelNumericFormat } from "../../../../../common/formik-fields-components/FormikLabelNumericFormat";
import { FormikLabelTextField }     from "../../../../../common/formik-fields-components/FormikFieldComponents";
import { ICMSAxiosInterceptor }     from "../../../../../config/axios.interceptor";
import ICMSLoader                   from '../../../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                 from '../../../../../common/icms-snackbar/IcmsSnackbar';
import { AdmissionValidationSchema } from "../../creditor-details/CreditorYupSchema";
import { ICMSButton }               from '../../../../../common/icms-styled-components/IcmsStyledComponents';

const ClaimAdmission = ({claimDetails, caseId, claimantId, show, onClose, onSuccess, claimMinDate}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});

    const cliamFormik = useFormik({
        initialValues: {
            debtDefaultId: claimDetails.debtDefaultId || null,
            debtId: claimDetails.debtId || null,
            disbursedAmount: claimDetails.disbursedAmount || null,
            sanctionSaleAgreementAmount: claimDetails.sanctionSaleAgreementAmount || null,
            claimTime: claimDetails.claimTime || null,
            cprincipal: claimDetails.cprincipal || null,
            cinterest: claimDetails.cinterest || null,
            cother: claimDetails.cother || null,
            cdescription: claimDetails.cdescription || null,
            totalClaim: claimDetails.totalClaim || null,
            anyMutualDues: claimDetails.anyMutualDues || null,
            amountOwedToD: claimDetails.amountOwedToD || null,
            claimId: claimDetails.claimId || null,
            admitId: claimDetails.admitId || null,
            admittedTime: claimDetails.admittedTime || null,
            bprincipal: claimDetails.bprincipal || null,
            binterest: claimDetails.binterest || null,
            bother: claimDetails.bother || null,
            bdescription: claimDetails.bdescription || null,
            totalBook: claimDetails.totalBook || null,
            aprincipal: claimDetails.aprincipal || 0,
            ainterest: claimDetails.ainterest || 0,
            aother: claimDetails.aother || 0,
            adescription: claimDetails.adescription || null,
            totalAdmit: claimDetails.totalAdmit || 0,
            claimCd: claimDetails.claimCd || null,
        },
        // validationSchema: AdmissionValidationSchema,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: () => {
        }
    })

    const handleSave = () => {
        let payload = {
            debtId: cliamFormik.values.debtId,
            disbursedAmount: cliamFormik.values.disbursedAmount,
            claimAdmitRejectDisburseId: cliamFormik.values.claimAdmitRejectDisburseId,
            debtDefaultId: cliamFormik.values.debtDefaultId,
            parentClaimAdmitRejectDisburseId: cliamFormik.values.parentClaimAdmitRejectDisburseId,
            claimAdmitRejectDisburseCd: cliamFormik.values.claimAdmitRejectDisburseCd,
            claimAdmitRejectDisburseLocale: cliamFormik.values.claimAdmitRejectDisburseLocale,
            occurredOn: cliamFormik.values.occurredOn,
            principal: cliamFormik.values.aprincipal,
            interest: cliamFormik.values.ainterest,
            other: cliamFormik.values.aother,
            description: cliamFormik.values.adescription,
            totalClaim: cliamFormik.values.totalAdmit
        };
        setLoading(true);
        ICMSAxiosInterceptor.post(`case/${caseId}/claimant/${claimantId}/admit/${cliamFormik.values.claimId}`, payload).then(response => {
            onSuccess({show: true, message: t('Claim_Admission_Updated_Successfully'), severity: 'success'});
            onClose();
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error?.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const handleChange = (event) => {
        event.stopPropagation();
        cliamFormik.handleChange(event);
    }

    useEffect(() => {
        cliamFormik.setFieldValue('totalAdmit', cliamFormik.values.aprincipal + cliamFormik.values.ainterest +
                cliamFormik.values.aother)
    }, [cliamFormik.values.aprincipal, cliamFormik.values.ainterest, cliamFormik.values.aother])

    return (
        <div>
            <Dialog open={show} fullWidth maxWidth="lg">
                <DialogTitle>{t('Claim_Admission_Details')}</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={2}>
                            <FormikNewDateTime
                                id='admittedTime'
                                fieldName='admittedTime'
                                formik={cliamFormik}
                                maxDate={claimMinDate}
                                // maxDate={(new Date())}
                                label={t('Due_Date')}
                                handleChange={handleChange}
                                size='small'
                                required={true}
                                onlyDate={true}
                            />
                        </Grid> */}
                        <Grid item xs={2}>
                            <FormikLabelNumericFormat
                                id="aprincipal"
                                fieldName="aprincipal"
                                formik={cliamFormik}
                                size="small"
                                label={t('Principal_Amount_Rs')}
                                isThousandSeparator={true}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormikLabelNumericFormat
                                id="ainterest"
                                fieldName="ainterest"
                                formik={cliamFormik}
                                size="small"
                                label={t('Interest_Amount_Rs')}
                                isThousandSeparator={true}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormikLabelNumericFormat
                                id="aother"
                                fieldName="aother"
                                formik={cliamFormik}
                                size="small"
                                label={t('Other_Charges_Rs')}
                                isThousandSeparator={true}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormikLabelNumericFormat
                                id="totalAdmit"
                                fieldName="totalAdmit"
                                formik={cliamFormik}
                                size="small"
                                isThousandSeparator={true}
                                label={t('Total')}
                                disabled={true}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikLabelTextField
                                id='adescription'
                                fieldName='adescription'
                                label={t('Remarks')}
                                formik={cliamFormik}
                                required={false}
                                disabled={cliamFormik.values.totalAdmit == 0}
                                handleChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ICMSButton onClick={onClose} color="primary" size="small" variant='outlined'>
                        {t('Cancel')}
                    </ICMSButton>
                    <ICMSButton onClick={handleSave} color="primary" size="small" variant='contained'
                            disabled={!cliamFormik.isValid || cliamFormik.values.totalAdmit <= 0}>
                        {t('Save')}
                    </ICMSButton>
                </DialogActions>
            </Dialog>
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} 
                    handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
        </div>
    )
}

export default ClaimAdmission;