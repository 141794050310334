import React, { 
    useState,
    useEffect 
}                                           from 'react';
import {
    Grid,
    Button,
    TableHead,
    TableBody,
    Tooltip,
    IconButton
}                                           from '@mui/material';
import { useTranslation }                   from 'react-i18next';
import AddIcon                              from '@mui/icons-material/Add';
import EditIcon                             from '@mui/icons-material/Edit';
import DeleteIcon                           from '@mui/icons-material/Delete';
import { 
    Currency,
    CurrencyFormatter, 
    CurrencyFormatterWithOutSymbol}                     from "../../../../../common/utils/Utils"
import { ICMSAxiosInterceptor }             from '../../../../../config/axios.interceptor';
import {
    IcmsTableContainer,
    IcmsMuiTable,
    IcmsTableRow,
    StyledTableCell
}                                           from '../../../../../common/generic-styles/NavPageContainerStyles';
import ClaimAdjudicationDetail              from './ClaimAdjudicationDetail';
import ICMSConfirmDialog                    from '../../../../../common/ConfirmationDialog/ICMSConfirmDialog';
import ICMSLoader                           from '../../../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                         from '../../../../../common/icms-snackbar/IcmsSnackbar';
import { ICMSButton }                       from '../../../../../common/icms-styled-components/IcmsStyledComponents';

const ClaimAdjudicationList = ({caseId, claimantId, debtDefaultId, canEdit}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});
    const [adjudications, setAdjudications] = useState([]);
    const [showAdjudicationDetail, setShowAdjudicationDetail] = useState({show: false, adjudicationDetail: null});
    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('Yes'),
        disAgreeBtnLabel: t('No'),
        onDisAgree: ''
    })

    const columns = [
        { id: 'orderReferenceNumber', label: 'Order_Reference_Number', minWidth: '25%' },
        { id: 'typeOfOrder', label: 'Type_Of_Order', minWidth: '20%' },
        { id: 'amount', label: 'Amount', minWidth: '10%' },
        { id: 'description', label: 'Description', minWidth: '35%' },
        { id: 'action', label: 'Action', minWidth: '10%', align:'center' }
    ]

    const getAdjudications = () => {
        setLoading(true);
        ICMSAxiosInterceptor.get(`case/${caseId}/claimant/${claimantId}/claim/${debtDefaultId}/adjudication-link`).then(response => {
            setAdjudications(response);
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const resetShowAdjudicationDetail = () => {
        setShowAdjudicationDetail({show: false, adjudicationDetail: null})
    }

    const onSuccess = (message) => {
        setMessage(message);
        getAdjudications();
    }

    const deleteConfirmation = (adjudication) => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: `${t('Are_You_Sure_That_You_Want_To_Delete_Adjudication')} ${adjudication?.orderReferenceNumber} ?`,
            onDisAgree: resetSeekConfirmation,
            onAgree: () => {
                resetSeekConfirmation();
                handleDelete(adjudication);
            },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    const handleDelete = (adjudication) => {
        setLoading(true);
        ICMSAxiosInterceptor.delete(`case/${caseId}/claimant/${claimantId}/claim/${debtDefaultId}/adjudication-link/${adjudication.claimAdjudicationId}`).then(response => {
            if (response) {
                onSuccess({show: true, message: t('Adjudication_Deleted_Succssfully'), severity: 'success'});
            }
            setLoading(false);
        }).catch(error => { 
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    useEffect(() => {
        getAdjudications();
    }, [])
    
    return (
        <div>
            <IcmsTableContainer>
                <Grid container>
                    <Grid item xs={12}>
                        {canEdit && <ICMSButton color="primary" variant="contained" size="small" sx={{float: 'right'}}
                                onClick={() => {setShowAdjudicationDetail({show: true, adjudicationDetail: null})}}
                                startIcon={<AddIcon />}>
                            {t('Add_Adjudication')}
                        </ICMSButton>}
                    </Grid>
                </Grid>
                <IcmsMuiTable stickyHeader aria-label="sticky table" data-testid="claim-adjudication-list-table"
                            sx={{padding: '0px !important'}}>
                    <TableHead>
                        <IcmsTableRow>
                            {columns.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    sx={{padding: '4px'}}
                                >
                                    {t(column.label)}
                                </StyledTableCell>
                            ))}
                        </IcmsTableRow>
                    </TableHead>
                    <TableBody>
                        {adjudications.length === 0 &&  <IcmsTableRow hover>
                            <StyledTableCell>
                                {t('No_Record_Found')}
                            </StyledTableCell>
                        </IcmsTableRow>}
                        {adjudications.map(adjudication => {
                            return (
                                <IcmsTableRow hover key={adjudication.claimAdjudicationId}>
                                    <StyledTableCell sx={{padding: '4px'}}>{adjudication.orderReferenceNumber}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '4px'}}>{adjudication.typeOfOrder}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '4px'}}>{CurrencyFormatterWithOutSymbol(Currency.INR, adjudication.amount)}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '4px'}}>{adjudication.description}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '4px', textAlign: 'center'}}>
                                       {canEdit && <Tooltip title={t('Edit')} arrow>
                                            <IconButton color='primary' id='edit'
                                                    onClick={() => {setShowAdjudicationDetail({show: true, adjudicationDetail: adjudication})}}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>}
                                        {canEdit && <Tooltip title={t('Delete')} arrow>
                                            <IconButton color='secondary' id='delete' onClick={() => deleteConfirmation(adjudication)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>}
                                    </StyledTableCell>
                                </IcmsTableRow>
                            )
                        })}
                    </TableBody>
                </IcmsMuiTable>
            </IcmsTableContainer>
            {showAdjudicationDetail.show && <ClaimAdjudicationDetail show={showAdjudicationDetail.show} 
                    adjudicationDetail={showAdjudicationDetail.adjudicationDetail} onClose={resetShowAdjudicationDetail}
                    caseId={caseId} claimantId={claimantId} debtDefaultId={debtDefaultId}
                    onSuccess={onSuccess}/>}
            {seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show} title={seekConfirmation.title} 
                message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}
            />}
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} 
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
        </div>
    )
}

export default ClaimAdjudicationList