import React, { Fragment, useState }                    from 'react';
import { useTranslation }                               from 'react-i18next';
import { IconButton, Menu, MenuItem, Tooltip }          from '@mui/material';
import { CheckCircleOutline, HighlightOff, MoreVert }   from '@mui/icons-material';
import HowToRegIcon                                     from '@mui/icons-material/HowToReg';
import { IcmsTableRow, StyledTableCell }                from '../../../../common/generic-styles/NavPageContainerStyles';
import ICMSConfirmDialog                                from '../../../../common/ConfirmationDialog/ICMSConfirmDialog';
import { CaseRoles }                                    from '../../../../common/GenericCodes';
import { ICMSAxiosInterceptor }                         from '../../../../config/axios.interceptor';
import ICMSLoader                                       from '../../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                                     from '../../../../common/icms-snackbar/IcmsSnackbar';

const AuthRepRow = ({authRep, claimantPartyRelationshipId, caseId, handleUpdateAuthRep, 
        canSendCredentials, sendCredentialsConfirmation, onDelete, canEdit, onSuccess, getCreditor}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});
    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('Yes'),
        disAgreeBtnLabel: t('No'),
        onDisAgree: ''
    })

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const roleAssignmentConfirmation = (documentId) => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: `${t('Are_You_Sure_That_You_Want_To_Assign_Claimant_Role')}?`,
            onDisAgree: resetSeekConfirmation,
            onAgree: () => {
                resetSeekConfirmation();
                assignClaimantRole();
            },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }
    
    const assignClaimantRole = () => {
        setLoading(true);
        setMessage({ show: false, message: '', severity: '' });
        let payload = {
                partyId: authRep.partyId,
                roleDetails: [
                    {
                        roleCd: CaseRoles.ROLE_CASE_CLAIMANT
                    }
                ]
        }
        ICMSAxiosInterceptor.post(`case-role/assign/${caseId}?parentPartyRelationhipId=${claimantPartyRelationshipId}`, payload).then(response => {
            onSuccess({ show: true, message: t('Climant_Role_Assigned_Successfully'), severity: 'success' });
            setLoading(false);
            if (getCreditor) getCreditor();
        }).catch(error => {
            if (error?.message) {
                setMessage({ show: true, message: error?.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' })
            } 
            setLoading(false); 
        })
    } 

    const roleRevokeConfirmation = (partyRelationshipId) => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: `${t('Are_You_Sure_That_You_Want_To_Revoke_Claimant_Role')}?`,
            onDisAgree: resetSeekConfirmation,
            onAgree: (remarks) => {
                resetSeekConfirmation();
                revokeRole(partyRelationshipId, remarks);
            },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No'),
            withConfirmText: true,
            isConfirmTextMandatory: true
        }))
    }

    const revokeRole = (partyRelationshipId, remarks) => {
        setLoading(true);
        ICMSAxiosInterceptor.patch(`case-role/${caseId}/${partyRelationshipId}`, {remarks: remarks}).then(response => {
            if(response) {
                onSuccess({show: true, message: t('Role_Revoked_Successfully'), severity: 'success'});
            }
            if (getCreditor) getCreditor();
            setLoading(false);
        }).catch(error => {
            if(error.message) {
                setMessage({show: true, message: error.message, severity: 'error'})
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'})
            }
            setLoading(false);
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    return (
        <Fragment>
            <IcmsTableRow hover key={authRep.authorizedRepresentativeId} sx={{fontSize: '0.8rem'}}>
                <StyledTableCell sx={{padding: '8px'}}>
                    <div>{authRep.name}{authRep.loginUid && <Tooltip title={t('Registered_User')}><HowToRegIcon color="success" sx={{float: 'right', paddingRight: '8px'}}/></Tooltip>}</div>
                    <div style={{color: 'gray'}}><em>{authRep.identification}</em></div>
                </StyledTableCell>
                <StyledTableCell sx={{padding: '8px'}}>
                    {authRep.relationshipName}
                </StyledTableCell>
                <StyledTableCell sx={{padding: '8px'}}>
                    {authRep.email}
                </StyledTableCell>
                <StyledTableCell sx={{padding: '8px'}}>
                    {authRep.phoneNumber}
                </StyledTableCell>
                <StyledTableCell sx={{padding: '8px'}}>
                    {authRep.mainContactPerson ? <CheckCircleOutline color='success'/> : <HighlightOff color='error'/>}
                </StyledTableCell>
                <StyledTableCell sx={{padding: '8px'}}>
                    {canEdit && <Tooltip title={t('More')} arrow>
                        <IconButton color='primary' id='more' onClick={handleClick}>
                            <MoreVert />
                        </IconButton>
                    </Tooltip>}
                </StyledTableCell>
            </IcmsTableRow>
            <Menu anchorEl={anchorEl} id="account-menu" open={open}
                    onClose={handleClose} onClick={handleClose}
                    inputProps={{ elevation: 0, sx: {
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                
                <MenuItem id='auth-rep-edit' onClick={() => handleUpdateAuthRep(authRep.authorizedRepresentativeId)}>
                    {t('Edit')}
                </MenuItem>
                {canSendCredentials && !authRep?.roleRelationship?.[CaseRoles.ROLE_CASE_CLAIMANT] && <MenuItem id='auth-rep-assign-role' 
                        onClick={roleAssignmentConfirmation}>
                    {t('Assign_Claimant_Role')}
                </MenuItem>}
                {canSendCredentials && authRep?.roleRelationship?.[CaseRoles.ROLE_CASE_CLAIMANT] && <MenuItem id='auth-rep-revoke-role' 
                        onClick={() => roleRevokeConfirmation(authRep?.roleRelationship?.[CaseRoles.ROLE_CASE_CLAIMANT])}>
                    {t('Revoke_Claimant_Role')}
                </MenuItem>}
                {(canSendCredentials && !authRep.loginUid) && <MenuItem id='auth-rep-edit' onClick={() => sendCredentialsConfirmation(authRep)}>
                    {t('Send_Credentials')}
                </MenuItem>}
                <MenuItem id='auth-rep-edit' onClick={() => onDelete(authRep)}>
                    {t('Delete')}
                </MenuItem>
            </Menu>
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} 
                    handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
            {/* {seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}
            />} */}
            {seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel} withConfirmText={seekConfirmation.withConfirmText}
                isConfirmTextMandatory={seekConfirmation.isConfirmTextMandatory} onAgree={seekConfirmation.onAgree} required={true} placeholder={"Enter reason for revoke Role"}
                disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree} label={"Remarks"}
            />}
        </Fragment>
    )
}

export default AuthRepRow