import { Person } from "@mui/icons-material";

export const ResolutionPlanSideValue = {
    RA_DETAILS: 'res-plan-details',
    COMPLIANCE: 'res-plan-compliance',
    APPROVAL: 'res-plan-approval',
    NON_COMPLIANCE: 'res-plan-non-compliance'
}

// left navigation order will take as in this list
export const ResolutionPlanSideMenu = [
    {
        id: 'radetails',
        value: ResolutionPlanSideValue.RA_DETAILS,
        title: 'RA Details',
        icon: <Person />
    },
    {
        id: 'compliance',
        value: ResolutionPlanSideValue.COMPLIANCE,
        title: 'Compliance',
        icon: <Person />
    },
    {
        id: 'non-compliance',
        value: ResolutionPlanSideValue.NON_COMPLIANCE,
        title: 'Deviation',
        icon: <Person />
    },
    {
        id: 'approval',
        value: ResolutionPlanSideValue.APPROVAL,
        title: 'Approval',
        icon: <Person />
    },
]