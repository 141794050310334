import { Button, Grid, Stack } from "@mui/material";
import { useFormik } from "formik";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { ICMSAxiosInterceptor } from "../../../config/axios.interceptor";
import { listCityTown } from "../../../lookup-service/AddressService";
import ICMSConfirmDialog from "../../ConfirmationDialog/ICMSConfirmDialog";
import { DefaultCountry, RegEx, Roles } from "../../GenericCodes";
import { UserContext } from "../../context/UserContext";
import { FormikLabelTextField } from "../../formik-fields-components/FormikFieldComponents";
import { SelectLabelController } from "../../formik-fields-components/SelectLabelController";
import { TextAreaLabelController } from "../../formik-fields-components/TextAreaLabelController";
import ICMSLoader from "../../icms-loader/ICMSLoader";
import { ICMSButton } from "../../icms-styled-components/IcmsStyledComponents";

const AddressDetails = (props) => {
    const { data, handleCloseAddress, setMessage, setDisabled, loginUser,
        partyId, disableCRUD, getAddress, fetchingAddress, buttonDisable, hideDelete } = props;
    const { t } = useTranslation();
    const stateListContext = useContext(UserContext)
    const [cityTownMenu, setCityTownMenu] = useState([]);
    const [stateId, setStateId] = useState('');
    const [editAddress, setEditAddress] = useState(false);
    const [loading, setLoading] = useState(false)
    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: t("Confirmation"),
        message: '',
        onAgree: '',
        onDisAgree: ''
    })    
    const isEnkAdmin = stateListContext.loginDetails.subscriberRole.includes(Roles.enkAdmin.roleValue);

    const addressValidationSchema = Yup.object().shape({
        cityOrTown: Yup.string().required(t("City_Town_Is_Required")),
        country: Yup.string().required(t("Country_Is_Required")),
        state: Yup.string().required(t("State_Is_Required")),
        postalCode: Yup.string()
            .matches(RegEx.PIN_CODE, t('Invalid_Pin_Code'))
            .required(t("Pin_Code_Is_Required")),
        addressLine1: Yup.string().required(t("Address_Line1_Is_Required")),
    });

    const addressFormik = useFormik({
        initialValues: {
            addressCd: data.addressCd,
            addressLine1: data.addressLine1 ? data.addressLine1 : '',
            addressLine2: data.addressLine2 ? data.addressLine2 : '',
            cityOrTown: data.cityOrTown ? data.cityOrTown : '',
            state: data.state ? data.state : '',
            addressLocale: data.addressLocale,
            postalCode: data.postalCode ? data.postalCode : '',
            country: data.country ? data.country : DefaultCountry.COUNTRY_NAME,
            partyAddressId: data.partyAddressId ? data.partyAddressId : '',
            addressId: data.addressId ? data.addressId : null,
            // partyId: data.partyId ? data.partyId : ''
        },
        onSubmit: (values) => {
            if (values.partyAddressId) {
                updateAddress(values, values.partyAddressId)
            } else {
                // if not profile pratyId has to be send as props
                if (fetchingAddress) {
                    getAddress(values)
                } else {
                    createAddress(values)
                }
            }
        },
        validationSchema: addressValidationSchema
    })

    const getCityTownMenu = () => {
        setLoading(true)
        listCityTown(stateId, (response) => {
            if (response) {
                setLoading(false)
                setCityTownMenu(response);
            }
        },
            (error) => {
                setLoading(false)
                console.error('List Lookup CityTowns', error);
                setMessage({ show: true, message: error.message, severity: 'error' });
            })
    }

    const handleStateChange = (event) => {
        stateListContext.stateList.every((state) => {
            if (state.name === event.target.value) {
                setStateId(state.stateId);
                addressFormik.setValues({
                    ...addressFormik.values,
                    [event.target.name]: event.target.value,
                    cityOrTown: '',
                });
                return false;
            } else {
                return true;
            }
        })
    }

    const handleCityChange = (event) => {
        cityTownMenu.every((city) => {
            if (city.name === event.target.value) {
                addressFormik.setFieldValue([event.target.name], event.target.value);
                // addressFormik.setFieldValue('postalCode', '');
                return false;
            } else {
                return true;
            }
        })
    }

    const handleChangePostalCode = (event) => {
        addressFormik.setFieldTouched([event.target.id], true);
        addressFormik.setFieldValue('postalCode', event.target.value.replace(/[^0-9]/g, ''));
    }

    const createAddress = (address) => {
        setLoading(true)
        let payload = { ...address }
        const createUrl = loginUser ? `address` : `address/${partyId}`
        ICMSAxiosInterceptor.post(createUrl, payload).then(response => {
            if (response) {
                setMessage({ show: true, message: t('Address_Created_Successfully'), severity: 'success' });
                handleCloseAddress(response.addressCd);
                setEditAddress(false);
            }
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            if (error.message) {
                setMessage({ show: true, message: error.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
            }
        })
    }

    const deleteAddress = (partyAddressId) => {
        const deleteUrl = loginUser ? `address/${partyAddressId}` : `address/${partyAddressId}/party/${partyId}`
        setLoading(true)
        ICMSAxiosInterceptor.delete(deleteUrl).then((response) => {
            if (response) {
                setMessage({ show: true, message: t("Address_Deleted_Successfully"), severity: 'success' });
                handleCloseAddress(null);
            }
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            if (error.message) {
                setMessage({ show: true, message: error.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
            }
        })
    }

    const updateAddress = (address, partyAddressId) => {
        setLoading(true)
        let payload = { ...address }
        ICMSAxiosInterceptor.put(`address/${partyAddressId}`, payload).then(response => {
            if (response) {
                setMessage({ show: true, message: t('Address_Updated_Successfully'), severity: 'success' });
                handleCloseAddress(address.addressCd);
                setEditAddress(false);
            }
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            if (error.message) {
                setMessage({ show: true, message: error.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
            }
        })
    }

    useEffect(() => {
        if (stateId !== '') {
            getCityTownMenu();
        }
    }, [stateId])

    const resetAddressFormik = () => {
        addressFormik.setValues({
            addressCd: data.addressCd,
            addressLine1: data.addressLine1 ? data.addressLine1 : '',
            addressLine2: data.addressLine2 ? data.addressLine2 : '',
            cityOrTown: data.cityOrTown ? data.cityOrTown : '',
            state: data.state ? data.state : '',
            addressLocale: data.addressLocale,
            postalCode: data.postalCode ? data.postalCode : '',
            country: data.country ? data.country : DefaultCountry.COUNTRY_NAME,
            partyAddressId: data.partyAddressId ? data.partyAddressId : '',
            addressId: data.addressId ? data.addressId : null
        })
    }

    useEffect(() => {
        // addressFormik.values.partyAddressId adding new address or editing addres any one condition should be true
        if ((DefaultCountry.COUNTRY_ID !== '' && (editAddress || addressFormik.values.partyAddressId === ''))) {
            stateListContext.stateList.forEach((state) => {
                if (state.name === addressFormik.values.state) {
                    setLoading(false)
                    setStateId(state.stateId);
                }
            })
        }
        if (editAddress) {
            resetAddressFormik();
        }
    }, [editAddress])

    const SubmitFormWithValues = () => {
        addressFormik.handleSubmit()
    }

    const deleteListAddress = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            message: <Fragment>{t('Are_You_Sure_That_You_Want_To_Delete_Address')} <b>{data.addressLocale}</b> ? </Fragment>,
            onAgree: () => deleteAddress(addressFormik.values.partyAddressId),
            onDisAgree: resetConfirmation,
        }))
    }

    const resetConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: t("Confirmation"),
            message: '',
            onDisAgree: '',
            onAgree: ''
        }))
    }

    const handleEditAddress = () => {
        setDisabled(true) // disabled add address button while editing
        setEditAddress(true)
        addressFormik.setTouched({
            cityOrTown: true,
            postalCode: true,
            state: true
        });
    }

    const handleCancel = () => {
        if (addressFormik.values.partyAddressId) {
            resetAddressFormik();
            setDisabled(false);
        } else {
            handleCloseAddress(null);
        }
        setEditAddress(false)
    }

    return (
        <Fragment>
            <Grid container spacing={1.5} mt={1}>
                <Grid item xs={6}>
                    <TextAreaLabelController
                        id={'addressLine1'}
                        fieldName='addressLine1'
                        label={t('Address_Line1')}
                        formik={addressFormik}
                        minRows={1}
                        required={true}
                        textAlign='start'
                        disabled={addressFormik.values.partyAddressId && !editAddress}
                        handleChange={addressFormik.handleChange}
                        handleBlur={addressFormik.handleBlur}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextAreaLabelController
                        id={'addressLine2'}
                        fieldName='addressLine2'
                        label={t('Address_Line2')}
                        formik={addressFormik}
                        minRows={1}
                        textAlign='start'
                        disabled={addressFormik.values.partyAddressId && !editAddress}
                        handleChange={addressFormik.handleChange}
                    />
                </Grid>
                <Grid item xs={3} style={{ textAlign: 'start' }}>
                    <FormikLabelTextField
                        id="country"
                        fieldName="country"
                        label={t("Country")}
                        formik={addressFormik}
                        size="small"
                        required={true}
                        menuFieldId={'countryId'}
                        disabled={true}
                    />
                    {/* } */}
                </Grid>
                <Grid item xs={3} style={{ textAlign: 'start' }}>
                    <FormikLabelTextField
                        id="postalCode"
                        fieldName="postalCode"
                        label={t("PinCode")}
                        formik={addressFormik}
                        size="small"
                        required={true}
                        disabled={addressFormik.values.partyAddressId && !editAddress}
                        handleChange={handleChangePostalCode}
                        handleBlur={addressFormik.handleBlur}
                    />
                </Grid>
                <Grid item xs={3} style={{ textAlign: 'start' }}>
                    {(editAddress || addressFormik.values.partyAddressId === '') ?
                        <SelectLabelController
                            id="state"
                            fieldName="state"
                            label={t("State")}
                            formik={addressFormik}
                            size="small"
                            required={true}
                            menuItems={stateListContext.stateList}
                            menuFieldId={'stateId'}
                            menuFieldTitle={'name'}
                            valueField={'name'}
                            handleChange={handleStateChange}
                            handleBlur={addressFormik.handleBlur}
                        />
                        :
                        <FormikLabelTextField
                            id="state"
                            fieldName="state"
                            label={t("State")}
                            formik={addressFormik}
                            size="small"
                            required={true}
                            menuFieldId={'stateId'}
                            disabled={true}
                        />
                    }
                </Grid>
                <Grid item xs={3} style={{ textAlign: 'start' }}>
                    {(editAddress || addressFormik.values.partyAddressId === '') ? <SelectLabelController
                        id="cityOrTown"
                        fieldName="cityOrTown"
                        label={t("City_Town")}
                        formik={addressFormik}
                        size="small"
                        required={true}
                        menuItems={cityTownMenu}
                        menuFieldId={'cityId'}
                        menuFieldTitle={'name'}
                        valueField={'name'}
                        handleChange={handleCityChange}
                        handleBlur={addressFormik.handleBlur}
                    /> :
                        <FormikLabelTextField
                            id="cityOrTown"
                            fieldName="cityOrTown"
                            label={t("City_Town")}
                            formik={addressFormik}
                            size="small"
                            required={true}
                            menuFieldId={'cityId'}
                            disabled={true}
                        />
                    }
                </Grid>
            </Grid><br />
            <Stack direction='row' justifyContent='flex-end' spacing={2}>
                {isEnkAdmin && (addressFormik.values.partyAddressId && !(editAddress) && !hideDelete) && <ICMSButton
                    variant='contained'
                    color='error'
                    size='small'
                    onClick={deleteListAddress}
                    // disabled={disableCRUD}
                    // disabled={isEnkAdmin ? false : true}
                >{t("Delete")}
                </ICMSButton>}
                {addressFormik.values.partyAddressId && !(editAddress) && <ICMSButton
                    variant='contained'
                    size='small'
                    onClick={handleEditAddress}
                    disabled={disableCRUD}
                >{t("Edit")}
                </ICMSButton>}
                {(addressFormik.values.partyAddressId === '' || editAddress) && <ICMSButton variant='outlined' size='small' onClick={handleCancel}>{t("Cancel")}</ICMSButton>}
                {(addressFormik.values.partyAddressId === '' || editAddress) && <ICMSButton variant='contained' size='small' disabled={!addressFormik.dirty || !addressFormik.isValid || buttonDisable} onClick={SubmitFormWithValues}>{addressFormik.values.partyAddressId ? t("Update") : t("Save")}</ICMSButton>}
            </Stack>
            {loading && <ICMSLoader show={loading} />}
            {seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message}
                onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} />}

        </Fragment>
    )
}

export default AddressDetails;