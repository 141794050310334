import { FormLabel, InputAdornment, Stack, TextField } from "@mui/material"
import MuiPhoneNumber from "mui-phone-number"
import { Fragment } from "react"
import { BiSolidMessageError } from "react-icons/bi"
import { FaCheck } from "react-icons/fa6"

const FormikLabelMuiPhone = (props) => {
    const { id, fieldName, label, formik, countryCodeName, disabled, required, handleBlur, size } = props;

    const onCountryCodeChange = (value) => {
        formik.setFieldTouched(fieldName, true);
        formik.setFieldValue(countryCodeName, value)
    }

    const onInputMobileNoChange = (event) => {
        formik.setFieldTouched(fieldName, true);
        let value = event.target.value;
        if (/^\d+$/.test(value)) {
            formik.setFieldValue(fieldName, value)
            // validateMobileNumber(formik.values[countryCodeName] + value, formik, fieldName)
        } else if (value.length === 0) {
            formik.setFieldValue(fieldName, '')
        }
    }

    return (
        <Stack spacing={0.01}>
            <FormLabel htmlFor={id} sx={{ fontSize: (theme) => theme.typography.body2 }} required={required}>{label}</FormLabel>
            <TextField
                id={id}
                name={fieldName}
                value={formik.values[fieldName]}
                error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                helperText={formik.touched[fieldName] && formik.errors[fieldName]}
                onChange={onInputMobileNoChange}
                onBlur={handleBlur}
                disabled={disabled}
                size={size}
                // onBlur={onMobileBlur}
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment
                            position="start"
                            style={{ width: `${44 + formik.values.extension.length * 19}px` }}
                        >
                            <MuiPhoneNumber
                                id="extension"
                                name="extension"
                                defaultCountry={"in"}
                                // disableCountryCode
                                value={formik.values[countryCodeName]}
                                error={formik.touched[countryCodeName] && Boolean(formik.errors[countryCodeName])}
                                helperText={formik.touched[countryCodeName] && formik.errors[countryCodeName]}
                                onChange={(event) => onCountryCodeChange(event)}
                                autoFormat={false}
                                size={size}
                                // onBlur={onMobileBlur}
                                style={{ top: "1px", color: "black !important" }}
                                required
                                disabled
                                disableDropdown={disabled}
                                countryCodeEditable={false}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <Fragment>
                            <InputAdornment position='end'>
                                {formik.touched[fieldName] && Boolean(formik.errors[fieldName]) && <BiSolidMessageError style={{ color: '#d32f2f', fontSize: '1.5rem' }} />}
                                {/* {validationIcon && formik.touched[fieldName] && !formik.errors[fieldName] && <FaCheck style={{ color: '#4caf50', fontSize: '1.3rem' }} />} */}
                            </InputAdornment>
                        </Fragment>
                    ),
                    sx: {
                        background: (theme) => theme.palette.background.paper,
                    }
                }}
                inputProps={{
                    style: {
                        height: '45px',
                        padding: '0px'
                    },
                }}
            />
        </Stack>
    )
}

export { FormikLabelMuiPhone }
