import React from 'react'
import AddressDetail from '../../address/AddressDetails'
import { Collapse, Grid, Typography } from '@mui/material';
import { FormikLabelTextField, FormikTextField } from '../../../common/formik-fields-components/FormikFieldComponents';
import { ApplicationUsers } from '../../../common/GenericCodes';
import { useTranslation } from 'react-i18next';

const RoleAddress = (props) => {
    const { loginPurposeFormik, selectedRole, handleChange, isselected } = props;
    const { t } = useTranslation();

    return (
        <div>
            {(selectedRole === ApplicationUsers.IP || selectedRole === ApplicationUsers.VALUER) && 
            // <Collapse in={isselected}>
                <Grid container>
                    <Grid  item xs={6} >
                        <FormikLabelTextField customStyle={{marginTop: '0px !important'}} id="ibbiRegNumber" fieldName="ibbiRegNumber" label={t('IBBI_Reg_Number')}
                            formik={loginPurposeFormik} handleChange={handleChange} required={true} size='small'
                        />
                    </Grid>
                </Grid>
            // </Collapse>
            }
            {(selectedRole === ApplicationUsers.CLAIMANT || selectedRole === ApplicationUsers.RESOLUTION_APPLICANT || selectedRole === ApplicationUsers.OTHER) &&
            //  <Collapse in={isselected}>
                <Grid container>
                    <Grid item xs={6} >
                        <FormikLabelTextField customStyle={{marginTop: '0px !important'}} id="pan" fieldName="pan" label={t('PAN')} size='small'
                            formik={loginPurposeFormik} handleChange={handleChange} required={true}
                        />
                    </Grid>
                </Grid>
            // </Collapse>
            }
            <Typography  sx={{paddingTop: '2rem', paddingBottom: '-3rem', fontSize:'14px', fontWeight:'bold'}}>
                {(selectedRole === ApplicationUsers.IP || selectedRole === ApplicationUsers.VALUER) ? 'IBBI Registered Address' : 'Registered Address'}
            </Typography>
            <AddressDetail addressFormik={loginPurposeFormik} required={true} />
        </div>
    )
}

export default RoleAddress