import React, { useEffect, useRef, useState, Fragment } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import { Grid, TextField, Card, CardActions, Button, Container, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { MdPassword } from 'react-icons/md';
import IcmsSnackbar from '../icms-snackbar/IcmsSnackbar';
import { ICMSButton } from '../../common/icms-styled-components/IcmsStyledComponents';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

const PDFViewer = ({ pdfData }) => {
    const { t } = useTranslation();
    const containerRef = useRef(null);
    const [password, setPassword] = useState('');
    const [passwordRequired, setPasswordRequired] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    useEffect(() => {
        const loadPDF = async () => {
            setMessage({show: false, message: '', severity: ''});
            try {
                const blob = new Blob([pdfData], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(blob);
                setPdfUrl(pdfUrl);
                const loadingPdf = pdfjsLib.getDocument({ url: pdfUrl, password });
                const pdf = await loadingPdf.promise;
                const numPages = pdf.numPages;

                for (let pageNum = 1; pageNum <= numPages; pageNum++) {
                    const page = await pdf.getPage(pageNum);
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    const viewport = page.getViewport({ scale: 1 });
                    canvas.width = containerRef.current.offsetWidth;
                    canvas.height = (containerRef.current.offsetWidth / viewport.width) * viewport.height;

                    const scale = containerRef.current.offsetWidth / viewport.width;

                    const renderContext = {
                        canvasContext: context,
                        viewport: page.getViewport({ scale })
                    };
                    await page.render(renderContext);

                    containerRef.current.appendChild(canvas);
                }
            } catch (error) {
                if (error?.name === 'PasswordException') {
                        setPasswordRequired(true);
                        setPassword('');
                        error?.message !== 'No password given' && setMessage({show: true, message: error.message, severity: 'error'});
                } else {
                    console.log('Error:', error);
                }
            }
        };

        if (!passwordRequired) {
            loadPDF();
        }

        return () => {
            URL.revokeObjectURL(pdfUrl);
        };

    }, [pdfData, password, passwordRequired]);

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        setPasswordRequired(false);
    };

    return (
        <Fragment>
        <Grid container justifyContent="center">
            <Grid item xs={12}>
                {passwordRequired ? (
                    <form onSubmit={handlePasswordSubmit} style={{alignItems: 'center', display: 'flex', justifyContent: 'center', height: '55vh'}}>
                        <Container maxWidth='xs'>
                            <Card>
                                <Grid container spacing={1.5} paddingLeft={2} paddingRight={2}>
                                    <Grid item xs={12} style={{marginTop: '4px', textAlign: 'center'}}>
                                        <MdPassword style={{fontSize: "1.5rem"}}/>
                                    </Grid>
                                    <Grid item xs={12} style={{marginTop: '4px'}}>
                                        <Typography variant="body2">{t('Enter_The_Password_To_Open_This_PDF_File')}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label={t('Password')}
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder={t('Enter_Password')}
                                            id='pdf-password'
                                            fullWidth
                                            size='small'
                                            autoComplete='new-password'
                                        />
                                    </Grid>
                                </Grid>
                                <CardActions>
                                    <Grid container>
                                        <Grid item xs={12} style={{textAlign: 'center'}}>
                                            <ICMSButton color="primary" type="submit" variant='contained'
                                                size='small' disabled={!password}>{t('Submit')}</ICMSButton>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Container>
                    </form>
                ) :
                (<div ref={containerRef} style={{ width: '100%', height: '100%' }} />)}
            </Grid>
            
        </Grid>
        {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} />}
        </Fragment>
    );
};

export default PDFViewer;