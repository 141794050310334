import React, {
    useState,
    useEffect
}                               from 'react';
import {
    Grid,
    TableBody,
    TableHead,
    IconButton,
    Tooltip
}                               from '@mui/material';
import {
    IcmsTableContainer,
    IcmsMuiTable,
    IcmsTableRow,
    StyledTableCell
}                               from '../generic-styles/NavPageContainerStyles';
import { useTranslation }       from 'react-i18next';
import AddIcon                  from '@mui/icons-material/Add';
import PhoneNumberRow           from './PhoneNumberRow';
import { ICMSAxiosInterceptor } from '../../config/axios.interceptor';
import { ContactType }          from '../GenericCodes';
import ICMSLoader               from '../../common/icms-loader/ICMSLoader';
import IcmsSnackbar             from '../../common/icms-snackbar/IcmsSnackbar';

const PhoneNumberList = ({partyId, canAdd, canModify, canDelete}) => {
    const { t } = useTranslation();
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    });
    const columns = [
        { id: 'phoneNumber', label: 'Phone_Number', minWidth: '75%' },
        { id: 'action', label: 'Action', minWidth: '25%', align: 'center' }
    ]

    const addPhoneNumber = () => {
        let phoneNumber = {
            phoneNumber: '',
            isEdit: true
        }
        setPhoneNumbers(prevState => ([
            phoneNumber,
            ...prevState
        ]))
    }

    const getPhoneNumbers = () => {
        if (partyId) {
            setLoading(true);
            ICMSAxiosInterceptor.get(`contact/list/${partyId}?contactCd=${ContactType.PHONE_NUMBER}`).then(response => {
                setPhoneNumbers(response.items);
                setLoading(false);
            }).catch(error => {
                if (error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                }
                setLoading(false);
            })
        } else {
            setLoading(true);
            ICMSAxiosInterceptor.get(`contact/list`).then(response => {
                setPhoneNumbers(response.items);
                setLoading(false);
            }).catch(error => {
                if (error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                }
                setLoading(false);
            })
        }
    }

    const createPhoneNumber = (phoneNumberDetails) => {
        if (partyId) {
            setLoading(true);
            ICMSAxiosInterceptor.post(`contact/${partyId}`, phoneNumberDetails).then(response => {
                getPhoneNumbers();
                setMessage({show: true, message: t('Created_Phone_Number_Successfully'), severity: 'success'});
                setLoading(false);
            }).catch(error => {
                if (error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                }
                setLoading(false);
            })
        } else {
            setLoading(true);
            ICMSAxiosInterceptor.post(`contact`, phoneNumberDetails).then(response => {
                getPhoneNumbers();
                setMessage({show: true, message: t('Created_Phone_Number_Successfully'), severity: 'success'});
                setLoading(false);
            }).catch(error => {
                if (error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                }
                setLoading(false);
            })
        }
    }

    const updatePhoneNumber = (phoneNumberDetails) => {
        setLoading(true);
        ICMSAxiosInterceptor.put(`contact/${phoneNumberDetails.partyContactId}`, phoneNumberDetails).then(response => {
            getPhoneNumbers();
            setMessage({show: true, message: t('Updated_Phone_Number_Successfully'), severity: 'success'});
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            }
            setLoading(false);
        })
    }

    const deletePhoneNumber = (partyContactId) => {
        if (partyId) { 
            setLoading(true);
            ICMSAxiosInterceptor.delete(`contact/${partyContactId}/party/${partyId}`).then(response => {
                getPhoneNumbers();
                setMessage({show: true, message: t('Deleted_Phone_Number_Successfully'), severity: 'success'});
                setLoading(false);
            }).catch(error => {
                if (error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                }
                setLoading(false);
            })
        } else {
            setLoading(true);
            ICMSAxiosInterceptor.delete(`contact/${partyContactId}`).then(response => {
                getPhoneNumbers();
                setMessage({show: true, message: t('Deleted_Phone_Number_Successfully'), severity: 'success'});
                setLoading(false);
            }).catch(error => {
                if (error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                }
                setLoading(false);
            })
        }
    }

    useEffect(() => {
        getPhoneNumbers();
    }, [])
    

    return (
        <Grid container direction="row" alignItems="baseline">
            <IcmsTableContainer>
                {canAdd && <Grid container sx={{padding: '0px 20px 0px 20px !important'}} spacing={2}>
                    <Grid item xs={12}>
                        <Tooltip title={t('Add_Phone_Number')}>
                            <IconButton data-testid="add-phone-number-btn" color="primary" size="small" sx={{float: 'right'}}
                                onClick={addPhoneNumber}><AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>}
                <IcmsMuiTable stickyHeader aria-label="sticky table" data-testid="phone-number-list-table">
                    <TableHead>
                        <IcmsTableRow>
                            {columns.map((column) => (
                                <StyledTableCell
                                    sx={{padding: '4px'}}
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {t(column.label)}
                                </StyledTableCell>
                            ))}
                        </IcmsTableRow>
                    </TableHead>
                    <TableBody>
                        {phoneNumbers.length === 0 && <IcmsTableRow colSpan={2}>
                            <StyledTableCell sx={{padding: '8px'}}>
                                {t('No_Record_Found')}
                            </StyledTableCell>
                        </IcmsTableRow>}
                        {!loading && phoneNumbers.map((phoneNumber, index) => {
                            return <PhoneNumberRow key={phoneNumber.contact} phoneNumberDetails={phoneNumber} handleCreate={createPhoneNumber} 
                                    handleUpdate={updatePhoneNumber} handleDelete={deletePhoneNumber}
                                    handleRefresh={getPhoneNumbers} edit={phoneNumber.isEdit} partyId={partyId}
                                    canModify={canModify} canDelete={canDelete}/>
                        })}
                    </TableBody>
                </IcmsMuiTable>
            </IcmsTableContainer>
            {loading && <ICMSLoader show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </Grid>
    )
}

export default PhoneNumberList