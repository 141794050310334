import { Grid, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { listCityTown, listCountries, listState } from "../../lookup-service/AddressService";
import { StepperSectionHeader } from "../../common/icms-styled-components/StepperSectionHeader";
import { TextAreaLabelController } from "../../common/formik-fields-components/TextAreaLabelController";
import { SelectLabelController } from "../../common/formik-fields-components/SelectLabelController";
import { DefaultCountry } from '../../common/GenericCodes';
import { FormikLabelNumericFormat } from "../../common/formik-fields-components/FormikLabelNumericFormat";
import { FormikLabelTextField } from "../../common/formik-fields-components/FormikFieldComponents"
import { useTranslation } from "react-i18next";
import MapModalComponent from "../../common/utils/map/MapModalComponent";
import { ICMSButton } from "../../common/icms-styled-components/IcmsStyledComponents";

const AddressDetail = (props) => {
    const { addressTitle, addressFormik, editMode, isDisabled, required, addressDetail, showLocationSelector } = props;
    const [countryMenu, setCountryMenu] = useState([{ name: DefaultCountry.COUNTRY_NAME, countryId: DefaultCountry.COUNTRY_ID }]);
    const [stateMenu, setStateMenu] = useState([]);
    const [cityTownMenu, setCityTownMenu] = useState([]);
    const [countryId, setCountryId] = useState(DefaultCountry.COUNTRY_ID);
    const [stateId, setStateId] = useState('');
    const { t } = useTranslation();
    const [showMap, setShowMap] = useState(false);

    const getCountryMenu = () => {
        listCountries((response) => {
            if (response) {
                setCountryMenu(response);
            }
        },
            (error) => {
                console.error('List Lookup Countries', error);
            })
    }

    const getStateMenu = () => {
        if (!sessionStorage.getItem('states')) {
            listState(countryId, (response) => {
                if (response) {
                    setStateMenu(response);
                    sessionStorage.setItem('states', JSON.stringify(response))
                    response.forEach((state) => {
                        if (state.name === addressFormik.values.state) {
                            setStateId(state.stateId);
                        }
                    })
                }
            },(error) => {
                console.error('List Lookup States', error);
            })
        } else {
            let states = JSON.parse(sessionStorage.getItem('states'));
            setStateMenu(states);
            states.forEach((state) => {
                if (state.name === addressFormik.values.state) {
                    setStateId(state.stateId);
                }
            })
        }
    }

    const getCityTownMenu = () => {
        listCityTown(stateId, (response) => {
            if (response) {
                setCityTownMenu(response);
            }
        },
            (error) => {
                console.error('List Lookup CityTowns', error);
            })
    }

    const handleStateChange = (event) => {
        stateMenu.forEach((state) => {
            if (state.name === event.target.value) {
                addressFormik.setFieldValue([event.target.name], event.target.value);
                setStateId(state.stateId);
                addressFormik.setFieldValue('cityOrTown', '');
            }
        })
    }

    const handleCityChange = (event) => {
        cityTownMenu.forEach((country) => {
            if (country.name === event.target.value) {
                addressFormik.setFieldValue([event.target.name], event.target.value);
            }
        })
    }

    useEffect(() => {
        if (stateId !== '') {
            getCityTownMenu();
        }
    }, [stateId])

    useEffect(() => {
        if (stateMenu.length !== 0) {
            if (addressFormik.values.state) {
                const selectedState = stateMenu.find(
                    (country) => country.name === addressFormik.values.state
                );
                if (selectedState) {
                    addressFormik.setFieldValue('state', selectedState.name);
                    setStateId(selectedState.stateId);
                }
            }
        }

    }, [stateMenu]);

    useEffect(() => {
        if (countryMenu.length === 0) {
            getCountryMenu();
        }
        if (Object.keys(addressFormik.values).length > 0) {
            if (addressFormik.values.country) {
                const selectedCountry = countryMenu.find(
                    (country) => country.name === addressFormik.values.country
                );
                if (selectedCountry) {
                    addressFormik.setFieldValue('country', selectedCountry.name);
                    setCountryId(selectedCountry.countryId);
                }
            }
        }
    }, [countryMenu, addressFormik.values]);

    useEffect(() => {
        if (countryMenu.length === 0) {
            getCountryMenu();
        }
        getStateMenu();
    }, [addressFormik.values.state])

    const handleSetCoordinates = (coordinates) => {
        addressFormik.setFieldValue('coordinate', coordinates);
    }

    const handleSetArea = (area) => {
        addressFormik.setFieldValue('area', area)
    }
    
    return (
        <Fragment>
            {addressTitle && <StepperSectionHeader title={addressTitle} />}
            <Grid container spacing={1.5} mt={1}>
                <Grid item xs={6}>
                    <TextAreaLabelController
                        id={'addressLine1'}
                        fieldName='addressLine1'
                        label={t('Address_Line1')}
                        onBlur={addressFormik.handleBlur}
                        size="small"
                        formik={addressFormik}
                        minRows={1}
                        required={required && !isDisabled}
                        handleChange={addressFormik.handleChange}
                        handleBlur={addressFormik.handleBlur}
                        disabled={(!!addressFormik.addressLine1) || isDisabled}
                    />
                    {addressFormik.touched.ipa && addressFormik.errors.addressLine1 && (
                        <Typography variant="caption" color="error">
                            {addressFormik.errors.addressLine1}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <TextAreaLabelController
                        id={'addressLine2'}
                        fieldName='addressLine2'
                        label={t("Address_Line2")}
                        size="small"
                        formik={addressFormik}
                        minRows={1}
                        handleChange={addressFormik.handleChange}
                        handleBlur={addressFormik.handleBlur}
                        disabled={( !!addressFormik.addressLine2) || isDisabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormikLabelTextField
                        id="country"
                        fieldName="country"
                        label={("Country")}
                        formik={addressFormik}
                        size="small"
                        required={required && !isDisabled}
                        menuFieldId={'countryId'}
                        disabled={true}
                    />
                    {/* <SelectLabelController
                        id="country"
                        fieldName="country"
                        label="Country"
                        formik={addressFormik}
                        size="small"
                        required={true}
                        menuItems={countryMenu}
                        menuFieldId={'countryId'}
                        menuFieldTitle={'name'}
                        valueField={'name'}
                        disabled={true}
                    /> */}
                    {/* {addressFormik.touched.ipa && addressFormik.errors.country && (
                      <Typography variant="caption" color="error">
                        {addressFormik.errors.country}
                      </Typography>
                    )} */}
                    {/* <FormikLabelTextField
                            id="country"
                            fieldName="country"
                            label="Country"
                            formik={addressFormik}
                            size="medium"
                            required={true}
                            handleChange={addressFormik.handleChange}
                        /> */}
                </Grid>
                <Grid item xs={3}>
                    <FormikLabelNumericFormat
                        id="postalCode"
                        fieldName="postalCode"
                        label={t("Pincode")}
                        size="small"
                        formik={addressFormik}
                        required={required && !isDisabled}
                        handleChange={addressFormik.handleChange}
                        handleBlur={addressFormik.handleBlur}
                        disabled={(!!addressFormik.postalCode) || isDisabled}
                    />
                    {addressFormik.touched.ipa && addressFormik.errors.postalCode && (
                        <Typography variant="caption" color="error">
                            {addressFormik.errors.postalCode}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={3}>
                    <SelectLabelController
                        id="state"
                        fieldName="state"
                        label={t("State")}
                        formik={addressFormik}
                        size="small"
                        required={required && !isDisabled}
                        menuItems={stateMenu}
                        menuFieldId={'stateId'}
                        menuFieldTitle={'name'}
                        valueField={'name'}
                        handleChange={handleStateChange}
                        handleBlur={addressFormik.handleBlur}
                        disabled={ (!!addressFormik.state)|| isDisabled}
                    />
                    {addressFormik.touched.ipa && addressFormik.errors.state && (
                        <Typography variant="caption" color="error">
                            {addressFormik.errors.state}
                        </Typography>
                    )}
                    {/* <FormikLabelTextField
                            id="state"
                            fieldName="state"
                            label="State"
                            formik={addressFormik}
                            size="medium"
                            required={true}
                            handleChange={addressFormik.handleChange}
                        /> */}
                </Grid>
                <Grid item xs={3}>
                    <SelectLabelController
                        id="cityOrTown"
                        fieldName="cityOrTown"
                        label={t("City_Town")}
                        formik={addressFormik}
                        size="small"
                        required={required && !isDisabled}
                        menuItems={cityTownMenu}
                        menuFieldId={'cityId'}
                        menuFieldTitle={'name'}
                        valueField={'name'}
                        handleChange={handleCityChange}
                        onBlur={addressFormik.handleBlur}
                        handleBlur={addressFormik.handleBlur}
                        disabled={(!!addressFormik.cityOrTown) || isDisabled}
                    />
                    {addressFormik.touched.ipa && addressFormik.errors.cityOrTown && (
                        <Typography variant="caption" color="error">
                            {addressFormik.errors.cityOrTown}
                        </Typography>
                    )}
                    {/* <FormikLabelTextField
                            id="cityOrTown"
                            fieldName="cityOrTown"
                            label="City"
                            formik={addressFormik}
                            size="medium"
                            required={true}
                            handleChange={addressFormik.handleChange}
                        /> */}
                </Grid>
                {showLocationSelector && <Fragment>
                    <Grid item xs={3}>
                        <FormikLabelTextField
                            id="coordinate.x"
                            fieldName="coordinate.x"
                            label={("Latitude")}
                            formik={addressFormik}
                            size="small"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormikLabelTextField
                            id="coordinate.y"
                            fieldName="coordinate.y"
                            label={("Longitude")}
                            formik={addressFormik}
                            size="small"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={2} style={{paddingTop: '2.2rem'}}>
                        <ICMSButton onClick={() => {setShowMap(true)}} color="primary" size="small" variant='outlined'>
                            {isDisabled ? t('Show_Location') : t('Select_Location')}
                        </ICMSButton>
                    </Grid>
                </Fragment>}
            </Grid>
            {showMap && <MapModalComponent show={showMap} onClose={() => setShowMap(false)} 
                coordinates={addressFormik.values.coordinate ? addressFormik.values.coordinate : null} 
                area={() => {}} setCoordinates={handleSetCoordinates} setArea={handleSetArea}
                disabled={isDisabled}/>}
        </Fragment>
    )
}

export default AddressDetail;