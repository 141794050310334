import { FormLabel, InputLabel, MenuItem, Select, Stack, FormHelperText, IconButton } from "@mui/material";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

const FormikSelectController = (props) => {
    const { id, fieldName, label, formik, menuItems, disabled, required, size, menuFieldId, menuFieldTitle, menuFieldTitle2, handleChange, placeholder } = props;
    
    return (
        <Stack>
            <InputLabel id={`${id}-label`} sx={{ fontSize: (theme) => theme.typography.body2 }} required={required}>{label}</InputLabel>
            <Select
                fullWidth
                id={id}
                labelId={`${id}-label`}
                // label={label}
                name={fieldName}
                value={formik.values[fieldName]}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                disabled={disabled}
                required={required}
                displayEmpty
                inputProps={{
                    "data-testid": id,
                }}
                sx={{
                    bgcolor: (theme) => disabled ? theme.palette.grey[100] : theme.palette.background.paper
                }}
                size={size}
                error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                endAdornment={
                    formik.values[fieldName] != null && formik.values[fieldName] != '' && !required && !disabled &&
                    <IconButton id={'clear-' + fieldName} data-testid={'clear-' + fieldName}
                        color="error" sx={{ marginRight: '20px !important' }}
                        onClick={() => formik.setFieldValue(fieldName, '')}
                    >
                        <HighlightOffRoundedIcon sx={{ fontSize: '20px', color: 'grey' }} />
                    </IconButton>
                }
            >
                 {required && placeholder && (
                    <MenuItem value="" disabled>
                        <em style={{color: 'gray'}}>{placeholder}</em>
                    </MenuItem>
                )}
                {menuItems.map((menu) => {
                    return (
                        <MenuItem key={`key-${menu[menuFieldId]}`} value={menu[menuFieldId]}>
                            { menu[menuFieldTitle] + ( (menuFieldTitle2 && menu[menuFieldTitle2]) ?  ' (' + menu[menuFieldTitle2] + ')'  : '' ) }
                        </MenuItem>
                    )
                })}
            </Select>
            <FormHelperText style={{color:'red'}}>{ formik.touched[fieldName] && formik.errors[fieldName] }</FormHelperText>
        </Stack>
    )
}

export { FormikSelectController };
