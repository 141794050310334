import React, { useState, useEffect }       from 'react';
import { useFormik }                        from "formik";
import {
    Dialog, DialogTitle,
    DialogContent, DialogActions, Grid
}                                           from '@mui/material';
import * as Yup                             from 'yup';
import { useTranslation }                   from 'react-i18next';
import ICMSLoader                           from '../../../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                         from '../../../../../common/icms-snackbar/IcmsSnackbar';
import { ICMSAxiosInterceptor }             from '../../../../../config/axios.interceptor';
import { SelectLabelController }            from '../../../../../common/formik-fields-components/SelectLabelController';
import { FormikLabelNumericFormat }         from '../../../../../common/formik-fields-components/FormikLabelNumericFormat';
import { TextAreaLabelController }          from '../../../../../common/formik-fields-components/TextAreaLabelController';
import { ICMSButton }                       from '../../../../../common/icms-styled-components/IcmsStyledComponents';
import { FormikNewDateTime }                from '../../../../../common/formik-fields-components/FormikNewDateTime';

const ClaimGuaranteeDetail = ({caseId, claimantId, debtDefaultId, show, onClose, guaranteeDetail,
        onSuccess}) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});
    const [guarantors, setGuarantors] = useState([]);

    const GuaranteeValidationSchema = Yup.object().shape({
        partyRelationshipId: Yup.string().required(t('Guarantor_Is_Required')),
        amount: Yup.number().required(t('Amount_Is_Required'))
    });

    const guaranteeFormik = useFormik({
        initialValues: {
            debtGuarantorId: guaranteeDetail?.debtGuarantorId ||  '',
            debtDefaultId: guaranteeDetail?.debtDefaultId ||  '',
            partyRelationshipId: guaranteeDetail?.partyRelationshipId ||  '',
            amount: guaranteeDetail?.amount ||  0,
            description: guaranteeDetail?.description ||  null,
            relinquish: guaranteeDetail?.relinquish ||  false,
            startTime: guaranteeDetail?.startTime ||  null,
            endTime: guaranteeDetail?.endTime ||  null
        },
        validationSchema: GuaranteeValidationSchema,
        validateOnBlur: true,
        validateOnMount: true,
        validateOnChange: true
    })

    const getGuarantors = () => {
        setLoading(true);
        ICMSAxiosInterceptor.post(`case/${caseId}/guarantor/list`, {}).then(response => {
            setGuarantors(response.items);
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'})
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'})
            }
            setLoading(false);
        })
    }
    
    const handleSave = () => {
        if (!guaranteeFormik.values.debtGuarantorId) {
            createGuarantee();
        } else {
            updateGuarantee();
        }
    }

    const createGuarantee = () => {
        setLoading(false);
        ICMSAxiosInterceptor.post(`case/${caseId}/claimant/${claimantId}/claim/${debtDefaultId}/guarantor-link/${guaranteeFormik.values.partyRelationshipId}`, 
                guaranteeFormik.values).then(response => {
            onSuccess({show: true, message: t('Guarantee_Added_Successfully'), severity: 'success'});
            onClose();
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'})
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'})
            }
            setLoading(false);
        })
    }

    const updateGuarantee = () => {
        setLoading(false);
        ICMSAxiosInterceptor.put(`case/${caseId}/claimant/${claimantId}/claim/${debtDefaultId}/guarantor-link/${guaranteeFormik.values.debtGuarantorId}`, 
                guaranteeFormik.values).then(response => {
            onSuccess({show: true, message: t('Guarantee_Updated_Successfully'), severity: 'success'});
            onClose();
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'})
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'})
            }
            setLoading(false);
        })
    }

    useEffect(() => {
        getGuarantors();
    }, [])
    
    return (
        <Dialog open={show} fullWidth maxWidth="xs">
            <DialogTitle>{t('Guarantee_Details')}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <SelectLabelController
                            id="partyRelationshipId"
                            fieldName="partyRelationshipId"
                            formik={guaranteeFormik}
                            label={t('Select_Guarantor')}
                            size="small"
                            menuItems={guarantors}
                            required={true}
                            handleChange={guaranteeFormik.handleChange}
                            handleBlur={guaranteeFormik.handleBlur}
                            menuFieldId={'id'}
                            menuFieldTitle={'name'}
                            valueField={'id'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikLabelNumericFormat
                            id="amount"
                            fieldName="amount"
                            label={t("Guarantee_Amount")}
                            formik={guaranteeFormik}
                            size="small"
                            isThousandSeparator={true}
                            required={true}
                            handleChange={guaranteeFormik.handleChange}
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <YesNoSwitchController
                            title={t('Relinquish')} 
                            description={t('Relinquish_Helper_Text')}
                            fieldName="relinquish"
                            id="relinquish"
                            formik={guaranteeFormik}/>
                    </Grid> */}
                    <Grid item xs={6}>
                        <FormikNewDateTime
                            id='startTime'
                            fieldName='startTime'
                            formik={guaranteeFormik}
                            maxDate={(new Date())}
                            label={t('Guarantee_From')}
                            // required={true}
                            handleChange={guaranteeFormik.handleChange}
                            size='small'
                            onlyDate={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormikNewDateTime
                            id='endTime'
                            fieldName='endTime'
                            formik={guaranteeFormik}
                            minDate={(new Date(guaranteeFormik.values.startTime))}
                            label={t('Guarantee_To')}
                            // required={true}
                            handleChange={guaranteeFormik.handleChange}
                            size='small'
                            onlyDate={true}
                            disabled={!guaranteeFormik.values.startTime}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextAreaLabelController
                            id={'description'}
                            fieldName='description'
                            label={t('Description')}
                            formik={guaranteeFormik}
                            minRows={2}
                            textAlign='start'
                            handleChange={guaranteeFormik.handleChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ICMSButton onClick={onClose} color="primary" size="small" variant='outlined'>
                    {t('Cancel')}
                </ICMSButton>
                <ICMSButton onClick={handleSave} color="primary" size="small" variant='contained' 
                        disabled={!guaranteeFormik.isValid || !guaranteeFormik.dirty}>
                    {t('Save')}
                </ICMSButton>
            </DialogActions>
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
        </Dialog>
    )
}

export default ClaimGuaranteeDetail