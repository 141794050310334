import React, { Fragment, useState }            from "react";
import { Delete, Edit }                         from "@mui/icons-material";
import SendIcon                                 from '@mui/icons-material/Send';
import { IconButton, TableBody, 
    TableHead, Tooltip }                        from "@mui/material";
import { useTranslation }                       from 'react-i18next';
import { 
    IcmsTableContainer, IcmsMuiTable,
    IcmsTableRow, StyledTableCell
}                                               from '../../../../common/generic-styles/NavPageContainerStyles';
import UpdateAuthRep                            from './UpdateAuthRep';
import { ICMSAxiosInterceptor }                 from "../../../../config/axios.interceptor";
import ICMSConfirmDialog                        from '../../../../common/ConfirmationDialog/ICMSConfirmDialog';
import IcmsSnackbar                             from '../../../../common/icms-snackbar/IcmsSnackbar';
import ICMSLoader                               from '../../../../common/icms-loader/ICMSLoader';
import AuthRepRow                               from "./AuthRepRow";

const AuthRepList = (props) => {
    const { authRepList, onDelete, caseId, claimantId, onSuccess, canEdit, canSendCredentials, claimantPartyRelationshipId,
        getCreditor
     } = props;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});
    const [showUpdateAuthRep, setShowUpdateAuthRep] = useState({
        show: false,
        authorizedRepresentativeId: null
    })
    const columns = [
        { id: 'name', label: 'Name', minWidth: '23%' },
        // { id: 'identification', label: 'Identification', minWidth: '10%'},
        { id: 'designation', label: 'Designation', minWidth: '15%'},
        { id: 'email', label: 'Email', minWidth: '25%'},
        { id: 'phoneNumber', label: 'Mobile_Number', minWidth: '15%'},
        { id: 'active', label: 'Active', minWidth: '8%'},
        { id: 'action', label: 'Action', minWidth: '12%' }
    ]

    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('Yes'),
        disAgreeBtnLabel: t('No'),
        onDisAgree: ''
    })

    const handleUpdateAuthRep = (authorizedRepresentativeId) => {
        setShowUpdateAuthRep({
            show: true,
            authorizedRepresentativeId: authorizedRepresentativeId
        })
    }

    const sendCredentialsConfirmation = (authRep) => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: `${t('Are_You_Sure_That_You_Want_To_Send_Credentials_To')} ${authRep.name} ?`,
            onDisAgree: resetSeekConfirmation,
            onAgree: () => {
                resetSeekConfirmation();
                sendCredentials(authRep.authorizedRepresentativeId)
            },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    const sendCredentials = (authorizedRepresentativeId) => {
        setLoading(true);
        ICMSAxiosInterceptor.post(`case/${caseId}/claimant/${claimantId}/authorized-representative/${authorizedRepresentativeId}/account`, {}).then(response => {
            onSuccess({show: true, message: t('Credentials_Sent_Successfully'), severity: 'success'});
            if (getCreditor) getCreditor();
            setLoading(false);
        }).catch(error => {
            setMessage({show: true, message: error.message, severity: 'error',});
            setLoading(false);
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    return (
        <div>
            <IcmsTableContainer>
                <IcmsMuiTable stickyHeader aria-label="sticky table" data-testid="auth-rep-list-table">
                    <TableHead>
                        <IcmsTableRow>
                            {columns.map((column) => (
                                <StyledTableCell
                                    role="columnheader"
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, padding: '8px' }}
                                >
                                    {t(column.label)}
                                </StyledTableCell>
                            ))}
                        </IcmsTableRow>
                    </TableHead>
                    <TableBody>
                    {
                        authRepList.length === 0 && <IcmsTableRow hover sx={{fontSize: '0.8rem'}}>
                            <StyledTableCell sx={{padding: '8px'}} colSpan={7}>
                                {t('No_Record_Found')}
                            </StyledTableCell>
                        </IcmsTableRow>
                    }
                    {
                        authRepList.length > 0 && authRepList.map((authRep, index) => {
                            return (
                                <AuthRepRow authRep={authRep} handleUpdateAuthRep={handleUpdateAuthRep} 
                                    canSendCredentials={canSendCredentials} sendCredentialsConfirmation={sendCredentialsConfirmation} 
                                    onDelete={onDelete} canEdit={canEdit} claimantPartyRelationshipId={claimantPartyRelationshipId}
                                    caseId={caseId} onSuccess={onSuccess} getCreditor={getCreditor}/>
                            )
                        })
                    }
                    </TableBody>
                </IcmsMuiTable>
            </IcmsTableContainer>
            {showUpdateAuthRep.show && <UpdateAuthRep show={showUpdateAuthRep.show}
                authorizedRepresentativeId={showUpdateAuthRep.authorizedRepresentativeId}
                caseId={caseId} claimantId={claimantId}
                onClose={() => setShowUpdateAuthRep({
                    show: false,
                    authorizedRepresentativeId: null
                })}
                onSuccess={(message) => {
                    if (onSuccess) onSuccess(message);
                    setShowUpdateAuthRep({
                        show: false,
                        authorizedRepresentativeId: null
                    })
                }}
            />}
            {seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}
            />}
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} 
                    handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
        </div>
    )
}

export default AuthRepList;