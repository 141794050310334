import { Close } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, RadioGroup, Stack, Tooltip, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsClipboardCheckFill, BsClipboardDataFill, BsFilePersonFill, BsPersonFillGear } from "react-icons/bs";
import { FaClipboardList } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import ICMSConfirmDialog from "../../common/ConfirmationDialog/ICMSConfirmDialog";
import { AddressTypes, ApplicationUsers, CaseManagementSideNav, DefaultCountry, IdentificationType, RegEx, Roles } from '../../common/GenericCodes';
import { UserContext } from '../../common/context/UserContext';
import IcmsSnackbar from "../../common/icms-snackbar/IcmsSnackbar";
import { ICMSButton } from '../../common/icms-styled-components/IcmsStyledComponents';
import { setFormikValueWithUpperCase } from '../../common/utils/Utils';
import { ICMSAxiosInterceptor } from "../../config/axios.interceptor";
import ClaimSteps from "../claim-stepper/ClaimSteps";
import { ColorlibStepIconRoot } from "../claim-stepper/ClaimStyles";
import RoleAddress from "./role-card/RoleAddress";
import { RoleCardController } from "./role-card/RoleCardController";
import ICMSLoader from "../../common/icms-loader/ICMSLoader";

const ColorlibStepIcon = (props) => {
    const { active, completed, className } = props;

    const icons = {
        1: <BsFilePersonFill style={{ fontSize: '1.5rem' }} />,
        2: <BsPersonFillGear style={{ fontSize: '1.5rem' }} />
    };

    return (
        <ColorlibStepIconRoot
            ownerState={{ completed, active }}
            className={className}
        >
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const ChoosePurpose = (props) => {
    const loginDetails = useContext(UserContext).loginDetails;
    const navigate = useNavigate();
    const { open } = props;
    const { t } = useTranslation();
    const isEnkAdmin = loginDetails.subscriberRole.includes(Roles.enkAdmin.roleValue);
    const isEnkSupport = loginDetails.subscriberRole.includes(Roles.enkSupport.roleValue);
    const isAccAdmin = loginDetails.subscriberRole.includes(Roles.accountAdmin.roleValue);
    const isAdministrator = loginDetails.subscriberRole.includes(Roles.administrator.roleValue);
    const isMonitor = loginDetails.subscriberRole.includes(Roles.monitor.roleValue);
    const [message, setMessage] = useState({ show: false, message: '', severity: '' });
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirectUrl = queryParams.get('redirect');
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('Yes'),
        disAgreeBtnLabel: t('No'),
        onDisAgree: ''
    })


    const steps = [
        "Role",
        "Verification"
    ];

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    }

    const handlePrevious = () => {
        setActiveStep(activeStep - 1);
    }

    const ValidationSchema = Yup.object().shape({
        selectedRole: Yup.string(),
        ibbiRegNumber: Yup.string().when('selectedRole', {
            is: (selectedRole) => { return selectedRole === ApplicationUsers.IP || selectedRole === ApplicationUsers.VALUER },
            then: () => Yup.string().required(t("Field_Is_Mandatory")).matches(RegEx.IBBI_REG_NO, t("Invalid_IBBI_REG_NO")),
            otherwise: () => Yup.string()
        }),
        pan: Yup.string().when('selectedRole', {
            is: (selectedRole) => { return selectedRole === ApplicationUsers.CLAIMANT || selectedRole === ApplicationUsers.RESOLUTION_APPLICANT || 
                selectedRole === ApplicationUsers.OTHER
             },
            then: () => Yup.string().required(t("Field_Is_Mandatory")).matches(RegEx.PAN, t("Invalid_PAN")),
            otherwise: () => Yup.string(),
        }),
        cityOrTown: Yup.string().required(t("City_Town_Is_Required")),
        country: Yup.string().required(t("Country_Is_Required")),
        state: Yup.string().required(t("State_Is_Required")),
        postalCode: Yup.string()
            .matches(RegEx.PIN_CODE, t('Invalid_Pin_Code'))
            .required(t("Pin_Code_Is_Required")),
        addressLine1: Yup.string().required(t("Address_Line1_Is_Required")),
    })

    const loginPurposeFormik = useFormik({
        initialValues: {
            selectedRole: ApplicationUsers.IP,
            ibbiRegNumber: '',
            pan: '',
            addressCd: AddressTypes.registerdWithIBBI.addressCd,
            addressLine1: '',
            addressLine2: '',
            cityOrTown: '',
            state: '',
            postalCode: '',
            country: DefaultCountry.COUNTRY_NAME,
        },
        onSubmit: () => {
            handleProceed()
        },
        validationSchema: ValidationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true,
    });

    const createAddress = () => {
        setLoading(true)
        const { ...addressValues } = loginPurposeFormik.values;
        let payload = { ...addressValues }
        ICMSAxiosInterceptor.post('address', payload).then(response => {
            if (response) {
                setMessage({ show: true, message: t('Address_Created_Successfully'), severity: 'success' });
                // handleProceed()
                if (redirectUrl) {
                    navigate(redirectUrl);
                } else if (loginPurposeFormik.values.selectedRole === ApplicationUsers.IP ||
                    loginPurposeFormik.values.selectedRole === ApplicationUsers.VALUER) {
                        navigate("/details/my-profile")

                } else if (loginPurposeFormik.values.selectedRole === ApplicationUsers.CLAIMANT) {
                    navigate("/details/file-claim")
                } else {
                    navigate("/details/case-management", {
                        state: {
                            caseType: (isAdministrator || isMonitor || isAccAdmin || isEnkAdmin || isEnkSupport) ? CaseManagementSideNav.active.key : CaseManagementSideNav.all.key
                        }
                    })
                }
            }
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            if (error.message) {
                setMessage({ show: true, message: error.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
            }
        })
    }

    const handleClose = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: `${t('Are_You_Sure_That_You_Want_To_Exit_From_Application')}?`,
            onDisAgree: () => {
                resetSeekConfirmation();
            },
            onAgree: () => {
                resetSeekConfirmation();
                navigate(`/sign-in${redirectUrl ? `?redirect=${redirectUrl}` : ''}`);
            },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    const handleChange = (event) => {
        setFormikValueWithUpperCase(event, loginPurposeFormik)
    }

    const handleProceed = () => {
        let payload = null;
        if (loginPurposeFormik.values.selectedRole === ApplicationUsers.IP ||
            loginPurposeFormik.values.selectedRole === ApplicationUsers.VALUER) {
            payload = {
                identificationCd: IdentificationType.IBBI_REG_IP,
                identification: loginPurposeFormik.values.ibbiRegNumber
            }
        } else {
            payload = {
                identificationCd: IdentificationType.PAN,
                identification: loginPurposeFormik.values.pan
            }
        }
        ICMSAxiosInterceptor.post(`party/${loginDetails.partyId}/identification`, payload).then(response => {
            createAddress()

        }).catch(error => {
            if (error?.message) {
                setMessage({ show: true, message: error?.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
            }
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }


    const getActionByStep = (step) => {
        switch (step) {
            case 0:
                return (<Grid container>
                    <Grid item xs={12}>
                        {!loginPurposeFormik.values.pan && <ICMSButton onClick={handleNext} color="primary" size="small" variant='contained'
                            sx={{ float: 'right', marginRight: '2px' }} >
                            {t('Save_And_Next')}
                        </ICMSButton>}
                        {loginPurposeFormik.values.pan && <ICMSButton onClick={handleNext} color="primary" size="small"
                            variant='contained' sx={{ float: 'right', marginRight: '2px' }} >
                            {t('Next')}
                        </ICMSButton>}
                        <ICMSButton onClick={handleClose} color="primary" size="small" variant='outlined' sx={{ float: 'right', marginRight: '2px' }}>
                            {loginPurposeFormik.values.pan ? t('Close') : t('Cancel')}
                        </ICMSButton>
                    </Grid>
                </Grid>)
            case 1:
                return (<Grid container>
                    <Grid item xs={12}>
                        <ICMSButton onClick={handlePrevious} color="primary" size="small" variant='outlined'>
                            {t('Previous')}
                        </ICMSButton>
                        <ICMSButton onClick={loginPurposeFormik.handleSubmit} disabled={!loginPurposeFormik.isValid} color="primary" size="small" variant='contained' sx={{ float: 'right', marginRight: '2px' }}>
                            {(loginPurposeFormik.values.selectedRole === ApplicationUsers.IP || loginPurposeFormik.values.selectedRole === ApplicationUsers.VALUER ||
                                loginPurposeFormik.values.selectedRole === ApplicationUsers.OTHER
                            ) ? t('Proceed') : loginPurposeFormik.values.selectedRole === ApplicationUsers.RESOLUTION_APPLICANT ? t("Submit_EOI") : t('File_Claim')}
                        </ICMSButton>
                        <ICMSButton onClick={handleClose} color="primary" size="small" variant='outlined' sx={{ float: 'right', marginRight: '2px' }}>
                            {t('Close')}
                        </ICMSButton>
                    </Grid>
                </Grid>)
        }
    }

    return (
        <Dialog open={open} fullWidth maxWidth='lg' PaperProps={{
            style: {
                minHeight: '85vh'
            }
        }}>
            <DialogTitle>
                <Stack direction="row" justifyContent='space-between' alignItems='center'>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {t('Verification')}
                    </Typography>
                    <Tooltip title="Close">
                        <IconButton
                            color="secondary"
                            onClick={() => handleClose()}>
                            <Close color='error' />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </DialogTitle>
            <Divider flexItem />
            <DialogContent>
                <ClaimSteps steps={steps} activeStep={activeStep} ColorlibStepIcon={ColorlibStepIcon} />&nbsp;
                {activeStep === 0 &&
                    <RadioGroup row aria-label="role" name='selectedRole' value={loginPurposeFormik.values.selectedRole} onChange={loginPurposeFormik.handleChange}>
                        <Stack spacing={2.2} width='100%' direction='column'>
                            <Stack spacing={2.2} direction='row'>
                                <RoleCardController
                                    id='ip'
                                    value={ApplicationUsers.IP}
                                    isselected={ApplicationUsers.IP === loginPurposeFormik.values.selectedRole}
                                    title={t('Insolvency_Professional')}
                                    description={t('Insolvency_Professional_Purpose_Helper_Text')}
                                    selectedRole={loginPurposeFormik.values.selectedRole}
                                    icon={<FaClipboardList style={{ fontSize: '2rem', position: 'absolute', right: '-16px', bottom: '0px', color: '#005588' }} />}
                                    handleChange={handleChange} loginPurposeFormik={loginPurposeFormik}
                                />
                                <RoleCardController
                                    id='claimant'
                                    value={ApplicationUsers.CLAIMANT}
                                    isselected={ApplicationUsers.CLAIMANT === loginPurposeFormik.values.selectedRole}
                                    title={t('Claimant')}
                                    description={t('Claimant_Purpose_Helper_Text')}
                                    selectedRole={loginPurposeFormik.values.selectedRole}
                                    icon={<GiReceiveMoney style={{ fontSize: '2.2rem', position: 'absolute', right: '-17px', bottom: '-2px', color: '#005588' }} />}
                                    handleChange={handleChange} loginPurposeFormik={loginPurposeFormik}
                                />
                            </Stack>
                            <Stack spacing={2.2} width='100%' direction='row'>
                                <RoleCardController
                                    id='valuer'
                                    value={ApplicationUsers.VALUER}
                                    isselected={ApplicationUsers.VALUER === loginPurposeFormik.values.selectedRole}
                                    title={t('Valuer')}
                                    description={t('Valuer_Purpose_Helper_Text')}
                                    selectedRole={loginPurposeFormik.values.selectedRole}
                                    icon={<BsClipboardCheckFill style={{ fontSize: '1.8rem', position: 'absolute', right: '-16px', bottom: '0px', color: '#005588' }} />}
                                    handleChange={handleChange} loginPurposeFormik={loginPurposeFormik}
                                />
                                <RoleCardController
                                    id='resolution'
                                    value={ApplicationUsers.RESOLUTION_APPLICANT}
                                    isselected={ApplicationUsers.RESOLUTION_APPLICANT === loginPurposeFormik.values.selectedRole}
                                    title={t('Resolution_Applicant')}
                                    description={t('Resolution_Applicant_Purpose_Helper_Text')}
                                    selectedRole={loginPurposeFormik.values.selectedRole}
                                    icon={<BsClipboardDataFill style={{ fontSize: '1.8rem', position: 'absolute', right: '-14px', bottom: '0px', color: '#005588' }} />}
                                    handleChange={handleChange} loginPurposeFormik={loginPurposeFormik}
                                />
                            </Stack>
                            <Stack spacing={2.2} width='50%' direction='row'>
                                <RoleCardController
                                    id='other'
                                    value={ApplicationUsers.OTHER}
                                    isselected={ApplicationUsers.OTHER === loginPurposeFormik.values.selectedRole}
                                    title={t('Other')}
                                    description={t('Other_Purpose_Helper_Text')}
                                    selectedRole={loginPurposeFormik.values.selectedRole}
                                    // icon={<BsClipboardCheckFill style={{ fontSize: '1.8rem', position: 'absolute', right: '-16px', bottom: '0px', color: '#005588' }} />}
                                    handleChange={handleChange} loginPurposeFormik={loginPurposeFormik}
                                />
                            </Stack>
                        </Stack>
                    </RadioGroup>
                }
                {activeStep === 1 &&
                    <RoleAddress loginPurposeFormik={loginPurposeFormik} selectedRole={loginPurposeFormik.values.selectedRole} handleChange={handleChange} />
                }
            </DialogContent>
            <DialogActions>
                {getActionByStep(activeStep)}
            </DialogActions>
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
            {seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}
            />}
        </Dialog>
    )
}

export default ChoosePurpose;