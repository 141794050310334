import { Stack, ToggleButton, 
    ToggleButtonGroup, Typography }     from "@mui/material";
import { FormikLabelTextField }         from "../FormikLabelTextField";
import { FormikLabelNumericFormat }     from '../FormikLabelNumericFormat';
import "./YesNoSwitch.css";

const YesNoSwitchController = (props) => {
    const { id, fieldName, formik, title, description, isTextField, textFieldId, textFieldfieldName, textFieldlabel,
        disabled, onChange, isNumberField, isNumberFieldRequired, required } = props;

    const handleChange = (event) => {
        let value = event.target.value === 'true' ? true : false
        formik.setFieldValue(fieldName, value)
        if(isTextField) {
            formik.setFieldValue(textFieldfieldName, '')
        }
        if (onChange) onChange(value);
    }

    return (
        <Stack alignItems='center' justifyContent='center' sx={{ background: (theme) => theme.palette.background.paper, border: '1px solid #eeeeee', borderRadius: '8px', height: '100%' }}>
            <Stack direction="row" alignItems='center' justifyContent='center' spacing={1} sx={{ padding: '1rem' }}>
                <Stack spacing={1}>
                    <Typography variant={'body2'} color='textSecondary' sx={{ fontWeight: 'bold' }}>
                        {title} {required && <span style={{color: 'red'}}>*</span>}
                    </Typography>
                    <Typography variant={'subtitle2'} color='textSecondary'>
                        {description}
                    </Typography>
                </Stack>
                <ToggleButtonGroup
                    value={formik.values[fieldName]}
                    id={id}
                    sx={{ height: 'fit-content' }}
                    exclusive
                    onChange={handleChange}
                    aria-label="text alignment"
                    size="small"
                    disabled={disabled}
                >
                    <ToggleButton value={true} aria-label="left aligned" color="success">
                        Yes
                    </ToggleButton>
                    <ToggleButton value={false} aria-label="centered" color="error">
                        No
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>
            {isTextField && <div style={{width: '92%', paddingBottom: '10px'}}>
                <FormikLabelTextField
                    id={textFieldId}
                    fieldName={textFieldfieldName}
                    label={textFieldlabel}
                    formik={formik}
                    size="medium"
                    autoFocus={true}
                    disabled={disabled}
                    required={true}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                />
            </div>}
            {isNumberField && <div style={{width: '92%', paddingBottom: '10px'}}>
                <FormikLabelNumericFormat
                    id={textFieldId}
                    fieldName={textFieldfieldName}
                    formik={formik}
                    label={textFieldlabel}
                    autoFocus={true}
                    disabled={disabled}
                    size="small"
                    required={isNumberFieldRequired}
                    handleChange={formik.handleChange}
                />
            </div>}
        </Stack>
    )
}

export default YesNoSwitchController;