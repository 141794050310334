import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { ICMSAxiosInterceptor } from '../../../config/axios.interceptor'
import { CreateIdentifications, IdentificationType, RegEx } from '../../GenericCodes'
import { FormikLabelTextField } from '../../formik-fields-components/FormikFieldComponents'
import { SelectLabelController } from '../../formik-fields-components/SelectLabelController'
import ICMSLoader from '../../icms-loader/ICMSLoader'
import IcmsSnackbar from '../../icms-snackbar/IcmsSnackbar'
import { ICMSButton } from '../../icms-styled-components/IcmsStyledComponents'
import { setFormikValueWithUpperCase } from '../../utils/Utils'

export const CreateIdentification = (props) => {
    const { data, handleClose, partyId } = props;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        show: false,
        message: null,
        severity: null
    })

    const getIDLocale = (cd) => {
        const item = CreateIdentifications.find(item => item.id === cd);
        return item ? item.title : '';
      };

    const identificationValidation = Yup.object().shape({
        identificationCd: Yup.number().required('Identification Type is required'),
        identification: Yup.string().required('Identification is required').test(
          'is-valid-identification',
          function (value) {
            const { identificationCd } = this.parent;
            // const locale = getIDLocale(identificationCd);
            let isValid = true;
            if (identificationCd === IdentificationType.PAN) {
              isValid = RegEx.PAN.test(value);
            } else if (identificationCd === IdentificationType.IBBI_REG_IP) {
              isValid = RegEx.IBBI_REG_NO.test(value);
            } else if (identificationCd === IdentificationType.GSTIN) {
              isValid = RegEx.GSTIN.test(value);
            }
      
            if (!isValid) {
              return this.createError({
                message: `Invalid Identification (${getIDLocale(identificationCd)})`
              });
            }
      
            return true; // For other identificationCd values, no additional validation
          }
        )
      });
    

    const identificationFormik = useFormik({
        initialValues: {
            identificationCd: data.identificationRow.identificationCd  || IdentificationType.PAN,
            identification: data.identificationRow.identification || '',
            identificationId: data.identificationRow.identificationId || '',
        },
        onSubmit: (values) => {
            createIdentification(values)
        },
        validationSchema: identificationValidation,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: true
    })

    const createIdentification = (values) => {
        setLoading(true)
        const payload = {
            ...values
        }
        const requestMethod = data.identificationRow.identificationId ? ICMSAxiosInterceptor.put : ICMSAxiosInterceptor.post;
        const url = data.identificationRow.identificationId ? `party/${partyId}/identification/${data.identificationRow.identificationId}` : `party/${partyId}/identification`
        requestMethod(url, payload).then(() => {
            handleClose(true)
            props.setMessage({ show: true, message: data.identificationRow.identificationId ? t("Identification_Updated_Successfully") : t("Identification_Created_Successfully"), severity: 'success' });
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            setMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

   

    return (
        <Dialog open={data.open} maxWidth='sm' fullWidth>
            <DialogTitle data-testid='bank-title'>{data.identificationRow.identificationId ? t("Update_Identification") : t("Create_Identification")}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SelectLabelController
                            id="identificationCd"
                            fieldName="identificationCd"
                            label="Identification Type"
                            formik={identificationFormik}
                            size="small"
                            required={true}
                            menuItems={CreateIdentifications}
                            handleChange={identificationFormik.handleChange}
                            handleBlur={identificationFormik.handleBlur}
                            menuFieldId={'id'}
                            menuFieldTitle={'title'}
                            valueField={'id'}
                            disabled={identificationFormik.values.identificationId}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikLabelTextField
                            id="identification"
                            fieldName="identification"
                            label={`Identification (${getIDLocale(identificationFormik.values.identificationCd)})`}
                            size='small'
                            formik={identificationFormik}
                            handleChange={(event) => setFormikValueWithUpperCase(event, identificationFormik)}
                            required={true}
                            handleBlur={identificationFormik.handleBlur}
                        />
                    </Grid>
                    </Grid>
                {/* <BankAccountFields bankFormik={identificationFormik} disabled={false} handleClear={clearForm} showIFSCSearch={!data.identificationRow.partyId} errorMessage={setMessage} /> */}
            </DialogContent>
            <DialogActions>
                <ICMSButton color='primary' size='small' data-testid='cancel' variant='outlined' onClick={() => handleClose(false)}>{t("Cancel")}</ICMSButton>
                <ICMSButton color='primary' size='small' data-testid='create-update' variant='contained' onClick={identificationFormik.handleSubmit}
                    disabled={!identificationFormik.dirty || !identificationFormik.isValid}>{data.identificationRow.identificationId ? t("Update") : t("Create")}</ICMSButton>
            </DialogActions>
            {loading && <ICMSLoader show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </Dialog>
    )
}

