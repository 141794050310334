import { Fragment, useState }               from "react"
import { Button, Grid, Stack }              from "@mui/material"
import { useFormik }                        from "formik"
import { FormikLabelTextField }             from "../../../../common/formik-fields-components/FormikFieldComponents"
import { SelectLabelController }            from "../../../../common/formik-fields-components/SelectLabelController"
import ICMSLoader2                          from "../../../../common/icms-loader/icms-loader-2.O/ICMSLoader2"
import IcmsSnackbar                         from "../../../../common/icms-snackbar/IcmsSnackbar"
import { SearchBankByIFSC }                 from "../../../../search-components/SearchBankByIFSC"
import { createBank, updateBank }           from "../../file-claim-services/BankService"
import { FormikLabelNumericFormat }         from "../../../../common/formik-fields-components/FormikLabelNumericFormat"
import { AccountTypes, BankAccountTypeCd }  from "./BankGenericCodes"
import { BankValidationSchema }             from "../creditor-details/CreditorYupSchema"
import { useTranslation }                   from "react-i18next"

const BankDetail = (props) => {
    const { t } = useTranslation();
    const { partyId, bankDetail, listForBank, closeCurrentPanel, index, handleDelete } = props;
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ show: false, message: '', severity: '' });
    const bankFormik = useFormik({
        initialValues: {
            bankId: bankDetail === null ? null : bankDetail.bankId,
            branchId: bankDetail === null ? 616 : bankDetail.branchId,
            partyId: partyId,
            accountHolderName: bankDetail === null ? '' : bankDetail.accountHolderName,
            accountNumber: bankDetail === null ? '' : bankDetail.accountNumber,
            bankAccountCd: bankDetail === null ? BankAccountTypeCd.SAVINGS_ACCOUNT.codeCd : bankDetail.bankAccountCd,
            bankAccountLocale: bankDetail === null ? '' : bankDetail.bankAccountLocale,
            bankName: bankDetail === null ? '' : bankDetail.bankName,
            branchName: bankDetail === null ? '' : bankDetail.branchName,
            ifsc: bankDetail === null ? '' : bankDetail.ifsc
        },
        validationSchema: BankValidationSchema
    })

    const handleChange = (event) => {
        bankFormik.handleChange(event);
    }

    const handleSave = () => {
        if (bankDetail === null) {
            createBank(bankFormik.values,
                (response) => {
                    console.log("oeiwfn eu on ed ", response)
                    if (response) {
                        listForBank({ show: true, message: t('Bank_Account_Added_Sucessfully'), severity: 'success' });
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    setMessage({ show: true, message: error.message, severity: 'error' });
                })
        } else {
            updateBank(bankDetail.bankId, bankFormik.values,
                (response) => {
                    if (response) {
                        listForBank({ show: true, message: t('Bank_Account_Updated_Sucessfully'), severity: 'success' });
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    setMessage({ show: true, message: error.message, severity: 'error' });
                })
        }
    }

    const handleSelectIFSC = (bank) => {
        bankFormik.setValues(values => ({
            ...values,
            branchId: bank.partyId,
            ifsc: bank.identification,
            branchName: bank.name,
            bankName: bank.bankName
        }))
    }

    const handleCancel = () => {
        if(bankDetail !== null) {
            closeCurrentPanel(index);
        }
    }

    return (
        <Fragment>
            <Stack px={2} spacing={1}>
                <Grid container spacing={2} my={1}>
                    <Grid item xs={4}>
                        <FormikLabelTextField
                            id="accountHolderName"
                            fieldName="accountHolderName"
                            label={t("Account_Name")}
                            formik={bankFormik}
                            size="small"
                            required={true}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormikLabelNumericFormat
                            id="accountNumber"
                            fieldName="accountNumber"
                            label={t("Account_Number")}
                            formik={bankFormik}
                            size="small"
                            required={true}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectLabelController
                            id="bankAccountCd"
                            fieldName="bankAccountCd"
                            label={t("Account_Type")}
                            formik={bankFormik}
                            size="small"
                            required={true}
                            menuItems={AccountTypes}
                            menuFieldId={'id'}
                            menuFieldTitle={'title'}
                            valueField={'id'}
                            handleChange={bankFormik.handleChange}
                            handleBlur={bankFormik.handleBlur}
                        />  
                    </Grid>
                </Grid>
                <br />
                <SearchBankByIFSC handleSelectIFSC={handleSelectIFSC }/>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormikLabelTextField
                            id="bankName"
                            fieldName="bankName"
                            label={t("Bank_Name")}
                            formik={bankFormik}
                            size="small"
                            required={true}
                            disabled={true}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormikLabelTextField
                            id="branchName"
                            fieldName="branchName"
                            label={t("Branch_Name")}
                            formik={bankFormik}
                            size="small"
                            required={true}
                            disabled={true}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormikLabelTextField
                            id="ifsc"
                            fieldName="ifsc"
                            label={t("IFSC_Code")}
                            formik={bankFormik}
                            size="small"
                            required={true}
                            disabled={true}
                            handleChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Stack direction="row" justifyContent='end' spacing={2} mt={4}>
                    <Button color='primary' variant="outlined" size="small" onClick={handleCancel}>
                        {t('Cancel')}
                    </Button>
                    <Button color='primary' variant="contained" size="small" onClick={handleSave}>
                        {bankDetail === null ?  t('Save'): t('Update')}
                    </Button>
                    {bankDetail !== null && <Button color='error' variant="contained" size="small" onClick={() => {handleDelete(bankDetail.bankId, index)}}>
                        {t('Delete')}
                    </Button>}
                </Stack>
            </Stack>
            {loading && <ICMSLoader2 show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </Fragment>
    )
}

export { BankDetail }
