import { Card } from "@mui/material";
import { styled } from "@mui/system";

const RoleCard = styled(Card)(({ theme, isselected }) => ({
    boxShadow: '0px 8px 10px 0px #d8dfeb !important',
    background: isselected ? '#e6f3ff7d !important' : 'inherit',
    border: `1px solid ${isselected ? theme.palette.primary.main : '#eae7e7'}`,
    '&:hover': {
        boxShadow: 'rgb(255 255 255 / 10%) 0px 5px 10px 0px inset, rgb(152 152 162 / 25%) 0px 30px 50px -20px, rgb(130 130 174 / 30%) 0px 30px 30px -30px !important',
        animation: '$scale 0.080s ease-out forwards 0.080s',
    },
    '@keyframes scale': {
        '0%': {
            transform: 'scale(1)'
        },
        // '50%': {
        //     transform: 'scale(1.01)'
        // },
        '100%': {
            transform: 'scale(1.02)'
        }
    },
}))

export {RoleCard}