import React, { 
    useState,
    useEffect 
}                                           from 'react';
import { useFormik }                        from "formik";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid
}                                           from '@mui/material';
import * as Yup                             from 'yup';
import { useTranslation }                   from 'react-i18next';
import ICMSLoader                           from '../../../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                         from '../../../../../common/icms-snackbar/IcmsSnackbar';
import { ICMSAxiosInterceptor }             from '../../../../../config/axios.interceptor';
import { SelectLabelController }            from '../../../../../common/formik-fields-components/SelectLabelController';
import { FormikLabelTextField }             from "../../../../../common/formik-fields-components/FormikFieldComponents";
import { TextAreaLabelController }          from '../../../../../common/formik-fields-components/TextAreaLabelController';
import { ICMSButton }                       from '../../../../../common/icms-styled-components/IcmsStyledComponents';

const ClaimChargesDetail = ({caseId, claimantId, debtDefaultId, show, onClose, chargeDetail,
        onSuccess}) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});
    const [charges, setCharges] = useState([]);

    const ChargeValidationSchema = Yup.object().shape({
        caseChargeId: Yup.string().required(t('Security_Interest_Is_Required'))
    });

    const chargeFormik = useFormik({
        initialValues: {
            claimUnderChargeId: chargeDetail?.claimUnderChargeId ||  '',
            debtDefaultId: chargeDetail?.debtDefaultId ||  '',
            caseChargeId: chargeDetail?.caseChargeId ||  '',
            trusteeName: chargeDetail?.trusteeName ||  '',
            description: chargeDetail?.description ||  null
        },
        validationSchema: ChargeValidationSchema,
        validateOnBlur: true,
        validateOnMount: true,
        validateOnChange: true
    })

    const getCharges = () => {
        setLoading(true);
        ICMSAxiosInterceptor.post(`case/${caseId}/case-charge/list`, {}).then(response => {
            setCharges(response.items);
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'})
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'})
            }
            setLoading(false);
        })
    }
    
    const handleSave = () => {
        if (!chargeFormik.values.claimUnderChargeId) {
            createCharge();
        } else {
            updateCharge();
        }
    }

    const createCharge = () => {
        setLoading(false);
        ICMSAxiosInterceptor.post(`case/${caseId}/claimant/${claimantId}/claim/${debtDefaultId}/charge-link/${chargeFormik.values.caseChargeId}`, 
                chargeFormik.values).then(response => {
            onSuccess({show: true, message: t('Security_Interest_Added_Successfully'), severity: 'success'});
            onClose();
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'})
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'})
            }
            setLoading(false);
        })
    }

    const updateCharge = () => {
        setLoading(false);
        ICMSAxiosInterceptor.put(`case/${caseId}/claimant/${claimantId}/claim/${debtDefaultId}/charge-link/${chargeFormik.values.claimUnderChargeId}`, 
        chargeFormik.values).then(response => {
            onSuccess({show: true, message: t('Security_Interest_Updated_Successfully'), severity: 'success'});
            onClose();
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'})
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'})
            }
            setLoading(false);
        })
    }

    useEffect(() => {
        getCharges();
    }, [])
    
    return (
        <Dialog open={show} fullWidth maxWidth="xs">
            <DialogTitle>{t('Security_Interest_Details')}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <SelectLabelController
                            id="caseChargeId"
                            fieldName="caseChargeId"
                            formik={chargeFormik}
                            label={t('Select_Security_Interest')}
                            size="small"
                            menuItems={charges}
                            required={true}
                            handleChange={chargeFormik.handleChange}
                            handleBlur={chargeFormik.handleBlur}
                            menuFieldId={'caseChargeId'}
                            menuFieldTitle={'chargeId'}
                            valueField={'caseChargeId'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikLabelTextField
                            id="trusteeName"
                            fieldName="trusteeName"
                            label={t("Trustee_Name")}
                            formik={chargeFormik}
                            size="small"
                            handleChange={chargeFormik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextAreaLabelController
                            id={'description'}
                            fieldName='description'
                            label={t('Description')}
                            formik={chargeFormik}
                            minRows={2}
                            textAlign='start'
                            handleChange={chargeFormik.handleChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ICMSButton onClick={onClose} color="primary" size="small" variant='outlined'>
                    {t('Cancel')}
                </ICMSButton>
                <ICMSButton onClick={handleSave} color="primary" size="small" variant='contained' 
                        disabled={!chargeFormik.isValid || !chargeFormik.dirty}>
                    {t('Save')}
                </ICMSButton>
            </DialogActions>
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
        </Dialog>
    )
}

export default ClaimChargesDetail