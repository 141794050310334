import { Step, Stepper } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { ColorStepLabel, ColorlibConnector } from "../../../../claim-stepper/ClaimStyles";

const ResolutionApplicantSteps = (props) => {
    const { steps, activeStep, ColorlibStepIcon } = props;
    const {t} = useTranslation();
  return (
    <Fragment>
        <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
        >
            {steps.map((label, index) => (
                <Step key={label}>
                    <ColorStepLabel StepIconComponent={ColorlibStepIcon}
                        >{t(label)}
                    </ColorStepLabel>
                </Step>
            ))}
        </Stepper>
    </Fragment>
  );
}

export default ResolutionApplicantSteps;
