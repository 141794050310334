import {  FormHelperText, IconButton, Stack, Typography } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Fragment } from 'react';
import LoopIcon from '@mui/icons-material/Loop';

export const FormikOtpInput = (props) => {
    const { id, fieldName, label, formik,resendOtp } = props;

    const handleChange = (newValue) => {
        formik.setFieldValue(fieldName, newValue)
    }

    const validateChar = (value, index) => {
        return /^\d+$/.test(value)  ? true : false;
    }

    return (
        <Fragment>
            <Typography>{label}</Typography>
            <MuiOtpInput
                id={id}
                value={formik.values[fieldName]}
                onChange={handleChange}
                length={6}
                sx={{ gap: '12px !important' }}
                TextFieldsProps={{
                    placeholder: '-', size: 'small', 'data-testid': id,
                    sx: { width: '40px' }, inputProps: { style: { padding: '8px' } } }}
                fontSize={1}
                validateChar={validateChar}
            />
            <FormHelperText error>{formik.touched[fieldName] && formik.errors[fieldName]}</FormHelperText>
            {/* <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
                <Typography style={{color:'GrayText'}}>Didn't get a code?</Typography>
                <Typography>Click to resend</Typography> 
                <IconButton onClick={resendOtp} data-testid='resend-btn'>
                    <LoopIcon style={{ color: 'blue' }} />
                </IconButton>
            </Stack> */}
        </Fragment>
    )
}