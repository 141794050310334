import React, { useState }          from 'react';
import {
    Dialog, DialogTitle,
    DialogContent, DialogActions
}                                   from '@mui/material';
import { useTranslation }           from 'react-i18next';
import { ICMSButton }               from '../../../../../common/icms-styled-components/IcmsStyledComponents';
import { ICMSAxiosInterceptor, ReportAxiosInterceptor }   from '../../../../../config/axios.interceptor';
import { UploadDropZone }           from "../../../../../common/upload-form/UploadDropZone";
import ICMSLoader                   from '../../../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                 from '../../../../../common/icms-snackbar/IcmsSnackbar';
import { ApplicantId, ApplicationName, ClaimStatusType, Stage } from '../../../../../common/GenericCodes';
import { UploadDocExt } from '../../../../../common/GenericCodes';
import axios from 'axios';

const UploadFormModel = ({show, onClose, caseId, formName, transactionId, fileName, onSuccess, acceptableFileTypes, 
        caseStage, isClaimForm}) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});

    const getFileType = () => {
        let reportType = 'PDF_A';
        let extension = files[0].name ? files[0].name.split('.').pop() : 'pdf';
        if(extension.toLowerCase().includes('xls') || extension.toLowerCase().includes('xlsx')) {
            reportType = 'EXCEL';
        }
        return reportType;
    }

    const uploadForm = async () => {
        if (isClaimForm) {
            try {
                let fileLocation = `${ApplicationName}/${process.env.REACT_APP_ENV}/${caseId}/FORMS/${formName?.name}/${fileName}.pdf`;
                setLoading(true);
                await ReportAxiosInterceptor.get(`presigned-url/true?fileName=${fileLocation}`).then(presignedUrl => {
                        axios.put(presignedUrl, files[0], {
                            headers: {
                                'Content-Type': files[0].type
                            },
                        }).then((response) => {
                            let claimStatusCd = caseStage == Stage.RESOLUTION ? ClaimStatusType.RES_CLAIM_SUBMITTED : ClaimStatusType.LIQ_CLAIM_SUBMITTED
                            let payload = {
                                template: formName?.template,
                                reportOf: ''+caseId,
                                applicationId: ApplicantId,
                                reportCd: formName?.code,
                                reportLocale: formName?.name,
                                transactionId: transactionId,
                                fileName: fileName,
                                version: true,
                                reportType: "PDF_A",
                                location: fileLocation,
                                versionId: response?.headers.get('x-amz-version-id')
                            }
                            ICMSAxiosInterceptor.post(`case/${caseId}/claim-form/claim-status/${transactionId}/${caseStage}/${claimStatusCd}`, payload).then(res => {
                                onSuccess({show: true, message: t('Uploaded_Successfully'), severity: 'success'});
                                setLoading(false);
                            }).catch(error => {
                                if (error?.message) {
                                    setMessage({ show: true, message: error.message, severity: 'error' });
                                } else {
                                    setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
                                }
                                setLoading(false);
                            });
                        })
                    })
            } catch (error) {
                if (error?.message) {
                    setMessage({ show: true, message: error.message, severity: 'error' });
                } else {
                    setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
                }
                setLoading(false);
            }
        } else {
            let payload = {
                template: formName?.template,
                reportOf: ''+caseId,
                applicationId: ApplicantId, 
                reportCd: formName?.code,
                reportLocale: formName?.name,
                transactionId: transactionId,
                fileName: `${fileName}`,
                version: true,
                reportType: getFileType()
            }
            let formData = new FormData();
            formData.append('file', files[0]);
            formData.append('data', JSON.stringify(payload));
            setLoading(true);
            ReportAxiosInterceptor.post(`upload-file?reportOf=${caseId}`, formData).then(response => {
                onSuccess({show: true, message: t('Uploaded_Successfully'), severity: 'success'});
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                if (error?.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                } else {
                    setMessage({show: true, message: t('Try_Again'), severity: 'error'});
                }
            })
        }
    }

    return (
        <div>
            <Dialog open={show} fullWidth maxWidth="sm">
                <DialogTitle>{t('Upload')} {t(formName?.label)}</DialogTitle>
                <DialogContent dividers>
                    <UploadDropZone setFiles={setFiles} files={files} acceptableFiles={UploadDocExt.pdfExcel}/>
                </DialogContent>
                <DialogActions>
                    <ICMSButton onClick={onClose} color="primary" size="small" variant='outlined'>
                        {t('Cancel')}
                    </ICMSButton>
                    <ICMSButton onClick={uploadForm} color="primary" size="small" variant='contained'
                            disabled={files.length === 0}>
                        {t('Upload')}
                    </ICMSButton>
                </DialogActions>
                {loading && <ICMSLoader show={loading}/>}
            </Dialog>
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} 
                    handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
        </div>
    )
}

export default UploadFormModel