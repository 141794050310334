import { Button, CardContent, Link, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { ApplicationClientId, RegEx } from '../common/GenericCodes';
import ICMSReCaptcha from '../common/ICMSReCaptcha';
import PoweredBy from '../common/PoweredBy';
import { FormikMuiPhoneNumber, FormikPasswordConfirmField, FormikPasswordField, FormikTermsAndCondition, FormikTextField } from '../common/formik-fields-components/FormikFieldComponents';
import ICMSLoader from '../common/icms-loader/ICMSLoader';
import IcmsSnackbar from '../common/icms-snackbar/IcmsSnackbar';
import { AuthDiv, JustifyCard } from '../common/icms-styled-components/IcmsStyledComponents';
import { AuthAxiosInterceptor } from '../config/axios.interceptor';
import TermsAndConditions from './TermsAndCondition';
import { RxHome } from "react-icons/rx";
import { setFormikValueWithLowerCase } from '../common/utils/Utils';

function Register() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const [message, setMessage] = useState({
        show: false,
        message: null,
        severity: null
    })
    const [pwdFocus, setPwdFocus] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirectUrl = queryParams.get('redirect');
    // const [termsAndCondition, setTermsAndCondition] = useState(false)

    const SignUpValidationSchema = Yup.object().shape({
        username: Yup.string().test('custom-validation', 'Invalid  Name', (value) => {
            return RegEx.USERNAME.test(value);
        }).required('Name is Required'),
        email: Yup.string().email('Invalid Email Address').test('custom-validation', 'Invalid Email Address', (value) => {
            return RegEx.EMAIL.test(value);
        }).required('Email Address is Required'),
        phoneNumber: Yup.string().required("Mobile Number is Required")
            .test("phoneNumber", "Mobile Number is not valid", (str, context) => {
                const pn = isValidPhoneNumber(signUpFormik.values.extension + str);
                return pn;
            }),

        password: Yup.string()
            .required("Please enter a password")
            .matches(
                RegEx.PASSWORD,
                "Please Enter Strong Password"
            ),
        passwordConfirmation: Yup.string().required('Confirm Password Required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        agree: Yup.boolean().oneOf([true], 'You must accept the terms and conditions')
    })

    const signUpFormik = useFormik({
        initialValues: {
            username: '',
            email: '',
            phoneNumber: '',
            extension: '+91',
            password: '',
            passwordConfirmation: '',
            agree: false
        },
        validationSchema: SignUpValidationSchema,
        onSubmit: (values) => {
            signUp();
        }
    });

    const onSubmit = () => {
        signUpFormik.handleSubmit()
    }

    const firstName = signUpFormik.values.username.replace(/^\s+|\s+$/gm, '').replace(/\s\s+/g, ' ')

    const signUp = () => {
        setLoading(true)
        setMessage({ show: false, message: '', severity: '' });
        let payload = {
            firstName: firstName,
            email: signUpFormik.values.email,
            phoneNumber: signUpFormik.values.extension + '' + signUpFormik.values.phoneNumber,
            password: signUpFormik.values.password,
            registeredClientId: ApplicationClientId.icms,
            // registeredClientId: ApplicationClientId.eVoting,
            authorities: ['SCOPE_read'],
            individual: true
        }
        AuthAxiosInterceptor.post(`user/create-account-with-captcha?recaptcha=${captcha}`, payload).then(response => {
            setLoading(false)
            navigate(`/verify-otp${redirectUrl ? `?redirect=${redirectUrl}` : ''}`, {
                state: {
                    firstName: firstName,
                    email: signUpFormik.values.email,
                    phoneNumber: signUpFormik.values.extension + '' + signUpFormik.values.phoneNumber,
                    // password: signUpFormik.values.password
                }
            })
        }).catch(error => {
            setLoading(false)
            setIsRefresh(true)
            setTimeout(() => {
                setIsRefresh(false)
            }, 1000);
            if (error.message) {
                setMessage({ show: true, message: error.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
            }
        })
    }

    const handleSignIn = () => {
        navigate(`/sign-in${redirectUrl ? `?redirect=${redirectUrl}` : ''}`);
    }
    const handleChange = (event) => {
        const { id, value } = event.target;
        signUpFormik.setFieldTouched(id, true);
        signUpFormik.handleChange({
            target: {
                id,
                value: value.trimStart(),
            },
        }) 
    }

    const handleBlur = (fieldName) => {
        setPwdFocus(false)
        // Update the value to false when a field loses focus
        signUpFormik.setFieldValue(fieldName, false);
    };

    const handleFocus = (fieldName) => {
        setPwdFocus(true)
        // Update the value to true when a field gains focus
        signUpFormik.setFieldValue(fieldName, true);
    };

    const acceptTermsAndCondition = (isAccept) => {
        // setTermsAndCondition(false)
        signUpFormik.setFieldValue('agree', isAccept)
    }

    const navigateToHomePage = () => {
        navigate('/home-page')
    }

    return (
        <Fragment >
            <AuthDiv>
                <Button startIcon={<RxHome />} onClick={navigateToHomePage} variant='outlined' color='primary' style={{background:'white', margin:'10px 0px 0px 10px'}}>Home</Button>
                <Stack justifyContent='center' alignItems='center' m='auto' height="100vh">
                    <JustifyCard style={{ width: '60%' }}>
                        <CardContent sx={{ p: 0, pb: '0px !important' }}>
                            <Stack direction='row'>
                                <Stack p={2} width='48%' spacing={2} alignItems='center' justifyContent='center' sx={{ background: '#e8f0ffcf' }}>
                                    <Typography variant="h3" sx={{ fontWeight: 'bold' }}>{'ICMS'}</Typography>
                                    <Typography variant="body2" color="GrayText" sx={{ fontWeight: 'bold' }}>{t('Insolvency_Case_Management_System_-_IBC_code_2016')}</Typography>
                                    <img src={process.env.PUBLIC_URL + 'images/signup.svg'} alt="Login-png" width="70%"></img>
                                </Stack>
                                {/* <img src={process.env.PUBLIC_URL + '/images/sunflower.svg'} width='20%' height='20%' float='right'/> */}
                                <Stack p={2} spacing={2} alignItems='center' justifyContent='center' direction='column' width='52%'>
                                    {/* <SignupImg /> */}
                                    <Typography variant="h4" data-testid='create' >{t('Sign_Up')}</Typography>
                                    <FormikTextField
                                        id="username"
                                        fieldName="username"
                                        label={t("Name")}
                                        formik={signUpFormik}
                                        handleChange={handleChange}
                                        validationIcon={true}
                                    />
                                    <FormikTextField
                                        id="email"
                                        fieldName="email"
                                        label={t("Email_Address")}
                                        formik={signUpFormik}
                                        handleChange={(event) => setFormikValueWithLowerCase(event, signUpFormik)}
                                        // handleChange={handleChange}
                                        validationIcon={true}
                                    />
                                    <FormikMuiPhoneNumber
                                        id="phoneNumber"
                                        fieldName="phoneNumber"
                                        label={t("Mobile_Number")}
                                        extensionName="extension"
                                        handleChange={handleChange}
                                        formik={signUpFormik}
                                        validationIcon={true}
                                    />
                                    <FormikPasswordField
                                        id="password"
                                        fieldName="password"
                                        label="Password"
                                        formik={signUpFormik}
                                        pwdFocus={pwdFocus}
                                        handleBlur={handleBlur}
                                        handleFocus={handleFocus}
                                        handleChange={handleChange}
                                        validationIcon={true}
                                    />
                                    <FormikPasswordConfirmField
                                        id="passwordConfirmation"
                                        fieldName="passwordConfirmation"
                                        label="Confirm Password"
                                        formik={signUpFormik}
                                        handleChange={handleChange}
                                        validationIcon={true}
                                    />
                                    <FormikTermsAndCondition
                                        id="agree"
                                        fieldName="agree"
                                        label="Accept Terms & Conditions"
                                        checked={signUpFormik.values.agree}
                                        //  acceptConditions="agree"
                                        handleChange={handleChange}
                                        formik={signUpFormik}
                                    />
                                    <Button variant="contained" data-testid='register-loading' onClick={onSubmit} disabled={(!signUpFormik.dirty || !signUpFormik.isValid)} fullWidth>{t('Submit')}</Button>
                                    <Typography id="donthaveaccount" variant="body2">
                                        {t("Do_You_Have_An_Account")} <Link onClick={handleSignIn} className="pointer font-weight" data-testid='navigate-to-signin'>{t("Sign_In")}</Link>
                                    </Typography>
                                    {/* <PoweredBy /> */}
                                    {/* <Link onClick={handleVerify} >Verify</Link> */}
                                </Stack>
                            </Stack>
                        </CardContent>
                    </JustifyCard>
                </Stack>
            </AuthDiv>

            {signUpFormik.values.agree && <TermsAndConditions show={signUpFormik.values.agree} acceptTermsAndCondition={acceptTermsAndCondition} />}
            {!isRefresh && <ICMSReCaptcha key="signup" captcha={setCaptcha} refresh={setIsRefresh} />}
            {loading && <ICMSLoader show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </Fragment>
    )
}

export default Register

