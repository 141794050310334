import { BsFillFileEarmarkBarGraphFill, BsFillFileEarmarkTextFill } from "react-icons/bs";
import { FaFileArrowUp } from "react-icons/fa6";

export const FormReportSideValue = {
    FORMS: 'fr-forms',
    REPORTS: 'fr-reports',
    // BULK_UPLOAD: 'fr-bulkupload'
}

// left navigation order will take as in this list
export const FormReportSideMenu = [
    {
        id: 'forms',
        value: FormReportSideValue.FORMS,
        title: 'Forms',
        icon: <BsFillFileEarmarkTextFill style={{ fontSize: '1.5rem' }} />
    },
    {
        id: 'reports',
        value: FormReportSideValue.REPORTS,
        title: 'Reports',
        icon: <BsFillFileEarmarkBarGraphFill style={{ fontSize: '1.5rem' }} />
    },
    // {
    //     id: 'bulk-upload',
    //     value: FormReportSideValue.BULK_UPLOAD,
    //     title: 'Bulk Upload',
    //     icon: <FaFileArrowUp style={{ fontSize: '1.5rem' }} />
    // },
]