import React, {
    useEffect,
    useState
}                               from 'react';
import { useTranslation }       from 'react-i18next';
import {
    TableHead,
    TableBody,
    Grid,
    Button,
    IconButton,
    Tooltip
}                               from '@mui/material';
import AddIcon                  from '@mui/icons-material/Add';
import EditIcon                 from '@mui/icons-material/Edit';
import DeleteIcon               from '@mui/icons-material/Delete';
import ICMSLoader               from '../../../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar             from '../../../../../common/icms-snackbar/IcmsSnackbar';
import { 
    Currency,
    CurrencyFormatter, 
    CurrencyFormatterWithOutSymbol}         from "../../../../../common/utils/Utils"
import {
    IcmsMuiTable,
    IcmsTableRow,
    StyledTableCell,
    IcmsTableContainer
}                               from '../../../../../common/generic-styles/NavPageContainerStyles';
import { ICMSAxiosInterceptor } from '../../../../../config/axios.interceptor';
import ClaimGuaranteeDetail     from './ClaimGuaranteeDetail';
import ICMSConfirmDialog        from '../../../../../common/ConfirmationDialog/ICMSConfirmDialog';
import { ICMSButton }           from '../../../../../common/icms-styled-components/IcmsStyledComponents';

const ClaimGuaranteeList = ({caseId, claimantId, debtDefaultId, canEdit}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [guarantors, setGuarantors] = useState([]);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});
    const [showGuaranteeDetail, setShowGuaranteeDetail] = useState({
        show: false,
        guaranteeDetail: null
    })
    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('Yes'),
        disAgreeBtnLabel: t('No'),
        onDisAgree: ''
    })
    const columns = [
        { id: 'name', label: 'Name', minWidth: '25%' },
        { id: 'amount', label: 'Amount', minWidth: '20%' },
        // { id: 'from', label: 'Guarantee_From', minWidth: '18%' },
        // { id: 'to', label: 'Guarantee_To', minWidth: '18%' },
        // { id: 'relinquish', label: 'Relinquish', minWidth: '10%' },
        { id: 'description', label: 'Description', minWidth: '35%' },
        { id: 'action', label: 'Action', minWidth: '10%', align:'center' }
    ]

    const getGuarantors = () => {
        setLoading(true);
        ICMSAxiosInterceptor.get(`case/${caseId}/claimant/${claimantId}/claim/${debtDefaultId}/guarantor-link`).then(response => {
            setGuarantors(response);
            setLoading(false);
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'})
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'})
            }
            setLoading(false);
        })
    }

    const resetShowGuaranteeDetail = () => {
        setShowGuaranteeDetail({show: false, guaranteeDetail: null})
    }

    const onSuccess = (message) => {
        setMessage(message);
        getGuarantors();
    }

    const deleteConfirmation = (guarantee) => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: `${t('Are_You_Sure_That_You_Want_To_Delete_Guarantee')} ${guarantee?.guarantorName} ?`,
            onDisAgree: resetSeekConfirmation,
            onAgree: () => {
                resetSeekConfirmation();
                handleDelete(guarantee);
            },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    const handleDelete = (guarantee) => {
        setLoading(true);
        ICMSAxiosInterceptor.delete(`case/${caseId}/claimant/${claimantId}/claim/${debtDefaultId}/guarantor-link/${guarantee.debtGuarantorId}`).then(response => {
            if (response) {
                onSuccess({show: true, message: t('Guarantee_Deleted_Succssfully'), severity: 'success'});
            }
            setLoading(false);
        }).catch(error => { 
            if (error?.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    useEffect(() => {
        getGuarantors();
    }, [])
    

    return (
        <div>
            <IcmsTableContainer>
                <Grid container>
                    <Grid item xs={12}>
                        {canEdit && <ICMSButton color="primary" variant="contained" size="small" sx={{float: 'right'}}
                                onClick={() => {setShowGuaranteeDetail({show: true, guaranteeDetail: null})}}
                                startIcon={<AddIcon />}>
                            {t('Add_Guarantee')}
                        </ICMSButton>}
                    </Grid>
                </Grid>
                <IcmsMuiTable stickyHeader aria-label="sticky table" data-testid="claim-guarantor-list-table"
                            sx={{padding: '0px !important'}}>
                    <TableHead>
                        <IcmsTableRow>
                            {columns.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    sx={{padding: '4px'}}
                                >
                                    {t(column.label)}
                                </StyledTableCell>
                            ))}
                        </IcmsTableRow>
                    </TableHead>
                    <TableBody>
                        {guarantors.length === 0 &&  <IcmsTableRow hover>
                            <StyledTableCell>
                                {t('No_Record_Found')}
                            </StyledTableCell>
                        </IcmsTableRow>}
                        { guarantors.map(guarantor => {
                            return (
                                <IcmsTableRow hover key={guarantor.debtGuarantorId}>
                                    <StyledTableCell sx={{padding: '4px'}}>{guarantor.guarantorName}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '4px'}}>{guarantor.amount ? CurrencyFormatterWithOutSymbol(Currency.INR, guarantor.amount): '-'}</StyledTableCell>
                                    {/* <StyledTableCell sx={{padding: '4px'}}>{guarantor.relinquish ? t('Yes') : t('No')}</StyledTableCell> */}
                                    <StyledTableCell sx={{padding: '4px'}}>{guarantor.description ? guarantor.description : '-'}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '4px', textAlign: 'center'}}>
                                        {canEdit && <Tooltip title={t('Edit')} arrow>
                                            <IconButton color='primary' id='edit'
                                                    onClick={() => {setShowGuaranteeDetail({show: true, guaranteeDetail: guarantor})}}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>}
                                        {canEdit && <Tooltip title={t('Delete')} arrow>
                                            <IconButton color='secondary' id='delete' onClick={() => deleteConfirmation(guarantor)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>}
                                    </StyledTableCell>
                                </IcmsTableRow>
                            )
                        })}
                    </TableBody>
                </IcmsMuiTable>
            </IcmsTableContainer>
            {showGuaranteeDetail.show && <ClaimGuaranteeDetail show={showGuaranteeDetail.show} 
                    guaranteeDetail={showGuaranteeDetail.guaranteeDetail} onClose={resetShowGuaranteeDetail}
                    caseId={caseId} claimantId={claimantId} debtDefaultId={debtDefaultId}
                    onSuccess={onSuccess}/>}
            {seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}
            />}
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
        </div>
    )
}

export default ClaimGuaranteeList