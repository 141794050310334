import { FaUserGroup } from "react-icons/fa6";
import { GiVote } from "react-icons/gi";
import { MdMeetingRoom } from "react-icons/md";
import { BsFillCreditCard2FrontFill } from 'react-icons/bs';

export const CommitteeSideValue = {
    COMMITTEE: 'committee',
    MEETING: 'committee-meeting',
    EVOTING: 'committee-evoting',
    CLASS_OF_CREDITOR: 'committee-classofcreditor',
    CONTRIBUTION: 'committee-contribution'
}

// left navigation order will take as in this list
export const CommitteeSideMenu = [
    {
        id: 'committee',
        value: CommitteeSideValue.COMMITTEE,
        title: 'Committe',
        icon: <FaUserGroup style={{fontSize: '1.5rem'}}/>
    },
    {
        id: 'meeting',
        value: CommitteeSideValue.MEETING,
        title: 'Meeting',
        icon: <MdMeetingRoom style={{ fontSize: '1.6rem' }} />
    },
    // {
    //     id: 'evoting',
    //     value: CommitteeSideValue.EVOTING,
    //     title: 'EVoting',
    //     icon: <GiVote style={{ fontSize: '1.5rem' }} />
    // },
    // {
    //     id: 'creditor',
    //     value: CommitteeSideValue.CLASS_OF_CREDITOR,
    //     title: 'Class Of Creditors',
    //     icon: <BsFillCreditCard2FrontFill style={{ fontSize: '1.5rem' }} />
    // },
    {
        id: 'contribution',
        value: CommitteeSideValue.CONTRIBUTION,
        title: 'Contribution',
        icon: <BsFillCreditCard2FrontFill style={{ fontSize: '1.5rem' }} />
    },
]