import { Stack, Step, Stepper, Typography } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { HiArrowSmRight } from "react-icons/hi";
import { ColorStepLabel, ColorlibConnector } from "./ClaimStyles";

const ClaimSteps = (props) => {
    const { steps, activeStep, ColorlibStepIcon, label, description } = props; // steps is a array of strings
    const {t} = useTranslation();

    return (
        <Fragment>
            <Stack direction="row" spacing={2} justifyContent='center' alignItems='center' mb={2}>
                {label && <Typography variant="h5" color="textSecondary">{label}&nbsp;{t('Claim')}</Typography>}
                {/* <HiArrowSmRight />
                <Typography variant="body2" color="textSecondary">{description}</Typography> */}
            </Stack>
            <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
            >
                {steps.map((label, index) => (
                    <Step key={label}>
                        <ColorStepLabel StepIconComponent={ColorlibStepIcon}
                            // isFirst={index === 0} isLast={index + 1 === steps.length} isLastActive={activeStep === index}
                            >{label}
                        </ColorStepLabel>
                    </Step>
                ))}
            </Stepper>
            <Typography variant="subtitle1" gutterBottom sx={{paddingTop: '2rem'}}><strong>{steps[activeStep]}</strong></Typography>
        </Fragment>
    )
}

export default ClaimSteps;