import { isValidPhoneNumber }   from 'react-phone-number-input';
import * as Yup                 from 'yup';
import { 
    CreditorsType, 
    IdentificationType 
}                               from '../../../../common/StaticVariables';
import { ClaimType, RegEx }     from '../../../../common/GenericCodes';
import { RelationshipCd }       from '../FileClaimGenericCodes';

const CreditorValidationSchema = Yup.object().shape({
    partyCd: Yup.string(),
    name: Yup.string().required('Name is required').matches(RegEx.COMBINATION_OF_LETTERS_OR_ONLY_LETTERS, 'Invalid Name'),
    identificationCd: Yup.string(),
    identification: Yup.string().required('Identification is required').when(['identificationCd', 'partyCd'], {
            is: (identificationCd, partyCd) => { return Number(identificationCd) === IdentificationType.CIN && Number(partyCd) !== CreditorsType.BANK},
            then: () => Yup.string().required('Identification is required').matches(RegEx.CIN, 'Invalid CIN'),
            otherwise: () => Yup.string().required('Identification is required')
        }).when('identificationCd', {
            is: (identificationCd) => { return Number(identificationCd) === IdentificationType.IRDAI_REG_NO},
            then: () => Yup.string().required('Identification is required')
        }).when('identificationCd', {
            is: (identificationCd) => { return (Number(identificationCd) === IdentificationType.PAN || Number(identificationCd) === IdentificationType.PROP_BUSINESS_PAN)},
            then: () => Yup.string().required('Identification is required').matches(RegEx.PAN, 'Invalid PAN')
        }).when('identificationCd', {
            is: (identificationCd) => { return Number(identificationCd) === IdentificationType.PASSPORT},
            then: () => Yup.string().required('Identification is required').matches(RegEx.PASSPORT, 'Invalid Passport No.')
        }).when('identificationCd', {
            is: (identificationCd) => { return Number(identificationCd) === IdentificationType.VOTERID},
            then: () => Yup.string().required('Identification is required').matches(RegEx.VOTER_ID, 'Invalid Voter ID')
        }),
    natureOfRelatedParty: Yup.string().when('realtedParty', {
        is: (realtedParty) => { return realtedParty === true},
        then: () => Yup.string().required('Relationship  is required'),
        otherwise: () => Yup.string().nullable(true),
    }),
    serialNumber: Yup.string().when('relationshipCd', {
        is: (relationshipCd) => { return relationshipCd === RelationshipCd.EMPLOYEE_CLAIMANT || relationshipCd === RelationshipCd.WORKMAN_CLAIMANT},
        then: () => Yup.string().required('ID is required').matches(RegEx.REFERENCE_NUMBER, 'Invalid Voter ID'),
        otherwise: () => Yup.string().nullable(true),
    }),
    email: Yup.string().when(['partyCd'], {
        is: (partyCd) => { return Number(partyCd) !== CreditorsType.BANK },
        then: () => Yup.string().email('Invalid Email Address').test('custom-validation', 'Invalid Email Address', (value) => {
            return RegEx.EMAIL.test(value);
        }).required('Email Address is required'),
        otherwise: () => Yup.string().nullable(true)
    }),
    dobDoi: Yup.string().when(['partyCd', 'relationshipCd'], {
        is: (partyCd, relationshipCd) => { return (Number(partyCd) !== CreditorsType.BANK && Number(partyCd) !== CreditorsType.INDIVIDUAL)},
        then: () => Yup.string().required('Date of Incorporation is required'),
        otherwise: () => Yup.string().nullable(true),
    }),
    extension: Yup.string(),
    phoneNumber: Yup.string().when(['partyCd'], {
        is: (partyCd) => { return Number(partyCd) !== CreditorsType.BANK},
        then: () => Yup.string().required("Mobile Number is required")
            .test("phoneNumber", "Invalid Mobile Number", (str, context) => {
                return isValidPhoneNumber(context.parent['extension'] + str);
            }),
        otherwise: () => Yup.string().nullable(true)
    }),
    addressLine1: Yup.string().required('Address Line 1 is required').min(2, 'Enter minimum of 2 letters')
        .max(100, 'Limit is till 100 characters'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    postalCode: Yup.string().required('Pin Code is required').matches(RegEx.PIN_CODE, 'Invalid Pin Code'),
    cityOrTown: Yup.string().required('City is required'),
    preferredAuthorizedRepresentativeId: Yup.string().when('creditorClasses', {
        is: (creditorClasses) => { return creditorClasses != '' && creditorClasses != undefined && creditorClasses != null},
        then: () => Yup.string().required('Preferred IP Auth. Rep. is required'),
        otherwise: () => Yup.string().nullable(true),
    }),
    secured: Yup.string().when('relationshipCd', {
        is: (relationshipCd) => { return relationshipCd != RelationshipCd.EMPLOYEE_CLAIMANT && relationshipCd != RelationshipCd.WORKMAN_CLAIMANT},
        then: () => Yup.boolean().required('Secured is required'),
        otherwise: () => Yup.string().nullable(true)
    }),
    realtedParty: Yup.boolean().required('Realted Party is required'),
    relatedUnderSection:  Yup.boolean().required('Realted Under Section is required'),
    voteShare: Yup.number().nullable().min(0, 'Vote Share must be at least 0%')
            .max(100, 'Vote Share must not exceed 100%')
            .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
});

const IPCreateCreditorValidationSchema = Yup.object().shape({
    partyCd: Yup.string(),
    name: Yup.string().required('Name is required').matches(RegEx.COMBINATION_OF_LETTERS_OR_ONLY_LETTERS, 'Invalid Name'),
    identificationCd: Yup.string(),
    identification: Yup.string().required('Identification is required').when(['identificationCd', 'partyCd'], {
            is: (identificationCd, partyCd) => { return Number(identificationCd) === IdentificationType.CIN && Number(partyCd) !== CreditorsType.BANK},
            then: () => Yup.string().required('Identification is required').matches(RegEx.CIN, 'Invalid CIN'),
            otherwise: () => Yup.string().required('Identification is required')
        }).when('identificationCd', {
            is: (identificationCd) => { return Number(identificationCd) === IdentificationType.IRDAI_REG_NO},
            then: () => Yup.string().required('Identification is required')
        }).when('identificationCd', {
            is: (identificationCd) => { return (Number(identificationCd) === IdentificationType.PAN || Number(identificationCd) === IdentificationType.PROP_BUSINESS_PAN)},
            then: () => Yup.string().required('Identification is required').matches(RegEx.PAN, 'Invalid PAN')
        }).when('identificationCd', {
            is: (identificationCd) => { return Number(identificationCd) === IdentificationType.PASSPORT},
            then: () => Yup.string().required('Identification is required').matches(RegEx.PASSPORT, 'Invalid Passport No.')
        }).when('identificationCd', {
            is: (identificationCd) => { return Number(identificationCd) === IdentificationType.VOTERID},
            then: () => Yup.string().required('Identification is required').matches(RegEx.VOTER_ID, 'Invalid Voter ID')
        }),
    natureOfRelatedParty: Yup.string().when('realtedParty', {
        is: (realtedParty) => { return realtedParty === true},
        then: () => Yup.string().required('Relationship  is required'),
        otherwise: () => Yup.string().nullable(true),
    }),
    serialNumber: Yup.string().when('relationshipCd', {
        is: (relationshipCd) => { return relationshipCd === RelationshipCd.EMPLOYEE_CLAIMANT || relationshipCd === RelationshipCd.WORKMAN_CLAIMANT},
        then: () => Yup.string().required('ID is required').matches(RegEx.REFERENCE_NUMBER, 'Invalid Voter ID'),
        otherwise: () => Yup.string().nullable(true),
    }),
    email: Yup.string().email('Invalid Email').test('custom-validation', 'Invalid Email', (value) => !value || RegEx.EMAIL.test(value)).nullable(true),
    dobDoi: Yup.string().when(['partyCd', 'relationshipCd'], {
        is: (partyCd, relationshipCd) => { return (Number(partyCd) !== CreditorsType.BANK && Number(partyCd) !== CreditorsType.INDIVIDUAL)},
        then: () => Yup.string().required('Date of Incorporation is required'),
        otherwise: () => Yup.string().nullable(true),
    }),
    extension: Yup.string(),
    phoneNumber: Yup.string().test("phoneNumber", "Invalid Mobile Number", (str, context) => 
        !str || isValidPhoneNumber(context.parent['extension'] + str)).nullable(true),
    addressLine1: Yup.string().required('Address Line 1 is required').min(2, 'Enter minimum of 2 letters')
        .max(100, 'Limit is till 100 characters'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    postalCode: Yup.string().required('Pin Code is required').matches(RegEx.PIN_CODE, 'Invalid Pin Code'),
    cityOrTown: Yup.string().required('City is required'),
    preferredAuthorizedRepresentativeId: Yup.string().when('creditorClasses', {
        is: (creditorClasses) => { return creditorClasses != '' && creditorClasses != undefined && creditorClasses != null},
        then: () => Yup.string().required('Preferred IP Auth. Rep. is required'),
        otherwise: () => Yup.string().nullable(true),
    }),
    secured: Yup.string().when('relationshipCd', {
        is: (relationshipCd) => { return relationshipCd != RelationshipCd.EMPLOYEE_CLAIMANT && relationshipCd != RelationshipCd.WORKMAN_CLAIMANT},
        then: () => Yup.boolean().required('Secured is required'),
        otherwise: () => Yup.string().nullable(true)
    }),
    realtedParty: Yup.boolean().required('Realted Party is required'),
    relatedUnderSection:  Yup.boolean().required('Realted Under Section is required'),
    voteShare: Yup.number().nullable().min(0, 'Vote Share must be at least 0%')
            .max(100, 'Vote Share must not exceed 100%')
            .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
});

const AuthRepValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').matches(RegEx.COMBINATION_OF_LETTERS_OR_ONLY_LETTERS, 'Invalid Name'),
    relationshipName: Yup.string().required('Designation is required').matches(RegEx.COMBINATION_OF_LETTERS_OR_ONLY_LETTERS, 'Invalid Designation'),
    identificationCd: Yup.number(),
    identification: Yup.string().required('Identification is required').when('identificationCd', {
        is: (identificationCd) => { return Number(identificationCd) === IdentificationType.PAN},
        then: () => Yup.string().required('Identification is required').matches(RegEx.PAN, 'Invalid PAN')
    }).when('identificationCd', {
        is: (identificationCd) => { return Number(identificationCd) === IdentificationType.PASSPORT},
        then: () => Yup.string().required('Identification is required').matches(RegEx.PASSPORT, 'Invalid Passport No.')
    }).when('identificationCd', {
        is: (identificationCd) => { return Number(identificationCd) === IdentificationType.VOTERID},
        then: () => Yup.string().required('Identification is required').matches(RegEx.VOTER_ID, 'Invalid Voter ID')
    }),
    email: Yup.string().email('Invalid Email Address').test('custom-validation', 'Invalid Email Address', (value) => {
        return RegEx.EMAIL.test(value);
    }).required('Email Address is required'),
    extension: Yup.string(),
    phoneNumber: Yup.string().required("Mobile Number is required")
        .test("phoneNumber", "Invalid Mobile Number", (str, context) => {
            return isValidPhoneNumber(context.parent['extension'] + str);
    }),
    addressLine1: Yup.string().required('Address Line 1 is required').min(2, 'Enter minimum of 2 letters')
        .max(100, 'Limit is till 100 characters'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    postalCode: Yup.string().required('Pin Code is required').matches(RegEx.PIN_CODE, 'Invalid Pin Code'),
    cityOrTown: Yup.string().required('City is required')
});

const IPCreateAuthRepValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').matches(RegEx.COMBINATION_OF_LETTERS_OR_ONLY_LETTERS, 'Invalid Name'),
    relationshipName: Yup.string().required('Designation is required').matches(RegEx.COMBINATION_OF_LETTERS_OR_ONLY_LETTERS, 'Invalid Designation'),
    identificationCd: Yup.number(),
    identification: Yup.string().required('Identification is required').when('identificationCd', {
        is: (identificationCd) => { return Number(identificationCd) === IdentificationType.PAN},
        then: () => Yup.string().required('Identification is required').matches(RegEx.PAN, 'Invalid PAN')
    }).when('identificationCd', {
        is: (identificationCd) => { return Number(identificationCd) === IdentificationType.PASSPORT},
        then: () => Yup.string().required('Identification is required').matches(RegEx.PASSPORT, 'Invalid Passport No.')
    }).when('identificationCd', {
        is: (identificationCd) => { return Number(identificationCd) === IdentificationType.VOTERID},
        then: () => Yup.string().required('Identification is required').matches(RegEx.VOTER_ID, 'Invalid Voter ID')
    }),
    email: Yup.string().email('Invalid Email').test('custom-validation', 'Invalid Email', (value) => !value || RegEx.EMAIL.test(value)).nullable(true),
    extension: Yup.string(),
    phoneNumber: Yup.string().test("phoneNumber", "Invalid Mobile Number", (str, context) => 
        !str || isValidPhoneNumber(context.parent['extension'] + str)).nullable(true),
    addressLine1: Yup.string().required('Address Line 1 is required').min(2, 'Enter minimum of 2 letters')
        .max(100, 'Limit is till 100 characters'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    postalCode: Yup.string().required('Pin Code is required').matches(RegEx.PIN_CODE, 'Invalid Pin Code'),
    cityOrTown: Yup.string().required('City is required')
});

const AddressValidationSchema = Yup.object().shape({
    addressLine1: Yup.string().required('Address Line 1 is required').min(2, 'Enter minimum of 2 letters')
        .max(100, 'Limit is till 100 characters'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    pincode: Yup.string().required('Pin Code is required').matches(RegEx.PIN_CODE, 'Invalid Pin Code'),
    cityOrTown: Yup.string().required('City is required')
});

const BankValidationSchema = Yup.object().shape({
    accountHolderName: Yup.string().required('Account Name is required').matches(RegEx.USERNAME, 'Invalid Name'),
    accountNumber: Yup.string().required('Account No. is required').min(11, 'Enter minimum of 11 digits')
        .max(16, 'Limit is till 16 digits'),
    bankAccountCd: Yup.string().required('Account Type is required'),
    bankName: Yup.string().required('Bank Name is required'),
    branchName: Yup.string().required('Bank Branch is required'),
    ifsc: Yup.string().required('IFSC is required'),
});

const ClaimValidationSchema = Yup.object().shape({
    debtId: Yup.string().when('claimCd', {
        is: (claimCd) => { return Number(claimCd) !== ClaimType.RES_EMPLOYEE && Number(claimCd) !== ClaimType.RES_WORKMAN &&
            Number(claimCd) !== ClaimType.LIQ_EMPLOYEE && Number(claimCd) !== ClaimType.LIQ_WORKMAN },
        then: () => Yup.string().required('Field is required').min(2, 'Enter minimum of 2 letters')
            .max(50, 'Limit is till 50 characters'),
        otherwise: () => Yup.string().nullable(true)
    }),
    projectName: Yup.string().when('claimCd', {
        is: (claimCd) => { return Number(claimCd) == ClaimType.RES_HOME_BUYER || Number(claimCd) == ClaimType.LIQ_HOME_BUYER },
        then: () => Yup.string().required('Field is required').matches(RegEx.COMBINATION_OF_LETTERS_OR_ONLY_LETTERS, 'Invalid Project Name'),
        otherwise: () => Yup.string().nullable(true)
    }),
    natureOfClaim: Yup.string().required('Field is required').matches(RegEx.COMBINATION_OF_LETTERS_OR_ONLY_LETTERS, 'Invalid Nature of Claim'),
    claimCd: Yup.string().required('Field is required'),
    claimTime: Yup.string().required('Field is required'),
    claimToTime: Yup.string().when('claimCd', {
        is: (claimCd) => { return Number(claimCd) !== ClaimType.RES_FIN_AS_GUARANTOR && Number(claimCd) !== ClaimType.RES_FIN_AS_PRINCIPAL_BORROWER &&
            Number(claimCd) !== ClaimType.RES_FIN_COVERED_UNDER_CLAUSE_H_I && Number(claimCd) !== ClaimType.RES_HOME_BUYER &&
            Number(claimCd) !== ClaimType.LIQ_FIN_AS_GUARANTOR && Number(claimCd) !== ClaimType.LIQ_FIN_AS_PRINCIPAL_BORROWER &&
            Number(claimCd) !== ClaimType.LIQ_FIN_COVERED_UNDER_CLAUSE_H_I && Number(claimCd) !== ClaimType.LIQ_HOME_BUYER },
        then: () => Yup.string().required('Field is required'),
        otherwise: () => Yup.string().nullable(true)
    }),
    disbursedAmount: Yup.string().when('sanctionSaleAgreementAmount', {
        is: (sanctionSaleAgreementAmount) => {return sanctionSaleAgreementAmount > 0},
        then: () => Yup.string().test("disbursedAmount", "Cannot be greater than Total Sanction Amount", (str, context) => {
            return str <= context.parent['sanctionSaleAgreementAmount']
            }).nullable(true),
        otherwise: () => Yup.string().nullable(true)
    })
});

const AdmissionValidationSchema = Yup.object().shape({
    admittedTime: Yup.string().required('Due Date is required'),
});

const GenerateValidationSchema = Yup.object().shape({
    submissionDate: Yup.string().required('Submission Date is required'),
});

export { CreditorValidationSchema, AuthRepValidationSchema, AddressValidationSchema, IPCreateCreditorValidationSchema,
    BankValidationSchema, ClaimValidationSchema, GenerateValidationSchema, AdmissionValidationSchema,
    IPCreateAuthRepValidationSchema }