import { FaCartFlatbedSuitcase, FaUserTie } from "react-icons/fa6";
import { RiPriceTag2Fill } from "react-icons/ri";

export const FinanceSideValue = {
    COST_SHEET: 'finance-costsheet',
    CASH_FLOW: 'finance-cashflow',
    PROFESSIONAL: 'finance-professional'
}

// left navigation order will take as in this list
export const FinanceSideMenu = [
    {
        id: 'costsheet',
        value: FinanceSideValue.COST_SHEET,
        title: 'Cost Sheet CIRP',
        icon: <RiPriceTag2Fill style={{ fontSize: '1.5rem' }} />
    },
    {
        id: 'cashflow',
        value: FinanceSideValue.CASH_FLOW,
        title: 'Cash Flow - Liq',
        icon: <FaCartFlatbedSuitcase style={{ fontSize: '1.5rem' }} />
    },
    {
        id: 'professional',
        value: FinanceSideValue.PROFESSIONAL,
        title: 'Professional',
        icon: <FaUserTie style={{ fontSize: '1.5rem' }} />
    },
]