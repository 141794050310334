import {
    Button,
    CardContent,
    Stack,
    Typography
} from '@mui/material';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiLockPasswordFill } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import ConfirmationDialog from '../common/ConfirmationDialog/ConfirmationDialog';
import ICMSReCaptcha from '../common/ICMSReCaptcha';
import ICMSLoader from '../common/icms-loader/ICMSLoader';
import IcmsSnackbar from '../common/icms-snackbar/IcmsSnackbar';
import { AuthDiv, JustifyCard } from '../common/icms-styled-components/IcmsStyledComponents';
import { AuthAxiosInterceptor } from '../config/axios.interceptor';
import { FormikOtpVerification } from '../common/formik-fields-components/FormikOtpVerification';
import { useRef } from 'react';
import { OtpText, TimeExpire } from './OtpExpireStyle';

function ConfirmOtpVerification() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const [message, setMessage] = useState({
        show: false,
        message: null,
        severity: null
    })
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [time, setTime] = useState(300); // 300 seconds = 5 minutes
    const countdownRef = useRef(null);
    const [timeExpired, setTimeExpired] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirectUrl = queryParams.get('redirect');

    const OtpValidationSchema = location.state.verifyAccount ? Yup.lazy((values) => {
        const hasEmailOtpValue = values.emailOtpValue && values.emailOtpValue.trim() !== '';
        const hasPhoneOtpValue = values.phoneOtpValue && values.phoneOtpValue.trim() !== '';

        if (hasEmailOtpValue || hasPhoneOtpValue) {
            return Yup.object({
                emailOtpValue: hasPhoneOtpValue
                    ? Yup.string().notRequired()
                    : Yup.string().required("Email OTP Required").min(6, "Enter Code Correctly").max(6, "Enter Code Correctly"),
                phoneOtpValue: hasEmailOtpValue
                    ? Yup.string().notRequired()
                    : Yup.string().required("Phone OTP Required").min(6, "Enter Code Correctly").max(6, "Enter Code Correctly")
            });
        }

        return Yup.object({
            emailOtpValue: Yup.string().required("Email OTP Required").min(6, "Enter Code Correctly").max(6, "Enter Code Correctly"),
            phoneOtpValue: Yup.string().required("Phone OTP Required").min(6, "Enter Code Correctly").max(6, "Enter Code Correctly")
        });
    }) : Yup.object().shape({
        emailOtpValue: Yup.string().required("Email OTP Required").min(6, "Enter Code Correctly").max(6, "Enter Code Correctly"),
        phoneOtpValue: Yup.string().min(6, "Enter Code Correctly").max(6, "Enter Code Correctly"),
    })

    const verifyFormik = useFormik({
        initialValues: {
            emailOtpValue: '',
            phoneOtpValue: ''
        },
        validationSchema: OtpValidationSchema,
        onSubmit: (values) => {
            validateOtp()
        }
    })

    const onSubmit = () => {
        // if (verifyFormik.isValid) {
        verifyFormik.handleSubmit();
        // }
    }

    const resendOtp = async (contact) => {
        verifyFormik.setFieldValue(contact === location.state.email && 'emailOtpValue', '');
        verifyFormik.setFieldValue(contact === location.state.phoneNumber && 'phoneOtpValue', '');
        setLoading(true)
        setMessage({ show: false, message: '', severity: '' });
        AuthAxiosInterceptor.patch(`user/request-otp?recaptcha=${captcha}&purpose=verify-signup`, {
            loginId: contact
        }).then(response => {
            setMessage({ show: true, message: <Fragment>{`${t("OTP_Sent_To_Your")} ${contact}`}</Fragment>, severity: 'success' });
            setLoading(false);
            setIsRefresh(true);
            setTimeExpired(false)
            setTime(300)
            setTimeout(() => {
                setIsRefresh(false);
            }, 1000);
        }).catch((error) => {
            setLoading(false);
            setIsRefresh(true);
            setTimeout(() => {
                setIsRefresh(false);
            }, 1000);
            if (error.message) {
                setMessage({ show: true, message: error.message, severity: 'error' });
            }
        })
    }

    const validateOtp = () => {
        let emailCode = verifyFormik.values.emailOtpValue.replaceAll(' ', '')
        let phoneCode = verifyFormik.values.phoneOtpValue.replaceAll(' ', '')
        if (location.state.email && location.state.phoneNumber) {
            setLoading(true);
            let payload = [];
            if (emailCode !== null && emailCode !== '') {
                payload.push(
                    {
                        contact: location.state.email,
                        otp: emailCode
                    }
                )
            }
            if (phoneCode !== null && phoneCode !== '') {
                payload.push(
                    {
                        contact: location.state.phoneNumber,
                        otp: phoneCode
                    }
                )
            }
            setMessage({ show: false, message: '', severity: '' });
            AuthAxiosInterceptor.post(`user/multiple-verify?recaptcha=${captcha}`, payload).then(response => {
                if (response === true) {
                    setMessage({ show: true, message: t('Account_Created_Successfully'), severity: 'success' });
                    setShowConfirmation(true);
                }
                setLoading(false)
            }).catch(error => {
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 1000);
                if (error.message) {
                    // setErrorMessage(error.message);
                    setMessage({ show: true, message: error.message, severity: 'error' });
                } else {
                    // setErrorMessage(t('Try_Again'));
                    setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
                }
                setLoading(false)
            })
        }
        else {
            setLoading(true);
            // let payload = {};
            let payload = {
                contact: location.state.emailOrPhoneNumber,
                otp: location.state.selectedValue === 'email' ? emailCode : phoneCode
            }
            setMessage({ show: false, message: '', severity: '' });
            AuthAxiosInterceptor.post(`user/verify?recaptcha=${captcha}`, payload).then(response => {
                if (response === true) {
                    setShowConfirmation(true);
                }
                setMessage({ show: true, message: t('Account_Verified_Successfully'), severity: 'success' });
                setLoading(false);
            }).catch(error => {
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 1000);
                if (error.message) {
                    setMessage({ show: true, message: error.message, severity: 'error' });
                } else {
                    setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
                }
                setLoading(false)
            })
        }

    }

    const navigateToSignIn = () => {
        navigate(`/sign-in${redirectUrl ? `?redirect=${redirectUrl}` : ''}`);
    }

    useEffect(() => {
        countdownRef.current = setInterval(() => {
            setTime((prevTime) => {
                if (prevTime === 0) {
                    clearInterval(countdownRef.current);
                    setTimeExpired(true)
                    // Timer reached zero, you can add your logic here
                    return prevTime;
                }
                return prevTime - 1;
            });
        }, 1000); // Update every 1000ms (1 second)

        // Cleanup the timer when the component unmounts
        return () => clearInterval(countdownRef.current);
    }, [timeExpired]);

    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    function maskNumber(number) {
        const maskedPart = '*'.repeat(Math.max(0, number.length - 3));
        const lastDigits = number.slice(-3);
        return maskedPart + lastDigits;
    }

    function maskEmail(email) {
        const [username, domain] = email.split('@');
        const maskedUsername = '*'.repeat(Math.max(0, username.length - 3)) + username.slice(-3);
        const lastDotIndex = domain.lastIndexOf('.');
        const maskedDomain = '*'.repeat(Math.max(0, lastDotIndex - 0)) + domain.slice(lastDotIndex - 0);
        return maskedUsername + '@' + maskedDomain;
    }

    return (
        <Fragment>
            <AuthDiv>
                <Stack justifyContent='center' alignItems='center' m='auto' height="100vh">
                    <JustifyCard style={{ width: '34%' }}>
                        <CardContent sx={{ p: 0, pb: '0px !important' }}>
                            <Stack direction='column'>
                                <Stack p={2} justifyContent='center' alignItems='center' spacing={2}>
                                    <img src={process.env.PUBLIC_URL + "/images/Group.svg"} alt="ICMS_LOGO" width="30%"></img>
                                    <div><RiLockPasswordFill size={100} color='#66c8e4' /></div>
                                    {/* <img src={process.env.PUBLIC_URL + 'images/verifyIcon.svg'} alt="Login-png" width="30%"></img> */}
                                    <Typography variant="h5" >{t('Verify_Your_Account')}</Typography>
                                    {location.state.verifyAccount && <Typography variant="subtitle2" style={{ textAlign: 'center' }}><Fragment>{t('We_Have_Sent_Verification_Code_To')} <b>{location.state.selectedValue === 'email' ? 'Email' : "Mobile Number"}</b></Fragment></Typography>}
                                    {!location.state.verifyAccount && <Typography variant="subtitle2" style={{ textAlign: 'center' }}><Fragment>{t('We_Have_Sent_Verification_Code_To')} <b>{maskEmail(location.state.email)}</b> {'and'} <b>{maskNumber(location.state.phoneNumber)}</b></Fragment></Typography>}
                                    <Typography style={{ background: '#fcf4ec', color: '#ff7100', fontSize: "0.9rem", borderRadius: '5px', textAlign: 'center' }}>
                                        {t("All_Communications_And_Notifications_Will_Only_Sent_Verified_Email_Mobile_Number")}</Typography>
                                    {/* {!location.state.verifyAccount && <Typography variant="subtitle2" sx={{color:'red'}} >{t("Email_Verification_Code_Is_Mandatory")}</Typography>} */}
                                </Stack>
                                <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
                                    {!timeExpired ?
                                        <Fragment>
                                            <OtpText minutes={minutes}>{t("OTP_Will_Expire_With_In")}</OtpText>
                                            <TimeExpire minutes={minutes}>
                                                {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                                            </TimeExpire>
                                        </Fragment> :
                                        <Fragment>
                                            <Typography style={{ color: 'red' }}>{t("Your_OTP_Has_Been_Expired_Please_Click_To_Resend")}</Typography>
                                        </Fragment>
                                    }
                                </Stack>
                                {location.state.verifyAccount ? <Stack p={2} justifyContent='center' alignItems='center'>
                                    <FormikOtpVerification
                                        id="emailOtpValue"
                                        fieldName={location.state.selectedValue === 'phoneNumber' ? 'phoneOtpValue' : "emailOtpValue"}
                                        label={location.state.selectedValue === 'email' ? t("Enter_Email_Verification_Code") : t("Enter_Mobile_Number_Verification_Code")}
                                        formik={verifyFormik}
                                        isEmailOrMobile={location.state.selectedValue === 'phoneNumber' && 'mobile'}
                                        resendOtp={() => resendOtp(location.state.emailOrPhoneNumber)}
                                    /><br /><br />
                                </Stack> :
                                    <Stack p={2} justifyContent='center' alignItems='center'>
                                        <FormikOtpVerification
                                            id="emailOtpValue"
                                            fieldName="emailOtpValue"
                                            label={t("Enter_Email_Verification_Code")}
                                            formik={verifyFormik}
                                            resendOtp={() => resendOtp(location.state.email)}
                                        /><br /><br />
                                        <FormikOtpVerification
                                            id="phoneOtpValue"
                                            fieldName="phoneOtpValue"
                                            label={t("Enter_Mobile_Number_Verification_Code")}
                                            formik={verifyFormik}
                                            resendOtp={() => resendOtp(location.state.phoneNumber)}
                                        />
                                    </Stack>}
                                <Stack direction='row' justifyContent='space-between' p={2}>
                                    <Button onClick={navigateToSignIn} variant='outlined' data-testid='back-signin'>{location.state.verifyAccount ? t("Back_To_Sign_In") : t("Verify_Later")}</Button>
                                    <Button onClick={onSubmit} variant='contained' data-testid='validate'>{t("Validate")}</Button>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </JustifyCard>
                </Stack>
            </AuthDiv>
            {!isRefresh && <ICMSReCaptcha key="signup" captcha={setCaptcha} refresh={setIsRefresh} />}
            {loading && <ICMSLoader show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
            {showConfirmation ? <ConfirmationDialog title={t('Verification_successful')} show={showConfirmation}
                description={[t('Your_'), <strong>{t('App_Name')}</strong>, t('_account_is_verified_successfully')]}
                redirect={true} redirectUrl={`/sign-in${redirectUrl ? `?redirect=${redirectUrl}` : ''}`} /> : null}
        </Fragment>
    )
}

export default ConfirmOtpVerification
