import { Avatar, Button, Divider, Grid, Stack, Tooltip, Typography, alpha } from '@mui/material';
import React, { Fragment, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBank2, BsFillPersonFill, BsPerson } from 'react-icons/bs';
import { FaAddressCard, FaHistory } from "react-icons/fa";
import { HiOutlineCamera } from "react-icons/hi";
import { IoPersonCircleSharp } from "react-icons/io5";
import { RiLockPasswordFill } from 'react-icons/ri';
import { TiArrowBack } from "react-icons/ti";
import { useNavigate } from 'react-router';
import { useLocation } from "react-router-dom";
import { CaseManagementSideNav, Roles } from '../GenericCodes';
import { UserContext } from '../context/UserContext';
import { ProfileContent } from '../icms-styled-components/IcmsStyledComponents';
import { MenuBar, MenuListIcon, MenuListItem, MenuListText, SideNavBar } from '../icms-styled-components/SideNavBarStyles';
import ProfileDashboard from './ProfileDashboard';
import UserDetails from './UserDetails';
import AddressContainer from './address/AddressContainer';
import BankListProfile from './bank-details/BankListProfile';
import ChangePassword from './change-password/ChangePassword';
import FeedBackPage from './feedback/FeedBackPage';
import LoginHistory from './login-history/LoginHistory';
import { TbCertificate } from 'react-icons/tb';
import AuthorisationForAssignmentList from '../../icms/case-inside/case-modules/case-detail/service-provider/afa/AuthorisationForAssignmentList';
import IdentificationList from './identification/IdentificationList';
import { PiIdentificationBadgeLight } from "react-icons/pi";


const getProfileValues = (isIp) => {
    return [
        // { id: 'details', value: "details", title: 'Profile Dashboard', icon: <MdSpaceDashboard fontSize={20} />, visibility: true },
        { id: 'profile', value: "profile", title: 'User Information', icon: <BsFillPersonFill fontSize={23} />, visibility: true },
        { id: 'identification', value: "identification", title: 'Identifications', icon: <PiIdentificationBadgeLight fontSize={22} />, visibility: true },
        { id: 'Address', value: "address", title: 'My Address', icon: <FaAddressCard fontSize={20} />, visibility: true },
        { id: 'afa', value: "afa", title: 'AFA', icon: <TbCertificate fontSize={23} />, visibility: isIp ? isIp : false},
        { id: 'bank-detail', value: "bank", title: 'Bank Details', icon: <BsBank2 fontSize={22} />, visibility: true },
        { id: 'change-password', value: "password", title: 'Change Password', icon: <RiLockPasswordFill fontSize={21} />, visibility: true },
        { id: 'login-history', value: "history", title: 'My Login History', icon: <FaHistory fontSize={20} />, visibility: true },
        // { id: 'Feedback', value: "feedback", title: 'My Feedback', icon: <MdFeedback fontSize={22} />, visibility: true },
        // { id: 'logout', value: "logout", title: 'Logout', icon: <BiLogOut color='red' fontSize={22} />, visibility: true },
        { id: 'Back', value: "back", title: 'Back', icon: <TiArrowBack color='blue' fontSize={22} />, visibility: true },
    ]
}


const MyProfile = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation()
    const context = useContext(UserContext);
    const [selectedValue, setSelectedValue] = useState('profile')
    // const [handleChangePage, setHandleChangePage] = useState('')
    const isIP = context?.loginDetails?.insolvencyProfessional? true : false;
    const profileValues = getProfileValues(isIP);
    const listChangeHandler = (value, title) => {
        if (value === 'back') {
            navigate("/details/case-management", {
                state: {
                    caseType: (location.state.loginDetails.subscriberRole.includes(Roles.enkAdmin.roleValue) ||
                        location.state.loginDetails.subscriberRole.includes(Roles.enkSupport.roleValue) ||
                        location.state.loginDetails.subscriberRole.includes(Roles.administrator.roleValue) ||
                        location.state.loginDetails.subscriberRole.includes(Roles.monitor.roleValue) ||
                        location.state.loginDetails.subscriberRole.includes(Roles.accountAdmin.roleValue)
                    ) ? CaseManagementSideNav.active.key : CaseManagementSideNav.all.key
                }
            })
        } else if (value === 'logout') {
            navigate("/sign-in")
        }
        setSelectedValue(value)
        // setHandleChangePage(value)
    }
    const handleChangeValue = (value) => {
        // setHandleChangePage(value)
        setSelectedValue(value)
    }
    
    return (
        <Grid container>
            <Grid item xs={2}>
                <SideNavBar>
                    <MenuBar>
                        <Stack justifyContent='start' alignItems='center' mb={2} sx={{ cursor: 'initial' }}>
                            <Avatar sx={{ bgcolor: (theme) => alpha(theme.palette.secondary.main, 0.1), color: (theme) => theme.palette.secondary.main, mb: 1 }}>
                                <BsPerson />
                            </Avatar>
                            <Typography variant='subtitle1' data-testid='welcome'>{t("Welcome")}</Typography>
                            <Typography variant='subtitle1' data-testid='name' sx={{ fontWeight: 'bold' }}>{context.userSession.userSession.session.name}</Typography>
                        </Stack>
                        { profileValues.map((item, index) => {
                            return (
                                <Fragment>
                                    {(item.value === 'logout' || item.value === 'details') && <Fragment><Divider flexItem /></Fragment>}
                                    {item.visibility && 
                                        <MenuListItem id={item.id} key={index} isSelected={selectedValue === item.value}
                                            onClick={() => { listChangeHandler(item.value, item.title) }}>
                                            <Stack direction='row' justifyContent='center' alignItems='center'>
                                                <MenuListIcon className='icon'>
                                                    {item.icon}
                                                </MenuListIcon>
                                                <MenuListText className='text'>
                                                    <Typography style={{ margin: '0px 0px 0px 15px' }} variant='body1'>{item.title}</Typography>
                                                </MenuListText>
                                            </Stack>
                                        </MenuListItem>
                                    }
                                </Fragment>
                            )
                        })}
                    </MenuBar>
                </SideNavBar>
            </Grid>
            <Grid item xs={10}>
                <Stack direction='column' spacing={2}>
                    { selectedValue === 'details' && <div><ProfileContent /></div> }
                    { selectedValue === 'details' && <Stack alignItems='center' justifyContent='center'>
                        <IoPersonCircleSharp style={{ borderRadius: '60%', marginTop: '-75px', fontSize: '7rem', background: 'white', padding: '0px' }} />
                        <Stack direction='row'>
                            <Button variant='outlined' data-testid='add-img' startIcon={<HiOutlineCamera />}>
                                {t("Add_Image")}
                            </Button>
                        </Stack>
                    </Stack>}
                    <div style={{ marginTop: '3rem' }}>
                        {/* { selectedValue === 'details' && <ProfileDashboard setHandleChangePage={handleChangeValue} />} */}                        
                        { selectedValue === 'identification' && <IdentificationList setHandleChangePage={handleChangeValue} partyId={location.state.loginDetails.partyId}/>}
                        { selectedValue === 'password' && <ChangePassword setHandleChangePage={handleChangeValue} contextValues={context.userSession.userSession.session} />}
                        { selectedValue === 'history' && <LoginHistory setHandleChangePage={handleChangeValue} />}
                        { selectedValue === 'profile' && <UserDetails setHandleChangePage={handleChangeValue} location={context.loginDetails} contextValues={context.userSession.userSession.session} />}
                        {/* { selectedValue === 'feedback' && <FeedBackPage setHandleChangePage={handleChangeValue} />} */}
                        { selectedValue === 'afa' && <AuthorisationForAssignmentList isEdit={true} ipaIpRelationId={null} />}
                        { selectedValue === 'other' && <ChangePassword setHandleChangePage={handleChangeValue} />}
                        { selectedValue === 'address' && <AddressContainer setHandleChangePage={handleChangeValue} />}
                        { selectedValue === 'bank' && <BankListProfile setHandleChangePage={handleChangeValue} partyId={location.state.loginDetails.partyId} canEdit={true}/>}
                    </div>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default MyProfile
