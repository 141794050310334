import React, { useEffect,
    useState, useContext 
}                                   from 'react';
import {
    Stack, Card, Grid,Dialog, 
    DialogTitle, DialogActions,
    DialogContent, Button
}                                   from '@mui/material';
import { useTranslation }           from 'react-i18next';
import { useFormik }                from "formik";
import { FormikLabelTextField,
    FormikLabelMuiPhone }           from "../../../../common/formik-fields-components/FormikFieldComponents";
import { SelectLabelController }    from "../../../../common/formik-fields-components/SelectLabelController";
import { AuthRepValidationSchema, IPCreateAuthRepValidationSchema }  from '../creditor-details/CreditorYupSchema';
import { getAuthRep, 
    updateAuthRep }                 from '../../file-claim-services/AuthRepSerivce';
import {
    IdentificationType,
    IdentificationByPartyType,
    PartyType,
    AddressTypes,
    Roles,
    CaseRoles
}                                   from '../../../../common/GenericCodes';
import AddressDetail                from "../../../address/AddressDetails";
import ICMSLoader                   from '../../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                 from '../../../../common/icms-snackbar/IcmsSnackbar';
import { setFormikValueWithLowerCase, setFormikValueWithUpperCase }  from '../../../../common/utils/Utils';
import { ICMSButton }               from '../../../../common/icms-styled-components/IcmsStyledComponents';
import { UserContext }              from '../../../../common/context/UserContext';
import { CaseContext }              from '../../../../common/context/case/CaseContext';

const UpdateAuthRep = ({caseId, claimantId, authorizedRepresentativeId, onClose, show,
        onSuccess}) => {
    const { t } = useTranslation();
    const { contextValue } = useContext(CaseContext);
    const userDetails = useContext(UserContext);
    const isEnkAdmin = userDetails?.loginDetails?.subscriberRole?.includes(Roles.enkAdmin.roleValue);
    const isEnkSupport = userDetails?.loginDetails?.subscriberRole?.includes(Roles.enkSupport.roleValue);
    const [isEdit, setIsEdit] = useState(true);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});

    const authRepFormik = useFormik({
        initialValues: {
            authorizedRepresentativeId: '',
            partyId: '',
            partyCd: PartyType.INDIVIDUAL,
            identificationCd: IdentificationType.PAN,
            name: '',
            identification: '',
            emailId: '',
            emailContactCd: '',
            phoneNumberId: '',
            phoneNumberContactCd: '',
            email: '',
            phoneNumber: '',
            extension: '+91',
            partyAddressId: '',
            addressId: '',
            addressCd: AddressTypes.registerdWithIBBI.addressCd,
            addressLine1: '',
            addressLine2: '',
            cityOrTown: '',
            state: '',
            postalCode: '',
            country: 'India',
            relationshipName: '',
            identificationId: '',
            loginUid: ''
        },
        validationSchema: (contextValue?.roles?.includes(CaseRoles.ROLE_CASE_MANAGER) || contextValue?.roles?.includes(CaseRoles.ROLE_CASE_WORKER)
                || isEnkAdmin || isEnkSupport)
            ? IPCreateAuthRepValidationSchema : AuthRepValidationSchema,
        validateOnBlur: true,
        validateOnMount: true,
        onSubmit: (values) => {
        }
    })

    const getAuthRepDetails = () => {
        setLoading(true);
        getAuthRep(caseId, claimantId, authorizedRepresentativeId, (response) => {
            authRepFormik.resetForm( { values : {
                authorizedRepresentativeId: response.authorizedRepresentativeId,
                partyId: response.partyId,
                partyCd: response.partyCd,
                identificationCd: response.identificationCd,
                identificationId: response.identificationId,
                name: response.name,
                identification: response.identification,
                emailId: response.emailId,
                emailContactCd: response.emailContactCd,
                phoneNumberId: response.phoneNumberId,
                phoneNumberContactCd: response.phoneNumberContactCd,
                email: response.email,
                phoneNumber: response.phoneDetail?.nationalNumber,
                extension: response.phoneDetail?.countryCode ? '+' + response.phoneDetail?.countryCode : '+91',
                partyAddressId: response.partyAddressId,
                addressId: response.addressId,
                addressCd: response.addressCd,
                addressLine1: response.addressLine1,
                addressLine2: response.addressLine2,
                cityOrTown: response.cityOrTown,
                state: response.state,
                postalCode: response.postalCode,
                country: response.country ? response.country : 'India',
                relationshipName: response.relationshipName,
                loginUid: response.loginUid
            }});
            setLoading(false);
        }, (error) => {
            if (error?.message) {
                setMessage({show: true, message: error?.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const getIDLocale = (partyCd, value) => {
        let title = '';
        let identificationList = IdentificationByPartyType[partyCd];
        identificationList.forEach((item, index) => {
            if (value === item.id) {
                title = item.title;
            }
        })
        return title;
    }

    const handleSave = () => {
        setLoading(true);
        updateAuthRep(caseId, claimantId, authorizedRepresentativeId, authRepFormik.values, (response) => {
            setLoading(false);
            setIsEdit(false);
            onSuccess({show: true, message: t('Authorized_Person_Updated_Successfully'), severity: 'success'});
        }, (error) => {
            if (error?.message) {
                setMessage({show: true, message: error?.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    useEffect(() => {
        getAuthRepDetails();
    }, [])
    
    return (
        <div>
            <Dialog open={show} fullWidth maxWidth="lg">
                <DialogTitle>{t('Auth_Person_Details')}</DialogTitle>
                <DialogContent dividers>
                    <Stack width="100%" spacing={2}>
                        <Stack>
                            <Card sx={{padding: '4px'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <FormikLabelTextField
                                            id="name"
                                            fieldName="name"
                                            label={t("Name")}
                                            formik={authRepFormik}
                                            size="small"
                                            required={true}
                                            handleChange={authRepFormik.handleChange}
                                            handleBlur={authRepFormik.handleBlur}
                                            disabled={!isEdit ||
                                                (authRepFormik.values.loginUid ? userDetails?.loginDetails.loginUuid !== authRepFormik.values.loginUid : false)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SelectLabelController
                                            id="identificationCd"
                                            fieldName="identificationCd"
                                            label={t("Identification_Type")}
                                            formik={authRepFormik}
                                            size="small"
                                            required={true}
                                            menuItems={IdentificationByPartyType[PartyType.INDIVIDUAL]}
                                            handleChange={authRepFormik.handleChange}
                                            handleBlur={authRepFormik.handleBlur}
                                            disabled={!isEdit ||
                                                (authRepFormik.values.loginUid ? userDetails?.loginDetails.loginUuid !== authRepFormik.values.loginUid : false)}
                                            menuFieldId={'id'}
                                            menuFieldTitle={'title'}
                                            valueField={'id'}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormikLabelTextField
                                            id="identification"
                                            fieldName="identification"
                                            label={`${t('Identification')} (${getIDLocale(authRepFormik.values.partyCd, authRepFormik.values.identificationCd)})`}
                                            formik={authRepFormik}
                                            size="small"
                                            required={true}
                                            handleBlur={authRepFormik.handleBlur}
                                            handleChange={(event) => setFormikValueWithUpperCase(event, authRepFormik)}
                                            disabled={!isEdit ||
                                                (authRepFormik.values.loginUid ? userDetails?.loginDetails.loginUuid !== authRepFormik.values.loginUid : false)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormikLabelTextField
                                            id="relationshipName"
                                            fieldName="relationshipName"
                                            label={t("Designation")}
                                            formik={authRepFormik}
                                            size="small"
                                            required={true}
                                            handleBlur={authRepFormik.handleBlur}
                                            handleChange={authRepFormik.handleChange}
                                            disabled={!isEdit}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormikLabelTextField
                                            id="email"
                                            fieldName="email"
                                            label={t("Email")}
                                            formik={authRepFormik}
                                            required={true}
                                            size='small'
                                            handleBlur={authRepFormik.handleBlur}
                                            handleChange={(event) => setFormikValueWithLowerCase(event, authRepFormik)}
                                            disabled={!isEdit ||
                                                (authRepFormik.values.loginUid ? userDetails?.loginDetails.loginUuid !== authRepFormik.values.loginUid : false)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormikLabelMuiPhone
                                            id="phoneNumber"
                                            fieldName="phoneNumber"
                                            label={t("Mobile_Number")}
                                            countryCodeName="extension"
                                            formik={authRepFormik}
                                            required={true}
                                            size='small'
                                            handleBlur={authRepFormik.handleBlur}
                                            handleChange={authRepFormik.handleChange}
                                            disabled={!isEdit ||
                                                (authRepFormik.values.loginUid ? userDetails?.loginDetails.loginUuid !== authRepFormik.values.loginUid : false)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <AddressDetail addressTitle={t('Authorized_Person_Contact_Details')} 
                                        required={true} addressFormik={authRepFormik} 
                                        isDisabled={!isEdit ||
                                            (authRepFormik.values.loginUid ? userDetails?.loginDetails.loginUuid !== authRepFormik.values.loginUid : false)}/>
                                </Grid>
                            </Card>
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <ICMSButton onClick={handleSave} color="primary" size="small" variant='contained'
                            disabled={!authRepFormik.isValid || !authRepFormik.dirty}>
                        {t('Save')}
                    </ICMSButton>
                    <ICMSButton onClick={onClose} color="primary" size="small" variant='outlined'>
                        {t('Close')}
                    </ICMSButton>
                </DialogActions>
                {loading && <ICMSLoader show={loading}/>}
                {message.show && <IcmsSnackbar show={message.show} message={message.message} 
                        severity={message.severity} handleResetOnClose={() => 
                        setMessage({ show: false, message: '', severity: '' })}/>}
            </Dialog>
        </div>
    )
}

export default UpdateAuthRep