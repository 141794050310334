import { 
    Fragment, 
    useEffect, 
    useState 
}                                   from "react";
import { 
    NavigateBeforeRounded, 
    NavigateNextRounded 
}                                   from "@mui/icons-material";
import { 
    Button, 
    Card, 
    Stack, 
    Tooltip
}                                   from "@mui/material";
import { BsCheckLg }                from "react-icons/bs";
import { IoClose }                  from "react-icons/io5";
import { useNavigate, useParams }   from "react-router-dom";
import { 
    createAuthRep, 
    listAuthRep, 
    deleteAuthRep 
}                                   from "../../file-claim-services/AuthRepSerivce";
import {  
    getClaimant 
}                                   from "../../file-claim-services/FileClaimServices"
import AuthRepList                  from "./AuthRepList";
import AuthRep                      from "./AuthRep";
import { useFormik }                from "formik";
import { IdentificationType }       from "../../../../common/StaticVariables";
import { AuthRepValidationSchema }  from "../creditor-details/CreditorYupSchema";
import IcmsSnackbar                 from "../../../../common/icms-snackbar/IcmsSnackbar";
import ICMSLoader2                  from "../../../../common/icms-loader/icms-loader-2.O/ICMSLoader2";
import { ICMSButton }               from '../../../../common/icms-styled-components/IcmsStyledComponents';
import { TiArrowBack }              from "react-icons/ti";
import { useTranslation }           from 'react-i18next';
import { AddressTypes } from "../../../../common/GenericCodes";

const AuthRepContainer = (props) => {
    const {t} = useTranslation();
    const { handlePrevious, handleNext, totalSteps, width, left, caseId } = props;
    const param = useParams();
    const relationshipCd = Number(param.cd);
    const claimantId = Number(param.id);
    const navigate = useNavigate();
    const [authRepList, setAuthRepList] = useState(null);
    const [isCreate, setIsCreate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [claimantDetails, setClaimantDetails] = useState(null);
    const [message, setMessage] = useState({ show: false, message: '', severity: '' });

    const authRepFormik = useFormik({
        initialValues: {
            identificationCd: IdentificationType.PAN,
            name: '',
            identification: '',
            email: '',
            partyCd: 1,
            phoneNumber: '',
            extension: '+91',
            description: '',
            isSameAsCreditor: false,
            addressCd: AddressTypes.registerdWithIBBI.addressCd,
            addressLine1: '',
            addressLine2: '',
            cityOrTown: '',
            state: '',
            postalCode: '',
            country: 'India',
            partyId: null
        },
        validationSchema: AuthRepValidationSchema,
        validateOnChange: true,
        // validateOnBlur: true,
        // validateOnMount: true,
        onSubmit: (values) => {
            setLoading(true);
            let payload = {
                identificationCd: values.identificationCd,
                name: values.name,
                identification: values.identification,
                email: values.email,
                partyCd: values.partyCd,
                phoneNumber: values.extension + '' + values.phoneNumber,
                relationshipName: values.relationshipName,
                isSameAsCreditor: values.isSameAsCreditor,
                addressCd: values.addressCd,
                addressLine1: values.addressLine1,
                addressLine2: values.addressLine2,
                cityOrTown: values.cityOrTown,
                state: values.state,
                postalCode: values.postalCode,
                country: values.country,
                partyId: values.partyId
            }
            createUpdateAuthRep(payload);
        }
    })

    const handleReset = () => {
        authRepFormik.handleReset();
    }

    const getCreditor = () => {
        setLoading(true);
        getClaimant(Number(param.caseId), Number(param.id), (response) => {
            setClaimantDetails(response);
            console.log('response', response);
            },
            (error) => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            }
        )
    }

    const listOfAuthRep = (message) => {
        setLoading(true);
        listAuthRep(caseId, claimantId,
            (response) => {
                setAuthRepList(response);
                setLoading(false);
                // if (message) {
                //     setMessage({ show: true, message: 'Created Auth Rep Succesfully', severity: 'success' });
                // }
            },
            (error) => {
                setLoading(false);
                console.error(error)
                setMessage({ show: true, message: error.message, severity: 'error' });
            }
        )
    }

    const createUpdateAuthRep = (resource) => {
        createAuthRep(caseId, claimantId, resource,
            (response) => {
                if (response) {
                    setIsCreate(false);
                    setMessage({ show: true, message: t('Auth_Rep_Created_Succesfully'), severity: 'success' });
                    listOfAuthRep();
                    authRepFormik.resetForm();
                }
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                console.error(error)
                setMessage({ show: true, message: error.message, severity: 'error' });
            }
        )
    }

    const deleteAuthRepById = (authRepId) => {
        setLoading(true);
        deleteAuthRep(caseId, claimantId, authRepId, 
            (response) => {
                setLoading(false);
                setMessage({ show: true, message: t('Auth_Rep_Deleted_Succesfully'), severity: 'success' });
                listOfAuthRep();
            },
            (error) => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            })
    }

    const addAuthRep = () => {
        setIsCreate(true);
    }

    useEffect(() => {
        listOfAuthRep();
        if (param.id) {
            getCreditor();
        } 
    }, [])

    return (
        <Fragment>
            {/* Content */}
            {authRepList !== null && authRepList.length > 0 && !isCreate && <Stack direction="row" justifyContent='end' mb={2}>
                <ICMSButton size='small' onClick={addAuthRep} variant='contained' sx={{ float: 'right', width: 'fit-content' }}>
                    {t('Add_Auth_Rep')}
                </ICMSButton>
                </Stack>}
            {authRepList !== null && (authRepList.length === 0 || isCreate) && <AuthRep listOfAuthRep={listOfAuthRep} authRepFormik={authRepFormik} 
                claimantId={claimantId} caseId={caseId}/>}
            {authRepList !== null && authRepList.length > 0 && !isCreate && <AuthRepList authRepList={authRepList} onDelete={deleteAuthRepById}/>}
            {/* Actions */}
            <Card sx={{ bottom: 0, position: 'fixed', width: width, borderTop: '2px solid #eeeeee', left: left }}>
                <Stack justifyContent='space-between' direction="row">
                    <ICMSButton startIcon={<NavigateBeforeRounded className='back-icon' />} sx={{ float: 'left', mx: 5, my: 1 }}
                        onClick={handlePrevious} variant="outlined" color='primary'>{'Back'}
                    </ICMSButton>
                    {authRepList !== null && (authRepList.length === 0 || isCreate) && <span>
                        {authRepList !== null && authRepList.length > 0 && <ICMSButton startIcon={<TiArrowBack />} sx={{ float: 'center', mr: 3, my: 1 }} 
                                onClick={() => setIsCreate(false)} variant="outlined" color='primary' size="small">
                            {t('Back_To_List')}
                        </ICMSButton>}
                        {authRepFormik.dirty &&
                            <ICMSButton startIcon={<IoClose />} sx={{ float: 'center', mr: 3, my: 1 }} onClick={handleReset} variant="outlined" 
                                    color='primary' size="small">
                                {t('Cancel')}
                            </ICMSButton>}
                        {
                            <ICMSButton startIcon={<BsCheckLg />} sx={{ float: 'center', mr: 3, my: 1 }} onClick={authRepFormik.handleSubmit} variant="contained" color='primary'
                                disabled={!authRepFormik.dirty || !authRepFormik.isValid} size="small">
                                {t('Save')}
                            </ICMSButton>}
                    </span>}
                    {param.activeStep < totalSteps &&
                        // <Tooltip title={'Create Auth Rep to enable Next or Cancel'} disableHoverListener={param.id === 'new'}>
                            <ICMSButton endIcon={<NavigateNextRounded className={'next-icon'} />} sx={{ float: 'right', mr: 5, my: 1 }} onClick={handleNext} variant="contained" color='primary'
                                disabled={param.id === 'new'} size="small">
                                {t('Next')}
                            </ICMSButton>
                        // </Tooltip>
                    }
                </Stack>
            </Card>
            {loading && <ICMSLoader2 show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </Fragment>
    )
}

export default AuthRepContainer;