import React, { Fragment, Suspense, lazy, useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import ConfirmOtpVerification from './authentication/ConfirmOtpVerification';
import ForgotPassword from './authentication/ForgotPassword';
import Login from './authentication/Login';
import Register from './authentication/Register';
import ResetPassword from './authentication/ResetPassword';
import VerifyAccount from './authentication/VerifyAccount';
import { RoutePathValues } from './common/DeclaredStaticObjects';
import { UserContext, SubscriberContext } from './common/context/UserContext';
import ICMSLoader from './common/icms-loader/ICMSLoader';
import NotFound from './common/not-found/NotFound';
import MyProfile from './common/profile/MyProfile';
import Details from './icms/Details';
import ModuleTopBar from './nav-bar/ModuleTopBar';
import TopBar from './nav-bar/TopBar';
import ChooseClaimant from './icms/file-claim/choose-claimant/ChooseClaimant';
import FCFileClaim from './icms/file-claim/fc-file-claim/FCFileClaim';
import { listState } from './lookup-service/AddressService';
import { DefaultCountry } from './common/GenericCodes';
import { CaseContextProvider }  from './common/context/case/CaseContext';
import FileClaim from './icms/claimant/create-creditor/FileClaim';
import HomePage from './authentication/HomePage';
import PublicAnnouncementList from './common/poblic-announcement/PublicAnnouncementList';
import PublicAnnoucements from './common/poblic-announcement/PublicAnnoucements';
import InvitationOfEOIList from './common/poblic-announcement/InvitationOfEOIList';

function App() {
    // const { t } = useTranslation();
    const [userSession, setUserSession] = useState({ isAuthenticated: false, session: null, system_admin_of: [], color: '' });
    const [loginDetails, setLoginDetails] = useState(null);
    const [stateList, setStateList] = useState([]);
    const [ subscriberData, setSubscriberData ] = useState(null);

    function lazyWithPreload(url) {
        const Component = lazy(url);
        Component.preload = url;
        return Component;
    }

    const CaseList = lazyWithPreload(() => import('./case/case-list/case-list-container/CaseListContainer'));
    const AdminToolsContainer = lazyWithPreload(() => import('./admin-tools/admin-tools-container/AdminToolsContainer'));
    const AdministrationContainer = lazyWithPreload(() => import('./administration/administration-container/AdministrationContainer'));
    const CaseDetailContainer = lazyWithPreload(() => import('./icms/case-inside/case-modules/case-detail/CaseDetail'));
    const ClaimContainer = lazyWithPreload(() => import('./icms/case-inside/case-modules/claim/Claim'));
    const CommitteeContainer = lazyWithPreload(() => import('./icms/case-inside/case-modules/committee/Committee'));
    const FormReportContainer = lazyWithPreload(() => import('./icms/case-inside/case-modules/form-report/FormReport'));
    const AssetContainer = lazyWithPreload(() => import('./icms/case-inside/case-modules/asset/Asset'));
    const VdrContainer = lazyWithPreload(() => import('./icms/case-inside/case-modules/vdr/Vdr'));
    const FinanceContainer = lazyWithPreload(() => import('./icms/case-inside/case-modules/finance/Finance'));
    const ResolutionPlanContainer = lazyWithPreload(() => import('./icms/case-inside/case-modules/resolution-plan/ResolutionPlan'));
    const LiquidationContainer = lazyWithPreload(() => import('./icms/case-inside/case-modules/liquidation/Liquidation'));
    const AuditTrailContainer = lazyWithPreload(() => import('./icms/case-inside/case-modules/audit-trail/AuditTrail'));
    const CorporateDebtorContainer = lazyWithPreload(() => import('./icms/case-inside/case-modules/case-detail/corp-debtor/CorporateDebtor'));
    const PublicInformation = lazyWithPreload(() => import('./public-information/PublicInfoContainer'))

    const loadStateList = () => {
        listState(DefaultCountry.COUNTRY_ID, (response) => {
            if (response) {
                setStateList(response);
            }
        },
        (error) => {
            console.error('List Lookup States', error);
        })
    }
    
    useEffect(() => {
        setTimeout(() => {
            CaseList.preload();
            AdminToolsContainer.preload();
            AdministrationContainer.preload();
            CaseDetailContainer.preload();
            ClaimContainer.preload();
            CommitteeContainer.preload();
            FormReportContainer.preload();
            AssetContainer.preload();
            VdrContainer.preload();
            FinanceContainer.preload();
            ResolutionPlanContainer.preload();
            LiquidationContainer.preload();
            AuditTrailContainer.preload();
            CorporateDebtorContainer.preload();
            loadStateList();
          }, 3000);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Suspense fallback={<ICMSLoader show={true}/>}>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
                <UserContext.Provider value={{ userSession, setUserSession, loginDetails, stateList }}>
                    <div className="app">
                        <Routes>
                            <Route path="/" element={<HomePage setLoginDetails={setLoginDetails} />} />
                            <Route path="/home-page" element={<HomePage setLoginDetails={setLoginDetails} />} />
                            <Route path="/sign-in" element={<Login setLoginDetails={setLoginDetails} />} />
                            <Route path="/sign-up" element={<Register />} />
                            <Route path="/verify-otp" element={<ConfirmOtpVerification />} />
                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="/verify-account" element={<VerifyAccount />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                            <Route path="/kyc" element={<Details show={true}/>} />
                            <Route path="/details/*" element={
                                <Fragment>
                                    <div className='app-header'>
                                        <TopBar setLoginDetails={setLoginDetails}/>
                                    </div>
                                    <div className='app-content'>
                                        <Routes>
                                            {/* <Route index element={<Details />} /> */}
                                            <Route path="/kyc" element={<Details show={true}/>} />
                                            <Route path="/home" element={<Details/>} />
                                            {/* <Route path="/file-claim" element={<FileClaim />} /> */}
                                            <Route path="/my-profile" element={<MyProfile />} />
                                            
                                            <Route path={RoutePathValues.publicInformation} element={<PublicInformation />} />
                                            <Route path={RoutePathValues.fileClaim} element={<CaseContextProvider><PublicAnnouncementList /></CaseContextProvider>} />
                                            <Route path={RoutePathValues.submitEoI} element={<CaseContextProvider><InvitationOfEOIList /></CaseContextProvider>} />
                                            <Route path="/select-claim/:caseId" element={<ChooseClaimant />} />
                                            <Route path="/file-claim/:caseId" element={<CaseContextProvider><FileClaim setLoginDetails={setLoginDetails}/></CaseContextProvider>} />
                                            <Route path="/file-claim/:caseId/:cd/:activeStep/:id/:isHome/:isIndividual" element={<FCFileClaim />} />
                                            <Route path={RoutePathValues.caseManagement} element={<CaseContextProvider><CaseList /></CaseContextProvider>} />
                                                <Route path="/case-management/:caseId/*" element={
                                                    <Fragment>
                                                        <CaseContextProvider>
                                                        <div className='case-header'>
                                                            <ModuleTopBar />
                                                        </div>
                                                        <div className='case-content'>
                                                            <Routes> 
                                                                <Route path="/case-detail" element={<CaseDetailContainer />} />
                                                                <Route path="/claim" element={<ClaimContainer />} />
                                                                <Route path="/claim/pending/:cd/:activeStep/:id/:isHome/:isIndividual" element={<ClaimContainer />} />
                                                                <Route path="/committee" element={<CommitteeContainer />} />
                                                                <Route path="/form-report" element={<FormReportContainer />} />
                                                                <Route path="/asset" element={<AssetContainer />} />
                                                                <Route path="/vdr" element={<VdrContainer />} />
                                                                <Route path="/finance" element={<FinanceContainer />} />
                                                                <Route path="/resolution-plan" element={<ResolutionPlanContainer />} />
                                                                <Route path="/liquidation" element={<LiquidationContainer />} />
                                                                <Route path="/audit-trail" element={<AuditTrailContainer />} />
                                                                <Route path="/corporate-debtor" element={<CorporateDebtorContainer />} />
                                                            </Routes>
                                                        </div>
                                                        </CaseContextProvider>
                                                    </Fragment>
                                                }
                                                />
                                                <Route path={RoutePathValues.adminTools} element={
                                                    <SubscriberContext.Provider value={{ subscriberData, setSubscriberData }} >
                                                        <AdminToolsContainer setLoginDetails={setLoginDetails} />
                                                    </SubscriberContext.Provider>
                                                } />
                                                <Route path={RoutePathValues.administration} element={
                                                    <SubscriberContext.Provider value={{ subscriberData, setSubscriberData }} >
                                                        <AdministrationContainer setLoginDetails={setLoginDetails} />
                                                    </SubscriberContext.Provider>
                                                } />
                                            <Route path="*" element={<NotFound />} />
                                        </Routes>
                                    </div>
                                </Fragment>
                            } />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </div>
                </UserContext.Provider>
            </GoogleReCaptchaProvider>
        </Suspense>
    );
}

export default App;
