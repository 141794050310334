import React, { createContext, useState, useContext } from 'react';

const CaseContext = createContext();

const CaseContextProvider = ({ children }) => {
    const [contextValue, setContextValue] = useState(null);

    const updateContextValue = (newValue) => {
        setContextValue(newValue);
    };

    return (
        <CaseContext.Provider value={{ contextValue, updateContextValue }}>
            {children}
        </CaseContext.Provider>
    );
};

export {CaseContext, CaseContextProvider};