// import { styled } from "@material-ui/styles";
import { List, ListItem, ListItemIcon, ListItemText, SvgIcon } from "@mui/material";
import { styled } from "@mui/system";
import { ICMSTheme } from '../../theme/Theme';

// const imgUrl = process.env.PUBLIC_URL + '/images/sidebar5.svg'
export const SideNavBar = styled(List)(({ theme, topBarHeight, ismenucollapse }) => ({
    // position: 'fixed !important',
    // width: ismenucollapse ? '82px' : '230px',
    height: 'calc(100vh - 64px)',
    padding: '0px !important',
    cursor:'pointer',
    // transition: 'width 0.3s ease',
    zIndex: 100,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    // backgroundImage: `url(${imgUrl})`,
    backgroundColor: '#fff',
    boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.06),0px 8px 10px 1px rgba(0, 0, 0, 0.042),0px 3px 14px 2px rgba(0, 0, 0, 0.036)',
}))

export const SideNavHeader = styled('header')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '10px'
}))

export const MenuSvgIcon = styled(SvgIcon)(({ theme, menucollapse }) => ({
    position: 'absolute',
    top: '2.5%',
    right: '-8px',
    transform: menucollapse ? 'translateY(-50%) rotate(0deg)' : 'translateY(-50%) rotate(180deg)',
    height: '20px',
    width: '20px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '22px',
    cursor: 'pointer',
    transition: 'all 0.3s ease'
}))

export const MenuBar = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    gap: '0.4rem',
    marginTop: '2px'
    // '&::-webkit-scrollbar': {
    //     display: 'none'
    // }
}))

export const MenuListItem = styled(ListItem)(({ theme, isSelected }) => ({
    height: '100%',
    transition: 'all 0.6s ease',
    borderRadius: '5px !important',
    '&:hover': {
        backgroundColor: `${ICMSTheme.palette.primary.main} !important`,
        '& .icon': {
            color: 'white !important'
        },
        '& .text': {
            color: 'white !important'
        }
    },
    '& .icon': {
        color: isSelected ? 'rgb(249 241 241) !important' : 'inherit'
    },
    '& .text': {
        color: isSelected ? 'rgb(249 241 241) !important' : 'inherit'
    },
    backgroundColor: isSelected ? `${ICMSTheme.palette.primary.main} !important` : 'transparent',
})
)

export const MenuListIcon = styled(ListItemIcon)(() => ({
    color: '#707070 !important',
    minWidth: '15px !important',
    margin: '4px 0px !important',
}))

export const MenuListText = styled(ListItemText)(() => ({
    fontWeight: 'bold !important',
    color: '#707070 !important',
    margin: '4px 0px !important',
}))

export const PollListContainer = styled('div')(({ iscollapse }) => ({
    position: 'absolute',
    left: iscollapse ? '82px' : '230px',
    width: iscollapse ? 'calc(100% - 82px)' : 'calc(100% - 230px )',
    transition: 'all 0.3s ease',
}))


// export const AddressImg = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="pin"><path fill="none" stroke="#000" stroke-miterlimit="10" d="M10 2C6.7 2 4 4.8 4 8.2c0 2.7 3.6 7.4 5.2 9.4.4.5 1.1.5 1.5 0 1.6-2 5.2-6.8 5.2-9.4C16 4.8 13.3 2 10 2zm0 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path></svg>