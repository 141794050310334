import React, { useState, useEffect,
    Fragment, useContext }                          from 'react';
import {
    Stack, Grid, Typography, Card, FormLabel,
    Fab, Tooltip, ButtonGroup, Button,
}                                       from '@mui/material';
import { useTranslation }               from 'react-i18next';
import EditTwoToneIcon                  from '@mui/icons-material/EditTwoTone';
import Close                            from '@mui/icons-material/Close';
import Check                            from '@mui/icons-material/Check';
import { FaHandshakeSimple }            from "react-icons/fa6"
import { GiPayMoney }                   from "react-icons/gi"
import { 
    RelationshipType, PartyType,
    IdentificationByPartyType, Section,
    Stage
}                                       from '../../../../common/GenericCodes';
import {
    FormikLabelMuiPhone,
    FormikLabelTextField 
}                                       from "../../../../common/formik-fields-components/FormikFieldComponents"; 
import { SelectLabelController }        from '../../../../common/formik-fields-components/SelectLabelController';
import YesNoSwitchController            from "../../../../common/formik-fields-components/yes-no-switch-controller/YesNoSwitchController"; 
import { FormikNewDateTime }            from '../../../../common/formik-fields-components/FormikNewDateTime';
import { FormikLabelNumericFormat }     from '../../../../common/formik-fields-components/FormikLabelNumericFormat';
import AddressDetail                    from "../../../address/AddressDetails";
import { ICMSAxiosInterceptor }         from '../../../../config/axios.interceptor';
import { StepperSectionHeader }         from "../../../../common/icms-styled-components/StepperSectionHeader";
import ICMSLoader                       from '../../../../common/icms-loader/ICMSLoader';
import IcmsSnackbar                     from '../../../../common/icms-snackbar/IcmsSnackbar';
import { StyledCard }                   from '../../../../common/icms-styled-components/IcmsStyledComponents';
import { listClassOfCreditor }          from "../../file-claim-services/FileClaimServices";
import { setFormikValueWithLowerCase, 
    setFormikValueWithUpperCase }       from '../../../../common/utils/Utils';
import { TextAreaLabelController }      from '../../../../common/formik-fields-components/TextAreaLabelController';
import { ICMSButton }                   from '../../../../common/icms-styled-components/IcmsStyledComponents';
import { UserContext }                  from '../../../../common/context/UserContext';

const UpdateCreditorDetails = ({caseId, creditorFormik, caseStage, restore, canEdit, claimSubmission, 
        canUpdateVoteShare, isHomeBuyer}) => {
    const { t } = useTranslation();
    const userDetails = useContext(UserContext)
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [classOfCreditor, setClassOfCreditor] = useState([]);
    const [classAuthReps, setClassAuthReps] = useState([]);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});

    const getClassOfCreditors = () => {
        setLoading(true);
        listClassOfCreditor(caseId, (response) => {
                setClassOfCreditor(response.items)
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            }
        )
    }

    const handleSave = () => {
        setLoading(true);
        let payload = {
            ...creditorFormik.values,
            relatedUnderSection: creditorFormik.values.relatedUnderSection ? Section.SECTION_21_2 : '',
            creditorClasses: creditorFormik.values.creditorClasses ? [creditorFormik.values.creditorClasses] : []
        }
        ICMSAxiosInterceptor.put(`case/${caseId}/claimant/${creditorFormik.values.claimantId}/case-stage/${caseStage}`, 
                payload).then(response => {
            setLoading(false);
            setIsEdit(false);
            setMessage({show: true, message: t('Creditor_Updated_Successfully'), severity: 'success'});
            if(restore) restore();
        }).catch(error => {
            if (error?.message) {
                setMessage({show: true, message: error?.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const cancelEdit = () => {
        setIsEdit(false);
        if(restore) restore();
    }

    const getIDLocale = (partyCd, value) => {
        let title = '';
        let identificationList = IdentificationByPartyType[partyCd];
        identificationList.forEach((item, index) => {
            if (value === item.id) {
                title = item.title;
            }
        })
        return title;
    }

    const handleEmployeeTypeChange = (type) => {
        creditorFormik.setFieldTouched('regular', true);
        creditorFormik.setFieldValue('regular', type)
    }

    useEffect(() => {
        getClassOfCreditors();
    }, [])

    const handelClassOfCreditorChange = (selectedClass) => {
        creditorFormik.setFieldValue('creditorClasses', selectedClass);
        let coc = classOfCreditor.find(coc => coc.name === selectedClass);
        if (coc) setClassAuthReps(coc.authorizedRepresentativeCreditorClass);
    }

    useEffect(() => {
        if (creditorFormik.values.creditorClasses) {
            handelClassOfCreditorChange(creditorFormik.values.creditorClasses);
        }
    }, [classOfCreditor])
    

    return (
        <Stack width="100%" spacing={2}>
            <Stack>
                <Card sx={{padding: '8px'}}>
                    {canEdit && <Stack p={1} spacing={1} direction='row' justifyContent='flex-end' >
                        {!isEdit && <Tooltip title={t('Edit')}>
                            <Fab id='edit' data-testid='edit' style={{ float: 'right', width: '40px', height: '40px', margin: '5px'}} 
                                onClick={() => {setIsEdit(true)}} 
                            >
                                <EditTwoToneIcon color="primary" />
                            </Fab>
                        </Tooltip>}
                        {isEdit && <Tooltip title={t('Save')}>
                            <Fab data-testid='save' id='save'
                                style={{ float: 'right', width: '40px', height: '40px', margin: '5px'}} 
                                onClick={handleSave} disabled={!creditorFormik.isValid || !creditorFormik.dirty}
                            >
                                <Check color="success" />
                            </Fab>
                        </Tooltip>}
                        {isEdit && <Tooltip title={t('Cancel')}>
                            <Fab id='cancel' data-testid='cancel' style={{ float: 'right', width: '40px', height: '40px', margin: '5px'}} 
                                onClick={cancelEdit}
                            >
                                <Close color="error" />
                            </Fab>
                        </Tooltip>}
                    </Stack>}
                    <Grid container spacing={2}>
                        {(RelationshipType.EMPLOYEE_CLAIMANT === creditorFormik.values.relationshipCd 
                                || RelationshipType.WORKMAN_CLAIMANT === creditorFormik.values.relationshipCd) &&
                            <Grid item xs={3}>
                                <FormikLabelTextField
                                    id="serialNumber"
                                    fieldName="serialNumber"
                                    label={RelationshipType.EMPLOYEE_CLAIMANT === creditorFormik.values.relationshipCd ? t('Employee_ID') : t('Workman_ID')}
                                    formik={creditorFormik}
                                    size="small"
                                    required={true}
                                    handleChange={creditorFormik.handleChange}
                                    handleBlur={creditorFormik.handleBlur}
                                    disabled={!isEdit}
                                />
                            </Grid>
                        }
                        <Grid item xs={4}>
                            <FormikLabelTextField
                                id="name"
                                fieldName="name"
                                label={PartyType.INDIVIDUAL === creditorFormik.values.partyCd ? t('Name') : 
                                    creditorFormik.values.partyLocale + ' ' + t('Name')}
                                formik={creditorFormik}
                                size="small"
                                required={true}
                                handleChange={creditorFormik.handleChange}
                                handleBlur={creditorFormik.handleBlur}
                                disabled={!isEdit || 
                                    (creditorFormik.values.loginUid ? userDetails?.loginDetails.loginUuid !== creditorFormik.values.loginUid : false)}
                            />
                        </Grid>
                        {/* {(RelationshipType.EMPLOYEE_CLAIMANT === creditorFormik.values.relationshipCd || RelationshipType.WORKMAN_CLAIMANT === creditorFormik.values.relationshipCd) &&
                            <Grid item xs={2}>
                                <FormikNewDateTime
                                    id='dobDoi'
                                    fieldName='dobDoi'
                                    formik={creditorFormik}
                                    maxDate={(new Date())}
                                    label={t('Appointment_Date')}
                                    required={true}
                                    handleChange={creditorFormik.handleChange}
                                    handleBlur={creditorFormik.handleBlur}
                                    size='small'
                                    disabled={!isEdit}
                                    onlyDate={true}
                                />
                        </Grid>} */}
                        {(RelationshipType.EMPLOYEE_CLAIMANT === creditorFormik.values.relationshipCd || RelationshipType.WORKMAN_CLAIMANT === creditorFormik.values.relationshipCd) &&
                            <Grid item xs={2}>
                                <FormLabel sx={{ fontSize: (theme) => theme.typography.body2 }}>
                                    {RelationshipType.EMPLOYEE_CLAIMANT === creditorFormik.values.relationshipCd && <Fragment>{t('Employee_Type')}</Fragment>}
                                    {RelationshipType.WORKMAN_CLAIMANT === creditorFormik.values.relationshipCd && <Fragment>{t('Workman_Type')}</Fragment>}
                                </FormLabel>
                                <ButtonGroup variant="outlined" aria-label="employee-type" size="small" disabled={!isEdit}>
                                    <ICMSButton color='primary' 
                                        variant={creditorFormik.values.regular ? "contained" : "outlined"}
                                        onClick={() => handleEmployeeTypeChange(true)}>{t('Regular')}</ICMSButton>
                                    <ICMSButton color='primary' 
                                        variant={!creditorFormik.values.regular ? "contained" : "outlined"}
                                        onClick={() => handleEmployeeTypeChange(false)}>{t('Casual')}</ICMSButton>
                                </ButtonGroup>
                        </Grid>}
                        {PartyType.INDIVIDUAL !== creditorFormik.values.partyCd && PartyType.BANK !== creditorFormik.values.partyCd &&
                            <Grid item xs={PartyType.PROPBBUSINESS === creditorFormik.values.partyCd ? 2 : 3}>
                                <FormikNewDateTime
                                    id='dobDoi'
                                    fieldName='dobDoi'
                                    formik={creditorFormik}
                                    maxDate={(new Date())}
                                    label={t('Date_Of_Incorporation')}
                                    required={!!(PartyType.INDIVIDUAL !== creditorFormik.values.partyCd 
                                        && PartyType.BANK !== creditorFormik.values.partyCd)}
                                    handleChange={creditorFormik.handleChange}
                                    handleBlur={creditorFormik.handleBlur}
                                    size='small'
                                    onlyDate={true}
                                    disabled={!isEdit ||
                                        (creditorFormik.values.loginUid ? userDetails?.loginDetails.loginUuid !== creditorFormik.values.loginUid : false)}
                                />
                        </Grid>}
                        {(PartyType.BANK !== creditorFormik.values.partyCd && PartyType.PROPBBUSINESS !== creditorFormik.values.partyCd) && 
                                IdentificationByPartyType[creditorFormik.values.partyCd].length > 1 && <Grid item xs={2}>
                            <SelectLabelController
                                id="identificationCd"
                                fieldName="identificationCd"
                                label={t("Identification_Type")}
                                formik={creditorFormik}
                                size="small"
                                required={true}
                                menuItems={IdentificationByPartyType[creditorFormik.values.partyCd]}
                                handleChange={creditorFormik.handleChange}
                                handleBlur={creditorFormik.handleBlur}
                                menuFieldId={'id'}
                                menuFieldTitle={'title'}
                                valueField={'id'}
                                disabled={!isEdit ||
                                    (creditorFormik.values.loginUid ? userDetails?.loginDetails.loginUuid !== creditorFormik.values.loginUid : false)}
                            />
                        </Grid>}
                        <Grid item xs={3}>
                            <FormikLabelTextField
                                id="identification"
                                fieldName="identification"
                                // label={`${t('Identification')} (${getIDLocale(creditorFormik.values.partyCd, 
                                //     creditorFormik.values.identificationCd)})`}
                                label={`${t('Identification')} ${getIDLocale(creditorFormik.values.partyCd, 
                                    creditorFormik.values.identificationCd) ? '(' + (getIDLocale(creditorFormik.values.partyCd, 
                                        creditorFormik.values.identificationCd)) + ')' : ''}`}
                                formik={creditorFormik}
                                size="small"
                                required={true}
                                handleChange={(event) => setFormikValueWithUpperCase(event, creditorFormik)}
                                handleBlur={creditorFormik.handleBlur}
                                disabled={!isEdit ||
                                    (creditorFormik.values.loginUid ? userDetails?.loginDetails.loginUuid !== creditorFormik.values.loginUid : false)}
                            />
                        </Grid>
                        {/* {PartyType.INDIVIDUAL !== creditorFormik.values.partyCd && PartyType.BANK !== creditorFormik.values.partyCd &&
                        <Grid item xs={PartyType.PROPBBUSINESS === creditorFormik.values.partyCd ? 2 : 3}>
                                <FormikNewDateTime
                                    id='dobDoi'
                                    fieldName='dobDoi'
                                    formik={creditorFormik}
                                    maxDate={(new Date())}
                                    label={t('Date_Of_Incorporation')}
                                    required={!!(PartyType.INDIVIDUAL !== creditorFormik.values.partyCd 
                                        && PartyType.BANK !== creditorFormik.values.partyCd)}
                                    handleChange={creditorFormik.handleChange}
                                    handleBlur={creditorFormik.handleBlur}
                                    size='small'
                                    onlyDate={true}
                                    disabled={!isEdit ||
                                        (creditorFormik.values.loginUid ? userDetails?.loginDetails.loginUuid !== creditorFormik.values.loginUid : false)}
                                />
                        </Grid>} */}
                        {PartyType.BANK !== creditorFormik.values.partyCd && <Grid item xs={4}>
                            <FormikLabelTextField
                                id="email"
                                fieldName="email"
                                label={t("Email")}
                                formik={creditorFormik}
                                size="small"
                                required={true}
                                handleChange={(event) => setFormikValueWithLowerCase(event, creditorFormik)}
                                handleBlur={creditorFormik.handleBlur}
                                disabled={!isEdit ||
                                    (creditorFormik.values.loginUid ? userDetails?.loginDetails.loginUuid !== creditorFormik.values.loginUid : false)}
                            />
                        </Grid>}
                        {PartyType.BANK !== creditorFormik.values.partyCd && <Grid item xs={3}>
                            <FormikLabelMuiPhone
                                id="phoneNumber"
                                fieldName="phoneNumber"
                                label={t("Mobile_Number")}
                                countryCodeName="extension"
                                formik={creditorFormik}
                                size="small"
                                required={true}
                                handleChange={creditorFormik.handleChange}
                                handleBlur={creditorFormik.handleBlur}
                                disabled={!isEdit ||
                                    (creditorFormik.values.loginUid ? userDetails?.loginDetails.loginUuid !== creditorFormik.values.loginUid : false)}
                            />
                        </Grid>}
                        { RelationshipType.FINANCIAL_CLAIMANT === creditorFormik.values.relationshipCd && classOfCreditor.length > 0 && isHomeBuyer &&
                            <Grid item xs={5}>
                                <SelectLabelController
                                    id="creditorClasses"
                                    fieldName="creditorClasses"
                                    label={t("Class_Of_Creditor")}
                                    formik={creditorFormik}
                                    size="small"
                                    menuItems={classOfCreditor}
                                    handleChange={(event) => {handelClassOfCreditorChange(event.target.value)}}
                                    handleBlur={creditorFormik.handleBlur}
                                    menuFieldId={'name'}
                                    menuFieldTitle={'name'}
                                    valueField={'name'}
                                    disabled={!isEdit}
                                />
                            </Grid>}
                        { RelationshipType.FINANCIAL_CLAIMANT === creditorFormik.values.relationshipCd && creditorFormik.values.creditorClasses &&
                            <Grid item xs={5}>
                                <SelectLabelController
                                    id="preferredAuthorizedRepresentativeId"
                                    fieldName="preferredAuthorizedRepresentativeId"
                                    label={t("Preferred_IP_Authorized_Representative")}
                                    formik={creditorFormik}
                                    size="small"
                                    menuItems={classAuthReps}
                                    required={creditorFormik.values.creditorClasses}
                                    handleChange={creditorFormik.handleChange}
                                    handleBlur={creditorFormik.handleBlur}
                                    menuFieldId={'arCreditorClassId'}
                                    menuFieldTitle={'authorizedRepresentative'}
                                    valueField={'arCreditorClassId'}
                                    disabled={!isEdit}
                                />
                            </Grid>}
                        </Grid>
                        <br/>
                        {(RelationshipType.EMPLOYEE_CLAIMANT !== creditorFormik.values.relationshipCd || RelationshipType.WORKMAN_CLAIMANT !== creditorFormik.values.relationshipCd) 
                            && caseStage === Stage.LIQUIDATION && <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <FormikLabelTextField
                                    id="coPartners"
                                    fieldName="coPartners"
                                    label={`${t("Co_Partners_Names")} ( ${t("Enter_Names_Separated_By_Comma")} )`}
                                    formik={creditorFormik}
                                    size="small"
                                    required={false}
                                    handleChange={creditorFormik.handleChange}
                                    handleBlur={creditorFormik.handleBlur}
                                    disabled={!isEdit}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormikLabelNumericFormat
                                    id="coPartnersClaim"
                                    fieldName="coPartnersClaim"
                                    formik={creditorFormik}
                                    size="small"
                                    label={t('Co_Partners_Claim_Rs')}
                                    isThousandSeparator={true}
                                    handleChange={creditorFormik.handleChange}
                                    handleBlur={creditorFormik.handleBlur}
                                    disabled={!isEdit}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextAreaLabelController
                                    id={'coPartnersConsideration'}
                                    fieldName='coPartnersConsideration'
                                    label={t('Co_Partners_Consideration')}
                                    formik={creditorFormik}
                                    minRows={1}
                                    maxRows={2}
                                    handleChange={creditorFormik.handleChange}
                                    handleBlur={creditorFormik.handleBlur}
                                    disabled={!isEdit}
                                />
                            </Grid>
                        </Grid>}
                    <Stack>
                        <AddressDetail addressTitle={t('Address_Details')} addressFormik={creditorFormik}
                            isDisabled={!isEdit || (creditorFormik.values.loginUid ? userDetails?.loginDetails.loginUuid !== creditorFormik.values.loginUid : false)}
                            // editMode={isEdit && (creditorFormik.values.loginUid ? userDetails?.loginDetails.loginUuid === creditorFormik.values.loginUid : true)}
                            required={true}/>
                    </Stack>
                    <StepperSectionHeader title={t('Additional_Required_Information')} />
                    <Stack direction="row" mt={2} spacing={2}>
                        <StyledCard sx={{width: '50% !important'}}>
                            <Stack direction='column' alignItems='center' justifyContent='space-between' spacing={0.5}
                                    sx={{display: 'flex', flexDirection: 'row'}}>
                                <Stack direction='column' spacing={1} mt={'5px !important'}>
                                    <Stack direction='row'>
                                        <Typography color='textSecondary' variant="body2" style={{ fontWeight: 'bold' }}>{t('Any_Mutual_Dues_To_Setoff_Rs')}</Typography>
                                    </Stack>
                                    <Stack direction='row'>
                                        <FormikLabelNumericFormat
                                            id="mutualDuesSetOff"
                                            fieldName="mutualDuesSetOff"
                                            formik={creditorFormik}
                                            size="small"
                                            isThousandSeparator={true}
                                            required={false}
                                            handleChange={creditorFormik.handleChange}
                                            disabled={!isEdit}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </StyledCard>
                        <StyledCard sx={{width: '50% !important'}}>
                            <Stack direction='column' alignItems='center' justifyContent='space-between' spacing={0.5}
                                    sx={{display: 'flex', flexDirection: 'row'}}>
                                <Stack direction='column' spacing={1} mt={'5px !important'}>
                                    <Stack direction='row'>
                                        <Typography color='textSecondary' variant="body2" style={{ fontWeight: 'bold' }}>{t('Amount_Owed_To_Corporate_Debtor_Rs')}</Typography>
                                    </Stack>
                                    <Stack direction='row'>
                                        <FormikLabelNumericFormat
                                            id="amountOwedToDebtor"
                                            fieldName="amountOwedToDebtor"
                                            formik={creditorFormik}
                                            size="small"
                                            isThousandSeparator={true}
                                            required={false}
                                            handleChange={creditorFormik.handleChange}
                                            disabled={!isEdit}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </StyledCard>
                        <StyledCard sx={{width: '50% !important'}}>
                            <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={0.5}
                                    sx={{display: 'flex', flexDirection: 'row'}}>
                                <Stack direction='column' spacing={1} mt={'5px !important'}>
                                    <Stack direction='row'>
                                        <Typography color='textSecondary' variant="body2" style={{ fontWeight: 'bold' }}>{t('Contigent_Claim_Rs')}</Typography>
                                    </Stack>
                                    <Stack direction='row'>
                                        <FormikLabelNumericFormat
                                            id="contigent"
                                            fieldName="contigent"
                                            formik={creditorFormik}
                                            size="small"
                                            isThousandSeparator={true}
                                            required={false}
                                            handleChange={creditorFormik.handleChange}
                                            disabled={!isEdit}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </StyledCard>
                        <StyledCard sx={{width: '50% !important'}}>
                            <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={0.5}
                                    sx={{display: 'flex', flexDirection: 'row'}}>
                                <Stack direction='column' spacing={1} mt={'5px !important'}>
                                    <Stack direction='row'>
                                        <Typography color='textSecondary' variant="body2" style={{ fontWeight: 'bold' }}>{t('Reconciliation_Amount_Rs')}</Typography>
                                    </Stack>
                                    <Stack direction='row'>
                                        <FormikLabelNumericFormat
                                            id="reconciliationAmount"
                                            fieldName="reconciliationAmount"
                                            formik={creditorFormik}
                                            size="small"
                                            isThousandSeparator={true}
                                            required={false}
                                            handleChange={creditorFormik.handleChange}
                                            disabled={!isEdit}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </StyledCard>
                    </Stack>
                    <Grid container spacing={2} my={1}>
                        {RelationshipType.EMPLOYEE_CLAIMANT !== creditorFormik.values.relationshipCd && RelationshipType.WORKMAN_CLAIMANT 
                                !== creditorFormik.values.relationshipCd && <Grid item xs={3}>
                            <YesNoSwitchController
                                title={t('Are_You_A_Secured_Creditor')} 
                                description={t('Secured_Creditor_Helper_Text')}
                                fieldName="secured"
                                id="secured"
                                formik={creditorFormik}
                                disabled={!isEdit}
                                required
                                />
                        </Grid>}
                        <Grid item xs={3}>
                            <YesNoSwitchController 
                                title={t('Are_You_Related_To_Corporate_Debtor')} 
                                description={t('Related_To_Corporate_Debtor_Helper_Text')}
                                fieldName="realtedParty"
                                id="realtedParty"
                                formik={creditorFormik}
                                isTextField={creditorFormik.values.realtedParty}
                                textFieldId='natureOfRelatedParty'
                                textFieldfieldName='natureOfRelatedParty'
                                textFieldlabel={t('Specify_The_Relationship')}
                                disabled={!isEdit}
                                required/>
                        </Grid>
                        <Grid item xs={3}>
                            <YesNoSwitchController 
                                title={t('Are_You_Related_Under_Section_21_2')} 
                                description={t('Related_Under_Section-21(2)_Helper_Text')}
                                fieldName="relatedUnderSection"
                                id="relatedUnderSection"
                                formik={creditorFormik}
                                disabled={!isEdit}
                                required
                            />
                        </Grid>
                        {(!claimSubmission && RelationshipType.FINANCIAL_CLAIMANT === creditorFormik.values.relationshipCd) && <Grid item xs={3}>
                            <YesNoSwitchController 
                                title={t('Voting_Right')} 
                                description={t('')}
                                fieldName="voteRight"
                                id="voteRight"
                                formik={creditorFormik}
                                disabled={!isEdit || !canUpdateVoteShare}
                                isNumberField={creditorFormik.values.voteRight}
                                textFieldId={'voteShare'}
                                textFieldfieldName={'voteShare'}
                                textFieldlabel={t('Voting_Share')}
                            />
                        </Grid>}
                    </Grid>
                    <Grid container spacing={2} my={1}>
                        <Grid item xs={12}>
                            <TextAreaLabelController
                                id={'retentionOfTitleArrangement'}
                                fieldName='retentionOfTitleArrangement'
                                label={t('Retention_Of_Title_Arrangement')}
                                formik={creditorFormik}
                                minRows={1}
                                maxRows={3}
                                handleChange={creditorFormik.handleChange}
                                handleBlur={creditorFormik.handleBlur}
                                disabled={!isEdit}
                            />
                        </Grid>
                    </Grid>        
                </Card>
            </Stack>
            {loading && <ICMSLoader show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}
            />}
        </Stack>
    )
}

export default UpdateCreditorDetails