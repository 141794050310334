import { DoneAllRounded } from "@mui/icons-material";
import { Fab, LinearProgress, Slide, Stack, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useRef, useState, useEffect } from "react";
import { useDropzone } from 'react-dropzone';
import { useTranslation } from "react-i18next";
import { FaFileUpload } from "react-icons/fa";
import { ImCheckmark, ImCross } from "react-icons/im";
import { IoCloudUpload } from "react-icons/io5";
import { FileIcon } from "../FileIcon";
import { ButtonStyled, DashedCard, FileUploadCard } from "./styles/UploadStyle";
import IcmsSnackbar             from '../icms-snackbar/IcmsSnackbar';

const UploadDropZone = (props) => {
    const {setFiles, files, acceptableFiles, disabled} = {...props, disabled: props.disabled || false};
    // const {isMultiple} = props;
    const {t} = useTranslation();
    // const [progress, setProgress] = useState(false);
    const progress = false;
    const containerRef = useRef(null);
    let uploadedFiles= [];
    let i = 0;
    const [message, setMessage] = useState({show: false, message: '', severity: ''});

    const onDrop = useCallback(acceptedFiles => {
        setFiles(acceptedFiles);
    }, [])

    const onDropRejected = useCallback(rejectedFiles => {
        setMessage({show: true, message: t('Uplod_File_Rejected') + Object.values(acceptableFiles) , severity: 'error'});
    }, [])

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone(
        {
            onDrop, noClick: true, multiple: false, onDropRejected,
            accept: acceptableFiles,
            validator: (file) => {
                const exeRegex = /\.(exe|dmg|appimage|app|sh)$/i;
                if (exeRegex.test(file.name)) {
                  return 'Executable files are not allowed';
                }
                return null;
            }
        }
    )

    const removeSelectedFiles = (filePath) => {
        setFiles(files.filter((file) => file.path !== filePath));
    }

    return (
        <Stack spacing={3} width='100%'>
            <Stack>
                <DashedCard elevation={0} {...getRootProps({ className: 'dropzone' })} id='auction-drop-file'
                    style={{
                        border: isDragActive ? '2px dashed #0a72d8' : '2px dashed #9dc3eb',
                        pointerEvents: disabled ? 'none' : 'inherit'
                    }}
                >
                    {progress && uploadedFiles.length !== files.length &&
                        <Stack direction='column'>
                            <FaFileUpload style={{ color: 'rgb(191 217 241)', fontSize: '6rem' }} />
                            <Typography color='primary' variant='h5' className='dot-loading'>{t('Uploading')}</Typography>
                        </Stack>}
                    {uploadedFiles.length === files.length && uploadedFiles.length !== 0 &&
                        <Stack direction='column' spacing={1} justifyContent='center' alignItems='center'>
                            <FaFileUpload style={{ color: 'rgb(191 217 241)', fontSize: '6rem' }} />
                            <DoneAllRounded color='success' style={{ fontSize: '2rem' }} />
                            <Typography variant='h5' color='green'>{t('Done')}</Typography>
                        </Stack>
                    }
                    {!progress && <>
                        <input className="input-zone" {...getInputProps()} data-testid='icms-input-file' />
                        <Stack spacing={1} width={{ xs: '100%' }} justifyContent='center' alignItems='center' p={2}>
                            <IoCloudUpload style={{ color: 'rgb(191 217 241)', fontSize: '6rem' }} />
                            {isDragActive ?
                                <><Typography color='primary'>{t('Release_to_drop_the_files_here')}</Typography></> :
                                <><Typography color='primary'>{ disabled ? t('Please_Select_Document_Type') : t('Drag And Drop your file here')}</Typography>
                                    {!disabled && <Typography color='primary'>{t('Or')}</Typography>}
                                    <ButtonStyled id="browse-file" variant='contained' onClick={open} disabled={disabled}>
                                        {t('Browse Files')}  
                                    </ButtonStyled>
                                </>
                            }
                        </Stack>
                    </>}
                </DashedCard>
                {/* <Stack justifyContent='space-between' direction="row" mt={0.5}>
                    <Typography variant="subtitle2" color="GrayText">Supported Format: PDF</Typography>
                    <Typography variant="subtitle2" color="GrayText">Maximum Size: 2MB</Typography>
                </Stack> */}
            </Stack>
            {files.length > 0 &&
                <Stack spacing={1} ref={containerRef}>
                    {files.map((file, index) => {
                        i = i + 100;
                        return (
                            <Slide direction='up' in={true} timeout={300 + i} container={containerRef.current} key={`file-card-${index}`}>
                                <FileUploadCard>
                                    <Stack spacing={1} direction='column'>
                                        <Stack spacing={1} direction='row'>
                                            <Stack width={{ xs: '10%' }}><FileIcon fileName={file.name} /></Stack>
                                            <Tooltip describeChild title={file.name}>
                                                <Stack width={{ xs: '75%' }}><Typography gutterBottom noWrap>{file.name}</Typography></Stack>
                                            </Tooltip>
                                            <Stack width={{ xs: '10%' }} alignItems='end'>
                                                {!(uploadedFiles.includes(file.path)) && !progress &&
                                                    <Tooltip title={t('Remove_Files')}>
                                                        <Fab id={`file-remove-${index}`} data-testid={`file-remove-${index}`} size='small' onClick={() => { removeSelectedFiles(file.path) }}
                                                            style={{
                                                                width: '25px',
                                                                background: '#ffdddd',
                                                                height: '25px', minHeight: '25px', boxShadow: '0px 3px 5px -1px rgb(185 10 10 / 20%), 0px 3px 8px 0px rgb(196 26 26 / 14%), 0px 1px 16px 0px rgb(224 111 111 / 12%)'
                                                            }}>
                                                            <ImCross color='rgb(204 22 22 / 69%)' fontSize='0.8em' />
                                                        </Fab>
                                                    </Tooltip>}
                                                {(uploadedFiles.includes(file.path)) && <ImCheckmark color='green' fontSize='1.5em' />}
                                            </Stack>
                                        </Stack>
                                        {!(uploadedFiles.includes(file.path)) && progress && <LinearProgress color='success' />}
                                    </Stack>
                                </FileUploadCard>
                            </Slide>
                        )
                    })}
                </Stack>
            }
            { message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} 
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>
            }
        </Stack>
    )
}

export { UploadDropZone };
